export function enhanceRoleName(roleName) {
  let newName = roleName.replace(/_/g, " ");
  newName = newName.replace(/^\w/, (chr) => chr.toUpperCase());

  return newName;
}

export function enhanceRoleObj(role) {
  const newName = enhanceRoleName(role.name);
  return {
    ...role,
    name: newName,
  };
}

export function enhanceRoleArray(roles) {
  return roles.map((role) => enhanceRoleObj(role));
}
