import React from "react";
import { Box, Icon, Divider, Dropdown } from "../../../components";
import Select from "react-select";
import translate from "../texts";
import Cookies from "universal-cookie";

const ALL_MEMBERS_GROUP_ID = "all_members";

const GroupMenuCollapsible = (
  groupId,
  groupName,
  members,
  expanded,
  collapseHandler,
  roles,
  updateRoleHandler,
  deleteGroup,
  editGroup,
  enableDeleteOption,
  deleteMember
) => {
  const cookies = new Cookies();

  return (
    <div key={groupId} className="collapsible-group">
      <Box dark>
        <div
          className={`group-header${expanded ? "" : " padding-bottom-16"}`}
          onClick={() => collapseHandler(groupId)}
          onKeyUp={() => collapseHandler(groupId)}
          role="presentation"
        >
          <div className="group-collapse">
            <Icon name={`chevron-${expanded ? "up" : "down"}`} />
          </div>

          <div className="group-name">{groupName}</div>

          {groupId !== ALL_MEMBERS_GROUP_ID && (
            <Dropdown autoClose className="group-edit" icon="dots-vertical">
              <button
                className="delete-group-button"
                onClick={() => deleteGroup(groupId)}
                type="button"
              >
                {translate("delete-group")}
              </button>

              <button
                className="edit-group-button"
                onClick={() => editGroup(groupId)}
                type="button"
              >
                {translate("edit-group")}
              </button>
            </Dropdown>
          )}
        </div>

        {expanded && (
          <div className="group-members">
            <Divider />
            {members.map(({ user, role: userRole }) => {
              // Prevent the user from seeing himself in the members list
              const userData = cookies.get("user");
              console.log(userData, "userData userData");
              if (user?.email !== userData?.email) {
                return (
                  <div key={user.id} className="member">
                    <div className="member-name">{user.email}</div>
                    <div className="member-role">
                      <Select
                        style={{
                          boxShadow: "none",
                          outline: "none",
                          background: "transparent",
                        }}
                        options={
                          enableDeleteOption
                            ? roles
                                .map((role) => ({
                                  id: role.id,
                                  name: translate(role.name),
                                  userId: user.id,
                                }))
                                .concat({
                                  id: -1,
                                  name: translate("remove"),
                                  userId: user.id,
                                  className: "remove-member",
                                })
                            : roles.map((role) => ({
                                id: role.id,
                                name: translate(role.name),
                                userId: user.id,
                              }))
                        }
                        value={userRole.id}
                        valueKey="id"
                        labelKey="name"
                        clearable={false}
                        onChange={(opt) =>
                          opt.id === -1
                            ? deleteMember([opt.userId])
                            : updateRoleHandler({
                                user: { id: opt.userId },
                                role: { id: opt.id },
                              })
                        }
                      />
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        )}
      </Box>
    </div>
  );
};
export default GroupMenuCollapsible;
