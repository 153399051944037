// FIXME: Potential bad use of snake case, switch everything to camel case
import React from "react";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import uuid from "uid";
import Uploader from "../../../components/Uploader/Uploader";
import BaseField from "./BaseField";
import translate from "./texts";

/**
 * @class FileUploader
 * @extends BaseField
 * @description File uploader component
 */
let fileName = "";

function getUniqFileName(name) {
  const fileExtension = name.split(".").pop();
  return `${uuid(32)}${new Date().getTime()}.${fileExtension}`;
}
export default class FileUploader extends BaseField {
  maxFileSize = this.props.settings.validation.maxFileSize;

  fileTypes =
    this.props.settings.validation.file_types ||
    this.props.settings.validation.fileTypes;

  uppy = Uppy({
    debug: false,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: this.fileTypes,
      maxFileSize: this.maxFileSize * 1000,
    },
    autoProceed: true,
    onBeforeFileAdded: (currentFile) => {
      if (currentFile.data.size > this.maxFileSize * 1000) {
        alert(`${translate("You maximized the size")} ${this.maxFileSize}`); // eslint-disable-line no-alert
      }
    },
  });

  componentDidMount() {
    super.componentDidMount();
    this.uppyUploader();
  }

  uppyUploader = () => {
    const { maxFileSize } = this.props.settings.validation;
    const maxSizeInByte = maxFileSize * 1000;

    this.uppy = Uppy({
      debug: false,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: this.fileTypes,
        maxFileSize: maxSizeInByte,
      },
      autoProceed: true,
      onBeforeFileAdded: (currentFile) => {
        if (currentFile.data.size > maxSizeInByte) {
          alert(`${translate("You maximized the size")} ${maxFileSize}`); // eslint-disable-line no-alert
        }
      },
    });

    this.uppy.use(AwsS3, {
      id: "AWsS3",
      getUploadParameters(file) {
        // Send a request to our PHP signing endpoint.
        fileName = getUniqFileName(file.name);
        return fetch(
          `${process.env.REACT_APP_AWS_S3_PRE_SIGN_URL}${encodeURI(fileName)}`,
          {
            method: "get",
          }
        )
          .then((response) => response.json())
          .then((data) => ({
            method: data.method,
            url: data.url,
            fields: {},
          }));
      },
    });

    if (document.getElementById("submit-form") !== null) {
      this.uppy.on("upload", () => {
        document.getElementById("submit-form").disabled = true;
      });

      this.uppy.on("complete", () => {
        this.onChange({
          target: {
            value: `${process.env.REACT_APP_AWS_S3_BUCKET_PATH}/${fileName}`,
          },
        });
        document.getElementById("submit-form").disabled = false;
      });
    }
  };

  render() {
    const { id } = this.Settings;
    const { required, maxFileSize } = this.props.settings.validation;
    return (
      <div className="field">
        {this.renderLabel()}
        <input
          type="hidden"
          id={id}
          name={id}
          value={this.state.value}
          required={required}
        />
        <Uploader
          uppy={this.uppy}
          note={`${translate("Max size")} ${maxFileSize / 1000}`}
        />
        {this.renderErrors()}
      </div>
    );
  }
}
