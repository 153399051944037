import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    search: "Search",
  },
  ar: {
    search: "بحث",
  },
});

strings.setLanguage(locale || "en");

const translate = (string) => strings[string];

export default translate;
