import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { updateMembersRoles } from "../../../actions/members-invitation.actions";
import * as membersActions from "../../../actions/members-management.actions";
import NewGroup from "./NewGroup";
import translate from "../texts";

class GroupCreatingContainer extends Component {
  state = {
    newGroupName: "",
    newGroupMembers: [],
    createGroupFormExpanded: false,
  };

  onNewGroupNameChange = (event) => {
    const {
      target: { value },
    } = event;

    this.setState({ newGroupName: value });
  };

  onNewGroupMembersAdd = (nextMembers) => {
    this.setState({ newGroupMembers: nextMembers });
  };

  onNewGroupClick = () => {
    this.setState((currentState) => ({
      ...currentState,
      createGroupFormExpanded: !currentState.createGroupFormExpanded,
    }));
  };

  newGroupFooterActions = () => {
    const { actions, programId } = this.props;
    const { newGroupName, newGroupMembers } = this.state;
    return [
      {
        content: translate("Save"),
        flat: true,
        onClick: () => {
          actions.createProgramMembersGroup(
            programId,
            newGroupName,
            newGroupMembers
          );
          this.resetNewGroup();
        },
      },
      {
        content: translate("Cancel"),
        flat: true,
        onClick: this.resetNewGroup,
      },
    ];
  };

  resetNewGroup = () => {
    this.setState({
      newGroupName: "",
      newGroupMembers: [],
      createGroupFormExpanded: false,
    });
  };

  render() {
    return (
      <>
        {NewGroup(
          this.state.newGroupName,
          this.onNewGroupNameChange,
          this.props.members,
          this.state.newGroupMembers,
          this.onNewGroupMembersAdd,
          this.state.createGroupFormExpanded,
          this.onNewGroupClick,
          this.newGroupFooterActions()
        )}
      </>
    );
  }
}

GroupCreatingContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  programId: PropTypes.string.isRequired,
  members: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { members } = state.membersManagement;
  const {
    program: { id: programId },
  } = state.program;

  return {
    members,
    programId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...membersActions,
      updateMembersRoles,
    },
    dispatch
  ),
});

const container = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupCreatingContainer);

export default container;
