import React from "react";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./custom-toast.scss";

const Toast = () => (
  <ToastContainer
    rtl={"en" === "ar"}
    className="toast-container"
    transition={Slide}
    closeButton={false}
    draggable={false}
    autoClose={3000}
  />
);

export default Toast;
