import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Tab, Button, Dropdown } from "../../../components";
import { Link } from "react-router-dom";
import { scroller, Element, Events } from "react-scroll";
import Permit from "../../../layouts/Authorization";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import axios from "axios";
import { isEqual } from "lodash";
import "./FunnelsTabs.scss";

const SortableItem = SortableElement(
  ({
    funnel,
    renderFunnelTab,
    closeFunnelSettings,
    activeFunnel,
    prepareRouterLink,
  }) =>
    renderFunnelTab({
      funnelId: funnel.id,
      title: funnel.name,
      rootFunnel: funnel.rootFunnel,
      link: prepareRouterLink(funnel.id),
      icon: "file-document",
      onClick: closeFunnelSettings,
      className: activeFunnel === funnel.id ? "is-active" : "",
    })
);

const SortableList = SortableContainer(
  ({
    funnels,
    renderFunnelTab,
    closeFunnelSettings,
    activeFunnel,
    prepareRouterLink,
    isActionAuthorized,
  }) => (
    <Element id="funnelsTabContainer">
      {funnels.map((funnel, index) => (
        <SortableItem
          key={`item-${funnel.id}`}
          funnel={funnel}
          index={index}
          i={index}
          renderFunnelTab={renderFunnelTab}
          closeFunnelSettings={closeFunnelSettings}
          activeFunnel={activeFunnel}
          prepareRouterLink={prepareRouterLink}
          disabled={funnel.rootFunnel || !isActionAuthorized("funnels.update")}
        />
      ))}
    </Element>
  )
);

class FunnelsTabs extends PureComponent {
  state = {
    funnels: this.props.funnels,
  };

  componentDidMount() {
    this.scrollToWithContainer();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.funnels !== newProps.funnels) {
      this.setState({
        funnels: newProps.funnels,
      });
    }
  }

  scrollToWithContainer = () => {
    const goToContainer = new Promise((resolve) => {
      Events.scrollEvent.register("end", () => {
        resolve();
        Events.scrollEvent.remove("end");
      });

      scroller.scrollTo("funnelsTabContainer", {
        duration: 0,
        smooth: true,
        horizontal: true,
      });
    });

    goToContainer.then(() =>
      scroller.scrollTo(`element-${this.props.activeFunnel}`, {
        duration: 0,
        smooth: true,
        containerId: "funnelsTabContainer",
        horizontal: true,
      })
    );
  };

  /**
   * This function will return prepared routable url for specific funnel
   */
  prepareRouterLink = (funnelId) => {
    const { path, programId } = this.props;
    let convertedURL;

    convertedURL = path.replace(":programId", programId);
    convertedURL = convertedURL.replace(":funnelId", funnelId);

    return convertedURL;
  };

  /**
   * This function will return the funnels tabs list in addition to all submission tab
   */
  createTabs = () => {
    const { funnels, activeFunnel, closeFunnelSettings } = this.props;

    return (
      <>
        {funnels.map((funnel) =>
          this.renderFunnelTab({
            funnelId: funnel.id,
            title: funnel.name,
            rootFunnel: funnel.rootFunnel,
            link: this.prepareRouterLink(funnel.id),
            icon: "file-document",
            onClick: closeFunnelSettings,
            className: activeFunnel === funnel.id ? "is-active" : "",
          })
        )}
      </>
    );
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { funnels } = this.state;
    const newSort = arrayMove(this.state.funnels, oldIndex, newIndex);
    if (!isEqual(newSort, funnels)) {
      this.setState(
        () => ({
          funnels: newSort,
        }),
        () => {
          this.saveSortingFields(this.props.funnels[oldIndex].id, newIndex);
        }
      );
    }
  };

  saveSortingFields = (funnelId) => {
    const newSortedIds = this.state.funnels.map((funnel) => funnel.id);
    axios.put(`${process.env.REACT_APP_API_URL}/funnels/${funnelId}/sort`, {
      funnelIds: newSortedIds,
    });
  };

  isActionAuthorized = (privilege) => this.props.privileges.includes(privilege);

  renderFunnelTab = ({
    funnelId,
    className,
    rootFunnel,
    onClick,
    title,
    link,
  }) => {
    const { onFunnelSettingsClick, programId, isEditing, activeFunnel } =
      this.props;

    return (
      <Element name={`element-${funnelId}`} key={funnelId}>
        <li
          key={funnelId}
          className={`funnel_tab ${className} ${
            funnelId === activeFunnel ? "active-funnel" : ""
          }`}
        >
          <Link to={link} draggable={false}>
            <div onClick={onClick} onKeyUp={onClick} role="presentation">
              {title}
            </div>
          </Link>
          <Permit targetPrivilege="funnels.update" targetId={programId}>
            {!rootFunnel && funnelId === activeFunnel && (
              <Button
                iconName="cog"
                className={`funnel_settings-btn${isEditing ? " active" : ""}`}
                onClick={() => {
                  onFunnelSettingsClick(funnelId);
                }}
                flatWhite
              />
            )}
          </Permit>
        </li>
      </Element>
    );
  };

  render() {
    const { onAddFunnelClick, programId, activeFunnel } = this.props;

    const trigger = (
      <div className="flex">
        <Button
          tiny
          flatWhite
          onClick={() => {}}
          iconName="menu"
          className="funnels-dropdown-menu"
          color="#5b718d"
        />
      </div>
    );

    return (
      <div className="funnel_tabs flex">
        <Permit targetPrivilege="funnels.create" targetId={programId}>
          <Button
            iconName="plus-circle"
            className="add_funnel-btn"
            onClick={onAddFunnelClick}
            flatWhite
            small
          />
        </Permit>

        <Dropdown trigger={trigger} autoClose className="funnels-dropdown">
          {this.state.funnels.map((funnel) => (
            <Link
              to={this.prepareRouterLink(funnel.id)}
              draggable={false}
              className={`${funnel.id === activeFunnel ? "active-funnel" : ""}`}
              key={funnel.id}
              onClick={this.scrollToWithContainer}
            >
              <div
                onClick={this.closeFunnelSettings}
                onKeyUp={this.closeFunnelSettings}
                role="presentation"
              >
                {funnel.name}
              </div>
            </Link>
          ))}
        </Dropdown>

        <Tab className="tabs">
          <SortableList
            funnels={this.state.funnels}
            renderFunnelTab={this.renderFunnelTab}
            activeFunnel={this.props.activeFunnel}
            closeFunnelSettings={this.closeFunnelSettings}
            prepareRouterLink={this.prepareRouterLink}
            onSortEnd={this.onSortEnd}
            axis="x"
            lockAxis="x"
            helperClass="funnel-sorting-active"
            pressDelay={150}
            lockToContainerEdges
            pressThreshold={20}
            isActionAuthorized={this.isActionAuthorized}
          />
        </Tab>
      </div>
    );
  }
}

FunnelsTabs.propTypes = {
  funnels: PropTypes.array.isRequired,
  activeFunnel: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
  onAddFunnelClick: PropTypes.func.isRequired,
  onFunnelSettingsClick: PropTypes.func.isRequired,
  closeFunnelSettings: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  privileges: PropTypes.array.isRequired,
};

export default FunnelsTabs;
