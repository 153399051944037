// FIXME: Potential bad use of snake case, switch everything to camel case
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { IconButton, Icon, Avatar, Button } from "../../components";
import translate from "./texts";

import "./NotificationItem.scss";

const AnnouncementNotification = (data, markRead) => (
  <div key={data.id} className="notification-box">
    <div className="notification-body">
      <div className="notification-icon">
        <Avatar />
      </div>
      <div className="notification-data">
        <div className="notification-timestamp">{data.createdAt}</div>
        <div className="notification-title">{data.extraData.title}</div>
        <div className="notification-description">{data.extraData.snippet}</div>
      </div>
    </div>
    <div className="notification-footer">
      <div className="footer-link">
        <Link to={`/programs/${data.extraData.programId}`}>
          <Icon name="layer" />
          <span>{translate("view_announcement")}</span>
        </Link>
      </div>
      <div className="footer-read">
        {data.status === "unread" && (
          <IconButton
            flat
            iconName="check"
            className="btn-mark-read"
            onClick={() => markRead(data)}
          />
        )}
      </div>
    </div>
  </div>
);
const CommentNotification = (data, markRead) => (
  <div key={data.id} className="notification-box">
    <div className="notification-body">
      <div className="notification-icon">
        <Icon name="bullhorn" />
      </div>
      <div className="notification-data">
        <div className="notification-timestamp">{data.createdAt}</div>
        <div className="notification-title">
          {data.extraData.ownerName} {translate("has_commented")}{" "}
          {data.extraData.submissionFriendlyId}
        </div>
        <div className="notification-description">{data.extraData.snippet}</div>
      </div>
    </div>
    <div className="notification-footer">
      <div className="footer-link">
        <Link
          to={{
            pathname: `/programs/${data.extraData.programId}/submissions/page/1/${data.extraData.submissionId}`,
            state: { fromCommentNotification: true },
          }}
        >
          <Icon name="layer" />
          <Button
            className="goto_submission_btn"
            flat
            content={translate("go_to_submission")}
            onClick={() => data.status === "unread" && markRead(data)}
          />
        </Link>
      </div>
      <div className="footer-read">
        {data.status === "unread" && (
          <IconButton
            flat
            iconName="check"
            className="btn-mark-read"
            onClick={() => markRead(data)}
          />
        )}
      </div>
    </div>
  </div>
);
const AssignmentNotification = (data, markRead) => (
  <div key={data.id} className="notification-box">
    <div className="notification-body">
      <div className="notification-icon">
        <Icon name="bullhorn" />
      </div>
      <div className="notification-data">
        <div className="notification-timestamp">{data.createdAt}</div>
        <div className="notification-title">
          {translate("have_been_assigned")} {data.extraData.submissionCount}{" "}
          {translate("submissions")}
        </div>
      </div>
    </div>
    <div className="notification-footer">
      <div className="footer-link">
        <Link to={`/programs/${data.extraData.programId}/submissions/page/1`}>
          <Icon name="layer" />
          <Button
            className="goto_submission_btn"
            flat
            content={translate("go_to_submission")}
            onClick={() => data.status === "unread" && markRead(data)}
          />
        </Link>
      </div>
      <div className="footer-read">
        {data.status === "unread" && (
          <IconButton
            flat
            iconName="check"
            className="btn-mark-read"
            onClick={() => markRead(data)}
          />
        )}
      </div>
    </div>
  </div>
);

const ScoringCardNotification = (data, markRead) => (
  <div key={data.id} className="notification-box">
    <div className="notification-body">
      <div className="notification-icon">
        <Avatar />
      </div>
      <div className="notification-data">
        <div className="notification-timestamp">{data.createdAt}</div>
        <div className="notification-title">
          {translate("scoring_card_title")}
        </div>
        <div className="notification-description">
          {translate("scoring_card_updated")}
        </div>
      </div>
    </div>
    <div className="notification-footer">
      <div className="footer-link">
        <Link to={`/programs/${data.extraData.programId}/submissions/page/1`}>
          <Icon name="layer" />
          <span>{translate("go_to_submissions")}</span>
        </Link>
      </div>
      <div className="footer-read">
        {data.status === "unread" && (
          <IconButton
            flat
            iconName="check"
            className="btn-mark-read"
            onClick={() => markRead(data)}
          />
        )}
      </div>
    </div>
  </div>
);

export default class NotificationItem extends PureComponent {
  render() {
    const { data, markRead } = this.props;
    return (
      <div className={data}>
        {(data.type === "comment_submission" &&
          CommentNotification(data, markRead)) ||
          (data.type === "announcement" &&
            AnnouncementNotification(data, markRead)) ||
          (data.type === "submission_assignment" &&
            AssignmentNotification(data, markRead)) ||
          (data.type === "update_scoring_card" &&
            ScoringCardNotification(data, markRead))}
      </div>
    );
  }
}

NotificationItem.propTypes = {
  data: PropTypes.object.isRequired,
  markRead: PropTypes.func,
};

NotificationItem.defaultProps = {
  markRead: null,
};
