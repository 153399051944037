import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Input, Button, Select } from "../../../components";
import { parseFilters } from "../../../utils/utils";

import translate from "../texts";
import "../AdvancedSearch.scss";

class SaveFilter extends PureComponent {
  state = {
    newFilterName: "",
    selectedFilterAction: "default",
  };

  updateNewFilterName = (e) => {
    this.setState({ newFilterName: e.target.value });
  };

  saveFilters = () => {
    const { newFilterName, selectedFilterAction } = this.state;
    const { programId, actions, funnelId, userSelectedFilters } = this.props;
    const selectedFiltersArr = parseFilters(userSelectedFilters);
    const filterActions = [
      { name: selectedFilterAction, type: selectedFilterAction },
    ];

    if (
      ![undefined, ""].includes(newFilterName) &&
      selectedFiltersArr.length !== 0
    ) {
      actions.saveFilter(
        programId,
        parseFilters(userSelectedFilters),
        newFilterName,
        filterActions,
        funnelId
      );
    }
    this.props.closeSearchControls();
  };

  onTriggerTypeChange = (value) => {
    this.setState({ selectedFilterAction: value });
  };

  render() {
    const { newFilterCollapsed, toggleNewFilter, filterActions } = this.props;

    const { newFilterName, selectedFilterAction } = this.state;

    const filterActionsOptions = filterActions.map((filterAction) => ({
      label: filterAction.name,
      value: filterAction.name,
    }));

    return (
      <>
        {!newFilterCollapsed && (
          <>
            <div className="my-4 mx-6 new-filter">
              <Input
                value={newFilterName}
                onChange={this.updateNewFilterName}
                placeholder={translate("Name your saved search")}
                inputId="filter-name"
                label="Name"
                autoFocus
              />
            </div>
            {/* TODO: it's not used yet, it can be removed. */}
            <div className="hidden action">
              <h2>Action</h2>
              <div className="type">
                <h3>Type</h3>
                <Select
                  name="form-field-name"
                  value={selectedFilterAction}
                  onChange={(e) => this.onTriggerTypeChange(e.value)}
                  options={filterActionsOptions}
                  placeholder="type"
                />
              </div>
            </div>
          </>
        )}
        {!newFilterCollapsed && (
          <div className="advanced-search-footer flex">
            <>
              <Button
                tiny
                flat
                onClick={toggleNewFilter}
                iconName="chevron-left"
                className="chevron-left p-0"
              />
              <Button
                onClick={this.saveFilters}
                content={translate("create-filter")}
                className="filter ms-auto"
                small
                disabled={newFilterName === ""}
              />
            </>
          </div>
        )}
      </>
    );
  }
}

SaveFilter.propTypes = {
  newFilterCollapsed: PropTypes.bool.isRequired,
  closeSearchControls: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  toggleNewFilter: PropTypes.func.isRequired,
  userSelectedFilters: PropTypes.object.isRequired,
  funnelId: PropTypes.string.isRequired,
  filterActions: PropTypes.array.isRequired,
};

export default SaveFilter;
