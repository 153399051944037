import axios from "axios";
import * as types from "../types/funnelAccess.types";

export function fetchFunnelPublicAccess(funnelId) {
  return {
    type: types.FETCH_FUNNEL_PUBLIC_ACCESS,
    payload: axios.get(
      `${process.env.REACT_APP_API_URL}/funnels/${funnelId}/public_access`
    ),
  };
}

export function setFunnelPublicAccess(funnelId) {
  return {
    type: types.SET_FUNNEL_PUBLIC_ACCESS,
    payload: axios.put(
      `${process.env.REACT_APP_API_URL}/funnels/${funnelId}/public_access`
    ),
  };
}
