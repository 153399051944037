import React from "react";
import BaseOptions from "./BaseOptions";

/**
 * @class CountryFieldOptions
 * @extends BaseOptions
 * @description A component which contains a withCity, title and required field options
 */
export default class CountryFieldOptions extends BaseOptions {
  render() {
    return (
      <div>
        {this.renderTitle()}
        {this.renderCityTitle()}
        {this.renderRequiredOption()}
        {this.renderVisibilityOption()}
        {this.renderWithCityCheck()}
      </div>
    );
  }
}
