import Text from "./fields/Text";
import Textarea from "./fields/Textarea";
import Radio from "./fields/Radio";
import Checkbox from "./fields/Checkbox";
import Select from "./fields/Select";
import Divider from "./fields/Divider";
import Number from "./fields/Number";
import Countries from "./fields/Countries";
import FileUploader from "./fields/FileUploader";
import StarRating from "./fields/StarRating";
import DateField from "./fields/DateField";
import TimeField from "./fields/TimeField";
import Paragraph from "./fields/Paragraph";

/**
 * @class FieldTypes
 * @description Contains a component which can be generated from the FieldFactory
 */
const FieldTypes = {
  Text: {
    component: Text,
    name: "Text",
  },
  Textarea: {
    component: Textarea,
    name: "Textarea",
  },
  Radio: {
    component: Radio,
    name: "Radio",
  },
  Checkbox: {
    component: Checkbox,
    name: "Checkbox",
  },
  Select: {
    component: Select,
    name: "Select",
  },
  Number: {
    component: Number,
    name: "Number",
  },
  FileUploader: {
    component: FileUploader,
    name: "FileUploader",
  },
  Divider: {
    component: Divider,
    name: "Divider",
  },
  Countries: {
    component: Countries,
    name: "Countries",
  },
  StarRating: {
    component: StarRating,
    name: "StarRating",
  },
  DateField: {
    component: DateField,
    name: "DateField",
  },
  TimeField: {
    component: TimeField,
    name: "TimeField",
  },
  Paragraph: {
    component: Paragraph,
    name: "Paragraph",
  },
};

export default FieldTypes;
