import React, { Component } from "react";
import { toast } from "react-toastify";
// import "uppy/dist/uppy.min.css";
import PropTypes from "prop-types";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import Uploader from "../../../components/Uploader/Uploader";
import translate from "../texts";
import uuid from "uid";

function getUniqFileName(name) {
  const fileExtension = name.split(".").pop();
  return `${uuid()}${new Date().getTime()}.${fileExtension}`;
}

let fileName = "";

class LogoUploader extends Component {
  uppy = Uppy({
    debug: false,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*"],
      maxFileSize: this.props.maxSize * 1000,
    },
    autoProceed: true,
    onBeforeFileAdded: (currentFile) => {
      if (currentFile.data.size > this.props.maxSize * 1000) {
        alert(`${translate("You maximized the size")} ${this.props.maxSize}`); // eslint-disable-line no-alert
      }
    },
  });

  componentDidMount() {
    this.uppyUploader();
  }

  uppyUploader = () => {
    this.uppy.on("complete", (result) => {
      this.props.onComplete(fileName);
    });
    this.uppy.use(AwsS3, {
      id: "AWsS3",
      getUploadParameters(file) {
        // Send a request to our PHP signing endpoint.
        fileName = getUniqFileName(file.name);
        return fetch(
          `${process.env.REACT_APP_AWS_S3_PRE_SIGN_URL}${encodeURI(fileName)}`,
          {
            method: "get",
          }
        )
          .then((response) => response.json())
          .then((data) => ({
            method: data.method,
            url: data.url,
            fields: {},
          }))
          .catch((err) => toast.error(err.message));
      },
    });
  };

  render() {
    return <Uploader uppy={this.uppy} note={`${translate("Max size")} 1000`} />;
  }
}

LogoUploader.propTypes = {
  onComplete: PropTypes.func.isRequired,
  maxSize: PropTypes.string,
};

LogoUploader.defaultProps = {
  maxSize: "1000",
};

export default LogoUploader;
