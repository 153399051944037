import React from "react";
import Select from "react-select";
import { Icon } from "../../components";
import "./IconMenu.scss";

/*
use this component as follows:
1- Pass any props that you would pass to a normal <Select />.
2- Pass `iconName` to choose the desired icon.
*/

const IconMenuOption = (opt) => {
  const { iconName, name, iconSvg, svgSrc } = opt;

  return (
    <div className="option-with-icon">
      <div className="option-icon">
        {iconSvg ? <img src={svgSrc} alt={name} /> : <Icon name={iconName} />}
      </div>
      <div className="option-label">{name}</div>
    </div>
  );
};

const IconMenu = (props) => (
  <div className="icon-menu-component">
    <Select
      {...props}
      arrowRenderer={null}
      clearable={false}
      style={{
        boxShadow: "none",
        outline: "none",
        borderRadius: "none",
      }}
      isOpen
      optionRenderer={IconMenuOption}
      valueRenderer={IconMenuOption}
    />
  </div>
);

export default IconMenu;
