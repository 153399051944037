import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route as RouteDOM,
  useParams,
  useHistory,
  Switch,
  Link,
} from "react-router-dom";
import {
  Button,
  Wallpaper,
  Modal,
  FixedHeader,
  Select,
  spinner,
  NormalSearch,
} from "../../components";
import * as Sentry from "@sentry/react";
import Workspaces from "./Workspaces";
import Programs from "./Programs";
import translate from "./texts";
import "./WorkspacesContainer.scss";
import ProgramSettingsModal from "../Program/ProgramSettingsModal/ProgramSettingsModal";

const Route = Sentry.withSentryRouting(RouteDOM);

const WorkspacesContainer = () => {
  const [newProgramModalActive, setNewProgramModalActive] = useState(false);
  const [archivedView, setArchivedView] = useState(false);
  const [filter, setFilter] = useState("");
  const [showNewProgramBtn, setShowNewProgramBtn] = useState(true);
  const [activeWorkspaceName, setActiveWorkspaceName] = useState("");
  const [refetch, setRefetch] = useState(true);
  const [clearSearch, setClearSearch] = useState(true);

  const { fetched } = useSelector((state) => state.workspaces);

  const { workspaceId } = useParams();

  const toggleNewProgramModal = () => {
    setNewProgramModalActive(!newProgramModalActive);
  };

  const Breadcrumb = () => (
    <ul className="bread-crumb pb-4">
      <li className={workspaceId ? "clickable" : ""}>
        <Link className="link-button" to={{ pathname: "/dashboard" }}>
          {translate("Workspaces")}
        </Link>
      </li>
      {workspaceId && <li className="p-view">{activeWorkspaceName}</li>}
    </ul>
  );

  const Header = () => (
    <div className="workspaces-search flex py-6">
      <NormalSearch
        doSearch={() => setRefetch(true)}
        updateQuery={(query) => setFilter(query)}
        className="me-4"
        clear={clearSearch}
      />
      <Select
        options={[
          { label: translate("all_programs"), value: false },
          { label: translate("archived_programs"), value: true },
        ]}
        onChange={(target) => {
          setArchivedView(target.value);
          setRefetch(true);
        }}
        value={archivedView}
        clearable={false}
        className="w-40 me-auto"
      />
      {showNewProgramBtn && (
        <div className="workspaces-new-program-button">
          <Button
            content={translate("New Program")}
            onClick={() => toggleNewProgramModal()}
            className="shadow-none"
          />
        </div>
      )}
    </div>
  );

  useEffect(() => {
    if (filter || fetched) spinner.hide();
    else spinner.show();
  }, [fetched]);

  useEffect(() => {
    setClearSearch(false);
  }, [clearSearch]);

  useEffect(() => {
    setRefetch(true);
    setClearSearch(!clearSearch);
    setShowNewProgramBtn(!workspaceId);
    setClearSearch(!clearSearch);
    setFilter("");
  }, [workspaceId]);

  useEffect(() => {
    if (refetch) {
      setRefetch(!refetch);
    }
  }, [filter]);

  return (
    <Wallpaper>
      <ProgramSettingsModal
        modalActive={newProgramModalActive}
        setModalActive={setNewProgramModalActive}
        createNew={true}
      />
      <FixedHeader isWorkspaceHeader />
      <div className="workspaces-container">
        {Header()}
        <div className="workspace">
          <Breadcrumb />
          <Switch>
            <Route exact path="/dashboard">
              <Workspaces
                archivedView={archivedView}
                filter={filter}
                refetch={refetch}
              />
            </Route>
            <Route path="/dashboard/workspaces/:workspaceId/:workspaceIndex">
              <Programs
                setActiveWorkspaceName={(name) => setActiveWorkspaceName(name)}
                setShowNewProgramBtn={(boolean) =>
                  setShowNewProgramBtn(boolean)
                }
                archivedView={archivedView}
                filter={filter}
                refetch={refetch}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </Wallpaper>
  );
};

export default WorkspacesContainer;
