import React from "react";
import { Button } from "../../components";
import PropTypes from "prop-types";
import "./FooterActions.scss";

const FooterActions = ({ children, actions }) => (
  <div className="footer-actions">
    <div>{children}</div>
    {actions.map((action) => (
      <Button key={action.content} disabled={false} {...action} />
    ))}
  </div>
);

FooterActions.propTypes = {
  children: PropTypes.any,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
};

FooterActions.defaultProps = {
  children: [],
};

export default FooterActions;
