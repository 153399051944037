import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  activatePromptMessage,
  deactivatePromptMessage,
} from "../../../actions/prompt-message.actions";
import { updateMembersRoles } from "../../../actions/members-invitation.actions";
import * as membersActions from "../../../actions/members-management.actions";
import translate from "../texts";
import GroupManagement from "./GroupManagement";

class GroupManagementContainer extends Component {
  state = {
    groupsExpandStatus: {},
    groupToEditId: "",
  };

  UNSAFE_componentWillMount() {
    const { actions, programId } = this.props;
    actions.fetchProgramMembers(programId);
    actions.fetchProgramGroups(programId);
  }

  onGroupCollapseClick = (groupId) => {
    this.setState((currentState) => ({
      ...currentState,
      groupsExpandStatus: {
        ...currentState.groupsExpandStatus,
        [groupId]: !currentState.groupsExpandStatus[groupId],
      },
    }));
  };

  updateMemberRole = (member) => {
    const { actions, programId } = this.props;
    actions.updateMembersRoles(programId, [member]).then(() => {
      actions.fetchProgramMembers(programId);
      actions.fetchProgramGroups(programId);
    });
  };

  deleteGroup = (groupId) => {
    const { actions, programId } = this.props;
    actions.activatePromptMessage({
      title: translate("score-card-edit-prompt-title"),
      message: translate("editing-score-card-message"),
      confirmationHandler: () => {
        actions
          .deleteProgramMembersGroup(programId, groupId)
          .then(() => actions.fetchProgramGroups(programId));
        actions.deactivatePromptMessage();
      },
    });
  };

  editGroup = (groupId) => {
    this.setState({
      groupToEditId: groupId,
    });
  };

  cancelGroupEdit = () => {
    this.setState({
      groupToEditId: "",
    });
  };

  saveGroupEdits = (name, members) => {
    const { groupToEditId } = this.state;
    const { programId, actions } = this.props;

    actions
      .updateProgramMembersGroup(programId, groupToEditId, name, members)
      .then(() => {
        actions.fetchProgramGroups(programId);
        this.cancelGroupEdit();
      });
  };

  deleteMember = (membersId) => {
    const { programId, actions, members } = this.props;
    const userToBeRemoved = members.find(
      (item) => item.user.id === membersId[0]
    );
    const userName = userToBeRemoved.user.name || userToBeRemoved.user.email;

    actions.activatePromptMessage({
      title: `${translate("remove")} ${userName}${translate("question_mark")}`,
      message: `${translate("remove_member_modal_message_part_1")} 
      ${userName} 
      ${translate("remove_member_modal_message_part_2")}`,
      confirmationHandler: () => {
        actions
          .deleteProgramMember(programId, membersId)
          .then(() => actions.fetchProgramMembers(programId));
        actions.deactivatePromptMessage();
      },
    });
  };

  render() {
    return (
      <GroupManagement
        groups={this.props.groups}
        members={this.props.members}
        roles={this.props.roles}
        groupsExpandStatus={this.state.groupsExpandStatus}
        groupToEditId={this.state.groupToEditId}
        saveGroupEdits={this.saveGroupEdits}
        cancelGroupEdit={this.cancelGroupEdit}
        onGroupCollapseClick={this.onGroupCollapseClick}
        updateMemberRole={this.updateMemberRole}
        editGroup={this.editGroup}
        deleteGroup={this.deleteGroup}
        deleteMember={this.deleteMember}
      />
    );
  }
}

GroupManagementContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  programId: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  members: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { groups, members } = state.membersManagement;
  const { roles } = state.membersInvitation;
  const {
    program: { id: programId },
  } = state.program;

  return {
    groups,
    members,
    programId,
    roles,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...membersActions,
      updateMembersRoles,
      activatePromptMessage,
      deactivatePromptMessage,
    },
    dispatch
  ),
});

const container = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupManagementContainer);

export default container;
