import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  toggleProgramMembersModal,
  displayMembersInvitation,
} from "../../actions/program-members-modal.actions";

import { FooterActions } from "../../components";
import GroupManagementContainer from "./GroupManagement/GroupManagementContainer";
import GroupCreatingContainer from "./GroupCreating/GroupCreatingContainer";
import translate from "./texts";

class MembersManagementContainer extends Component {
  membersManagementActions = () => {
    const { actions } = this.props;
    return [
      {
        content: translate("Done"),
        flat: false,
        onClick: actions.toggleProgramMembersModal,
      },
    ];
  };

  render() {
    return (
      <div id="MembersManagementContainer">
        <GroupManagementContainer />
        <GroupCreatingContainer />

        <FooterActions actions={this.membersManagementActions()} />
      </div>
    );
  }
}

MembersManagementContainer.propTypes = {
  actions: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      toggleProgramMembersModal,
      displayMembersInvitation,
    },
    dispatch
  ),
});

const container = connect(
  mapStateToProps,
  mapDispatchToProps
)(MembersManagementContainer);

export default container;
