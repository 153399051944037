import uuid from "uid";
import { tint, desaturate } from "polished";
import convert from "color-convert";

let reactKey = 0;

export function generateReactKey() {
  reactKey += 1;
  return reactKey;
}

export function humanizeField(field) {
  const prepositions =
    /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i;
  const alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/;

  // Replace all underscores with space
  field = field.replace(/[_-]/g, " ");

  return field
    .split(/( )/)
    .map((current, index, array) => {
      if (
        // Check for prepositions
        current.search(prepositions) > -1 &&
        // Skip first and last word
        index !== 0 &&
        index !== array.length - 1 &&
        // Ignore title end and subtitle start
        array[index - 3] !== ":" &&
        array[index + 1] !== ":"
      ) {
        return current.toLowerCase();
      }

      // Change words such as "id" entirely to uppercase
      if (
        current.toLowerCase() === "id" ||
        current.toLowerCase() === "ar" ||
        current.toLowerCase() === "en"
      ) {
        return current.toUpperCase();
      }

      // Change the prepositions to uppercase if was first or last word
      if (
        current.search(prepositions) > 1 &&
        (index === 0 || index === array.length - 1)
      ) {
        return current.toUpperCase();
      }

      // Round ID numbers with parenthesis
      if (index === array.length - 1 && /^\d+$/.test(current)) {
        return `(${current})`;
      }

      // Capitalize the first letter
      return current.replace(alphanumericPattern, (match) =>
        match.toUpperCase()
      );
    })
    .join("");
}

export function uniqueArrayOfObjects(arr, prop) {
  const unique = [];
  const ledger = {};
  arr.forEach((item) => {
    if (!ledger[item[prop]]) {
      unique.push(item);
    }
    ledger[item[prop]] = true;
  });
  return unique;
}

export function delObjKey(obj, key) {
  // This is an un-mutating way of deleting a key from object.
  // See more: https://stackoverflow.com/questions/33053310/remove-value-from-object-without-mutating-it
  const { [key]: omit, ...rest } = obj;
  return rest;
}

export function direction() {
  if (document.dir !== undefined) {
    return document.dir;
  }

  const dir = document.getElementsByTagName("html")[0].getAttribute("dir");

  if (dir == null) {
    return "ltr";
  }

  return dir;
}

export function isRTL() {
  return direction() === "rtl";
}

export function parseFilter(filter) {
  const { field } = filter;
  if (filter.operator === "_null" || filter.operator === "not_null") {
    return [
      field.id,
      filter.operator,
      // it must have a value
      filter.operator,
      field.category,
      field.type,
    ];
  }

  return [
    field.id,
    filter.operator,
    // To insure that timezone is set correctly
    filter.value && filter.value.toString(),
    field.category,
    field.type,
  ];
}

export function isFiltersFilled(selectedFilters) {
  return Object.values(selectedFilters).every((filtron) => {
    if (filtron.operator === "_null" || filtron.operator === "not_null") {
      return filtron.operator && filtron.field;
    }

    return filtron.operator && filtron.value && filtron.field;
  });
}

export function parseFilters(selectedFilters) {
  return Object.values(selectedFilters)
    .map(parseFilter)
    .filter((filtron) => {
      return !filtron.includes("") && !filtron.includes(undefined);
    });
}

export function unparseFilters(filtersArr, fields) {
  const result = {};

  filtersArr.forEach((item) => {
    const field = fields.filter((f) => f.id === item[0])[0];
    if (field === undefined) {
      return;
    }
    const filterId = uuid();
    result[filterId] = {
      id: filterId,
      field,
      operator: item[1],
      value: item[2],
    };
  });
  return result;
}

export function isURL(str) {
  if (typeof str !== "string") return false;

  const pattern =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
  return pattern.test(str);
}

export function getScoreTitle(funnelId, rootFunnelId) {
  return funnelId === rootFunnelId ? "score" : "Funnel Score";
}

// TODO: Find a clean way to share avaliable locales between client & server
export const availableLocales = [
  { code: "en", language: "English" },
  { code: "ar", language: "العربيّة" },
];

export const OBJECT_ID_REGEX = /^[a-f\d]{24}$/i;
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export function eventPropagationPath(event) {
  const path = (event.composedPath && event.composedPath()) || event.path;
  const { target } = event;

  if (path != null) {
    // Safari doesn't include Window, but it should.
    return path.indexOf(window) < 0 ? path.concat(window) : path;
  }

  if (target === window) {
    return [window];
  }

  function getParents(node, memo) {
    memo = memo || [];
    const { parentNode } = node;

    if (!parentNode) {
      return memo;
    }

    return getParents(parentNode, memo.concat(parentNode));
  }

  return [target].concat(getParents(target), window);
}

export function prepend(value, array) {
  const newArray = array.slice();
  newArray.unshift(value);
  return newArray;
}

export function backgroundColorFallback(color) {
  return color
    ? `linear-gradient(to bottom right, ${desaturate(
        0.2,
        tint(0.8, color)
      )}, ${tint(0.2, color)})`
    : "linear-gradient(white, rgba(0, 0, 0, .01))";
}

export function lightAdjuster(color, light = 70) {
  // recieves a HEX color and return a lightened HEX color based on the light value.
  const hsl = convert.rgb.hsl(convert.hex.rgb(color.slice(1)));
  hsl[2] = light;
  return `#${convert.rgb.hex(convert.hsl.rgb(hsl))}`;
}

export function convertHinduNumerals(string) {
  return string.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (digit) => digit.charCodeAt(0) - 1632);
}
