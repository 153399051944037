import React from "react";
import PropTypes from "prop-types";
import "./Radio.scss";

const Radio = ({
  name,
  value,
  wide,
  checked,
  onChange,
  onClick,
  loading,
  className,
  checkmark,
}) => (
  <label
    className={`radio ${wide ? "wide" : ""} ${className}`}
    htmlFor={name + value}
  >
    <input
      id={name + value}
      type="radio"
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
      onClick={onClick}
    />
    {loading ? (
      <svg width="16px" height="16px" viewBox="0 0 20 20">
        <circle cx="10" cy="10" r="9" />
        <path
          d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
          className="outer"
        />
      </svg>
    ) : (
      <div className={`${checkmark ? "checkmark" : "radiomark"}`} />
    )}
    {value}
  </label>
);

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  wide: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  className: PropTypes.string,
  checkmark: PropTypes.bool,
};

Radio.defaultProps = {
  wide: false,
  checked: false,
  loading: false,
  className: "",
  checkmark: false,
  onClick: () => {},
};

export default Radio;
