import React, { Component } from "react";
import PropTypes from "prop-types";
import uuid from "uid";
import PaginationTypes from "./pagination-types";
import Button from "../Button/Button";
import "./ListViewContainer.scss";

class ListViewContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
    };
  }

  componentDidMount() {
    this.props.onPagination(this.state.pageNumber);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.doReset) {
      this.setState({ pageNumber: 1 }, () => {
        this.props.onReset();
        this.props.onPagination(this.state.pageNumber);
      });
    }
  }

  getPaginationElement = (type, loading) => {
    switch (type) {
      case PaginationTypes.showMore:
        return (
          <div className="showMore-btn">
            <Button
              content={this.props.buttonText}
              flat
              className={loading ? "is-loading" : ""}
              onClick={this.handlePagination}
            />
          </div>
        );
      default:
        return null;
    }
  };

  handlePagination = () => {
    this.setState(
      (prevState) => ({
        pageNumber: prevState.pageNumber + 1,
      }),
      () => {
        this.props.onPagination(this.state.pageNumber);
      }
    );
  };

  reset() {
    this.setState({ pageNumber: 1 }, () => {
      this.props
        .onReset()
        .then(() => this.props.onPagination(this.state.pageNumber));
    });
  }

  render() {
    const Item = this.props.listItemComponent;
    const AllItemsWithData = this.props.listItemData.map((dataItem) => (
      <div className="list-item" key={uuid()}>
        <Item data={dataItem} {...this.props.clickHandlers} />
      </div>
    ));

    let Pagination;
    const { paginationType, loading } = this.props;

    if (this.props.listItemData.length) {
      Pagination = this.getPaginationElement(paginationType, loading);
    }

    if (
      this.props.paginationMetadata.totalCount ===
      this.props.listItemData.length
    ) {
      Pagination = null;
    }

    return (
      <div className="list-view-container">
        {AllItemsWithData}
        {Pagination}
      </div>
    );
  }
}

ListViewContainer.defaultProps = {
  paginationType: PaginationTypes.none,
  buttonText: "",
  onPagination: null,
  clickHandlers: {},
  onReset: null,
  doReset: false,
  paginationMetadata: {},
  loading: false,
};

ListViewContainer.propTypes = {
  listItemComponent: PropTypes.func.isRequired,
  listItemData: PropTypes.array.isRequired,
  onReset: PropTypes.func,
  doReset: PropTypes.bool,
  clickHandlers: PropTypes.object,
  paginationType: PropTypes.number,
  buttonText: PropTypes.string,
  onPagination: PropTypes.func,
  paginationMetadata: PropTypes.object,
  loading: PropTypes.bool,
};

export default ListViewContainer;
