import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button } from "../../../components";

import translate from "../texts";
import "../AdvancedSearch.scss";

class AdvancedSearchHeader extends PureComponent {
  render() {
    const { closeSearchControls } = this.props;

    return (
      <div className="advanced-search-header">
        <h1 className="font-bold">{translate("advanced-search")}</h1>
        <Button
          iconName="close"
          tiny
          flatWhite
          className="close-icon text-sm"
          onClick={closeSearchControls}
        />
      </div>
    );
  }
}

AdvancedSearchHeader.propTypes = {
  closeSearchControls: PropTypes.func.isRequired,
};

export default AdvancedSearchHeader;
