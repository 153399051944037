import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Route as RouteDOM,
  Switch,
  Redirect,
  useParams,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import { FixedHeader, spinner } from "../../components";
import ProgramDashboardContainer from "../../layouts/ProgramDashboard";
import FormBuilderContainer from "../../layouts/FormBuilder";
import FunnelsContainer from "../../layouts/Funnels";
import { ProgramMembersModal } from "../../layouts/ProgramMembers";
import * as MembersInvitationActions from "../../actions/members-invitation.actions";
import * as programActions from "../../actions/program.actions";
import NotificationsContainer from "../Notifications";
import PublishFormModal from "../FormBuilder/PublishFormModal";
import "./ProgramContainer.scss";
import translate from "./texts";

const Route = Sentry.withSentryRouting(RouteDOM);

const ProgramContainer = () => {
  const [publishModalActive, setPublishModalActive] = useState(false);
  const [expandedEmbedded, setExpandedEmbedded] = useState(false);

  const dispatch = useDispatch();
  const { programId } = useParams();
  const { program, fetched, errors } = useSelector((state) => state.program);
  const privileges = useSelector(
    (state) => state.privileges.privileges[programId]
  );
  const { state, pathname } = useLocation();

  const onUpdateProgramName = (newProgramName) => {
    dispatch(
      programActions.updateProgramName(program.id, newProgramName.trim())
    ).then(() => toast(translate("update_program_name_success_msg")));
  };

  const canUserEditForm = () =>
    privileges && privileges.includes("programs.form.edit");

  const canUserViewReport = () =>
    privileges && privileges.includes("submission_report.view");

  const togglePublishModal = () => {
    setPublishModalActive(!publishModalActive);
  };

  const collapseHandler = () => {
    setExpandedEmbedded(!expandedEmbedded);
  };

  const toggleEnabled = () => {
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/forms/${program.form.id}/toggle_enabled`
      )
      .then(() => dispatch(programActions.fetchProgram(programId)));
  };

  const renderError = () => (
    <div className="program-content-pane">
      <article className="message is-danger">
        <div className="message-body">{errors}</div>
      </article>
    </div>
  );

  const renderRoutes = () =>
    privileges && (
      <div className="program-content-pane">
        <Switch>
          {canUserViewReport() && (
            <Route
              path="/dashboard/programs/:programId/overview"
              component={ProgramDashboardContainer}
            />
          )}
          {canUserEditForm() && (
            <Route
              path="/dashboard/programs/:programId/form"
              component={FormBuilderContainer}
            />
          )}
          <Route
            path="/dashboard/programs/:programId/funnels/:funnelId"
            component={FunnelsContainer}
          />
          <Route
            path="/dashboard/programs/:programId/notifications"
            component={NotificationsContainer}
          />
          <Redirect
            from={`/dashboard/programs/${programId}`}
            to={
              canUserViewReport()
                ? `/dashboard/programs/${programId}/overview`
                : `/dashboard/programs/${programId}/funnels/${program.rootFunnelId}/submissions/page/1`
            }
          />
        </Switch>
        <ProgramMembersModal />
      </div>
    );

  const canRenderProgram = errors.length === 0 && fetched;
  const tabsList = [
    { name: "overview", link: `/dashboard/programs/${programId}/overview` },
    {
      name: "submissions",
      link: `/dashboard/programs/${programId}/funnels/${program.rootFunnelId}/submissions/page/1`,
    },
    { name: "formBuilder", link: `/dashboard/programs/${programId}/form` },
  ];

  useEffect(() => {
    dispatch(programActions.fetchProgram(programId));
    dispatch(MembersInvitationActions.resetInvitationMembers());
    dispatch(programActions.resetProgram());
    setPublishModalActive(Boolean(state && state.isDirectedFromAuth));
  }, [dispatch]);

  useEffect(() => {
    if (fetched) spinner.hide();
    else spinner.show();
  }, [fetched]);

  return (
    <div className="program-wrapper">
      <FixedHeader
        program={program}
        onUpdateProgramName={onUpdateProgramName}
        canUpdateProgramName={
          privileges && privileges.includes("programs.update")
        }
        tabsSettings={{ tabsList, pathname, canRenderTabs: canRenderProgram }}
      />
      {errors.length > 0 ? renderError() : null}
      {canRenderProgram ? renderRoutes() : null}

      {/* TODO: Move this somewhere if no good reason for it placed here */}
      <PublishFormModal
        publishLink={`${global.serverUrl}/forms/${program.form.id}`}
        collapseHandler={collapseHandler}
        expandedEmbedded={expandedEmbedded}
        publishModalActive={publishModalActive}
        togglePublishModal={togglePublishModal}
        isFormEnabled={program.form.enabled}
        toggleEnabled={toggleEnabled}
      />
    </div>
  );
};

export default ProgramContainer;
