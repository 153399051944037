import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    confirm: "Confirm",
    cancel: "Cancel",
  },
  ar: {
    confirm: "استمرار",
    cancel: "إلغاء",
  },
});

strings.setLanguage(locale || "en");

const translate = (string) => strings[string] || string;

export default translate;
