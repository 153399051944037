import * as types from "../types/alert-message.types";

export function showAlertMessage(title, message, okHandler) {
  return {
    type: types.SHOW_ALERT_MESSAGE,
    title,
    message,
    okHandler,
    active: true,
  };
}

export function hideAlertMessage() {
  return {
    type: types.HIDE_ALERT_MESSAGE,
  };
}
