// FIXME: Potential bad use of snake case, switch everything to camel case
import React, { Component } from "react";
import PropTypes from "prop-types";
import { IconMenu, IconButton, Button, Box, Divider } from "../../components";
import { cloneDeep } from "lodash";
import FormRenderer from "../../components/FormRenderer/FormRenderer";
import OptionsFactory from "./FieldOptions/OptionsFactory";
import fieldsDefinitions from "./fields-definitions";
import translate from "./texts";
import "./Field.scss";

const FIELDS_LIST = Object.values(fieldsDefinitions);

class Field extends Component {
  state = {
    field: {
      ...this.props.field,
      options: { ...this.props.field.options },
      schema: { ...this.props.field.schema },
    },
    errorMessages: {},
  };

  onFieldTypeChange = (opt) => {
    const { field } = this.props;
    if (this.haveChoices()) {
      field.schema.choices = [];
    }
    if (field.schema.validation) {
      field.schema.validation.required = false;
    }
    field.schema.title = "";
    opt.schema.id = this.props.onFieldTypeChange();
    this.setState({ field: { ...opt }, errorMessages: {} });
  };

  onSave = () => {
    if (!this.validate()) {
      return;
    }

    this.props.onSave(this.state.field.schema);
  };

  validate = () => {
    // Seperate this condition to validateTitle() function if any additional type
    // of validation is required.
    if (!this.state.field.schema.title) {
      this.setState((prevState) => ({
        errorMessages: {
          ...prevState.errorMessages,
          titleValidation: translate("titleValidation"),
        },
      }));

      return false;
    }

    return true;
  };

  haveChoices = () => this.state.field.schema.choices instanceof Array;

  mapOptionToSchema = (newOptions) => {
    const newField = cloneDeep(this.state.field);
    newField.schema.title = newOptions.title;

    delete newOptions.title;

    newField.schema.visibility = newOptions.visibility;
    newField.schema.visibilityOptions = newOptions.visibilityOptions;

    // If the visibilityOptions is empty then disable the visibility boolean
    if (!newOptions.visibilityOptions.length) {
      newField.schema.visibility = true;
    }

    // Reset visibility options when the visibility boolean is set to true
    if (newField.schema.visibility) {
      newField.schema.visibilityOptions = [];
    }

    delete newOptions.visibilityOptions;
    delete newOptions.visibility;

    if (newField.schema.type.name === "StarRating") {
      newField.schema.size = parseInt(newOptions.size, 10);
      newField.schema.start_label = newOptions.start_label;
      newField.schema.end_label = newOptions.end_label;
      delete newOptions.size;
      delete newOptions.start_label;
      delete newOptions.end_label;
    }

    if (this.haveChoices()) {
      newField.schema.choices = newOptions.choices;
      delete newOptions.choices;
    }

    if (typeof newOptions.primary_email === "boolean") {
      newField.schema.primary_email = newOptions.primary_email;
      delete newOptions.primary_email;
    }

    if (typeof newOptions.withCity === "boolean") {
      newField.schema.withCity = newOptions.withCity;
      newField.schema.cityTitle = newOptions.cityTitle;
      delete newOptions.withCity;
      delete newOptions.cityTitle;
    }

    if (newField.schema.type.name === "Number") {
      newField.schema.min = newOptions.min;
      newField.schema.max = newOptions.max;
      delete newOptions.min;
      delete newOptions.max;
    }

    newField.schema.validation = {
      ...newField.schema.validation,
      ...newOptions,
    };

    this.setState({ field: { ...newField } });
  };

  handleCancel = () => {
    if (this.props.isNew) {
      this.onFieldTypeChange(this.state.field);
    }
    this.setState({
      field: { schema: { title: this.props.field.schema.title } },
    });
    this.props.closeFieldView();
  };

  render() {
    const { field } = this.state;
    const { isNew } = this.props;

    return (
      <div id="field-container">
        <div className="add-new-field">
          <Box>
            <div className="field-edit">
              <div className="field-edit-body">
                {isNew && (
                  <div>
                    <IconMenu
                      options={FIELDS_LIST}
                      value={field}
                      onChange={this.onFieldTypeChange}
                      labelKey="name"
                      className="input-type"
                    />
                    <Divider />
                  </div>
                )}
                <OptionsFactory
                  key={field.schema.ref}
                  options={{
                    ...field.options,
                    parentRef: `${field.schema.ref}_${field.schema.id}`,
                    id: field.schema.id,
                  }}
                  onChange={this.mapOptionToSchema}
                  errors={this.state.errorMessages}
                  fieldsList={this.props.fieldsList}
                />
                <br />
              </div>
              <div className="field-edit-footer">
                <Button
                  iconName="check"
                  content={translate("saveNewField")}
                  onClick={this.onSave}
                />
                <Button
                  flat
                  content={translate("cancelNewField")}
                  onClick={this.handleCancel}
                />
                {!isNew && (
                  <IconButton
                    iconName="delete"
                    id="deleteQuestionBtn"
                    onClick={this.props.deleteField}
                  />
                )}
              </div>
            </div>
            <div id="field-preview" className="field-preview">
              <FormRenderer fields={[field.schema]} allowSubmission={false} />
              <div className="preview-label">{translate("previewOnly")}</div>
            </div>
          </Box>
        </div>
      </div>
    );
  }
}

Field.propTypes = {
  field: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  closeFieldView: PropTypes.func.isRequired,
  onFieldTypeChange: PropTypes.func,
  isNew: PropTypes.bool,
  deleteField: PropTypes.func,
  fieldsList: PropTypes.array.isRequired,
};

Field.defaultProps = {
  onFieldTypeChange: () => {},
  isNew: false,
  deleteField: () => {},
};

export default Field;
