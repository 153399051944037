import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, useHistory, useParams } from "react-router-dom";
import Permit from "../../../layouts/Authorization";
import buildGraphString from "../../../utils/funnel-visualization/funnel-visualization-builder";
import { direction } from "../../../utils/utils";
import translate from "../../../layouts/Funnels/texts";
import * as programActions from "../../../actions/program.actions";
import Mermaid from "./Mermaid";
import theme from "./razeen-mermaid-theme";
import "./FunnelVisualization.scss";

const config = {
  flowchart: {
    curve: "monotoneX",
  },
  themeCSS: theme,
  securityLevel: "loose",
};

let graphDir = "LR";

if (direction() === "rtl") {
  graphDir = "RL";
}

window.onMermaidNodeClick = (nodeId) => {
  const event = new CustomEvent("onMermaidNodeClick", {
    detail: {
      nodeId,
    },
  });

  document.dispatchEvent(event);
};

const FunnelVisualization = () => {
  const [graph, setGraph] = useState("");
  const dispatch = useDispatch();
  const { programId } = useParams();
  const history = useHistory();
  const { program } = useSelector((state) => state.program);

  const onNodeClick = (event) => {
    const { nodeId } = event.detail;
    history.push(
      `/dashboard/programs/${program.id}/funnels/${nodeId}/submissions/page/1`
    );
  };

  useEffect(() => {
    document.addEventListener("onMermaidNodeClick", onNodeClick);

    return () => {
      document.removeEventListener("onMermaidNodeClick", onNodeClick);
    };
  });

  useEffect(() => {
    dispatch(programActions.fetchInputSources(programId));
  }, [dispatch]);

  useEffect(() => {
    let timer;
    if (program && Object.keys(program.inputSources).length) {
      // TODO: Investigate the weird delay in graph building
      // TODO: A state persisting for the graph is needed
      // The time is for UI purpose in-order to reset
      // the shape of the box and show the spinner
      timer = setTimeout(() => {
        setGraph(
          buildGraphString(program.funnels, program.inputSources, graphDir)
        );
      }, 100);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [program]);

  return (
    <Permit targetPrivilege="funnels.index" targetId={program.id}>
      <div id="FunnelVisualization">
        <div className="heroic-content flex mb-4">
          <h4 id="funnels-header" className="section-title" title="Funnels">
            {translate("funnels_graph")}
          </h4>
          <div className="flex h-4 items-center ms-3">
            <span className="bg-warning-yellow border border-solid border-warning-brown h-4 me-2 ms-2 p-1 w-4" />
            <span>{translate("public_funnel")}</span>
          </div>
        </div>
        <div className="heroic">
          <div className="mermaid-wrapper">
            {graph ? (
              <Mermaid className="diagram" config={config} diagram={graph} />
            ) : (
              <div className="loader m-auto" />
            )}
          </div>
        </div>
      </div>
    </Permit>
  );
};

export default withRouter(FunnelVisualization);
