/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Checkbox, Icon } from "../../../../components";
import "./ColumnsControls.scss";
import { humanizeField } from "../../../../utils/utils";
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
  arrayMove,
} from "react-sortable-hoc";

const DragHandle = sortableHandle(() => (
  <div className="ff-drag">
    <Icon name="drag" />
  </div>
));
const SortableItem = SortableElement(({ field, idList, handleFieldChange }) => (
  <div className="field-item" key={field.id}>
    <DragHandle />
    <Checkbox
      className="field-checkbox"
      value={field.id}
      checked={idList.indexOf(field.id) === -1}
      id={field.id}
      name={field.name}
      onChange={(event) => handleFieldChange(event, field.id)}
      label={humanizeField(field.name)}
    />
  </div>
));

const SortableList = SortableContainer(
  ({ fields, idList, handleFieldChange }) => (
    <ul>
      {fields.map((field, index) => (
        <SortableItem
          key={`item-${field.id}`}
          index={index}
          field={field}
          idList={idList}
          handleFieldChange={handleFieldChange}
        />
      ))}
    </ul>
  )
);
class ColumnsControls extends PureComponent {
  state = {
    fields: this.props.fields,
  };

  /**
   * This function will handle local state changes and then will call onChange callback
   */
  handleFieldChange = (event, value) => {
    const hiddenColumns = this.props.value;
    const index = hiddenColumns.indexOf(value);
    if (index > -1) {
      hiddenColumns.splice(index, 1);
    } else {
      hiddenColumns.push(value);
    }

    this.props.onChange({ target: { value: null } }, hiddenColumns);
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { fields } = this.state;
    const orderedFields = arrayMove(fields, oldIndex, newIndex);
    const fieldsOrder = {};
    orderedFields.forEach((fld, i) => {
      fieldsOrder[i] = fld.id;
    });
    this.setState({ fields: orderedFields }, () =>
      this.props.onOrderChange({ target: { value: null } }, fieldsOrder)
    );
  };

  render() {
    const { value } = this.props;
    const { fields } = this.state;
    return (
      <div className="fields_list">
        <SortableList
          fields={fields}
          idList={value}
          onSortEnd={this.onSortEnd}
          helperClass="sorting-active"
          handleFieldChange={this.handleFieldChange}
          useDragHandle
        />
      </div>
    );
  }
}

ColumnsControls.propTypes = {
  fields: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onOrderChange: PropTypes.func.isRequired,
};

ColumnsControls.defaultProps = {
  fields: [],
  value: [],
};

export default ColumnsControls;
