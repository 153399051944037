import React from "react";
import PropTypes from "prop-types";
import {
  Icon,
  Modal,
  Input,
  Box,
  FooterActions,
  Divider,
} from "../../components";
import translate from "./texts";
import "./PublishFormModal.scss";

// TODO: This component needs some enhancement, so that some functionlities
// should be handled in the component state it self not in it is container

const PublishFormModal = ({
  publishLink,
  publishModalActive,
  togglePublishModal,
  expandedEmbedded,
  collapseHandler,
  isFormEnabled,
  toggleEnabled,
}) => (
  <div id="publish-form-modal">
    <div className="publish-icon">
      <Modal
        active={publishModalActive}
        toggle={togglePublishModal}
        className="publish-modal"
      >
        <div className="publish-box">
          <div className="publish-links-header">
            <h6>{translate("form_link")}</h6>
            <p>{translate("share_this_link_receive_submissions")}</p>
          </div>
          <Input
            inputName="publishInput"
            value={publishLink}
            onChange={() => {}}
            onFocus={(event) => {
              event.target.select();
            }}
            disabled={!isFormEnabled}
            onClick={(event) => {
              event.target.focus();
            }}
          />
          <div className="collapsible">
            <Box dark>
              <div
                className={`group-header${
                  expandedEmbedded ? "" : " padding-bottom-16"
                } embedded-input`}
                onClick={() => collapseHandler()}
                onKeyUp={() => collapseHandler()}
                role="presentation"
              >
                <div className="embedding-instructions">
                  {translate("embed_code")}
                </div>
                <div>
                  <Icon name={`chevron-${expandedEmbedded ? "up" : "down"}`} />
                </div>
              </div>
              {expandedEmbedded && (
                <div className="embedded-code">
                  <Input
                    inputName="embeddedInput"
                    multiline
                    value={`<iframe width='560' height='580' src=${publishLink} frameborder='0'></iframe>`}
                    onChange={() => {}}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                    disabled={!isFormEnabled}
                    onClick={(event) => {
                      event.target.focus();
                    }}
                  />
                </div>
              )}
            </Box>
          </div>
          <Divider />
          <div className="toggle-enabled">
            <input
              type="checkbox"
              id="toggle-enabled"
              name="toggle-enabled"
              checked={isFormEnabled}
              onChange={toggleEnabled}
            />
            {translate("Enable Form")}
          </div>
        </div>
        <FooterActions
          actions={[
            {
              content: translate("close"),
              flat: true,
              onClick: togglePublishModal,
            },
          ]}
        />
      </Modal>
    </div>
  </div>
);

PublishFormModal.propTypes = {
  publishLink: PropTypes.string.isRequired,
  publishModalActive: PropTypes.bool.isRequired,
  togglePublishModal: PropTypes.func.isRequired,
  expandedEmbedded: PropTypes.bool.isRequired,
  collapseHandler: PropTypes.func.isRequired,
  isFormEnabled: PropTypes.bool,
  toggleEnabled: PropTypes.func.isRequired,
};

PublishFormModal.defaultProps = {
  isFormEnabled: true,
};

export default PublishFormModal;
