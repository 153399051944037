import axios from "axios";
import * as types from "../types/reason.types";

export default function updateReason(submissionId, reason, funnelId) {
  return {
    type: types.UPDATE_REASON,
    payload: axios.post(
      `${process.env.REACT_APP_API_URL}/submissions/${submissionId}/evaluate`,
      {
        evaluations: [
          {
            ...reason,
          },
        ],
        funnelId,
      }
    ),
    meta: {
      questionId: reason.questionId,
      submissionId,
    },
  };
}
