/* eslint-disable no-unused-vars */

import * as types from "../types/alert-message.types";

const initialState = {
  active: false,
  title: "",
  message: "",
  okHandler: () => {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SHOW_ALERT_MESSAGE: {
      const { title, message, okHandler } = action;
      return {
        ...state,
        active: true,
        okHandler,
        message,
        title,
      };
    }
    case types.HIDE_ALERT_MESSAGE:
      return {
        ...state,
        active: false,
      };
    default:
      return state;
  }
}
