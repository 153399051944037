import React, { Component } from "react";
import PropTypes from "prop-types";
import translate from "../../../../layouts/Funnels/texts";
import { fetchFunnelProgress } from "../../../../actions/funnels.actions";
import UserView from "../../../../layouts/UserView/UserView";
import "./ProgressCard.scss";

class ProgressCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      loading: false,
      funnelMembers: [],
      funnelStats: {},
    };

    this.expandCard = this.expandCard.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  getFirstLetter(string) {
    return string.substring(0, 1);
  }

  async expandCard() {
    this.setState({ loading: true });
    if (!this.state.expanded) {
      const { funnel } = this.props;
      const res = await fetchFunnelProgress(funnel.id).payload;
      this.setState({
        funnelMembers: res.data.programUsers,
        funnelStats: res.data.funnelStats,
      });
    }
    this.setState((state) => ({ expanded: !state.expanded, loading: false }));
  }

  // eslint-disable-next-line class-methods-use-this
  renderUserCard(user, index) {
    return (
      <div className="user-progress" key={index}>
        <UserView user={user} />
        <h3>{user.reviewed}</h3>
      </div>
    );
  }

  render() {
    const { funnel } = this.props;
    const { expanded, funnelMembers, funnelStats } = this.state;
    const { reviewed, submissionCount } = funnelStats;

    let reviewedPercentage = 0;

    if (submissionCount !== 0) {
      reviewedPercentage = Math.floor((reviewed / submissionCount) * 100);
    }

    let style = {
      background: `linear-gradient(90deg, #268AFF ${reviewedPercentage}%, rgb(102, 119, 136, 30%) ${reviewedPercentage}%)`,
    };
    if ("en" !== "en") {
      style = {
        background: `linear-gradient(90deg, rgb(102, 119, 136, 30%) ${
          100 - reviewedPercentage
        }%, #268AFF ${100 - reviewedPercentage}%)`,
      };
    }

    return (
      <div className="progress-card">
        <div
          className="progress-details"
          onClick={this.expandCard}
          onKeyUp={(e) => {
            if (e.which === 13) this.expandCard();
          }}
          role="button"
          tabIndex={0}
        >
          <div className="progress-header">
            <h3>{funnel.name}</h3>
            <div>
              {this.state.loading && <span>{translate("loading")}</span>}
            </div>
            {expanded && (
              <div className="progress-numbers">
                <h3>{reviewed}</h3>
                <h5>{` / ${submissionCount}`}</h5>
              </div>
            )}
          </div>
          <div className="progress-key">
            {expanded && (
              <div className="percentage">
                <span className="dot" />
                <span>{`${reviewedPercentage}% ${translate("reviewed")}`}</span>
              </div>
            )}
          </div>
        </div>
        <div className="progress-bar" style={style} />
        <div className={`progress-users-details ${expanded ? "active" : ""}`}>
          {funnelMembers.length === 0 && (
            <div className="empty-state">
              <h5>{translate("no_assigned_users")}</h5>
            </div>
          )}

          {funnelMembers &&
            funnelMembers.map((user, index) =>
              this.renderUserCard(user, index)
            )}
        </div>
      </div>
    );
  }
}

ProgressCard.propTypes = {
  funnel: PropTypes.object.isRequired,
};

export default ProgressCard;
