import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    Workspaces: "Workspaces",
    Submissions: "Submissions",
    Submitters: "Submitters",
    Fields: "Fields",
    Profile: "Profile",
    "Manage Members": "Manage Members",
    "Create Program": "Create Program",
    Back: "Back",
    Filtration: "Filtration",
    Save: "Save",
    Title: "Title",
    verify_message:
      "One last thing, Please verify your email by clicking the link we sent to you",
    resend_email_btn: "Resend Email",
    copy_success: "Link Copied Successfully",
  },
  ar: {
    Workspaces: "مساحات العمل",
    Submissions: "المشاركات",
    Submitters: "المشاركين",
    Fields: "الحقول",
    Profile: "الملف الشخصي",
    "Manage Members": "إدارة الأعضاء",
    "Create Program": "إنشاء برنامج جديد",
    Back: "رجوع",
    Filtration: "التصفيات",
    Save: "حفظ",
    Title: "العنوان",
    verify_message: "الرجاء تأكيد الإيميل بالضغط على الرابط المرسل إليك",
    resend_email_btn: "اعاده إرسال البريد الكتروني",
    copy_success: "تم نسخ الرابط بنجاح",
  },
});

strings.setLanguage(locale || "en");
// strings.setLanguage( global.currentLocale);

const translate = (string) => strings[string];

export default translate;
