import * as types from "../types/reason.types";

const initialReasonState = [
  {
    questionId: "",
    submissionId: "",
    isSaved: false,
    isSaving: false,
    error: false,
  },
];

export default function reasonsReducer(state = initialReasonState, action) {
  switch (action.type) {
    case types.UPDATE_REASON_PENDING: {
      const { questionId, submissionId } = action.meta;
      const reasonState = {
        questionId,
        submissionId,
        isSaved: false,
        isSaving: true,
        error: false,
      };

      if (state[0].questionId.length === 0) {
        return [reasonState];
      }

      const newState = state.filter((reason) => {
        if (reason.questionId === questionId) {
          if (reason.submissionId === submissionId) {
            return false;
          }
          return true;
        }
        return true;
      });

      return [...newState, reasonState];
    }
    case types.UPDATE_REASON_REJECTED: {
      const { questionId, submissionId } = action.meta;
      const reasonState = {
        questionId,
        submissionId,
        isSaved: false,
        isSaving: false,
        error: true,
      };

      if (state[0].questionId.length === 0) {
        return [reasonState];
      }

      const newState = state.filter((reason) => {
        if (reason.questionId === questionId) {
          if (reason.submissionId === submissionId) {
            return false;
          }
          return true;
        }
        return true;
      });

      return [...newState, reasonState];
    }
    case types.UPDATE_REASON_FULFILLED: {
      const { questionId, submissionId } = action.meta;

      const reasonState = {
        questionId,
        submissionId,
        isSaved: true,
        isSaving: false,
        error: false,
      };

      if (state[0].questionId.length === 0) {
        return [reasonState];
      }

      const newState = state.filter((reason) => {
        if (reason.questionId === questionId) {
          if (reason.submissionId === submissionId) {
            return false;
          }
          return true;
        }
        return true;
      });

      return [...newState, reasonState];
    }
    default:
      return state;
  }
}
