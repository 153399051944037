import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");

const strings = new LocalizedStrings({
  en: {
    label: "Tracking Status",
    "Select Label": "Select Status",
  },
  ar: {
    label: "الحاله",
    "Select Label": "اختر الحاله",
  },
});

strings.setLanguage(locale || "en");

const translate = (string) => strings[string] || string;

export default translate;
