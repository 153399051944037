import React from "react";
import BaseField from "./BaseField";

/**
 * @class Checkbox
 * @extends BaseField
 * @description Checkbox field component
 */
export default class Checkbox extends BaseField {
  /**
   * @override
   */

  onChange = (event) => {
    const {
      target: { value, checked },
    } = event;

    // Since setState is overrided in BaseField component, if we use the correct
    // style of setting state with the prevState, that method will break.

    /* eslint-disable react/no-access-state-in-setstate */
    if (checked) {
      this.setState({ value: [...this.state.value, value] }, () => {
        this.props.onChange(this.Settings, this.state.value);
      });
    } else {
      const newVal = [...this.state.value];
      newVal.splice(newVal.indexOf(value), 1);
      this.setState({ value: newVal }, () => {
        this.props.onChange(this.Settings, this.state.value);
      });
    }
  };

  renderCheckBoxes(id) {
    return this.props.settings.choices.map((op, i) => {
      const uKey = `${id}_${i}`;
      return (
        <label
          className="checkbox side-padding block"
          key={uKey}
          htmlFor={uKey}
        >
          <input
            type="checkbox"
            name={id}
            id={uKey}
            value={op}
            checked={this.state.value.includes(op)}
            onChange={this.onChange}
            required={this.Settings.validation.required}
          />
          <span>{op}</span>
        </label>
      );
    });
  }

  /**
   * @override
   */
  render() {
    const { id } = this.Settings;
    return (
      <div className="field">
        {this.renderLabel()}
        <div className="control">{this.renderCheckBoxes(id)}</div>
        {this.renderErrors()}
      </div>
    );
  }
}
