import React from "react";
import { StatusBar, DragDrop } from "@uppy/react";
import PropTypes from "prop-types";
import "@uppy/core/dist/style.css";
// import '@uppy/drag-drop/dist/style.css';
// import '@uppy/status-bar/dist/style.css';
// import '@uppy/url/dist/style.css';

const Uploader = ({ uppy, note }) => (
  <div className="uploader-container">
    <DragDrop
      width="100%"
      height="100%"
      uppy={uppy}
      note={note}
      // plugins={["Url"]}
      // disableStatusBar={true}
      locale={{
        strings: {
          dropHereOr: "Drop here or %{browse}",
          browse: "browse",
          dropPasteImportFiles:
            "You can drag and drop files here, %{browseFiles} or import from:",
        },
      }}
    />
    <StatusBar
      width="100%"
      height="100%"
      uppy={uppy}
      hideUploadButton
      showProgressDetails
      hideAfterFinish={false}
    />
  </div>
);
Uploader.propTypes = {
  uppy: PropTypes.object.isRequired,
  note: PropTypes.string,
};
Uploader.defaultProps = {
  note: "",
};
export default Uploader;
