import axios from "axios";
import * as types from "../types/members-management.types";

export function fetchProgramMembers(programId) {
  return {
    type: types.FETCH_PROGRAM_MEMBERS,
    payload: axios.get(
      `${process.env.REACT_APP_API_URL}/programs/${programId}/members`
    ),
  };
}

export function resetProgramMembers() {
  return {
    type: types.RESET_PROGRAM_MEMBERS,
  };
}

export function fetchProgramGroups(programId) {
  return {
    type: types.FETCH_PROGRAM_GROUPS,
    payload: axios.get(
      `${process.env.REACT_APP_API_URL}/programs/${programId}/groups`
    ),
  };
}

export function createProgramMembersGroup(programId, name, users) {
  return {
    type: types.CREATE_PROGRAM_MEMBERS_GROUP,
    payload: axios.post(
      `${process.env.REACT_APP_API_URL}/programs/${programId}/groups`,
      // TODO: remove programId as it's passed in the route already.
      { programId, name, users: users.map((user) => user.id) }
    ),
  };
}

export function deleteProgramMembersGroup(programId, groupId) {
  return {
    payload: axios.delete(
      `${process.env.REACT_APP_API_URL}/programs/${programId}/groups/${groupId}`
    ),
  };
}

export function updateProgramMembersGroup(programId, groupId, name, users) {
  return {
    payload: axios.put(
      `${process.env.REACT_APP_API_URL}/programs/${programId}/groups/${groupId}`,
      { name, users: users.map((user) => user.id) }
    ),
  };
}

export function deleteProgramMember(programId, memberIds) {
  return {
    payload: axios.delete(
      `${process.env.REACT_APP_API_URL}/programs/${programId}/members`,
      {
        data: { memberIds },
      }
    ),
  };
}
