import * as types from "../types/funnelAccess.types";

const initialState = {
  isFetching: false,
  fetched: false,
  errors: [],
  funnelAccess: {
    enablePublic: false,
    funnelPublicAccessToken: "",
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_FUNNEL_PUBLIC_ACCESS_FULFILLED: {
      return {
        ...state,
        funnelAccess: action.payload.data,
        isFetching: false,
        fetched: true,
      };
    }

    case types.FETCH_FUNNEL_PUBLIC_ACCESS_PENDING:
      return {
        ...state,
        isFetching: true,
      };

    case types.FETCH_FUNNEL_PUBLIC_ACCESS_REJECTED:
      return {
        ...state,
        isFetching: false,
        errors: { ...action.payload },
      };

    case types.SET_FUNNEL_PUBLIC_ACCESS_FULFILLED: {
      return {
        ...state,
        fetched: true,
        funnelAccess: action.payload.data,
      };
    }

    default:
      return state;
  }
}
