import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    fields: "Fields",
    id: "ID",
    createdAt: "Created at",
    status: "Status",
    evaluation: "Evaluation",
    score: "Score",
    labels: "Labels",
    questions: "Questions",
    "Select Field": "Select Field...",
    "Select Operator": "Select Operator...",
    "Saved Searches": "Saved Searches",
    "New Filter": "New Filter",
    "Name your saved search": "Name your saved search",
    Search: "Search",
    Cancel: "Cancel",
    saveSearch: "Save Search",
    assignee: "Assignee",
    "advanced-search": "Advanced Search",
    criteria: "Criteria",
    field: "Field",
    operator: "Operator",
    value: "Value",
    "save-filter": "Save Filter",
    "create-filter": "Create Filter",
  },
  ar: {
    fields: "الحقول",
    id: "رقم المشاركة",
    createdAt: "تاريخ المشاركة",
    status: "الحالة",
    evaluation: "التقييم",
    score: "الدرجة",
    labels: "الوسوم",
    questions: "أسلة التقييم",
    "Select Field": "اختر حقل...",
    "Select Operator": "اختر عامل المقارنة ...",
    "Saved Searches": "البحوثات المحفوظة",
    "New Filter": "حقل جديد",
    "Name your saved search": "اسم هذا البحث",
    Search: "بحث",
    Cancel: "إلغاء",
    saveSearch: "حفظ البحث",
    assignee: "الفارز",
    "advanced-search": "البحث المتقدم",
    criteria: "المعايير",
    field: "الحقل",
    operator: "العملية",
    // this translate to عامل but this is more clear
    value: "القيمة",
    "save-filter": "حفظ الفلتر",
    "create-filter": "إنشاء الفلتر",
  },
});

strings.setLanguage(locale || "en");

const translate = (string) => strings[string];

export default translate;
