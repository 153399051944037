import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Permit from "../../layouts/Authorization";
import { Wallpaper } from "../../components";
import translate from "../../layouts/ProgramDashboard/texts";
import ProgramDashboardStatistics from "./ProgramDashboardStatistics/ProgramDashboardStatistics";
import FunnelVisualization from "./FunnelVisualization/FunnelVisualization";
import FunnelProgress from "./FunnelProgress/FunnelProgress";
import "./ProgramDashboard.scss";

class ProgramDashboard extends PureComponent {
  render() {
    const { program } = this.props;
    return (
      <div id="ProgramDashboard">
        <Wallpaper>
          <div className="container">
            <Permit targetPrivilege="programs.settings" targetId={program.id}>
              <ProgramDashboardStatistics
                submissionCount={program.submissionCount}
                archived={program.archived}
                createdAt={program.createdAt}
              />
            </Permit>
            {program.funnels.length > 1 ? (
              <>
                <FunnelVisualization program={program} />
                <FunnelProgress program={program} />
              </>
            ) : (
              <h4 className="section-title empty-state">
                {translate("no_funnels")}
              </h4>
            )}
          </div>
        </Wallpaper>
      </div>
    );
  }
}

ProgramDashboard.propTypes = {
  program: PropTypes.object.isRequired,
};

export default ProgramDashboard;
