/* It's incredibly difficult to import CSS as a Mermaid theme.
   The only way we could do it is through their initializer which takes custom
   CSS as a string. */

export default `
.node rect,
.node circle,
.node ellipse,
.node polygon {
  fill: #e9edf1;
  rx: 20.5px;
  ry: 20.5px;
  stroke: none;
}

.node.clickable {
  transition: opacity 0.2s ease-in-out;
}

.node.clickable:hover {
  opacity: .7 !important;
}

.node div {
  color: #5b718d;
  font-weight: bold;
  font-family: Dubai, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif!important;
  font-size: 16px;
}
.edgeLabel {
  background-color: #e8eef2;
  padding: 4px 8px;
  border-radius: 500px;
  font-size: 10px;
  font-weight: bold;
  color: #58738f99;
}
.edgeLabel:empty {
  display: none;
}
.edgePath path {
  stroke-width: 1.5px!important;
}
.arrowheadPath {
  fill: #58738f99;
}
`;
