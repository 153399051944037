import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { hideAlertMessage } from "../../actions/alert-message.actions";
import AlertMessage from "./AlertMessage";

const AlertMessageContainer = ({
  okHandler,
  message,
  active,
  title,
  actions,
}) => (
  <AlertMessage
    active={active}
    message={message}
    title={title}
    okHandler={okHandler || actions.hideAlertMessage}
  />
);

AlertMessageContainer.propTypes = {
  active: PropTypes.bool.isRequired,
  okHandler: PropTypes.func,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
};
AlertMessageContainer.defaultProps = {
  okHandler: undefined,
};

const mapStateToProps = (state) => state.alertMessage;

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ hideAlertMessage }, dispatch),
});

const container = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertMessageContainer);

export default container;
