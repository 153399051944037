import useQueryParams from "./useQueryParams";
import { useAuthContext } from "./useAuthContext";
import { useEffect } from "react";
import { setToken } from "../utils/auth-utils";

function useOldAuth() {
  const query = useQueryParams();

  const token = query.get("pcode");
  const tokenExpiry = query.get("expiry");
  const name = query.get("name");
  const email = query.get("email");

  const { dispatch: dispatchAuthAction } = useAuthContext();

  const login = (token, tokenExpiry) => {
    // save the user to local storage
    if (name && email) {
      setToken(token, tokenExpiry, {
        id: email,
        name,
        email,
      });
    } else {
      setToken(token, tokenExpiry, null);
    }

    // update the auth context
    dispatchAuthAction({ type: "LOGIN", payload: token });
  };

  useEffect(() => {
    if (token && window.location.href.includes("pcode")) {
      destroyToken();
      login(token, tokenExpiry);
    }
  }, []);

  useEffect(() => {
    // Avoid appending the callback code to url after signing-in
    setTimeout(() => {
      if (window.location.href.includes("pcode")) {
        window.history.replaceState(null, null, `/#/dashboard`);
        window.location.reload();
      }
    }, 300);
  }, [location]);

  return {
    token,
    tokenExpiry,
    name,
    email,
  };
}

export default useOldAuth;
