import React from "react";
import PropTypes from "prop-types";
import "./BoxWithActions.scss";

const BoxWithActions = ({ children }) => {
  children = Array.isArray(children) ? children : [children];

  return (
    <div className="box-with-actions">
      <div>{children}</div>
    </div>
  );
};

BoxWithActions.propTypes = {
  children: PropTypes.any,
};

BoxWithActions.defaultProps = {
  children: [],
};

export default BoxWithActions;
