import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Modal, Box } from "../../components";
import PropTypes from "prop-types";
import MembersManagementContainer from "../../layouts/MembersManagement";
import MembersInvitationContainer from "../../layouts/MembersInvitation";
import * as programMembersModal from "../../actions/program-members-modal.actions";
import "./ProgramMembersModal.scss";

const programMembersModalPages = {
  MembersInvitation: MembersInvitationContainer,
  MembersManagement: MembersManagementContainer,
};

const ProgramMembersModal = ({ actions, active, activePage }) => {
  const TargetPage = programMembersModalPages[activePage];
  return (
    <Modal
      active={active}
      toggle={actions.toggleProgramMembersModal}
      className="z-50"
    >
      <div id="ProgramMembersModal">
        <Box>
          <TargetPage />
        </Box>
      </div>
    </Modal>
  );
};

ProgramMembersModal.propTypes = {
  active: PropTypes.bool.isRequired,
  activePage: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { active, activePage } = state.programMembersModal;

  return { active, activePage };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(programMembersModal, dispatch),
});

const container = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgramMembersModal);

export default container;
