import React from "react";
import PropTypes from "prop-types";
import { Icon } from "../../components";
import "./IconButton.scss";

/*
use this component as follows:
1- by default transparent and rectangular.
2- you should pass the function in the onClick attr.
3- you should pass the icon name in the iconName attr.
4- you should pass the content of the button in the content attr.
*/

// TODO: delete this component as it's functionality are provided by the <Button /> component.
const IconButton = ({ iconName, content, onClick, className }) => (
  <div
    onClick={onClick}
    onKeyDown={onClick}
    className={`icon-button ${className}`}
    onKeyUp={onClick}
    role="presentation"
  >
    <div className="icon-button-icon">
      <Icon name={iconName} additionalClasses="icon-button-icon" />
    </div>
    <div className="icon-button-text">{content}</div>
  </div>
);

IconButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  content: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

IconButton.defaultProps = {
  className: "",
  content: "",
};

export default IconButton;
