import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");

const strings = new LocalizedStrings({
  en: {
    devise: {
      confirmations: {
        confirmed: "Your email address has been successfully confirmed.",
        send_instructions:
          "You will receive an email with instructions for how to confirm your email address in a few minutes.",
        send_paranoid_instructions:
          "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes.",
        resend_confirmation_instructions: "Resend confirmation instructions",
      },
      failure: {
        already_authenticated: "You are already signed in.",
        inactive: "Your account is not activated yet.",
        invalid: "Invalid {authentication_keys} or password.",
        locked: "Your account is locked.",
        last_attempt:
          "You have one more attempt before your account is locked.",
        not_found_in_database: "Invalid {authentication_keys} or password.",
        timeout: "Your session expired. Please sign in again to continue.",
        unauthenticated: "You need to sign in or sign up before continuing.",
        unconfirmed:
          "You have to confirm your email address before continuing.",
      },
      mailer: {
        confirmation_instructions: {
          subject: "Confirmation instructions",
        },
        reset_password_instructions: {
          subject: "Reset password instructions",
        },
        unlock_instructions: {
          subject: "Unlock instructions",
        },
        email_changed: {
          subject: "Email Changed",
        },
        password_change: {
          subject: "Password Changed",
        },
      },
      omniauth_callbacks: {
        failure: 'Could not authenticate you from {kind} because "{reason}".',
        success: "Successfully authenticated from {kind} account.",
      },
      passwords: {
        no_token:
          "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
        send_instructions:
          "You will receive an email with instructions on how to reset your password in a few minutes.",
        send_instructions_button: "Send reset instructions",
        send_paranoid_instructions:
          "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
        updated:
          "Your password has been changed successfully. You are now signed in.",
        updated_not_active: "Your password has been changed successfully.",
        change_your_password: "Change your password",
        current_password: "Current password",
        current_password_description:
          "we need your current password to confirm your changes",
        password_leave_blank: "leave blank if you don't want to change it",
      },
      registrations: {
        email: "Email",
        name: "Name",
        password: "Password",
        mobile_number: "Mobile Number (e.g.966512345678)",
        confirm_password: "Confirm password",
        destroyed:
          "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
        signed_up: "Welcome! You have signed up successfully.",
        signed_up_but_inactive:
          "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
        signed_up_but_locked:
          "You have signed up successfully. However, we could not sign you in because your account is locked.",
        signed_up_but_unconfirmed:
          "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
        update_needs_confirmation:
          "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirm link to confirm your new email address.",
        updated: "Your account has been updated successfully.",
        minimum_characters: "characters minimum",
        cancel_my_account: "Cancel my account",
        unhappy: "Unhappy?",
      },
      sessions: {
        signed_in: "Signed in successfully.",
        signed_out: "Signed out successfully.",
        already_signed_out: "Signed out successfully.",
        remember_me: "Remember me",
      },
      unlocks: {
        send_instructions:
          "You will receive an email with instructions for how to unlock your account in a few minutes.",
        send_paranoid_instructions:
          "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
        unlocked:
          "Your account has been unlocked successfully. Please sign in to continue.",
      },
      shared_links: {
        log_in: "Log in",
        sign_up: "Sign up",
        forgot_your_password: "Forgot your password?",
        "didn't_receive_confirmation_instructions":
          "Didn't receive confirmation instructions?",
        "didn't_receive_unlock_instructions":
          "Didn't receive unlock instructions?",
        sign_in_with: "Sign in with",
        no_account: "Don't have an account?",
        register_now: "Register Now",
        already_have_account: "Already have an account?",
      },
    },
    errors: {
      messages: {
        email_required: "Email is required.",
        email_validation: "Email is invalid.",
        password_required: "Password is required.",
        name_required: "Name is required.",
        // mobile_required: "Mobile number is required.",
        mobile_validation: "Mobile number is invalid.",
        password_required: "Password is required.",
        password_minimum_length: `Password must be at least {minimum_password_length} characters long.`,
        password_confirmation_required: "Password confirmation is required.",
        password_minimum_length: `Password must be at least {minimum_password_length} characters long.`,
        password_confirmation_match: "Password confirmation does not match.",
        already_confirmed: "was already confirmed, please try signing in",
        confirmation_period_expired:
          "needs to be confirmed within {period}, please request a new one",
        expired: "has expired, please request a new one",
        not_found: "not found",
        not_locked: "was not locked",
        not_saved: {
          one: "1 error prohibited this {resource} from being saved:",
          other: "{count} errors prohibited this {resource} from being saved:",
        },
      },
    },
    collect_data: "collect data",
    make_better_decisions: "make better decisions",
    description:
      "Razeen is a submission management and filtration software that helps teams review and act on vast amounts of submissions with ease. It offers powerful reviewing-process customization features, and easy to use interface for all team roles.",

    language: "العربية",
  },
  ar: {
    devise: {
      confirmations: {
        confirmed: "بريدك الالكترونيّ تمّ التحقّق منه بنجاح.",
        send_instructions:
          "سوف تصلك رسالة بتعليمات التحقّق من بريدك الالكترونيّ خلال دقائق.",
        send_paranoid_instructions:
          "إذا كان بريدك الالكترونيّ موجود في النّظام، فستصلك رسالة بالتحقّق منه خلال دقائق",
        resend_confirmation_instructions: "إعادة إرسال تعليمات التأكيد",
      },
      failure: {
        already_authenticated: "لقد قمتَ بتسجيل دخولك بالفعل.",
        inactive: "لم يتمّ التحقّق من حسابك بعد.",
        invalid: " {authentication_keys} أو كلمة المرور غير صحيحة",
        locked: "لقد تمّ حظر حسابك.",
        last_attempt: "لديك محاولة أخيرة قبل أن يتمّ حظر حسابك.",
        not_found_in_database:
          " {authentication_keys} أو كلمة المرور غير صحيحة",
        timeout: "الرّجاء محاولة تسجيل الدّخول مرّة أخرى.",
        unauthenticated: "أنت بحاجة إلى التّسجيل أو تسجيل دخولك للمتابعة.",
        unconfirmed: "أنت بحاجة لتأكيد بريدك الالكترونيّ للمتابعة.",
      },
      mailer: {
        confirmation_instructions: {
          subject: "تعليمات تأكيد البريد الالكترونيّ",
        },
        reset_password_instructions: {
          subject: "تعليمات إعادة تعيين كلمة المرور",
        },
        unlock_instructions: {
          subject: "تعليمات إلغاء الحظر",
        },
        password_change: {
          subject: "تمّ تغيير كلمة المرور",
        },
      },
      omniauth_callbacks: {
        failure: "لم نستطع تسجيلك عبر {kind} بسبب {reason}.",
        success: "تمّ تسجيلك بنجاح عبر حسابك في {kind} .",
      },
      passwords: {
        no_token:
          "  لا يمكنك الوصول إلى هذه الصّفحة إلاّ عبر رسالة إعادة تعيين كلمة المرور، إذا حاولت فعلاً الوصول لها عبر صفحة إعادة تعيين كلمة المرور، رجاء تأكّد أنّك استخدمت العنوان المرسل لك كاملاً .",
        send_instructions:
          "سوف يصلك بريد إلكترونيّ بتعليمات إعادة تعيين كلمة المرور خلال دقاىق.",
        send_instructions_button: "أرسل تعليمات إعادة تعيين كلمة المرور",
        send_paranoid_instructions:
          "  إذا كان بريدك الالكترونيّ موجودًا في نظامنا فسوف يصلك رابط لاسترجاع كلمة المرور في بريدك خلال دقائق..",
        updated: "تمّ تغيير كلمة مرورك بنجاح، أنت الآن مسجّل الدّخول.",
        updated_not_active: "تمّ تغيير كلمة مرورك بنجاح.",
        change_your_password: "غير كلمة المرور الخاصة بك",
        current_password: "كلمة المرور الحالية",
        current_password_description:
          "نحتاج إلى كلمة المرور الحالية لتأكيد التغييرات التي ستجريها",
        password_leave_blank: "اتركه فارغة إذا كنت لا تريد تغييره",
      },
      registrations: {
        email: "البريد الإلكتروني",
        name: "الاسم",
        mobile_number: "رقم الجوال مثال (٩٦٦٥١٢٣٤٥٦٧٨)",
        password: "كلمة المرور",
        confirm_password: "تأكيد كلمة المرور",
        destroyed:
          "وداعًا، تمّ إلغاء  حسابك بنجاح، نأمل أن نراك مرّة أخرى قريبًا.",
        signed_up: "مرحبًا بك، تمّ تسجيلك بنجاح في الموقع.",
        signed_up_but_inactive:
          "  تمّ تسجيلك بنجاح في الموقع، لكنّك لن تتمكّن من تسجيل الدّخول قبل تفعيل حسابك.",
        signed_up_but_locked:
          "  تمّ تسجيلك بنجاح في الموقع، لكنّك لن تتمكّن من تسجيل الدّخول إذ أنّ حسابك تمّ حظره.",
        signed_up_but_unconfirmed:
          "  لقد تمّ إرسال رسالة إلى بريدك الالكتروني بتعليمات تفعيل الحساب، فضلاً قم بالضّغط على العنوان المرفق  لتفعيل حسابك.",
        update_needs_confirmation:
          "  لقد قمت بتحديث حسابك بنجاح، لكنّك بحاجة إلى تأكيد بريدك الإلكتروني الجديد، فضلاً قم بتفقّد بريدك الالكترونيّ واضغط على رابط التأكيد المرفق لتأكيد بريدك الالكتروني الجديد.",
        updated: "تمّ تحديث حسابك بنجاح.",
        minimum_characters: "احرف كحد أدنى",
        cancel_my_account: "إلغاء حسابي",
        unhappy: "غير راضي؟",
      },
      sessions: {
        signed_in: "تمّ تسجيل دخولك بنجاح.",
        signed_out: "تمّ تسجيل خروجك.",
        already_signed_out: "لقد قمت فعلاً بتسجيل خروجك.",
        remember_me: "تذكرني",
      },
      unlocks: {
        send_instructions:
          "سوف تصلك رسالة على بريدك الالكترونيّ بتعليمات إلغاء الحظر عن حسابك.",
        send_paranoid_instructions:
          "إذا كان حسابك موجودًا في النّظام، فسوف تصلك رسالة بتعليمات إلغاء حظره خلال دقائق.",
        unlocked: "تمّ إلغاء الحظر عن حسابك بنجاح، قم بتسجيل الدّخول للمتابعة.",
      },
      shared_links: {
        log_in: "تسجيل الدخول",
        sign_up: "تسجيل",
        forgot_your_password: "هل نسيت كلمة المرور؟",
        "didn't_receive_confirmation_instructions":
          "هل لم تصلك معلومات التأكيد لحسابك؟",
        "didn't_receive_unlock_instructions":
          "هل لم تصلك معلومات إلغاء الحضر عن حسابك؟",
        sign_in_with: "تسجيل الدخول بي",
        no_account: "ليس لديك حساب؟",
        register_now: "سجل الآن",
        already_have_account: "لديك حساب بالفعل؟",
      },
    },
    errors: {
      messages: {
        email_required: "البريد الالكترونيّ مطلوب.",
        email_validation: "البريد الالكترونيّ غير صحيح.",
        password_required: "كلمة المرور مطلوبة.",
        name_required: "الاسم مطلوب.",
        // mobile_required: "رقم الجوال مطلوب.",
        mobile_validation: "رقم الجوال غير صحيح.",
        password_required: "كلمة المرور مطلوبة.",
        password_minimum_length: `كلمة المرور يجب أن تكون على الأقل {minimum_password_length} أحرف.`,
        password_confirmation_required: "تأكيد كلمة المرور مطلوب.",
        password_minimum_length: `كلمة المرور يجب أن تكون على الأقل {minimum_password_length} أحرف.`,
        password_confirmation_match: "تأكيد كلمة المرور غير متطابق.",

        already_confirmed:
          "لقد تمّ تأكيد بريدك الالكترونيّ سابقًا، فضلاً حاول تسجيل الدّخول.",
        confirmation_period_expired:
          "أنت بحاجة لتأكيد حسابك خلال {period} ، فضلاً قم بطلب تأكيد بريدك الالكترونيّ من جديد.",
        expired: "انتهت صلاحيته، فضلاً قم بطلب واحد جديد.",
        not_found: "لا يوجد.",
        not_locked: "لم يكن حسابك محظورًا.",
        not_saved: {
          one: "حدث خطأ واحد منع حفط ال{resource} ",
          other: "{count} أخطاء منعت {resource} حفظ الـ:",
        },
      },
    },
    collect_data: "اجمع البيانات",
    make_better_decisions: "اتخذ قرارات أفضل",
    description:
      "رزين هو برنامج إدارة المشاركات والترشيحات يساعد الفرق على مراجعة كميات هائلة من المشاركات و اتخاذ القرارات بناء عليها بسهولة. يوفر ميزات تخصيص عملية و مراجعة قوية ، وواجهة سهلة الاستخدام للجميع.",

    language: "English",
  },
});

strings.setLanguage(locale || "en");

// const t = (string) => strings[string] || string;

const t = (key, variables) => {
  const keys = key.split(".");
  let value = strings;
  for (const k of keys) {
    value = value[k];
    if (!value) {
      return key;
    }
  }

  if (typeof value === "string" && variables) {
    return value.replace(
      /{([^}]+)}/g,
      (match, variable) => variables[variable] || match
    );
  }

  return value;
};

export default t;
