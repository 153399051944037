export const FETCH_ROLES = "FETCH_ROLES";
export const FETCH_ROLES_PENDING = "FETCH_ROLES_PENDING";
export const FETCH_ROLES_REJECTED = "FETCH_ROLES_REJECTED";
export const FETCH_ROLES_FULFILLED = "FETCH_ROLES_FULFILLED";

export const INVITE_MEMBERS = "INVITE_MEMBERS";
export const INVITE_MEMBERS_PENDING = "INVITE_MEMBERS_PENDING";
export const INVITE_MEMBERS_REJECTED = "INVITE_MEMBERS_REJECTED";
export const INVITE_MEMBERS_FULFILLED = "INVITE_MEMBERS_FULFILLED";

export const FETCH_MEMBERS = "FETCH_MEMBERS";
export const FETCH_MEMBERS_PENDING = "FETCH_MEMBERS_PENDING";
export const FETCH_MEMBERS_REJECTED = "FETCH_MEMBERS_REJECTED";
export const FETCH_MEMBERS_FULFILLED = "FETCH_MEMBERS_FULFILLED";

export const UPDATE_MEMBERS = "UPDATE_MEMBERS";
export const UPDATE_MEMBERS_PENDING = "UPDATE_MEMBERS_PENDING";
export const UPDATE_MEMBERS_REJECTED = "UPDATE_MEMBERS_REJECTED";
export const UPDATE_MEMBERS_FULFILLED = "UPDATE_MEMBERS_FULFILLED";

export const SET_INVITATION_DEFAULT_ROLE = "SET_INVITATION_DEFAULT_ROLE";
export const CHANGE_GLOBAL_ROLE = "CHANGE_GLOBAL_ROLE";
export const ADD_MEMBERS = "ADD_MEMBERS";
export const UPDATE_INDIVIDUAL_MEMBER_ROLE = "UPDATE_INDIVIDUAL_MEMBER_ROLE";
export const RESET_INVITATION_MEMBERS = "RESET_INVITATION_MEMBERS";
