import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../components";
import "./MembersButton.scss";
import translate from "./texts";

const MembersButton = ({ onClick }) => (
  <Button
    onClick={onClick}
    content={translate("Manage Members")}
    flatWhite
    small
    iconName="account-multiple-plus"
  />
);

MembersButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default MembersButton;
