import { createStore, applyMiddleware, compose } from "redux";
import promise from "redux-promise-middleware";
import * as Sentry from "@sentry/react";
import createReducer from "./reducers";

const middlewares = [promise()];
let composeEnhancers = compose;

// Those rules should disabled automaticely if the ENV is development but
// no method to do so eslint-plugin-import documentations where found.

if (process.env.NODE_ENV === "development") {
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  } else {
    console.info(
      "%cInstall redux dev tools %s",
      "font-weight:bold",
      "https://github.com/zalmoxisus/redux-devtools-extension"
    );
    const { logger } = require("redux-logger");
    middlewares.push(logger);
  }
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export function configureStore(initialState) {
  const store = createStore(
    createReducer(),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer)
  );

  store.asyncReducers = {};

  return store;
}

export function injectAsyncReducer(store, name, asyncReducer) {
  store.asyncReducers[name] = asyncReducer;
  store.replaceReducer(createReducer(store.asyncReducers));
}
