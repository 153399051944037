import * as types from "../types/program.types";

const initialProgram = {
  id: "",
  scoringCardId: "",
  name: "",
  rootFunnelId: "",
  assignmentStatistics: {},
  statistics: {
    submissionCount: 0,
    reviewedSubmissionCount: 0,
    submitterCount: 0,
    completionPercentage: 0,
  },
  questions: [],
  form: {
    formFields: {},
  },
  fetchedEmail: {},
  programFieldNames: [],
  programFields: [],
  funnels: [],
  inputSources: {},
};

const initialState = {
  isFetching: false,
  fetched: false,
  isDistributing: false,
  distributed: false,
  errors: [],
  program: initialProgram,
  message: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.RESET_PROGRAM:
      return initialState;

    case types.FETCH_PROGRAM:
      return initialState;

    case types.FETCH_PROGRAM_PENDING:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };

    case types.FETCH_PROGRAM_REJECTED:
      return {
        ...state,
        isFetching: false,
        fetched: false,
        errors: action.payload.response
          ? action.payload.response.data.errors
          : "",
      };

    case types.FETCH_PROGRAM_FULFILLED:
      return {
        ...state,
        isFetching: false,
        fetched: true,
        errors: [],
        ...action.payload.data,
        program: {
          ...state.program,
          ...action.payload.data.program,
        },
      };

    case types.UPDATE_PROGRAM_NAME_FULFILLED:
      return {
        ...state,
        isFetching: false,
        fetched: true,
        errors: [],
        program: {
          ...state.program,
          ...action.payload.data.program,
        },
      };

    case types.DISTRIBUTE_SUBMISSIONS_PENDING:
      return {
        ...state,
        isDistributing: true,
        errors: [],
      };

    case types.DISTRIBUTE_SUBMISSIONS_REJECTED:
      return {
        ...state,
        isDistributing: false,
        distributed: false,
        errors: action.payload.response.data.errors,
      };

    case types.DISTRIBUTE_SUBMISSIONS_FULFILLED:
      return {
        ...state,
        isDistributing: false,
        distributed: true,
        errors: [],
        message: action.payload.data.message,
      };
    case types.FETCH_EMAIL_FULFILLED:
      return {
        ...state,
        fetchedEmail: action.payload.data.email,
      };

    case types.FETCH_INPUT_SOURCES_FULFILLED:
      return {
        ...state,
        program: {
          ...state.program,
          inputSources: action.payload.data.inputSources,
        },
      };

    default:
      return state;
  }
}
