export const FETCH_FUNNEL_PUBLIC_ACCESS = "FETCH_FUNNEL_PUBLIC_ACCESS";
export const FETCH_FUNNEL_PUBLIC_ACCESS_PENDING =
  "FETCH_FUNNEL_PUBLIC_ACCESS_PENDING";
export const FETCH_FUNNEL_PUBLIC_ACCESS_REJECTED =
  "FETCH_FUNNEL_PUBLIC_ACCESS_REJECTED";
export const FETCH_FUNNEL_PUBLIC_ACCESS_FULFILLED =
  "FETCH_FUNNEL_PUBLIC_ACCESS_FULFILLED";
export const SET_FUNNEL_PUBLIC_ACCESS = "SET_FUNNEL_PUBLIC_ACCESS";
export const SET_FUNNEL_PUBLIC_ACCESS_FULFILLED =
  "SET_FUNNEL_PUBLIC_ACCESS_FULFILLED";
