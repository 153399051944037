import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import "./InputRange.scss";

const Slider = (props) => {
  const values = props.choices.map((choice) => choice.label);

  const currentValueIndex = props.choices.findIndex(
    (choice) => choice.value === props.currentValue.value
  );

  const [value, setValue] = useState(currentValueIndex || 0);


  const [sliderBarValue, setSliderBarValue] = useState(0);
  const sliderRef = useRef(null);

  const trackSlider = () => {
    const slider = sliderRef.current;
    let val =
      (slider.value - slider.getAttribute("min")) / slider.getAttribute("max") -
      slider.getAttribute("min");
    setSliderBarValue(val);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    props.onChange(props.choices[event.target.value]);
    trackSlider();
  };

  useEffect(() => {
    trackSlider();
  }, []);

  return (
    <div className="">
      <input
        ref={sliderRef}
        className="appearance-none bg-transparent range-slider-thumb-custom"
        style={{
          backgroundImage: `-webkit-gradient(linear, left top, right top,
            color-stop(${sliderBarValue}, #268aff),
            color-stop(${sliderBarValue}, #E3E3E3))`,
        }}
        type="range"
        min={0}
        max={values.length - 1}
        step={1}
        value={value}
        onChange={handleChange}
      />

      <div className="flex justify-between">
        {values.map((item, index, array) => {
          if (index === 0 || index === array.length - 1) {
            return (
              <div
                key={index}
                className={`text-xs text-gray-600 ${
                  index === value ? "text-blue-600" : ""
                }`}
              >
                {item}
              </div>
            );
          }
          return null;
        })}
      </div>

      <div className="mt-2">
        value:{" "}
        <span className="text-blue-600">{props.choices[value]?.value}</span>
        {/* label:{" "} */}
        {/* {props.choices[value]?.label} */}
      </div>
    </div>
  );
};

export default Slider;
