import React, { Component } from "react";
import PropTypes from "prop-types";

import Select from "react-select";
import { Button, Input, Modal } from "../../components";

import Permit from "../../layouts/Authorization";
import translate from "./texts";
import "../SubmissionLabel/SubmissionLabel.scss";
import { useSelector } from "react-redux";

class SubmissionStatus extends Component {
  state = {
    newStatus: "",
    notes: "",
    showModal: false,
  };

  inputRef = React.createRef();

  onStatusInputChange = (e) => {
    this.setState({ newStatus: e.target.value });
  };

  onNotesInputChange = (e) => {
    this.setState({ notes: e.target.value });
  };

  onStatusInputBlur = (e) => {
    if (e.target.value.length === 0) {
      this.clearAndToggleInput();
    }
  };

  addNewStatus = () => {
    this.props.addStatus({
      status: this.state.newStatus,
      notes: this.state.notes,
    });
    this.clearAndToggleInput();
    this.toggleModal();
  };

  onStatusInputKeyUp = (e) => {
    if (e.key === "Enter") {
      this.addNewStatus();
    }
  };

  clearAndToggleInput = () => {
    this.setState(
      (prevState) => ({
        newStatus: "",
        notes: "",
        toggleNewStatus: !prevState.toggleNewStatus,
      }),
      () => {
        if (this.state.toggleNewStatus) {
          // this.inputRef.current.querySelector("input").focus();
        }
      }
    );
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  render() {
    const { creatable, isFetching, programId } = this.props;

    const { newStatus, notes, showModal, toggleNewStatus } = this.state;

    // const trigger = (
    //   <Button
    //     loading={isFetching}
    //     content={translate("Tracking Status")}
    //     iconName="label"
    //     small
    //     flatWhite
    //     onClick={() => {}}
    //   />
    // );

    return (
      <div>
        <Button
          loading={isFetching}
          content={translate("Tracking Status")}
          iconName="label"
          small
          flatWhite
          onClick={this.toggleModal}
        />

        <Modal active={showModal} toggle={this.toggleModal}>
          <div className="bg-white">
            <Permit
              targetPrivilege="submissions.status_tracking"
              targetId={programId}
            >
              <div className="flex flex-col">
                {creatable && !toggleNewStatus && (
                  <Button
                    onClick={this.clearAndToggleInput}
                    content={translate("New Tracking Status")}
                    flatWhite
                    small
                  />
                )}

                {creatable && toggleNewStatus && (
                  <div ref={this.inputRef} className="">
                    <SubmissionStatusSelect
                      onChange={this.onStatusInputChange}
                      value={newStatus}
                    />

                    <Input
                      multiline
                      inputName="new-label"
                      placeholder={"notes"}
                      onChange={this.onNotesInputChange}
                      value={notes}
                      // onBlur={this.onStatusInputBlur}
                      // onKeyUp={this.onStatusInputKeyUp}
                    />

                    <Button
                      onClick={this.addNewStatus}
                      iconName="check"
                      flatWhite
                      style={{
                        width: newStatus.length > 0 ? "32px" : "0.001px",
                        overflow: "hidden",
                      }}
                    />
                    <Button
                      onClick={this.clearAndToggleInput}
                      iconName="close"
                      flatWhite
                      style={{
                        width: newStatus.length > 0 ? "32px" : "0.001px",
                        overflow: "hidden",
                      }}
                    />
                  </div>
                )}
              </div>
            </Permit>
          </div>
        </Modal>
      </div>
    );
  }
}

SubmissionStatus.propTypes = {
  addStatus: PropTypes.func.isRequired,
  creatable: PropTypes.bool,
  programId: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

SubmissionStatus.defaultProps = {
  creatable: false,
};

export default SubmissionStatus;

function SubmissionStatusSelect({ onChange, value }) {
  const statuses = useSelector(
    (state) => state.submissions.externalSubmissionStatuses
  );

  const options = statuses.map((status) => ({
    label: status,
    value: status,
  }));

  return (
    <Select
      className="w-full "
      options={options}
      onChange={(value) => {
        onChange({ target: { value: value.value } });
      }}
      value={options.find((option) => option.value === value)}
      searchable={false}
      // clearable={false}
    />
  );
}
