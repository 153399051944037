import LocalizedStrings from "react-localization";
import validations from "../../components/FormRenderer/validation-messages";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    form_submit_button: "Submit",
    loading: "Loading...",
    success_msg: "Thank you! Your submission has been saved successfully.",
    newFieldTitle: "Field Title",
    textBox: "Short Text",
    textBoxMultiline: "Long Text",
    error_msg: "Please try again later",
    saveNewField: "SAVE",
    shareFormLink: "Share Form",
    programField: "Submission Form",
    designFormDescription:
      "design your form here, choose the type of questions and answers.",
    embed_code: "Embed code in your website",
    cancelNewField: "CANCEL",
    required: "Required",
    share_this_link_receive_submissions:
      "Share this link to receive submissions",
    formPreview: "Field Preview",
    previewOnly: "Preview Only",
    addNewField: "ADD NEW FIELD",
    form_link: "Form Link",
    close: "Close",
    radioButton: "Radio (Single Choice)",
    options: "Options",
    addNewOption: "Add new option",
    checkBox: "Checkboxes (Multiple Choice)",
    select: "List Select (Single Choice)",
    fileUploader: "File Uploader",
    nameField: "Name field",
    phone: "Phone number",
    email: "Email",
    address: "Address",
    Divider: "Divider",
    withCity: "With city field",
    country: "Country",
    dateField: "Date",
    "Enable Form": "Allow users to submit this form.",
    "Form is disabled": "This form is currently unavaliable",
    fileSizeOption: "Maximum file size (Megabytes)",
    fileType: "File types",
    imageType: "Images",
    docsType: "Documents",
    videoType: "Video",
    delete_confirmation_message: "Want to remove this field?",
    yes: "Yes",
    remove: "Remove",
    starRating: "Star Rating",
    formTitle: "Form Title",
    cityTitle: "City title",
    timeField: "Time",
    draft_saved_successfully: "Draft Saved Successfully!",
    draft_url_description: "Please use the following URL to load your draft:",
    numberOfStars: "Number of stars",
    "Enable drafting": "Enable saving drafts",
    "Choose file types": "Please choose at least one file types category",
    enable_rtl_direction: "Enable right alignment",
    close_draft_url_box: "Close",
    "Max size": "Max size in Kilobyte: ",
    "You maximized the size":
      "You've exceeded the filesize limit in kilobytes: ",
    change_logo: "Change Logo",
    upload_logo: "Uplaod Logo",
    primaryEmail: "Contact Email",
    save: "Save",
    titleValidation: "Field Title cannot be empty",
    contactEmailWarning:
      "Don't forget to add an email field in your form to be able to contact applicants.",
    startDateValidation: "Starting date",
    endDateValidation: "Ending date",
    dataRangeValidation: "Restrict date range",
    number: "Number",
    minimumLength: "Minimum length",
    maximumLength: "Maximum length",
    optionsListPlaceholder: "Place each option on a new line.",
    appendList: "Append List",
    visibilityLogic: "Visibility logic",
    newCondition: "Add new condition",
    visibilityHelpText: "Field is visible only when the conditions are met",
    selectField: "Select field",
    selectOperator: "Select operator",
    value: "Value",
    is_exactly: "Is exactly",
    hidden: "Hidden",
    ...validations.en,
    paragraph: "Paragraph",
    duplacteOptions: "Duplacte options are not allowed",
  },
  ar: {
    form_submit_button: "إرسال",
    loading: "تحميل ...!",
    success_msg: "شكرا لك، تم استلام مشاركتك بنجاح",
    newFieldTitle: "عنوان الحقل",
    textBox: "نص قصير",
    textBoxMultiline: "نص طويل",
    saveNewField: "حفظ",
    shareFormLink: "شارك النموذج",
    programField: "نموذج المشاركة",
    designFormDescription:
      "قم بتصميم نموذج المشاركة من هنا، اختر نوعية الاسئلة والأجوبة.",
    error_msg: "خطأ، رجاء المحاولة مرة أخري",
    cancelNewField: "إلغاء التغييرات",
    required: "حقل إجباري",
    embed_code: "تضمين الكود فى موقعك",
    share_this_link_receive_submissions:
      "شارك هذا الرابط لتتمكن من استقبال المشاركات",
    formPreview: "معاينة الحقل",
    previewOnly: "معاينة فقط",
    addNewField: "أضف حقلاً جديدًا",
    form_link: "رابط النموذج",
    close: "إغلاق",
    radioButton: "خيارات متعددة (اختيار فردي)",
    options: "الخيارات",
    checkBox: "خيارات متعددة (اختيار متعدد)",
    addNewOption: "اضف خيار جديد",
    select: "قائمة (اختيار فردي)",
    fileUploader: "رفع ملفّات",
    nameField: "الاسم",
    phone: "رقم الهاتف",
    email: "البريد الالكتروني",
    address: "العنوان",
    Divider: "فاصل",
    withCity: "حقل المدينة",
    country: "الدولة",
    dateField: "التاريخ",
    "Enable Form": "تفعيل استقبال المشاركات",
    "Form is disabled": "هذا النّموذج غير متاح للمشاركة حاليًا",
    fileSizeOption: "(الحجم الأقصى (ميغابايت",
    fileType: "نوع الملف",
    imageType: "صور",
    docsType: "مستندات",
    videoType: "فيديو",
    delete_confirmation_message: " هل تريد حذف الحقل ؟",
    yes: "نعم",
    remove: "حذف",
    starRating: "تقيم بالنجوم",
    formTitle: "عنوان النموذج",
    cityTitle: "عنوان حقل المدينة",
    timeField: "الوقت",
    draft_saved_successfully: "تم حفظ المسودة بنجاح",
    draft_url_description: "يرجى استخدام الرابط التالي لتحميل المسودة:",
    numberOfStars: "عدد النجوم",
    "Enable drafting": "تفعيل حفظ المسودّات",
    "Choose file types": "فضلاً اختر على الأقل نوعًا واحدًا من الملفات",
    enable_rtl_direction: "تفعيل المحاذاة من اليمين لليسار",
    close_draft_url_box: "إغلاق",
    "Max size": "(الحجم الأقصى (كيلوبايت: ",
    "You maximized the size":
      "الملف الذي حملته تجاوز الحجم الأقصى المسموح بالكيلوبايت: ",
    change_logo: "تغيير الشعار",
    upload_logo: "ارفع الشعار",
    primaryEmail: "البريد الالكتروني الخاص بالتواصل",
    save: "حفظ",
    titleValidation: "فضلاً، قم بملء حقل العنوان",
    contactEmailWarning:
      "لا تنس إضافة حقل بريد إلكتروني في النموذج لتتمكن من التواصل مع المشاركين لاحقاً.",
    startDateValidation: "تاريخ البداية",
    endDateValidation: "تاريخ النهاية",
    dataRangeValidation: "تقييد التاريخ لفترة محددة",
    number: "الوقت",
    minimumLength: "الحد الأدنى لعدد الأرقام",
    maximumLength: "الحد الأعلى لعدد الأرقام",
    optionsListPlaceholder: "أدخل كل خيار في سطر جديد",
    appendList: "أضيف القائمة",
    visibilityLogic: "شروط الظهور",
    newCondition: "اظف شرط جديد",
    visibilityHelpText: "يكون الحقل ظاهراً فقط عند ما تتحقق الشروط",
    selectField: "اختر حقل",
    selectOperator: "اختر عامل المقارنة",
    value: "القيمة",
    is_exactly: "يساوي",
    hidden: "مخفي",
    ...validations.ar,
    paragraph: "وصف",
    duplacteOptions: "غيد مسموح بالتكرار",
  },
});

strings.setLanguage(locale || "en");

const translate = (string) => strings[string] || string;

export default translate;
