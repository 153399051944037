import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { store } from "..";
import fetchPrivileges from "../actions/auth.actions";
import { Route as RouteDOM, Switch } from "react-router-dom";
import WorkspacesContainer from "./Workspaces";
import ProgramContainer from "./Program";
import { useAuthContext } from "../hooks/useAuthContext";
import useOldAuth from "../hooks/useOldAuth";

const Route = Sentry.withSentryRouting(RouteDOM);

export default function Dashboard() {
  const { user } = useAuthContext();

  const oldAuth = useOldAuth();

  useEffect(() => {
    // Fetch privileges and store them in Redux store on app load
    store.dispatch(fetchPrivileges());
  }, [user]);

  const isMOC = process.env.REACT_APP_THEME === "moc" ? true : false;

  const [fade, setFade] = useState(false);

  const [isloading, setIsloading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsloading(false);
    }, 1500);
  }, []);

  function Loading() {
    return (
      <div
        className={`h-screen w-screen grid place-items-center bg-white ${
          isloading ? "fade-in" : ""
        }`}
      >
        <img src={isMOC ? "/loader_moc.gif" : "/verse_loader.gif"} alt="as" />
      </div>
    );
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div className={fade ? "fade-out" : ""}>
      {/* <  fade={fade} setFade={setFade} /> */}

      <Switch>
        <Route
          exact
          path="/dashboard/workspaces/:workspaceId/:workspaceIndex"
          component={WorkspacesContainer}
        />
        <Route
          path="/dashboard/programs/:programId"
          component={ProgramContainer}
        />
        <Route path="*" component={WorkspacesContainer} />
      </Switch>
    </div>
  );
}
