import * as types from "../types/submissions.types";

const initialState = {
  isFetching: false,
  fetched: false,

  isDeleting: false,
  deleted: false,
  messages: null,

  error: {},
  submissions: [],
  programFieldNames: [],
  programFields: [],
  filtrationFields: [],
  program: {},
  pages: 0,
  sortBy: "created_at",
  direction: 1,
  errors: {},
  submission: {
    data: {
      attachments: [],
    },
    evaluations: [],
    funnelScores: [],
    funnelStatuses: [],
    labels: [],
    isFetching: false,
  },
  fetchHandler: () => {},
  chosenLabel: { key: "", name: "" },
  fetchedLabels: [],
  metadata: {
    filteredItemCount: 0,
    totalCount: 0,
  },
  submissionActivities: [],
  submissionStatuses: [],
  openFirstSubmission: false,

  externalSubmissionStatuses: [],
  allExternalSubmissionStatuses: [],

  submissionHistory: [],

  checkLabel: null,
  checkRemoveLabel: null,
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "RESET_CHECK_LABEL":
      return {
        ...state,
        checkLabel: null,
        checkRemoveLabel: null,
      };

    case types.CHECK_LABEL_FULFILLED: {
      return {
        ...state,
        checkLabel: action.payload.data,
      };
    }

    case types.CHECK_REMOVE_LABEL_FULFILLED: {
      return {
        ...state,
        checkRemoveLabel: action.payload.data,
      };
    }

    case types.ADD_LABEL_FULFILLED: {
      return {
        ...state,
        checkLabel: null,
      };
    }
    case types.REMOVE_SUBMISSION_LABEL_FULFILLED: {
      return {
        ...state,
        checkRemoveLabel: null,
      };
    }
    case types.SET_SORT_CONFIG:
      return {
        ...state,
        sortBy: action.sortBy,
        direction: action.direction,
      };

    case types.OPEN_FIRST_SUBMISSION:
      return {
        ...state,
        openFirstSubmission: true,
        firstSubmissionIdFromCurrentList: action.payload,
      };
    case types.FIRST_SUBMISSION_DID_OPEN:
      return {
        ...state,
        openFirstSubmission: false,
      };
    case types.RESET_SUBMISSIONS:
      return {
        ...state,
        submissions: [],
      };
    case types.FETCH_SUBMISSIONS_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_SUBMISSIONS_REJECTED:
      return {
        ...state,
        isFetching: false,
        fetched: false,
        error: { ...action.payload },
      };
    case types.FETCH_SUBMISSIONS_FULFILLED: {
      const {
        submissions,
        programFieldNames,
        programFields,
        filtrationFields,
        program,
        submissionStatuses,
        previousFunnelScores,
      } = action.payload.data;
      // TODO: avoid dividing by 0.
      const pages = Math.ceil(
        action.payload.data.filteredItemCount / action.payload.data.limit
      );
      const metadata = { ...action.payload.data.Metadata };

      return {
        ...state,
        isFetching: false,
        fetched: true,
        error: {},
        submissions,
        programFieldNames,
        programFields,
        filtrationFields,
        submissionStatuses,
        program,
        pages,
        metadata,
        previousFunnelScores,
      };
    }

    case types.UPDATE_SUBMISSIONS_FULFILLED: {
      const { submission } = action.payload.data;

      const submissions = state.submissions.map((s) => {
        if (s.id === submission.id) {
          return submission;
        }
        return s;
      });

      return {
        ...state,
        submissions,
      };
    }

    case types.FETCH_SUBMISSION_ACTIVITIES_FULFILLED: {
      const { submissionActivities } = action.payload.data;
      return {
        ...state,
        isFetching: false,
        fetched: true,
        error: {},
        submissionActivities,
      };
    }
    case types.FETCH_SUBMISSION_PENDING:
      return {
        ...state,
        submission: {
          ...state.submission,
          isFetching: true,
        },
      };
    case types.FETCH_SUBMISSION_FULFILLED:
      return {
        ...state,
        submission: {
          ...action.payload.data.submission,
          isFetching: false,
        },
      };
    case types.FETCH_SUBMISSION_REJECTED:
      return {
        ...state,
        submission: {
          ...state.submission,
          isFetching: false,
          error: { ...action.payload },
        },
      };
    case types.DELETE_SUBMISSION_PENDING:
      return {
        ...state,
        isDeleting: true,
        deleted: false,
      };
    case types.DELETE_SUBMISSION_FULFILLED:
      return {
        ...state,
        isDeleting: false,
        deleted: true,
        messages: action.payload.data.messages,
      };
    case types.FETCH_HIDDEN_COLUMNS:
      return {
        ...state,
        hiddenColumns: action.payload,
      };
    case types.SET_FETCH_SUBMISSIONS_HANDLER: {
      const { fetchHandler } = action;
      return {
        ...state,
        fetchHandler,
      };
    }
    case types.REMOVE_SUBMISSION_ASSIGNEE:
      return {
        ...state,
      };

    case types.ADD_EVALUATIONS_FULFILLED:
      const { submission } = action.payload.data;

      const submissions = state.submissions.map((s) => {
        if (s.id === submission.id) {
          return submission;
        }
        return s;
      });

      return {
        ...state,
        submission: action.payload.data.submission,
        submissions,
      };

    case types.CLEAR_EVALUATION_FULFILLED:
      return {
        ...state,
        submission: action.payload.data.submission,
        messages: action.payload.data.messages,
      };
    case types.UPDATE_REASON_FULFILLED:
      return {
        ...state,
        submission: action.payload.data.submission,
      };

    case types.SUBMISSION_STATUSES_FULFILLED:
      return {
        ...state,
        externalSubmissionStatuses: action.payload.data,
      };

    case types.ALL_SUBMISSION_STATUSES_FULFILLED:
      return {
        ...state,
        allExternalSubmissionStatuses: action.payload.data,
      };

    case types.FETCH_SUBMISSION_HISTORY_FULFILLED:
      return {
        ...state,
        submissionHistory: action.payload.data,
      };

    default:
      return state;
  }
}

// TODO: Separate filter reducer to filters.reducer.js file.
const initialFiltersState = {
  operators: {},
  filters: [],
  filter: {},
  filterActions: [],
};

// TODO: replace this function with the one in utils.
function uniqueArrayOfObjects(arr) {
  const unique = [];
  const ledger = {};
  arr.forEach((f) => {
    if (!ledger[f.id]) {
      unique.push(f);
    }
    ledger[f.id] = true;
  });
  return unique;
}

export function filtersReducer(state = initialFiltersState, action) {
  switch (action.type) {
    case types.FETCH_OPERATORS_FULFILLED: {
      const { operators } = action.payload.data;
      return {
        ...state,
        operators,
      };
    }
    case types.FETCH_FILTERS_FULFILLED: {
      const { filters } = action.payload.data;
      return {
        ...state,
        filters,
      };
    }
    case types.SAVE_FILTER_FULFILLED: {
      const { filter, errors } = action.payload.data;
      if (errors) {
        return state;
      }
      return {
        ...state,
        filters: uniqueArrayOfObjects([...state.filters, filter]),
      };
    }
    case types.DELETE_FILTER_FULFILLED: {
      const { filterId } = action.meta;
      return {
        ...state,
        filters: state.filters.filter((filter) => filter.id !== filterId),
      };
    }
    case types.FETCH_FILTER_ACTION_TYPES_FULFILLED: {
      const { filterActions } = action.payload.data;
      return {
        ...state,
        filterActions,
      };
    }
    default:
      return state;
  }
}
