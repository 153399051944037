import React from "react";
import BaseOptions from "./BaseOptions";

/**
 * @class FileFieldOptions
 * @extends BaseOptions
 * @description A component which contains a title and required field options
 */
export default class FileFieldOptions extends BaseOptions {
  render() {
    return (
      <div>
        {this.renderTitle()}
        {this.renderRequiredOption()}
        {this.renderVisibilityOption()}
        {this.renderMaxFileSizeOption()}
        {this.renderFileTypesOptions()}
      </div>
    );
  }
}
