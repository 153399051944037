import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { FooterActions, Box, Icon } from "../../components";
import Select from "react-select";
import MembersManagementContainer from "../MembersManagement/MembersManagementContainer";
import MembersManagementHeader from "../MembersManagement/Header/MembersManagementHeader";
import RoleSelect from "./RoleSelect";
import translate from "./texts";

class MembersInvitation extends PureComponent {
  render() {
    const {
      showMembersWithCustomRoles,
      globalMembersRole,
      roles,
      members,
      membersInvitationActions,
      addMembers,
      onGlobalRoleChange,
      onIndividualRoleChange,
    } = this.props;

    return (
      <div id="MembersInvitationContainer">
        <div className="add-member-section">
          <MembersManagementHeader />
          <div className="label">
            <p className="program-members-modal-subtitle invite-member">
              {translate("Invite team members to your workspace")}
            </p>
            <span data-tooltip={translate("data_tooltip_content")}>
              <Icon name="information" />
            </span>
          </div>
          <div className="add-member-inputs" id="invitationEmailArea">
            <Select.Creatable
              multi
              valueKey="id"
              labelKey="email"
              placeholder={translate("Enter email address")}
              clearable={false}
              arrowRenderer={null}
              noResultsText={null}
              onChange={addMembers}
              value={members}
            />
            {members.length > 0 && (
              <RoleSelect
                roles={[...roles, { id: "custom", name: "Custom Role" }]}
                value={globalMembersRole}
                onChange={onGlobalRoleChange}
              />
            )}
            {members.length > 0 && showMembersWithCustomRoles && (
              <Box dark>
                {members.map((member) => (
                  <div key={member.id} className="invited-member-row">
                    <span className="invited-member-email">{member.email}</span>
                    <span className="invited-member-role">
                      <RoleSelect
                        roles={roles}
                        value={member.role}
                        onChange={(option) =>
                          onIndividualRoleChange(option, member.email)
                        }
                      />
                    </span>
                  </div>
                ))}
              </Box>
            )}
          </div>
        </div>
        <FooterActions actions={membersInvitationActions} />
        <MembersManagementContainer />
      </div>
    );
  }
}

MembersInvitation.propTypes = {
  showMembersWithCustomRoles: PropTypes.bool.isRequired,
  globalMembersRole: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  members: PropTypes.array.isRequired,
  membersInvitationActions: PropTypes.array.isRequired,
  addMembers: PropTypes.func.isRequired,
  onGlobalRoleChange: PropTypes.func.isRequired,
  onIndividualRoleChange: PropTypes.func.isRequired,
};

export default MembersInvitation;
