import React, { useState } from "react";
import AuthLayout from "./AuthLayout";
import { useForm } from "react-hook-form";
import Cookies from "universal-cookie";

import t from "./texts";

import EyeIcon from "./images/eye.svg";
import { useDispatch, useSelector } from "react-redux";
import { SignInAction } from "../../actions/auth.actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function SignIn(props) {
  const cookies = new Cookies();

  const history = useHistory();
  const dispatch = useDispatch();

  const { signInError, signInLoading } = useSelector((state) => {
    return {
      signInLoading: state.privileges.signInLoading,
      signInError: state.privileges.signInError,
    };
  });

  const user = cookies.get("user");
  const token = cookies.get("Rtoken");

  if (user && token) {
    history.push("/dashboard");
  }

  const [showPassword, setShowPassword] = useState(false);

  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { register, handleSubmit, formState, watch } = form;

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const submitSuccess = (data, e) => {
    dispatch(SignInAction(data));
  };
  const submitError = (errors, e) => {
    console.log("submit error:", errors);
  };

  return (
    <AuthLayout>
      <div>
        <h2>{t("devise.shared_links.log_in")}</h2>
        <form onSubmit={handleSubmit(submitSuccess, submitError)}>
          {signInError?.errors && (
            <p className="text-sm text-red-600 mb-2">{signInError.errors[0]}</p>
          )}
          <div className="mb-4">
            <div className="field !mb-0">
              <input
                type="email"
                name="email"
                autoFocus={true}
                placeholder={t("devise.registrations.email")}
                {...register("email", {
                  required: {
                    value: true,
                    message: t("errors.messages.email_required"),
                  },
                  maxLength: 128,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t("errors.messages.email_validation"),
                  },
                })}
              />
            </div>
            {formState.errors.email && (
              <p className="text-xs text-red-600 mt-1">
                {formState.errors.email.message}
              </p>
            )}
          </div>

          <div className="mb-4">
            <div className="field !mb-0">
              <input
                type={showPassword ? "text" : "password"}
                name="email"
                placeholder={t("devise.registrations.password")}
                autoComplete="off"
                {...register("password", {
                  required: {
                    value: true,
                    message: t("errors.messages.password_required"),
                  },
                  maxLength: 128,
                  // pattern: {
                  //   value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                  //   message: t("errors.messages .password_validation"),
                  // },
                })}
              />
              <span className="password-toggle" onClick={handlePasswordToggle}>
                <img src={EyeIcon} alt="eye-icon" className="eye-icon w-6" />
              </span>
            </div>
            {formState.errors.password && (
              <p className="text-xs text-red-600 mt-1">
                {formState.errors.password.message}
              </p>
            )}
          </div>

          <div className="actions">
            <button
              type="submit"
              disabled={formState.isSubmitting || signInLoading}
            >
              {t("devise.shared_links.log_in")}
            </button>
          </div>
        </form>

        <div className="">
          <a href="/#/forgot-password" className="underline">
            {t("devise.shared_links.forgot_your_password")}
          </a>
        </div>
        {/* <div className="">
          {t("devise.shared_links.no_account")}{" "}
          <a href="/#/signup" className="underline">
            {t("devise.shared_links.register_now")}
          </a>
        </div> */}
      </div>
    </AuthLayout>
  );
}

export default SignIn;
