import axios from "axios";
import * as types from "../types/notifications.types";

export function fetchNotifications(programId, status, pageNumber = 1) {
  return {
    type: types.FETCH_NOTIFICATIONS,
    payload: axios.get(
      `${process.env.REACT_APP_API_URL}/programs/${programId}/notifications/${pageNumber}?status=${status}`
    ),
  };
}

export function updateNotificationStatus(notificationId, status) {
  return {
    type: types.MARK_READ,
    payload: axios.put(
      `${process.env.REACT_APP_API_URL}/user_notifications/${notificationId}`,
      {
        status,
      }
    ),
  };
}

export function resetNotifications() {
  return {
    type: types.RESET_NOTIFICATIONS,
  };
}
