/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, Icon, Avatar, Button } from "../../../../components";
import { toggleProgramMembersModal } from "../../../../actions/program-members-modal.actions";
import { fetchProgramMembers } from "../../../../actions/members-management.actions";
import { difference } from "lodash";
import "./UserAssignments.scss";
import translate from "./texts";

const UserAssignments = ({
  users,
  selectedUsers,
  groups,
  handleFieldChange,
  programId,
}) => {
  const dispatch = useDispatch();
  const usersRef = useRef(users);
  const { posted } = useSelector((state) => state.membersInvitation);

  useEffect(() => {
    const newUsers = difference(
      users.map((i) => i.user.id),
      usersRef.current.map((i) => i.user.id)
    );
    if (newUsers.length && posted) {
      handleFieldChange(newUsers);
    }
  }, [users]);

  return (
    <div className="users_list">
      <Box>
        {groups.length + users.length === 0 && (
          <div className="no-members">{translate("noMemebersInvited")}</div>
        )}

        {groups.map((group) => (
          <div
            className={`assignment_item group_item ${
              selectedUsers.indexOf(group.id) > -1 ? "selected" : null
            }`}
            onClick={() => handleFieldChange(group.id)}
            onKeyUp={() => handleFieldChange(group.id)}
            role="presentation"
            key={group.id}
          >
            <Icon name="account-multiple" />
            <div className="item_name">{group.name}</div>
          </div>
        ))}
        {users.map(({ user }) => (
          <div
            className={`assignment_item user_item ${
              selectedUsers.indexOf(user.id) > -1 ? "selected" : null
            }`}
            onClick={() => handleFieldChange(user.id)}
            onKeyUp={() => handleFieldChange(user.id)}
            role="presentation"
            key={user.id}
          >
            <Avatar />
            <div className="item_name">{user.email}</div>
          </div>
        ))}
        <Button
          iconName="plus-circle"
          wide
          flat
          content={translate("Add")}
          onClick={() => {
            dispatch(toggleProgramMembersModal());
            dispatch(fetchProgramMembers(programId));
          }}
        />
      </Box>
    </div>
  );
};

UserAssignments.propTypes = {
  users: PropTypes.array.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
};

export default UserAssignments;
