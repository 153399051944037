import React from "react";
import PropTypes from "prop-types";
import "./Divider.scss";

/*
use this component as follows:
1- by default it have a light-grey border-bottom of 1px.
2- if you want to add title with the divider, use the title attr.
3- if you want to add a caption, use the caption attr.
4- if you want to change the position of the divider to be at the top, just add upper in the tag.
*/
const Divider = ({ title, caption, upper, light }) => (
  <div
    className={`tiny-sub-heading divider
                  ${light ? "divider-light" : "divider-dark"}
                  ${upper ? "divider-top" : "divider-bottom"}`}
  >
    {caption && <p className="caption">{caption}</p>}
    {title}
  </div>
);

Divider.propTypes = {
  title: PropTypes.string,
  caption: PropTypes.string,
  upper: PropTypes.bool,
  light: PropTypes.bool,
};

Divider.defaultProps = {
  title: "",
  caption: "",
  upper: false,
  light: false,
};

export default Divider;
