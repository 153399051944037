import React from "react";
import PropTypes from "prop-types";
import { Modal, Box, FooterActions } from "../../../components";
import LogoUploader from "./LogoUploader";
import translate from "../texts";
import "./LogoUploaderModal.scss";

class LogoUploaderModal extends React.Component {
  constructor(props) {
    super(props);

    this.oldLogo = null;
  }

  state = {
    logoImage: null,
  };

  UNSAFE_componentWillMount() {
    const { logoImage } = this.props;

    if (logoImage) {
      this.oldLogo = logoImage;
      this.setState({
        logoImage,
      });
    }
  }

  onUploadComplete = (fileName) => {
    const fullFileUrl =
      process.env.REACT_APP_AWS_S3_BUCKET_PATH + "/" + fileName;
    this.setState(
      {
        logoImage: fullFileUrl,
      },
      this.props.onSave(fullFileUrl)
    );
  };

  resetLogoImage = () => {
    this.setState({ logoImage: this.props.logoImage });
  };

  render() {
    const { active, toggleModal } = this.props;
    const { logoImage } = this.state;

    return (
      <div id="logo-uploader-modal">
        <Modal
          active={active}
          toggle={() => {
            this.resetLogoImage();
            toggleModal();
          }}
        >
          <div>
            <Box>
              <div className="logo-uploader-modal-content">
                <h6>{translate("upload_logo")}</h6>
                {logoImage ? (
                  <div>
                    <div className="logo-container">
                      <Box>
                        <img src={logoImage} alt="form logo" />
                      </Box>
                    </div>
                    <FooterActions
                      actions={[
                        {
                          content: translate("remove"),
                          flat: true,
                          wide: true,
                          onClick: () => {
                            this.setState({ logoImage: null });
                            this.props.onSave(null);
                          },
                          iconName: "delete",
                        },
                      ]}
                    />
                  </div>
                ) : (
                  <div>
                    <LogoUploader onComplete={this.onUploadComplete} />
                  </div>
                )}
              </div>
            </Box>
          </div>
        </Modal>
      </div>
    );
  }
}

LogoUploaderModal.propTypes = {
  active: PropTypes.bool.isRequired,
  logoImage: PropTypes.string,
  toggleModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

LogoUploaderModal.defaultProps = {
  logoImage: null,
};

export default LogoUploaderModal;
