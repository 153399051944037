import React from "react";
import BaseOptions from "./BaseOptions";

/**
 * @class DateFieldOpions
 * @extends BaseOptions
 * @description A component which Date field options
 */
export default class DateFieldOpions extends BaseOptions {
  render() {
    return (
      <div>
        {this.renderTitle()}
        {this.renderVisibilityOption()}
        {this.renderRequiredOption()}
        {this.renderDateRange()}
      </div>
    );
  }
}
