import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    "Edit Fields": "Edit Fields",
    "Select Field": "Select Field",
    "Field Name": "Field Name",
    submit: "Submit",
  },
  ar: {
    "Edit Fields": "تعديل الحقول",
    "Select Field": "اختر حقل",
    "Field Name": "اسم الحقل",
    submit: "تقديم",
  },
});

strings.setLanguage(locale || "en");

const translate = (string) => strings[string] || string;

export default translate;
