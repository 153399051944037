import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button, Select } from "../../../components";
import uuid from "uid";
import { isFiltersFilled } from "../../../utils/utils";
import { debounce } from "lodash";

import FilterField from "../FilterField/FilterField";
import translate from "../texts";
import "../AdvancedSearch.scss";

const defaultFilters = (id = "initialFilter") => ({
  initialFilter: {
    id,
    field: {
      id: "",
      type: "string",
      category: "fields",
    },
  },
});

class FieldsList extends PureComponent {
  state = {
    selectedFilters: this.props.userSelectedFilters,
  };

  throttledFetchSubmissions = debounce(this.props.searchForSubmissions, 400, {
    leading: false,
  });

  applyAdvanceSearch = () => {
    this.props.setAdvanceSearchState(true);
    this.props.closeSearchControls();
    this.throttledFetchSubmissions();
  };

  onFilterChange = (id, property, value, labels = {}) => {
    const { selectedFilters } = this.state;
    const targetFilter = selectedFilters[id];

    const nextFilters = {
      ...selectedFilters,
      [id]: {
        ...targetFilter,
        [property]: value,
      },
    };

    if (property === "operator") {
      // update the operatorLabel on operator change.
      const updatedNextFilters = {
        ...nextFilters,
        [id]: { ...nextFilters[id], operatorLabel: labels[value] },
      };

      this.updateSelectedFiltersState(updatedNextFilters);
    } else if (property === "field") {
      const { filtrationFields } = this.props;
      const field = filtrationFields.filter((f) => f.id === value)[0];

      // reset operator and value on field change.
      const updatedNextFilters = {
        ...nextFilters,
        [id]: {
          id,
          field: {
            id: value,
            category: field.category,
            type: field.type,
            choices: field.choices,
          },
        },
      };

      this.updateSelectedFiltersState(updatedNextFilters);
    } else {
      this.updateSelectedFiltersState(nextFilters);
    }
  };

  updateSelectedFiltersState = (filters) => {
    const { updateSelectedFilters } = this.props;

    this.setState({ selectedFilters: filters }, updateSelectedFilters(filters));
  };

  selectFilter = () => {
    const { selectedFilters } = this.state;
    const newFilterId = uuid();
    const newSelectedFiltersState = {
      ...selectedFilters,
      [newFilterId]: defaultFilters(newFilterId).initialFilter,
    };

    this.updateSelectedFiltersState(newSelectedFiltersState);
  };

  removeFilter = (filterId) => {
    const { selectedFilters } = this.state;
    delete selectedFilters[filterId];
    const newSelectedFiltersState = {
      ...selectedFilters,
    };

    this.updateSelectedFiltersState(newSelectedFiltersState);
  };

  render() {
    const {
      newFilterCollapsed,
      filtrationFields,
      operators,
      questions,
      searchForSubmissions,
      toggleNewFilter,
      setFilterOperator,
    } = this.props;

    const { selectedFilters } = this.state;

    const filtersArray = Object.values(selectedFilters);

    return (
      <>
        {newFilterCollapsed && (
          <div className="filter-fields">
            <h2>{translate("criteria")}</h2>
            <div className="flex mb-2 mt-3">
              <h3 className="w-2/5 me-5">{translate("field")}</h3>
              <h3 className="w-1/5 me-1">{translate("operator")}</h3>
              <h3>{translate("value")}</h3>
            </div>
            {filtersArray.map((filter) => (
              <FilterField
                filter={filter}
                onFilterChange={this.onFilterChange}
                removeFilter={this.removeFilter}
                fetchSubmissions={searchForSubmissions}
                fields={filtrationFields}
                operatorsMap={operators}
                key={filter.id}
                questions={questions}
                isSingleField={!(filtersArray.length > 1)}
              />
            ))}

            <div className="flex my-3 gap-2 items-center justify-between">
              <div className="">Operator between filters</div>
              <div className="w-20">
                <Select
                  style={{
                    boxShadow: "none",
                    outline: "none",
                    background: "transparent",
                  }}
                  options={[
                    { value: "$or", label: "or" },
                    { value: "$and", label: "and" },
                  ]}
                  value={this.props.filterOperatorValue || "$and"}
                  clearable={false}
                  onChange={(e) => {
                    setFilterOperator(e.value);
                  }}
                />
              </div>
            </div>
            <div className="filter-controls">
              <Button
                id="add-button"
                onClick={this.selectFilter}
                iconName="plus-circle"
                content={translate("New Filter")}
                flat
                small
                wide
              />
            </div>
          </div>
        )}
        {newFilterCollapsed && (
          <div className="advanced-search-footer flex">
            <>
              <Button
                flat
                onClick={toggleNewFilter}
                content={translate("create-filter")}
                className="filter ms-auto"
                small
                disabled={!isFiltersFilled(selectedFilters)}
              />
              <Button
                onClick={this.applyAdvanceSearch}
                content={translate("Search")}
                className="ms-3"
                small
                disabled={!isFiltersFilled(selectedFilters)}
              />
            </>
          </div>
        )}
      </>
    );
  }
}

FieldsList.propTypes = {
  newFilterCollapsed: PropTypes.bool.isRequired,
  filtrationFields: PropTypes.array.isRequired,
  questions: PropTypes.array.isRequired,
  operators: PropTypes.object.isRequired,
  searchForSubmissions: PropTypes.func.isRequired,
  updateSelectedFilters: PropTypes.func.isRequired,
  userSelectedFilters: PropTypes.object.isRequired,
  toggleNewFilter: PropTypes.func.isRequired,
  setAdvanceSearchState: PropTypes.func.isRequired,
  closeSearchControls: PropTypes.func.isRequired,
};

export default FieldsList;
