// FIXME: Potential bad use of snake case, switch everything to camel case
import React, { Component } from "react";
import PropTypes from "prop-types";
import Field from "./Field";
import fieldsDefinitions from "./fields-definitions";
import "./CreateField.scss";

class EditField extends Component {
  onSave = (fld) => {
    this.closeFieldView();
    this.props.saveField(fld);
  };

  closeFieldView = () => {
    this.props.removeFromEditList();
  };

  render = () => {
    const { schema } = this.props;
    const field = { ...fieldsDefinitions[schema.ref] };
    field.schema = {
      ...schema,
      validation: {
        required: schema.validation.required,
        startDate: schema.validation.startDate,
        endDate: schema.validation.endDate,
        maxFileSize:
          schema.validation.max_file_size || schema.validation.maxFileSize,
        fileTypes: schema.validation.file_types || schema.validation.fileTypes,
      },
    };
    field.options = {
      ...fieldsDefinitions[schema.ref].options,
      choices: schema.choices,
      title: schema.title,
      required: schema.validation.required,
      maxFileSize:
        schema.validation.max_file_size || schema.validation.maxFileSize,
      primary_email: schema.primary_email,
      fileTypes: schema.validation.fileTypes || schema.validation.file_types,
      size: schema.size,
      withCity: schema.withCity,
      cityTitle: schema.cityTitle,
      start_label: schema.start_label,
      end_label: schema.end_label,
      startDate: schema.validation.startDate,
      endDate: schema.validation.endDate,
      min: schema.min,
      max: schema.max,
      visibility: schema.visibility,
      visibilityOptions: schema.visibilityOptions
        ? [...schema.visibilityOptions]
        : [],
    };
    return (
      <div id="create-field-container">
        <Field
          field={field}
          onSave={this.onSave}
          closeFieldView={this.closeFieldView}
          deleteField={this.props.deleteField}
          fieldsList={this.props.fieldsList}
        />
      </div>
    );
  };
}

EditField.propTypes = {
  saveField: PropTypes.func.isRequired,
  schema: PropTypes.object.isRequired,
  removeFromEditList: PropTypes.func.isRequired,
  deleteField: PropTypes.func.isRequired,
  fieldsList: PropTypes.array.isRequired,
};

export default EditField;
