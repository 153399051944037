import Cookies from "universal-cookie";

const cookies = new Cookies();

export const isTokenExpired = () => {
  const tokenExpiry = cookies.get("tokenExpiry");
  const currentTime = Date.now() / 1000;
  return tokenExpiry < currentTime;
};

export const destroyToken = () => {
  cookies?.remove("Rtoken");
  cookies?.remove("user");
  cookies?.remove("tokenExpiry");
  cookies?.remove("refreshToken");
};

export const setToken = (token, tokenExpiry, user) => {
  const tokenExpiryDate = new Date(tokenExpiry * 1000);

  cookies.set("Rtoken", token, { path: "/", expires: tokenExpiryDate });
  cookies.set("tokenExpiry", tokenExpiry, {
    path: "/",
    expires: tokenExpiryDate,
  });

  user
    ? cookies.set("user", user, { path: "/", expires: tokenExpiryDate })
    : null;
};
