import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ProgramDashboard from "./ProgramDashboard";

const ProgramDashboardContainer = ({ program }) => (
  <ProgramDashboard program={program} />
);

ProgramDashboardContainer.propTypes = {
  program: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const programState = state.program;

  return {
    program: programState.program,
  };
};

const container = connect(mapStateToProps)(ProgramDashboardContainer);

export default container;
