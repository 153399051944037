import React, { Component } from "react";
import PropTypes from "prop-types";
import { Box, Select, Button, IconButton } from "../../../../components";
import uuid from "uid";
import { parseFilters, unparseFilters } from "../../../../utils/utils";
import InputSourceFilters from "./InputSourceFilters";
import "./InputSource.scss";
import translate from "../../texts";

const defaultFilters = (id = "initialFilter") => ({
  initialFilter: {
    id,
    field: {
      id: "",
      type: "string",
      category: "fields",
    },
  },
});

class InputSource extends Component {
  state = {
    fields: [],
    funnel: null,
    selectedFilters: {},
    questions: [],
  };

  UNSAFE_componentWillMount() {
    this.fillState(this.props);
  }

  onFilterChange = (id, property, value, labels = {}) => {
    const { selectedFilters } = this.state;
    const targetFilter = selectedFilters[id];

    const nextFilters = {
      ...selectedFilters,
      [id]: {
        ...targetFilter,
        [property]: value,
      },
    };

    if (property === "operator") {
      // update the operatorLabel on operator change.
      const updatedNextFilters = {
        ...nextFilters,
        [id]: { ...nextFilters[id], operatorLabel: labels[value] },
      };

      this.updateSelectedFiltersState(updatedNextFilters);
    } else if (property === "field") {
      const { fields } = this.state;
      const field = fields.filter((f) => f.id === value)[0];

      // reset operator and value on field change.
      const updatedNextFilters = {
        ...nextFilters,
        [id]: {
          id,
          field: {
            id: value,
            category: field.category,
            type: field.type,
            choices: field.choices,
          },
        },
      };

      this.updateSelectedFiltersState(updatedNextFilters);
    } else {
      this.updateSelectedFiltersState(nextFilters);
    }
  };

  onSourceChange = (funnel) => {
    const funnelId = funnel.value;
    const questionsList = this.getQuestionsList(funnelId);
    const newFields = [...this.props.fields, ...questionsList];
    this.setState(
      {
        funnel,
        selectedFilters: {},
        questions: questionsList,
        fields: newFields,
      },
      this.props.onSelectSource(funnel)
    );
  };

  onOperationChange = (value) => {
    this.props.onInputSourcesOperationChange(
      { target: { value: null } },
      value
    );
  };

  // Return the question list based on the selected input source
  getQuestionsList = (funnelId) => {
    const { questions } = this.props;
    return questions.filter((question) => question.funnelId === funnelId);
  };

  fillState = (props) => {
    const { inputSource, funnels } = props;
    const funnelId = inputSource.sourceFunnel
      ? inputSource.sourceFunnel.id
      : null;

    // Only for updating a funnel
    if (inputSource && funnelId) {
      const funnelName = funnels.find((item) => item.id === funnelId).name;
      const questionsList = this.getQuestionsList(funnelId);
      const fieldsWithoutQuestions = this.props.fields.filter(
        (field) =>
          field.category !== "questions" || field.category === undefined
      );
      const newFields = [...fieldsWithoutQuestions, ...questionsList];
      this.setState({
        fields: newFields,
        selectedFilters: inputSource.filters
          ? unparseFilters(inputSource.filters, newFields)
          : {},
        funnel: {
          value: funnelId,
          label: funnelName,
        },
        questions: questionsList,
      });
    }
  };

  updateSelectedFiltersState = (filters) => {
    this.setState({ selectedFilters: filters }, () => this.saveFilters());
  };

  saveFilters = () => {
    const { selectedFilters, funnel } = this.state;
    const selectedFiltersArr = parseFilters(selectedFilters);

    this.props.onUpdateFilter(funnel.value, selectedFiltersArr);
  };

  selectFilter = () => {
    const selectedFilters = { ...this.state.selectedFilters };
    const newFilterId = uuid();
    selectedFilters[newFilterId] = defaultFilters(newFilterId).initialFilter;
    this.updateSelectedFiltersState(selectedFilters);
  };

  removeFilter = (filterId) => {
    const selectedFilters = { ...this.state.selectedFilters };
    delete selectedFilters[filterId];
    this.updateSelectedFiltersState(selectedFilters);
  };

  render() {
    const { operators, funnels, submissionStatuses, removeInputSource } =
      this.props;

    const { selectedFilters, questions, fields } = this.state;

    const operatorOptions = [
      { value: "$or", label: "or" },
      { value: "$and", label: "and" },
    ];
    return (
      <div className="input_sources">
        <Box>
          <div className="input_source_select">
            <label htmlFor="select_input">
              {translate("funnel_input_source")}
              <Select
                id="select_input"
                name="form-field-name"
                searchable={false}
                onChange={this.onSourceChange}
                value={this.state.funnel}
                options={funnels.map((funnel) => ({
                  value: funnel.id,
                  label: funnel.name,
                }))}
                placeholder=""
                clearable={false}
              />
            </label>
            <IconButton iconName="delete" onClick={removeInputSource} />
          </div>
          <div className="filter-fields">
            <InputSourceFilters
              selectedFilters={selectedFilters}
              fields={fields}
              submissionStatuses={submissionStatuses}
              questions={questions}
              operators={operators}
              onFilterChange={this.onFilterChange}
              removeFilter={this.removeFilter}
              onSameInputSourceOperationChange={
                this.props.onSameInputSourceOperationChange
              }
              operationOnSameInputSource={this.props.operationOnSameInputSource}
            />
            <Button
              content={translate("funnel_new_filter")}
              flat
              small
              iconName="plus-circle"
              disabled={!this.state.funnel}
              onClick={this.selectFilter}
            />
          </div>
        </Box>
        <div className="label-with-line">
          <div className="operator-dropdown">
            <Select
              style={{
                boxShadow: "none",
                outline: "none",
                background: "transparent",
              }}
              options={operatorOptions}
              value={this.props.operationBetweenInputSources}
              clearable={false}
              onChange={(e) => this.onOperationChange(e.value)}
            />
          </div>
        </div>
      </div>
    );
  }
}

InputSource.propTypes = {
  fields: PropTypes.array.isRequired,
  operators: PropTypes.object.isRequired,
  operationBetweenInputSources: PropTypes.string.isRequired,
  funnels: PropTypes.array,
  submissionStatuses: PropTypes.array.isRequired,
  onSelectSource: PropTypes.func.isRequired,
  onUpdateFilter: PropTypes.func.isRequired,
  onInputSourcesOperationChange: PropTypes.func.isRequired,
  removeInputSource: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
};

InputSource.defaultProps = {
  funnels: [],
};

export default InputSource;
