import React from "react";
import PropTypes from "prop-types";
import "./Icon.scss";

/**
 * A material design icon. Search for desired icon name here: https://materialdesignicons.com
 */
// className allows to control size for example.
const Icon = ({ name, className, style }) => (
  <i title={name} className={`mdi mdi-${name} ${className}`} style={style} />
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

Icon.defaultProps = {
  className: "",
  style: {},
};

export default Icon;
