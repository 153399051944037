import * as types from "../types/program-members-modal.types";

const initialState = {
  active: false,
  activePage: "MembersInvitation",
};

export default function programMembersModalReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.TOGGLE_PROGRAM_MEMBERS_MODAL:
      return { ...state, active: !state.active };
    case types.DISPLAY_MEMBERS_INVITATION:
      return { ...state, activePage: "MembersInvitation" };
    case types.DISPLAY_MEMBERS_MANAGEMENT:
      return { ...state, activePage: "MembersManagement" };
    default:
      return state;
  }
}
