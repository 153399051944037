import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Icon } from "../../../components";
import Permit from "../../../layouts/Authorization";
import { withRouter } from "react-router-dom";
import translate from "../../../layouts/Funnels/texts";
import axios from "axios";
import ProgressCard from "./ProgressCard/ProgressCard";

class FunnelProgress extends PureComponent {
  refreshFunnelsProgress() {
    const params = {
      id: this.props.program.id,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/programs/update_cache`, params)
      .then(() => {
        window.location.reload();
      });
  }

  // eslint-disable-next-line class-methods-use-this
  renderProgressCard(funnel, index) {
    return <ProgressCard funnel={funnel} key={index} />;
  }

  render() {
    const { program } = this.props;

    return (
      <Permit targetPrivilege="funnels.index" targetId={program.id}>
        <div id="FunnelProgress">
          <div className="heroic-content">
            <h4 id="funnels-header" className="section-title" title="Funnels">
              {translate("funnels_progress")}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.refreshFunnelsProgress();
                }}
                onKeyUp={() => {
                  this.refreshFunnelsProgress();
                }}
                role="presentation"
              >
                <Icon name="refresh" />
              </span>
            </h4>
            <p className="description">{translate("Submissions_note")}</p>
          </div>
          <div className="funnel-progress-wrapper">
            {program.funnels
              .filter((funnel) => funnel.rootFunnel === false)
              .map((funnel, index) => this.renderProgressCard(funnel, index))}
          </div>
        </div>
      </Permit>
    );
  }
}

FunnelProgress.propTypes = {
  program: PropTypes.object.isRequired,
};

export default withRouter(FunnelProgress);
