export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_PENDING = "FETCH_NOTIFICATIONS_PENDING";
export const FETCH_NOTIFICATIONS_REJECTED = "FETCH_NOTIFICATIONS_REJECTED";
export const FETCH_NOTIFICATIONS_FULFILLED = "FETCH_NOTIFICATIONS_FULFILLED";

export const MARK_READ = "MARK_READ";
export const MARK_READ_PENDING = "MARK_READ_PENDING";
export const MARK_READ_REJECTED = "MARK_READ_REJECTED";
export const MARK_READ_FULFILLED = "MARK_READ_FULFILLED";

export const RESET_NOTIFICATIONS = "RESET_NOTIFICATIONS";
