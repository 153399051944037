import React from "react";
import PropTypes from "prop-types";
import "./Switch.scss";

const Switch = ({ name, value, wide, checked, onChange, className }) => (
  <label htmlFor={name} className={`switch ${wide ? "wide" : ""} ${className}`}>
    <input
      id={name}
      type="checkbox"
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
    />
    <span className="slider round" />
    {value}
  </label>
);

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  wide: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Switch.defaultProps = {
  wide: false,
  checked: false,
  className: "",
};

export default Switch;
