// FIXME: Potential bad use of snake case, switch everything to camel case
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Radio } from "../../../components";
import { isEqual, sortBy } from "lodash";
import Reason from "../Reason/Reason";
import "./Questions.scss";
import Slider from "../../../components/InputRange/InputRange";

const getAnswer = (evaluations, questionId) => {
  if (evaluations.length > 0) {
    const [answer] = evaluations.filter(
      (item) => item.questionId === questionId
    );
    return answer !== undefined &&
      Object.prototype.hasOwnProperty.call(answer, "value")
      ? answer
      : {};
  }
  return {};
};

const Questions = ({ questions, evaluations, onChange, withReason }) => {
  const [evaluationsState, setEvaluationsState] = useState(evaluations);

  const updateEvaluationsState = (choice, question) => {
    const updatedEvaluations = [...evaluationsState];
    const index = updatedEvaluations
      .map((i) => i.questionId)
      .indexOf(question.id);

    if (index === -1) {
      updatedEvaluations.push({ ...choice, questionId: question.id });
    } else {
      updatedEvaluations[index] = { ...choice, questionId: question.id };
    }

    setEvaluationsState(updatedEvaluations);
  };

  const updateOnChange = (choice, question, questionIndex) => {
    updateEvaluationsState(choice, question, questionIndex);

    onChange({
      ...choice,
      id: question.id,
      questionIndex,
      reason: `${getAnswer(evaluationsState, question.id).reason || ""}`,
    });
  };

  useEffect(() => {
    setEvaluationsState(evaluations);
  }, [evaluations]);

  return (
    <div>
      <div id="questionsWithChoices">
        {questions.map((question, i) => (
          <div
            className={`question ${
              /[\u0600-\u06FF]/.test(question.title) ? "rtl" : ""
            }`}
            key={question.id}
          >
            <p>
              <strong>{question.title}</strong>
            </p>
            <div>
              {question.isSlider ? (
                <Slider
                  choices={question.choices}
                  onChange={(choice) => updateOnChange(choice, question, i)}
                  currentValue={getAnswer(evaluationsState, question.id)}
                />
              ) : (
                <>
                  {question.choices.map((choice) => (
                    <div key={choice.id}>
                      <Radio
                        wide
                        name={question.id}
                        value={choice.label}
                        checked={
                          getAnswer(evaluationsState, question.id).value ===
                          choice.label
                        }
                        onChange={() => updateOnChange(choice, question, i)}
                      />
                    </div>
                  ))}
                </>
              )}

              {withReason[i] &&
                withReason[i].includes(
                  getAnswer(evaluationsState, question.id).value
                ) && (
                  <Reason
                    questionId={question.id}
                    answer={getAnswer(evaluationsState, question.id)}
                  />
                )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

Questions.propTypes = {
  questions: PropTypes.array.isRequired,
  evaluations: PropTypes.array,
  onChange: PropTypes.func,
  withReason: PropTypes.array.isRequired,
};

Questions.defaultProps = {
  evaluations: [],
  onChange: () => {},
};

const shouldComponentUpdate = (prevProps, nextProps) => {
  const nextEvaluations = sortBy(nextProps.evaluations, [(i) => i.questionId]);
  const prevEvaluations = sortBy(prevProps.evaluations, [(i) => i.questionId]);

  return (
    prevProps.funnelId === nextProps.funnelId &&
    prevProps.submissionId === nextProps.submissionId &&
    nextProps.evaluations.length !== 0 &&
    !isEqual(nextEvaluations, prevEvaluations)
  );
};

export default React.memo(Questions, shouldComponentUpdate);
