import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Box, Tab, ListViewContainer, Wallpaper } from "../../components";
import paginationType from "../../components/ListView/pagination-types";
import * as notificationsActions from "../../actions/notifications.actions";
import translate from "./texts";
import NotificationItem from "./NotificationItem";

import "./NotificationsContainer.scss";

class NotificationsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newTabActive: true,
      readTabActive: false,
    };

    this.openNewTab = this.openNewTab.bind(this);
    this.openReadTab = this.openReadTab.bind(this);
    this.markRead = this.markRead.bind(this);
    this.fetchNotifications = this.fetchNotifications.bind(this);
  }

  openNewTab() {
    this.setState({ newTabActive: true, readTabActive: false });
  }

  openReadTab() {
    this.setState({ newTabActive: false, readTabActive: true });
  }

  markRead(notification) {
    this.props.actions.updateNotificationStatus(notification.id, "read");
  }

  fetchNotifications(status, pageNumber) {
    const { program } = this.props;

    if (pageNumber === 1) {
      this.props.actions.resetNotifications();
    }

    this.props.actions.fetchNotifications(program.id, status, pageNumber);
  }

  filterNotificationsByStatus(status) {
    const { notifications } = this.props;

    return notifications.filter((item) => item.status === status);
  }

  render() {
    const { isFetching } = this.props;
    const { newTabActive, readTabActive } = this.state;

    return (
      <Wallpaper>
        <div id="notification-container" className="container">
          <Box>
            <div className="notifications-header">
              <div className="header-title">{translate("Notifications")}</div>
              <div className="header-description">
                {translate("notifications_description")}
              </div>
            </div>
            <div className="notification-tabs">
              <Tab centered={false}>
                <Tab.Item
                  button
                  title={translate("New")}
                  link="/"
                  icon="bell mdi-24px"
                  className={newTabActive ? "is-active" : ""}
                  onClick={this.openNewTab}
                />
                <Tab.Item
                  button
                  title={translate("Read")}
                  link="#activities"
                  icon="bell-outline mdi-24px"
                  className={readTabActive ? "is-active" : ""}
                  onClick={this.openReadTab}
                />
              </Tab>
            </div>
            <div className="notification-list">
              {newTabActive && (
                <div id="NotificationsListView">
                  <ListViewContainer
                    listItemComponent={NotificationItem}
                    listItemData={this.filterNotificationsByStatus("unread")}
                    paginationType={paginationType.showMore}
                    paginationMetadata={this.props.paginationMetadata}
                    loading={isFetching}
                    buttonText={translate("ShowMore")}
                    clickHandlers={{
                      markRead: this.markRead,
                    }}
                    onPagination={(pageNumber) => {
                      this.fetchNotifications("unread", pageNumber);
                    }}
                  />
                </div>
              )}
              {readTabActive && (
                <div id="NotificationsListView">
                  <ListViewContainer
                    listItemComponent={NotificationItem}
                    listItemData={this.filterNotificationsByStatus("read")}
                    paginationType={paginationType.showMore}
                    paginationMetadata={this.props.paginationMetadata}
                    loading={isFetching}
                    buttonText={translate("ShowMore")}
                    clickHandlers={{
                      markRead: this.markRead,
                    }}
                    onPagination={(pageNumber) => {
                      this.fetchNotifications("read", pageNumber);
                    }}
                  />
                </div>
              )}
            </div>
          </Box>
        </div>
      </Wallpaper>
    );
  }
}

NotificationsContainer.propTypes = {
  notifications: PropTypes.array,
  actions: PropTypes.object,
  program: PropTypes.object.isRequired,
  paginationMetadata: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

NotificationsContainer.defaultProps = {
  notifications: [],
  actions: {},
};

const mapStateToProps = (state) => {
  const notificationsState = state.notifications;
  const { program } = state.program;

  return {
    notifications: notificationsState.notifications,
    paginationMetadata: notificationsState.paginationMetadata,
    program,
    isFetching: notificationsState.isFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(notificationsActions, dispatch),
});

const container = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsContainer);

export default container;
