import axios from "axios";
import * as types from "../types/members-invitation.types";

export function fetchRoles() {
  return {
    type: types.FETCH_ROLES,
    payload: axios.get(`${process.env.REACT_APP_API_URL}/roles`),
  };
}

export function inviteMembers(programId, membersToInvite) {
  return {
    type: types.INVITE_MEMBERS,
    payload: axios.post(
      `${process.env.REACT_APP_API_URL}/programs/${programId}/invitation`,
      {
        invitees: membersToInvite,
      }
    ),
  };
}

// TODO: check this function's usage and remove it.
export function fetchMembers(workspaceId) {
  return {
    type: types.FETCH_MEMBERS,
    payload: axios.get(
      `${process.env.REACT_APP_API_URL}/workspaces/${workspaceId}/members`
    ),
  };
}

export function updateMembersRoles(programId, members) {
  return {
    type: types.UPDATE_MEMBERS,
    payload: axios.put(
      `${process.env.REACT_APP_API_URL}/programs/${programId}/users`,
      {
        users: members,
      }
    ),
  };
}

export function onGlobalRoleChange(nextGlobalRoleSettings) {
  return {
    type: types.CHANGE_GLOBAL_ROLE,
    nextGlobalRoleSettings,
  };
}

export function onAddMembers(members) {
  return {
    type: types.ADD_MEMBERS,
    members,
  };
}

export function onIndividualRoleChange(members) {
  return {
    type: types.UPDATE_INDIVIDUAL_MEMBER_ROLE,
    members,
  };
}

export function resetInvitationMembers() {
  return {
    type: types.RESET_INVITATION_MEMBERS,
  };
}
