export const FETCH_PRIVILEGES = "FETCH_PRIVILEGES";
export const RESET_PRIVILEGES = "RESET_PRIVILEGES";
export const FETCH_PRIVILEGES_PENDING = "FETCH_PRIVILEGES_PENDING";
export const FETCH_PRIVILEGES_FULFILLED = "FETCH_PRIVILEGES_FULFILLED";
export const FETCH_PRIVILEGES_REJECTED = "FETCH_PRIVILEGES_REJECTED";

export const SIGNIN = "SIGNIN";
export const SIGNIN_PENDING = "SIGNIN_PENDING";
export const SIGNIN_REJECTED = "SIGNIN_REJECTED";
export const SIGNIN_FULFILLED = "SIGNIN_FULFILLED";

export const SIGNUP = "SIGNUP";
export const SIGNUP_PENDING = "SIGNUP_PENDING";
export const SIGNUP_REJECTED = "SIGNUP_REJECTED";
export const SIGNUP_FULFILLED = "SIGNUP_FULFILLED";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_PENDING = "FORGOT_PASSWORD_PENDING";
export const FORGOT_PASSWORD_REJECTED = "FORGOT_PASSWORD_REJECTED";
export const FORGOT_PASSWORD_FULFILLED = "FORGOT_PASSWORD_FULFILLED";
