import Multiselect from "multiselect-react-dropdown";
import React from "react";
import "./MultiSelectDropdown.scss";

function MultiSelectDropdown({
  options,
  placeholder,
  onSelected,
  selectedValue,
}) {
  const color = process.env.REACT_APP_THEME === "moc" ? "#eb5a3d" : "#268aff";

  return (
    <Multiselect
      options={options}
      displayValue="label"
      selectedValues={selectedValue}
      onSelect={onSelected}
      onRemove={onSelected}
      placeholder={placeholder}
      style={{
        chips: {
          background: color,
          margin: "0px",
          flexShrink: "0",
        },
        searchBox: {
          textAlign: "start",
          display: "flex",
          gap: "5px",
          overflow: "auto",
        },
      }}
      avoidHighlightFirstOption={true}
    />
  );
}

export default MultiSelectDropdown;
