import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Input,
  Box,
  Dropdown,
  IconButton,
  Chip,
  Checkbox,
  Icon,
} from "../../../components";
import uuid from "uid";
import { toast } from "react-toastify";
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import TextLength from "../../../utils/settings/text-length";
import PromptMessage from "../../PromptMessage/PromptMessage";
import "./QuestionList.scss";
import translate from "../texts";

const SortableItem = SortableElement(({ question, renderQuestions, i }) =>
  renderQuestions(question.id, question, i)
);

const SortableList = SortableContainer(({ questions, renderQuestions }) => (
  <ul>
    {questions.map((question, index) => (
      <SortableItem
        key={`item-${question.id}`}
        index={index}
        i={index}
        question={question}
        renderQuestions={renderQuestions}
      />
    ))}
  </ul>
));
class QuestionList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editQuestions: [],
      questions: [],
      maxWeight: 0,
      // TODO: DRY! remove this state and use editQuestions if it is applicable
      questionsUpdated: [],
      activeSavePrompt: false,
      isAddNewQuestion: false,
      isQuestionUpdated: false,
      isQuestionsReasonUpdated: false,
    };

    this.questionsClone = {};
  }

  UNSAFE_componentWillMount() {
    if (this.props.value.length > 0) {
      this.setState(
        { questions: [...this.props.value] },
        this.calculateMaxWeight(this.props.value)
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(
      { questions: [...nextProps.value] },
      this.calculateMaxWeight(nextProps.value)
    );
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.value) !== JSON.stringify(prevProps.value)) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState({ questions: [...this.props.value] });
    }
  }

  addEditQuestion = (questionId, isEdit) => {
    const { editQuestions, questions } = this.state;
    if (editQuestions.indexOf(questionId) === -1) {
      editQuestions.push(questionId);
      this.setState({ editQuestions });
    }

    if (isEdit) {
      this.state.questionsUpdated.push(questionId);
      this.setState({
        isQuestionUpdated: false,
        isQuestionsReasonUpdated: false,
      });
    }
    // deep clone of questions list
    this.questionsClone = JSON.parse(JSON.stringify(questions));
  };

  deleteQuestion = (event, questionId, index) => {
    this.setState(
      (prevState) => {
        this.removeEditQuestion(questionId);
        const questionsCopy = [...prevState.questions];
        questionsCopy.splice(index, 1);
        return { questions: questionsCopy };
      },
      () => {
        this.calculateMaxWeight();
        this.handleChange();
      }
    );
  };

  duplicateQuestion = (questionId, isEdit, index) => {
    const newId = uuid();
    this.setState(
      (prevState) => {
        const question = prevState.questions[index];
        const questionsCopy = [...prevState.questions];
        const choices = question.choices.map((choice) => ({
          ...choice,
          uuid: uuid(),
        }));
        const newQuestion = {
          id: newId,
          isPending: true,
          choices,
          title: question.title,
        };
        questionsCopy.splice(question.order, 0, newQuestion);
        questionsCopy.forEach((item, i) => {
          item.order = i;
        });

        return {
          questions: questionsCopy,
        };
      },
      () => {
        this.calculateMaxWeight();
        this.handleChange();
      }
    );
  };

  handleChange = (softSave) => {
    const { questionsUpdated, questions } = this.state;

    // Remove Duplicate values from the array.
    let distinctQuestionsUpdated = questionsUpdated.filter(
      (value, index, arr) => arr.indexOf(value) === index
    );

    distinctQuestionsUpdated = distinctQuestionsUpdated.map((questionId) => ({
      questionId,
      softSave,
    }));
    this.props.onChange(
      { target: { value: null } },
      questions,
      distinctQuestionsUpdated
    );
  };

  removeEditQuestion = (questionId) => {
    const { editQuestions } = this.state;
    const editQuestionIndex = editQuestions.indexOf(questionId);
    if (editQuestionIndex > -1) {
      editQuestions.splice(editQuestionIndex, 1);
      this.setState({ editQuestions });
    }
  };

  addNewQuestion = () => {
    const newId = uuid();
    this.setState(
      (prevState) => ({
        questions: [
          ...prevState.questions,
          {
            id: newId,
            isPending: true,
            choices: [
              { uuid: newId, weight: 0 },
              { uuid: newId, weight: 0 },
            ],
            order: prevState.questions.length,
            isSlider: "false",
          },
        ],
        isAddNewQuestion: true,
        questionsUpdated: [...prevState.questionsUpdated, newId],
      }),
      () => {
        this.handleChange();
      }
    );

    this.addEditQuestion(newId);
  };

  updateQuestionChoiceState = (event, index, choiceIndex) => {
    const { target } = event;

    this.setState(
      (prevState) => {
        const field = target.name;
        const question = { ...prevState.questions[index] };
        question.choices[choiceIndex][field] = target.value;
        prevState.questions[index] = question;
        prevState.questions = [...prevState.questions];
        return { questions: prevState.questions, isQuestionUpdated: true };
      },
      () => this.calculateMaxWeight()
    );
  };

  updateReasonAssociation = (event, index, choiceIndex) => {
    const { target } = event;
    this.setState((prevState) => {
      const field = target.name;
      const question = { ...prevState.questions[index] };
      question.choices.forEach((choice, i) => {
        if (choiceIndex === i) choice[field] = !choice[field];
      });
      prevState.questions[index] = question;
      prevState.questions = [...prevState.questions];
      return { questions: prevState.questions, isQuestionsReasonUpdated: true };
    });
  };

  calculateMaxWeight = (questions = this.state.questions) => {
    const maxWeight = questions.reduce((currentWeight, question) => {
      const choiceWeights = question.choices.map((choice) => choice.weight);
      let maxChoiceWeight = 0;
      if (choiceWeights.length) {
        maxChoiceWeight = Math.max(...choiceWeights);
      }
      return currentWeight + maxChoiceWeight;
    }, 0);
    this.setState({ maxWeight });
  };

  updateQuestionState = (event, index) => {
    const { target } = event;

    this.setState((prevState) => {
      const field = target.name;
      const question = { ...prevState.questions[index] };
      question[field] = target.value;
      prevState.questions[index] = question;
      prevState.questions = [...prevState.questions];
      return { questions: prevState.questions, isQuestionUpdated: true };
    });
  };

  saveQuestion = (event, index, questionId, softSave = false) => {
    const question = this.state.questions[index];
    const ChoicesHaveValues = question.choices.filter((choice) => choice.label);

    this.setState({ activeSavePrompt: false, isAddNewQuestion: false });

    if (ChoicesHaveValues.length < 2) {
      this.cancelEditQuestion(questionId);
      toast.error(translate("Ignore_save_action"));
    } else {
      this.handleChange(softSave);
      this.removeEditQuestion(questionId);
    }
  };

  cancelEditQuestion = (questionId) => {
    this.removeEditQuestion(questionId);
    this.setState({
      questions: this.questionsClone,
      activeSavePrompt: false,
      isAddNewQuestion: false,
      isQuestionUpdated: false,
    });
  };

  addQuestionChoice = (questionIndex) => {
    this.setState((prevState) => {
      const question = prevState.questions[questionIndex];
      question.choices = [...question.choices, { uuid: uuid(), weight: 0 }];
      return { questions: [...prevState.questions] };
    });
  };

  removeChoice = (questionIndex, choiceIndex) => {
    // Deep immutable copy of the questions array
    const questions = JSON.parse(JSON.stringify(this.state.questions));
    questions[questionIndex].choices.splice(choiceIndex, 1);

    this.setState({
      questions: [...questions],
    });

    this.calculateMaxWeight();
  };

  showPrompt = () => {
    this.setState({ activeSavePrompt: true });
  };

  saveHandler = (event, index, questionId) => {
    const { isAddNewQuestion, isQuestionUpdated, isQuestionsReasonUpdated } =
      this.state;
    if (isAddNewQuestion) {
      this.saveQuestion(event, index, questionId);
    } else if (isQuestionUpdated || isQuestionsReasonUpdated) {
      this.showPrompt();
    }
  };

  disableSave = (question, numOfChoices) => {
    const { isQuestionsReasonUpdated, isQuestionUpdated, isAddNewQuestion } =
      this.state;
    const choicesHaveLabels = question.choices.some((choice) => !choice.label);
    const requiredInputsExist =
      !question.title || choicesHaveLabels || numOfChoices < 2;
    if (isAddNewQuestion) {
      return requiredInputsExist;
    }
    return (
      (!isQuestionUpdated || requiredInputsExist) && !isQuestionsReasonUpdated
    );
  };

  renderQuestions = (questionId, question, index) => {

    const { editQuestions, isAddNewQuestion } = this.state;
    const numOfChoices = question.choices.length;
    const maximumChoices = 4;
    const DragHandle = sortableHandle(() => (
      <div className="ff-drag">
        <Icon name="drag" />
      </div>
    ));

    return editQuestions.indexOf(questionId) === -1 ? (
      <div className="question">
        <DragHandle />
        <div className="question-content">
          <p className="flex w-full">
            <strong className="ellipses">{question.title}</strong>
          </p>
          <div className="flex">
            {question.choices.map(
              (choice, i) =>
                i < maximumChoices && (
                  <span key={uuid()} className="ellipses">
                    {choice.label}
                  </span>
                )
            )}
          </div>
        </div>
        <Dropdown className="question-actions" icon="dots-vertical" autoClose>
          <button
            onClick={() => this.addEditQuestion(question.id, true)}
            className="dropdown-item is-text"
            type="button"
          >
            {translate("Edit")}
          </button>
          <button
            onClick={(event) => this.deleteQuestion(event, question.id, index)}
            className="dropdown-item is-text"
            type="button"
          >
            {translate("Delete")}
          </button>
          <button
            onClick={() => this.duplicateQuestion(question.id, true, index)}
            className="dropdown-item is-text"
            type="button"
          >
            {translate("Duplicate")}
          </button>
        </Dropdown>
      </div>
    ) : (
      <div className="edit-q-container">
        <div className="edit-question-title">
          <p>{translate("Question Title")}</p>
          <p className="light">{translate("Question title description")}</p>
          <Input
            key={`title-${question.id}`}
            inputName="title"
            onChange={(event) => {
              this.updateQuestionState(event, index);
            }}
            placeholder={translate("Title")}
            value={question.title}
            maxlength={TextLength.LONG}
          />
        </div>
        <div className="edit-question-choices">
          <p className="question-choices-header">
            <span>{translate("Question Choices")}</span>
            <span>{translate("weight")}</span>
            <span>{translate("reason_field")}</span>
          </p>
          {question.choices.map((choice, choiceIndex) => (
            // The id if choice exists or uuid(set in container) if it is new
            <div className="choice" key={choice.id || choiceIndex}>
              <Input
                inputName="label"
                placeholder={translate("Label")}
                value={choice.label}
                className="f-question"
                onChange={(event) =>
                  this.updateQuestionChoiceState(event, index, choiceIndex)
                }
                maxlength={TextLength.MEDIUM}
              />
              <Input
                inputName="weight"
                inputType="number"
                className="f-question"
                placeholder={translate("weight")}
                value={choice.weight}
                onChange={(event) =>
                  this.updateQuestionChoiceState(event, index, choiceIndex)
                }
              />
              <Checkbox
                className="field-checkbox"
                value="enable-reason"
                checked={choice.withReason}
                id={`${choiceIndex}-enable-reason`}
                name="withReason"
                onChange={(e) => {
                  this.updateReasonAssociation(e, index, choiceIndex);
                }}
                label={translate("enable_reason_text")}
              />
              <IconButton
                iconName="close-circle"
                className={numOfChoices <= 2 ? "disabled" : "enabled"}
                onClick={() =>
                  numOfChoices <= 2 ? "" : this.removeChoice(index, choiceIndex)
                }
              />
            </div>
          ))}
          <Button
            small
            flat
            className="btn-add-question-choice"
            disabled={
              typeof question.choices.find((choice) => choice.label === "") !==
              "undefined"
            }
            iconName="plus-circle"
            type="button"
            content={translate("Add Choice")}
            onClick={() => this.addQuestionChoice(index)}
          />
        </div>

        <div className="p-4">
          <Checkbox
            className="field-checkbox"
            value={question.isSlider === "false" ? "true" : "false"}
            checked={question.isSlider === "true" ? true : false}
            id={`${questionId}-isSlider`}
            name="isSlider"
            onChange={(e) => {
              this.updateQuestionState(e, index);
            }}
            label={"Is Slider"}
          />
        </div>

        <div className="edit-question-actions">
          <Button
            small
            className="btn-save-question"
            iconName="check"
            disabled={this.disableSave(question, numOfChoices)}
            onClick={(event) => this.saveHandler(event, index, question.id)}
            content={translate("Save")}
          />
          <Button
            small
            flat
            className="btn-cancel-question-changes"
            content={translate("Cancel")}
            onClick={() => this.cancelEditQuestion(question.id)}
          />
          {!isAddNewQuestion && (
            <IconButton
              iconName="delete"
              id="deleteQuestionBtn"
              onClick={(event) =>
                this.deleteQuestion(event, question.id, index)
              }
            />
          )}
          <PromptMessage
            active={this.state.activeSavePrompt}
            message={translate("save_confirmation_message")}
            title={translate("Save")}
            buttonContent={translate("yes")}
            softSaveButtonContent={translate("safe_save")}
            confirmationHandler={(event) =>
              this.saveQuestion(event, index, question.id)
            }
            softConfirmationHandler={(event) => {
              this.saveQuestion(event, index, question.id, true);
            }}
            cancelingHandler={() => this.cancelEditQuestion(question.id)}
            disabledButton={false}
          />
        </div>
      </div>
    );
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    let qInitSort = this.state.questions;
    // arraymove sort the array by index (qInitSort)
    // we want to save each new index as order & update DB (orderedQs)
    qInitSort = arrayMove(qInitSort, oldIndex, newIndex);
    const orderedQs = [];
    const questionIDs = [];

    qInitSort.forEach((item, index) => {
      item.order = index;
      orderedQs.push(item);
      questionIDs.push(item.id);
    });
    this.setState(
      (prevState) => {
        prevState.questions = orderedQs;
        return {
          questions: prevState.questions,
          questionsUpdated: questionIDs,
        };
      },
      () => {
        this.handleChange();
      }
    );
  };

  render() {
    return (
      <div key="QuestionList" id="QuestionList" className="question-list">
        {this.state.questions.length > 0 && (
          <div className="question-list-title">
            <Chip
              content={`${translate("total_weight")} ${this.state.maxWeight}`}
            />
          </div>
        )}
        {this.state.questions.length === 0 && (
          <div className="input_sources_container" />
        )}
        <div>
          <Box
            className="question-wrapper"
            dark
            cozy
            style={{
              paddingTop: `${this.state.questions.length === 0 ? "0" : ""}`,
            }}
          >
            <SortableList
              questions={this.state.questions}
              onSortEnd={this.onSortEnd}
              renderQuestions={this.renderQuestions}
              helperClass="sorting-active"
              useDragHandle
            />
            {this.state.editQuestions.length === 0 && (
              <Button
                iconName="plus-circle"
                className="btn-add-question "
                wide
                flat
                content={translate("Add")}
                onClick={this.addNewQuestion}
                style={{
                  marginTop: `${this.state.questions.length === 0 ? "0" : ""}`,
                }}
              />
            )}
          </Box>
        </div>
      </div>
    );
  }
}

QuestionList.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
};

QuestionList.defaultProps = {
  value: [],
};

export default QuestionList;
