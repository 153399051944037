import React from "react";
import { Box, Columns, Button, Modal } from "../../components";
import PropTypes from "prop-types";

import "./PromptMessage.scss";
import translate from "./texts";

const PromptMessage = ({
  active,
  message,
  title,
  buttonContent,
  confirmationHandler,
  cancelingHandler,
  disabledButton,
  softSaveButtonContent,
  softConfirmationHandler,
}) => (
  <Modal active={active} id="PromptMessage" className="prompt-message">
    <Box>
      <h3>{title}</h3>
      <p>{message}</p>
      <Columns>
        <div>
          <Button
            id="prompt-confirmation-btn"
            wide
            disabled={disabledButton}
            content={buttonContent}
            onClick={confirmationHandler}
          />
        </div>
        {softSaveButtonContent && (
          <div>
            <Button
              id="prompt-confirmation-btn"
              wide
              disabled={disabledButton}
              content={softSaveButtonContent}
              onClick={softConfirmationHandler}
            />
          </div>
        )}
        <div>
          <Button
            id="CancelBtn"
            wide
            flat
            content={translate("cancel")}
            onClick={cancelingHandler}
          />
        </div>
      </Columns>
    </Box>
  </Modal>
);

PromptMessage.propTypes = {
  buttonContent: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  confirmationHandler: PropTypes.func.isRequired,
  cancelingHandler: PropTypes.func.isRequired,
  disabledButton: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  softSaveButtonContent: PropTypes.string,
  softConfirmationHandler: PropTypes.func,
};

PromptMessage.defaultProps = {
  softSaveButtonContent: "",
  softConfirmationHandler: () => {},
};

export default PromptMessage;
