import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    noMemebersInvited: "You must invite memebers to your program first.",
    Add: "Add",
  },
  ar: {
    noMemebersInvited: "يجب أن تقوم بدعوة أعضاء جدد لبرنامجك.",
    Add: "إضافة",
  },
});

const translate = (string) => strings[string];

strings.setLanguage(locale || "en");
export default translate;
