// FIXME: Potential bad use of snake case, switch everything to camel case
import React from "react";
import HuePicker from "react-color/lib/components/hue/Hue";
import { debounce } from "lodash";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import "../../utils/dayjs.ar";
import TextLength from "../../utils/settings/text-length";
import { Button, Box, Input, Icon, IconButton, Switch } from "../../components";
import CreateField from "./CreateField";
import translate from "./texts";
import EditField from "./EditField";
import fieldsDefinitions from "./fields-definitions";
import LogoUploaderModal from "./LogoUploader/LogoUploaderModal";
import "./FormBuilder.scss";
import "./FormFields.scss";

class FormBuilder extends React.Component {
  constructor(props) {
    super(props);

    // To cache the old title value, so can be used in validation
    this.oldTitle = "";
  }

  state = {
    editList: [],
    formSettings: {
      title: "",
      rtlDirectionEnabled: false,
      backgroundColor: null,
      logo: null,
    },
    logoUploadModalActive: false,
  };

  UNSAFE_componentWillMount() {
    this.setState({
      formSettings: { ...this.props.formSettings },
    });
    this.changeCalenderLocal(this.props.formSettings.rtlDirectionEnabled);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      formSettings: { ...newProps.formSettings },
    });
  }

  onDirectionChange = () => {
    const newValue = !this.state.formSettings.rtlDirectionEnabled;
    this.props.onDirectionChanged(newValue);
    this.props.onSettingsChanged({ rtlDirectionEnabled: newValue });
    this.updateValue("rtlDirectionEnabled", newValue);
    this.changeCalenderLocal(newValue);
  };

  onBackgroundColorChanged = (color) => {
    const { onBackgroundColorChanged, onSettingsChanged } = this.props;

    onBackgroundColorChanged(color);
    onSettingsChanged({ backgroundColor: color.hex });
    this.updateValue("backgroundColor", color.hex);
  };

  onLogoChange = (logoURL) => {
    this.props.onSettingsChanged({ logo: logoURL });
    this.updateValue("logo", logoURL);
  };

  changeCalenderLocal = (isRtl) => dayjs.locale(isRtl ? "ar" : "en");

  isThereAPrimaryEmail = () =>
    this.props.formSettings.primaryEmailFieldId !== "";

  titleBlur = (value) => {
    if (value === this.oldTitle) {
      return;
    }

    const { onTitleBlur, onSettingsChanged } = this.props;

    this.oldTitle = value;
    onTitleBlur(value);
    onSettingsChanged({ title: value });
  };

  updateValue = (item, value) => {
    this.setState((prevState) => ({
      formSettings: {
        ...prevState.formSettings,
        [item]: value,
      },
    }));
  };

  addToEditList = (id) => this.setState({ editList: [id] });

  removeFromEditList = () => this.setState({ editList: [] });

  shareButtonDisplayHandler = () => {
    const numberOfFields = this.props.fieldsList.length;
    // Convert the variable to boolean
    return (
      Boolean(numberOfFields) && (
        <Button
          iconName="link-variant"
          content={translate("shareFormLink")}
          onClick={this.props.onShareButtonClick}
        />
      )
    );
  };

  toggleLogoUploadModal = () => {
    this.setState((prevState) => ({
      logoUploadModalActive: !prevState.logoUploadModalActive,
    }));
  };

  primaryEmailLabel = (fld) =>
    fieldsDefinitions[fld.ref].name === "Email" &&
    fld.primary_email && <span> | {translate("primaryEmail")}</span>;

  renderPrimaryEmailWarning = () =>
    !this.isThereAPrimaryEmail() && (
      <div className="checkbox-controller">
        <p className="warning">
          <Icon name="alert-outline" />
          {translate("contactEmailWarning")}
        </p>
      </div>
    );

  renderFieldsList = () => {
    const { fieldsList } = this.props;
    if (!fieldsList.length) {
      return null;
    }

    const SortableItem = SortableElement(({ fld }) => {
      if (this.state.editList.includes(fld.id)) {
        return (
          <div key={fld.id} className="sortable-list-item">
            <EditField
              schema={fld}
              saveField={this.props.saveField}
              removeFromEditList={this.removeFromEditList}
              deleteField={() => this.props.onDeleteField(fld)}
              fieldsList={this.props.fieldsList}
            />
          </div>
        );
      }
      return (
        <div
          key={fld.id}
          className="form-field sortable-list-item"
          onClick={() => this.addToEditList(fld.id)}
          onKeyUp={() => this.addToEditList(fld.id)}
          role="presentation"
          style={{ cursor: "pointer" }}
        >
          <div className="ff-icon">
            <Icon name={fieldsDefinitions[fld.ref].iconName} />
          </div>
          <div className="ff-body">
            <div className="ff-title ellipses">
              {fld.title}
              <span className="opacity-75 text-xs text-brand-gray">{`${
                fld.visibility || fld.visibility === undefined
                  ? ""
                  : ` (${translate("hidden")})`
              }`}</span>
            </div>
            <div className="ff-type-description">
              {fieldsDefinitions[fld.ref].name}
              {this.primaryEmailLabel(fld)}
            </div>
          </div>
          <div className="ff-drag">
            <Icon name="drag-horizontal" />
          </div>
        </div>
      );
    });

    const SortableList = SortableContainer(({ fields }) => (
      /* eslint-disable react/no-array-index-key */
      // I enforced to use index in key, becuase key should not changed after rendering
      <div>
        {fields.map((fld, i) => (
          <SortableItem key={`item-${i}`} index={i} fld={fld} />
        ))}
      </div>
    ));

    const onSortEnd = (e) => {
      if (e.oldIndex === e.newIndex) {
        return;
      }

      const sortedFields = arrayMove(
        this.props.fieldsList,
        e.oldIndex,
        e.newIndex
      );
      this.props.onSortingEnd(sortedFields);
    };

    const checkSortingTarget = (e) => {
      const classesAllowedToBeMoved = ["ff-drag", "mdi mdi-drag-horizontal "];
      if (classesAllowedToBeMoved.includes(e.target.className)) {
        return false;
      }
      return true;
    };

    return (
      <div id="form-fields-container">
        {" "}
        <SortableList
          helperClass="sorting-active"
          shouldCancelStart={checkSortingTarget}
          lockAxis="y"
          fields={fieldsList}
          onSortEnd={onSortEnd}
          pressDelay={10}
        />{" "}
      </div>
    );
  };

  render() {
    const { formSettings } = this.state;
    // Right alignment handled using css classes because if we using react props we need to
    // pass the property to 4 child component which is bad.
    return (
      <div
        id="form-builder-component"
        className={
          formSettings.rtlDirectionEnabled
            ? "right-direction"
            : "left-direction"
        }
      >
        <Box>
          <div className="form-builder-inner-container">
            <div className="title-edit-container">
              <HuePicker
                color={formSettings.backgroundColor}
                onChangeComplete={debounce(this.onBackgroundColorChanged, 700)}
                width="100%"
                height="4px"
              />
              <div className="title-area">
                <div className="logo-upload-btn">
                  {formSettings.logo ? (
                    <div
                      className="logo-img"
                      role="button"
                      tabIndex="-1"
                      onClick={this.toggleLogoUploadModal}
                      onKeyUp={this.toggleLogoUploadModal}
                    >
                      <img src={formSettings.logo} alt="logo" />
                    </div>
                  ) : (
                    <IconButton
                      iconName="image"
                      onClick={this.toggleLogoUploadModal}
                    />
                  )}
                </div>
                <Input
                  inputType="text"
                  placeholder={translate("formTitle")}
                  value={formSettings.title}
                  onBlur={(e) => this.titleBlur(e.target.value)}
                  onChange={(e) => this.updateValue("title", e.target.value)}
                  inputId="txtFormTitle"
                  maxlength={TextLength.LONG}
                />
                {this.shareButtonDisplayHandler()}
                <div className="checkbox-controller">
                  <Switch
                    name="direction-controller"
                    wide
                    checked={formSettings.rtlDirectionEnabled}
                    onChange={this.onDirectionChange}
                    value={translate("enable_rtl_direction")}
                  />
                </div>
                {this.renderPrimaryEmailWarning()}
              </div>
            </div>
            {this.renderFieldsList()}
          </div>
          <CreateField
            saveField={this.props.saveField}
            removeFromEditList={this.removeFromEditList}
            fieldsList={this.props.fieldsList}
          />
        </Box>
        <LogoUploaderModal
          active={this.state.logoUploadModalActive}
          toggleModal={this.toggleLogoUploadModal}
          onSave={this.onLogoChange}
          logoImage={formSettings.logo}
        />
      </div>
    );
  }
}

FormBuilder.propTypes = {
  // properties
  fieldsList: PropTypes.array.isRequired,
  formSettings: PropTypes.object,
  // events
  onTitleBlur: PropTypes.func,
  onBackgroundColorChanged: PropTypes.func,
  onDirectionChanged: PropTypes.func,
  onShareButtonClick: PropTypes.func,
  onSettingsChanged: PropTypes.func,
  saveField: PropTypes.func.isRequired,
  onDeleteField: PropTypes.func.isRequired,
  onSortingEnd: PropTypes.func.isRequired,
};

FormBuilder.defaultProps = {
  onShareButtonClick: () => {},
  onBackgroundColorChanged: () => {},
  onTitleBlur: () => {},
  onDirectionChanged: () => {},
  onSettingsChanged: () => {},
  formSettings: {
    title: "",
    rtlDirectionEnabled: false,
    backgroundColor: "",
    logo: "",
  },
};

export default FormBuilder;
