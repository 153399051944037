// FIXME: Potential bad use of snake case, switch everything to camel case
import React, { Component } from "react";
import PropTypes from "prop-types";
import uuid from "uid";
import { range } from "lodash";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Input, Icon, Switch, Button, Select } from "../../../components";
import TextLength from "../../../utils/settings/text-length";
import { convertHinduNumerals } from "../../../utils/utils";
import translate from "../texts";
import CreateChoices from "./CreateChoices";
import FileTypes from "./fileTypes";
import VisibilityOperators from "./visibilityLogicOperators";
import "./options.scss";

const MAX_LINEAR_SCALE_SIZE = 10;
const MIN_LINEAR_SCALE_SIZE = 2;

const MIN_FILE_SIZE_MB = 1;
const MAX_FILE_SIZE_MB = 512;

/**
 * @class BaseOptions
 * @extends Component
 * @description Every field options componnet should extend this class
 * and use the implemnted fields in it
 */
class BaseOptions extends Component {
  constructor(props) {
    super(props);

    const { options } = props;
    this.state = { options, warning_message: "" };
  }

  onToggleRequired = () => {
    const { options } = this.state;

    this.setState({ options: { ...options, required: !options.required } });
  };

  onFieldTitleChange = (e) => {
    const {
      target: { value },
    } = e;
    const { options } = this.state;

    this.setState({ options: { ...options, title: value } });
  };

  onStarRatingSizeChange = (e) => {
    const { options } = this.state;
    const {
      target: { value },
    } = e;

    this.setState({ options: { ...options, size: value } });
  };

  onCityFieldTitleChange = (e) => {
    const { options } = this.state;
    const {
      target: { value },
    } = e;

    this.setState({ options: { ...options, cityTitle: value } });
  };

  onOptionChanged = (choices) => {
    const { options } = this.state;

    this.setState({ options: { ...options, choices } });
  };

  onMaxFileSize = (e) => {
    let val = e.target.value;

    if (val < MIN_FILE_SIZE_MB) {
      val = MIN_FILE_SIZE_MB;
    } else if (val > MAX_FILE_SIZE_MB) {
      val = MAX_FILE_SIZE_MB;
    }

    const { options } = this.state;

    this.setState({ options: { ...options, maxFileSize: val * 1000 } });
  };

  onNumberRangeChange = (e, changedOption) => {
    const { options } = this.state;
    const {
      target: { value },
    } = e;

    this.setState({
      options: { ...options, [changedOption]: convertHinduNumerals(value) },
    });
  };

  onToggleCity = () => {
    const { options } = this.state;
    this.setState({ options: { ...options, withCity: !options.withCity } });
  };

  onToggleFileType = (e) => {
    const { options } = this.state;
    const types = e.target.getAttribute("data-ext").split(",");
    if (e.target.checked) {
      this.setState({
        options: {
          ...options,
          fileTypes: [...options.fileTypes, ...types],
        },
      });
    } else {
      const afterRemoveTypes = options.fileTypes.filter(
        (t) => !types.includes(t)
      );
      this.setState({ options: { ...options, fileTypes: afterRemoveTypes } });
    }
  };

  onTogglePrimaryEmail = () => {
    this.setPrimaryEmailWarningMessage();
    const { options } = this.state;

    this.setState({
      options: {
        ...options,
        primary_email: !options.primary_email,
      },
    });
  };

  onDateRangeChanged = (date, optionName) => {
    date = date ? moment(date).format() : date;
    const { options } = this.state;
    this.setState({ options: { ...options, [optionName]: date } });
  };

  setPrimaryEmailWarningMessage = () => {
    const { options: optionsFromProps } = this.props;
    const { options: optionsFromState } = this.state;
    if (optionsFromProps.primary_email && optionsFromState.primary_email) {
      this.setState({ warning_message: translate("contactEmailWarning") });
    } else {
      this.clearWarningMessage();
    }
  };

  /**
   * @override
   */
  setState = (newState, cb) => {
    super.setState(newState, () => {
      const newOptions = { ...this.state.options };
      delete newOptions.component;
      delete newOptions.parentRef;
      this.props.onChange(newOptions);
      cb && cb();
    });
  };

  clearWarningMessage = () => this.setState({ warning_message: "" });

  warningMessage = () => (
    <>
      <Icon name="alert-outline" />
      {this.state.warning_message}
    </>
  );

  titleErrorMessage = () => (
    <>
      <Icon name="alert-circle-outline" />
      {this.props.errors.titleValidation}
    </>
  );

  renderWarningMessage = () => (
    <div className="errors-container">
      <p className="warning">
        {this.state.warning_message.length !== 0 && this.warningMessage()}
      </p>
    </div>
  );

  renderTitle = () => (
    <>
      <Input
        placeholder={translate("newFieldTitle")}
        onChange={this.onFieldTitleChange}
        value={this.state.options.title}
        className="input-title"
        maxlength={TextLength.LONG}
      />
      {this.renderTitleValidationErrors()}
    </>
  );

  renderParagraph = () => (
    <>
      <Input
        placeholder={translate("paragraph")}
        onChange={this.onFieldTitleChange}
        value={this.state.options.title}
        className="input-title px-6 pt-6"
        maxlength={TextLength.LONG}
        multiline
      />
      {this.renderTitleValidationErrors()}
    </>
  );

  renderTitleValidationErrors = () => (
    <div className="errors-container">
      <p className="rejected">
        {this.props.errors.titleValidation !== undefined &&
          this.titleErrorMessage()}
      </p>
    </div>
  );

  renderCityTitle = () =>
    this.state.options.withCity && (
      <Input
        placeholder={translate("cityTitle")}
        onChange={this.onCityFieldTitleChange}
        value={this.state.options.cityTitle}
        className="input-title"
        maxlength={TextLength.MEDIUM}
      />
    );

  renderRequiredOption = () => (
    <Switch
      name="required-checkbox"
      wide
      onChange={this.onToggleRequired}
      checked={this.state.options.required}
      value={translate("required")}
      className="px-6"
    />
  );

  renderCreateChoices = () => (
    <CreateChoices
      key={this.props.options.parentRef}
      onOptionChange={this.onOptionChanged}
      choices={
        this.props.options.choices.length ? this.props.options.choices : [""]
      }
    />
  );

  renderWithCityCheck = () => (
    <div className="required-checkbox">
      <label htmlFor="with-city-ch">
        <input
          id="with-city-ch"
          type="checkbox"
          onChange={this.onToggleCity}
          checked={this.state.options.withCity}
        />
        {translate("withCity")}
      </label>
    </div>
  );

  renderStarRatingSize = () => (
    <div className="option-ls control">
      <span>{translate("numberOfStars")}</span>:
      <select
        onChange={this.onStarRatingSizeChange}
        value={this.state.options.size}
        className="linear-scale-size-option"
      >
        {range(MIN_LINEAR_SCALE_SIZE, MAX_LINEAR_SCALE_SIZE + 1).map((n) => (
          <option key={`${n}_ls_option`}>{n}</option>
        ))}
      </select>
    </div>
  );

  renderMaxFileSizeOption = () => {
    const uid = uuid();
    return (
      <div className="input-component">
        <label htmlFor={uid}>
          {translate("fileSizeOption")}
          <input
            id={uid}
            type="number"
            onChange={this.onMaxFileSize}
            min={MIN_FILE_SIZE_MB}
            max={MAX_FILE_SIZE_MB}
            value={this.state.options.maxFileSize / 1000}
            className="input-title input"
          />
        </label>
      </div>
    );
  };

  renderFileTypesOptions = () => {
    const unique1 = uuid();
    const unique2 = uuid();
    const unique3 = uuid();
    return (
      <div className="required-checkbox">
        {/* eslint-disable-next-line   */}
        <label>{translate("fileType")}</label>
        <br />
        <label htmlFor={unique1}>
          <input
            id={unique1}
            type="checkbox"
            onChange={this.onToggleFileType}
            data-ext={FileTypes.images}
            checked={this.state.options.fileTypes.includes(".jpg")}
          />
          {translate("imageType")}
        </label>

        <label htmlFor={unique2} className="side-space">
          <input
            id={unique2}
            type="checkbox"
            onChange={this.onToggleFileType}
            data-ext={FileTypes.docs}
            checked={this.state.options.fileTypes.includes(".pdf")}
          />
          {translate("docsType")}
        </label>

        <label htmlFor={unique3} className="side-space">
          <input
            id={unique3}
            type="checkbox"
            onChange={this.onToggleFileType}
            data-ext={FileTypes.video}
            checked={this.state.options.fileTypes.includes(".mp4")}
          />
          {translate("videoType")}
        </label>
        {this.renderFileTypesValidationErrors()}
      </div>
    );
  };

  renderFileTypesValidationErrors = () =>
    this.state.options.fileTypes.length === 0 && (
      <p className="rejected">{translate("Choose file types")}</p>
    );

  renderNumberRange = () => {
    const unique1 = uuid();
    const unique2 = uuid();
    return (
      <div>
        <div className="input-component">
          <label htmlFor={unique1}>
            {translate("minimumLength")}
            <input
              id={unique1}
              placeholder={translate("minimumLength")}
              onChange={(e) => this.onNumberRangeChange(e, "min")}
              value={this.state.options.min}
              className="input-title input"
            />
          </label>
        </div>
        <div className="input-component">
          <label htmlFor={unique2}>
            {translate("maximumLength")}
            <input
              id={unique2}
              placeholder={translate("maximumLength")}
              onChange={(e) => this.onNumberRangeChange(e, "max")}
              value={this.state.options.max}
              className="input-title input"
            />
          </label>
        </div>
      </div>
    );
  };

  renderDateRange = () => {
    const startDate = this.state.options.startDate
      ? new Date(this.state.options.startDate)
      : null;
    const endDate = this.state.options.endDate
      ? new Date(this.state.options.endDate)
      : null;
    return (
      <div className="input-component">
        {/* eslint-disable-next-line   */}
        <label>{translate("dataRangeValidation")}</label>
        <DatePicker
          selected={startDate}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="yyyy-MM-dd"
          onChange={(e) => this.onDateRangeChanged(e, "startDate")}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          isClearable
          placeholderText={translate("startDateValidation")}
        />
        <DatePicker
          selected={endDate}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          dateFormat="yyyy-MM-dd"
          onChange={(e) => this.onDateRangeChanged(e, "endDate")}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          isClearable
          placeholderText={translate("endDateValidation")}
        />
      </div>
    );
  };

  renderPrimaryEmailOption = () => (
    <>
      <div className="required-checkbox">
        {/* eslint-disable-next-line   */}
        <label>
          <input
            type="checkbox"
            checked={this.state.options.primary_email}
            onChange={this.onTogglePrimaryEmail}
          />
          {translate("primaryEmail")}
        </label>
      </div>
      {this.renderWarningMessage()}
    </>
  );

  onToggleVisibilityCondition = () => {
    const { options } = this.state;

    this.setState(
      {
        options: {
          ...options,
          visibility: !options.visibility,
        },
      },
      () => {
        if (options.visibilityOptions && !options.visibilityOptions.length) {
          this.appendVisibilityCondition();
        }
      }
    );
  };

  onVisibilityOptionsChange = (e, i, object) => {
    const { options } = this.state;
    options.visibilityOptions[i][object] = e.value;
    if (object === "id") {
      // Rest the value if field changed
      options.visibilityOptions[i].value = "";
    }

    if (e.target) {
      options.visibilityOptions[i].value = e.target.value;
    }
    this.setState({
      options: {
        ...options,
        visibilityOptions: [...options.visibilityOptions],
      },
    });
  };

  appendVisibilityCondition = () => {
    const { options } = this.state;

    this.setState({
      options: {
        ...options,
        visibilityOptions: [
          ...options.visibilityOptions,
          {
            uuid: uuid(),
            id: "",
            condition: "",
            value: "",
          },
        ],
      },
    });
  };

  removeVisibilityCondition = (i) => {
    const { options } = this.state;

    this.setState((prevState) => {
      const visibilityOptions = [...prevState.options.visibilityOptions];
      visibilityOptions.splice(i, 1);
      return {
        options: {
          ...options,
          visibilityOptions: [...visibilityOptions],
        },
      };
    });
  };

  getFieldObject = (id) =>
    this.props.fieldsList.filter((field) => field.id === id)[0];

  renderVisibilityCondition = () => {
    const {
      options: { visibilityOptions, id },
    } = this.state;

    return (
      <div>
        {visibilityOptions &&
          visibilityOptions.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div
              key={index}
              className={`visibility-conditions px-6 w-full flex ${
                !index ? "pt-0" : ""
              }`}
            >
              <div className="flex w-full items-center mb-2">
                <div className="w-full">
                  <Select
                    name="form-field-name"
                    value={item.id}
                    onChange={(e) =>
                      this.onVisibilityOptionsChange(e, index, "id")
                    }
                    options={this.props.fieldsList
                      .filter((field) => field.id !== id)
                      .map((field) => ({
                        value: field.id,
                        label: field.title,
                      }))}
                    placeholder={translate("selectField")}
                    clearable={false}
                  />
                  <Select
                    name="form-field-name"
                    value={item.operator}
                    onChange={(e) =>
                      this.onVisibilityOptionsChange(e, index, "operator")
                    }
                    options={VisibilityOperators.map((op) => ({
                      value: op,
                      label: translate(op),
                    }))}
                    placeholder={translate("selectOperator")}
                    clearable={false}
                    className="pt-0"
                  />
                  {this.getFieldObject(item.id) &&
                  this.getFieldObject(item.id).choices ? (
                    <Select
                      name="form-field-name"
                      value={item.value}
                      onChange={(e) =>
                        this.onVisibilityOptionsChange(e, index, "value")
                      }
                      options={this.getFieldObject(item.id).choices.map(
                        (choice) => ({
                          value: choice,
                          label: choice,
                        })
                      )}
                      placeholder={translate("value")}
                      clearable={false}
                      className="pt-0"
                    />
                  ) : (
                    <Input
                      placeholder={translate("value")}
                      onChange={(e) =>
                        this.onVisibilityOptionsChange(e, index, "value")
                      }
                      value={item.value}
                      className="p-0"
                      maxlength={TextLength.LONG}
                    />
                  )}
                </div>
                <button
                  type="button"
                  className="w-1/12 close-icon focus:outline-none ms-1 border-solid rounded border h-full"
                  onClick={() => {
                    this.removeVisibilityCondition(index);
                  }}
                >
                  <Icon name="close-circle" />
                </button>
              </div>
            </div>
          ))}
      </div>
    );
  };

  renderVisibilityOption = () => {
    let {
      options: { visibility },
    } = this.state;
    // Only check for boolean false and not undefined
    visibility = !(visibility === false);

    return (
      <>
        <Switch
          name="visibility-conditions"
          wide
          checked={!visibility}
          onChange={this.onToggleVisibilityCondition}
          value={translate("visibilityLogic")}
          className="px-6"
        />
        {!visibility && (
          <>
            <span className="px-6">{translate("visibilityHelpText")}</span>
            {this.renderVisibilityCondition()}
            <Button
              flatWhite
              className="w-full mt-4"
              onClick={this.appendVisibilityCondition}
              content={translate("newCondition")}
              iconName="plus-circle"
            />
          </>
        )}
      </>
    );
  };

  render() {
    return <div />;
  }
}

BaseOptions.propTypes = {
  options: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  fieldsList: PropTypes.array.isRequired,
};

BaseOptions.defaultProps = {
  errors: {},
  onChange: () => {},
};

export default BaseOptions;
