// FIXME: Potential bad use of snake case, switch everything to camel case
import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "../../components";
import translate from "./texts";

const SelectAllBtn = (props) => {
  const {
    selectedSubmissions,
    submissions,
    toggleSubmissionSelect,
    deselectAll,
    metadata,
  } = props;

  return (
    <div>
      <div className="select-all-container">
        <Dropdown autoClose className="select-all-menu" icon="menu-down">
          <button
            onClick={() => toggleSubmissionSelect("SELECT_ALL", true)}
            className="is-text"
            disabled={submissions.length === 0}
            type="button"
          >
            <span>{translate("current_page")}</span>
            <span className="all-count">({submissions.length})</span>
          </button>
          <button
            onClick={() => toggleSubmissionSelect("ALL_OF_ALL", true)}
            className="is-text"
            disabled={submissions.length === 0}
            type="button"
          >
            <span>{translate("all")}</span>
            <span className="all-count">({metadata.filteredItemCount})</span>
          </button>
        </Dropdown>

        {(() => {
          // The selected submissions count
          const selectedSubCount = Object.values(selectedSubmissions).filter(
            (value) => value
          ).length;

          if (selectedSubCount && selectedSubCount < submissions.length) {
            return (
              <button
                type="button"
                className="button is-info minus-btn"
                onClick={deselectAll}
              >
                -
              </button>
            );
          }
          return (
            <input
              readOnly
              type="checkbox"
              checked={selectedSubCount === submissions.length}
              onClick={(e) =>
                toggleSubmissionSelect("SELECT_ALL", e.target.checked)
              }
              disabled={submissions.length === 0}
            />
          );
        })()}
      </div>
    </div>
  );
};

SelectAllBtn.propTypes = {
  toggleSubmissionSelect: PropTypes.func.isRequired,
  selectedSubmissions: PropTypes.object.isRequired,
  submissions: PropTypes.array.isRequired,
  deselectAll: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
};

export default SelectAllBtn;
