import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    Cancel: "Cancel",
    Settings: "Settings",
    question_list_title: "Scoring Card",
    question_list_description:
      "Add questions of the program to make it easy to evaluate the submissions.",
    scoringCardPage: "Scoring Card",
    distributionPage: "Distribution Page",
    distributionAndAssignment: "Distribution And Assignment",
    distributionTip:
      "Here you will find information regarding distribution and assignment",
    newSubmissions: "New Submissions",
    unassignedSubmissions: "Unassigned Submissions",
    filtererCount: "Filterers",
    evenlyDistrbution: "Evenly Distribution",
    evenlyDistributionButton: "Evenly distribute",
    evenlyDistrbutionTip:
      'Once you click on "Evenly Distribute", the new submissions (assigned and unassigned) will be distrubted evenly between filterers',
    forEachFilterer: "for each filterer",
    zeroFiltererShare:
      "There are no filterers in your program, or there are no submissions to be distributed.",
    filterers: "Filterers",
    distribute: "Distribute",
    distributionMessage:
      "The distribution will be reset for all unassigned submissions. The already assigned submissions will not be set.",
    customDistribution: "Custom Distribution",
    numberOfSubmission: "Number Of Submission",
    customDistributeMessage: "Distribution was done successfully for",
    submissions: "submissions.",
    Error: "Error!",
    errorMessage: "Please enter a valid number of submissions.",
    IncludeAssignedSubmissions:
      "Include already assigned submissions which aren't reviewed yet",
    "score-card-edit-prompt-title": "Are you sure?",
    "editing-score-card-message": "This will reset all submissions statuses.",
    "Question Title": "Question Title:",
    "Question title description": "Question title description",
    "Question Choices": "Question Choices: ",
    "Question choices description": "Question choices description",
    "Add Choice": "Add Choice",
    Save: "Save",
    Edit: "Edit",
    Delete: "Delete",
    Title: "Title",
    weight: "Weight",
    question_score_description:
      "Add the weight for each choice to calculate the submission score",
    total_weight: "Total Maximum Score:",
    all: "All",
    "All Filterer": "All Filterer",
    Add: "Add",
    Ignore_save_action:
      "This action will be ignored, because the number of choices is less than 2",
    save_confirmation_message:
      "This will delete all evaluations for this question. Do you want to save changes?",
    yes: "Yes",
    enable_reason_text: "Enable",
    reason_field: "Reason Field",
    safe_save: "Save, without effecting evalutions",
    duplicate: "Duplicate",
  },
  ar: {
    Cancel: "إلغاء",
    Settings: "الإعدادات",
    question_list_title: "الاسئلة",
    question_list_description: "قم بتصميم اسئلة الفرز الخاصة بالبرنامج ستفيدك ",
    scoringCardPage: "الأسئلة",
    distributionPage: "الفرز",
    distributionAndAssignment: "التّوزيع والتّعيين",
    distributionTip:
      "هنا ستجد معلومات عن طرق التوزيع والتعيين للمشاركات على الفارزين.",
    newSubmissions: "مشاركات جديدة",
    unassignedSubmissions: "مشاركات لم يتمّ فرزها",
    filtererCount: "الفارزين",
    evenlyDistrbution: "التوزيع بالتساوي",
    evenlyDistrbutionTip:
      "عند ضغطك على توزيع بالتساوي، سيتم توزيع المشاركات الغير مفروزه (المعينة والغير معينة) بالتساوي على جميع الفارزين.",
    evenlyDistributionButton: "وزّع بالتّساوي",
    forEachFilterer: "لكل فارز",
    zeroFiltererShare:
      "لا يوجد فارزين في نظامك أو لا يوجد أي مشاركات ليتمّ فرزها.",
    filterers: "الفارزين",
    distribute: "توزيع",
    distributionMessage:
      "سيتم إعادة تعيين الفارزين لكل المشاركات التى لم يتم فرزها. لن يتم تعيين المشاركات المفروزة.",
    customDistribution: "توزيع مخصص",
    numberOfSubmission: "عدد المشاركات",
    customDistributeMessage: "تم التوزيع بنجاح على عدد",
    submissions: "مشاركات",
    Error: "خطأ",
    errorMessage: "من فضلك أدخل رقم صحيح لعدد المشاركات",
    IncludeAssignedSubmissions:
      "تضمين المشاركات الّتي تمّ توزيعها مسبقًا بين الفارزين ولم يتم مراجعتها بعد.",
    "score-card-edit-prompt-title": "هل أنت متأكد؟",
    "editing-score-card-message":
      "عند حفظ التغييرات سيتم إعادة تعيين حالة جميع المشاركات السابقة.",
    "Question Title": "عنوان السؤال: ",
    "Question title description": "ضع عنوان السؤال الّذي سوف يجيبه الفارز",
    "Question Choices": "خيارات السؤال: ",
    "Question choices description": "الفارز سوف يختار واحدًا من هذه الخيارات",
    Save: "حفظ",
    Edit: "تعديل",
    Delete: "حذف",
    "Add Choice": "أضف خيارًا",
    Title: "العنوان",
    weight: "الدرجة",
    question_score_description:
      "اضف درجة التقييم لكل جواب لحساب درجة المشاركات.",
    total_weight: "مجموع التقييم:",
    all: "الجميع",
    "All Filterer": "جميع المرشحين",
    Add: "إضافة",
    Ignore_save_action: "سيتم رفض حفظ السؤال، لان عدد الاجابات اقل من ٢",
    save_confirmation_message:
      "سيتم حذف جميع الإجابات المتعلقة بهذا السؤال. هل تريد حفظ التغييرات؟",
    yes: "نعم",
    enable_reason_text: "تمكين",
    reason_field: "حقل السبب",
    safe_save: "حفظ، بدون حذف الإجابات",
    duplicate: "نسخ",
  },
});

strings.setLanguage(locale || "en");

const translate = (string) => strings[string] || string;

export default translate;
