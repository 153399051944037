// FIXME: Potential bad use of snake case, switch everything to camel case

import React, { Component } from "react";
import PropTypes from "prop-types";
import FieldFactory from "./FieldFactory";
import "./form.scss";
import translate from "./fields/texts";
import ReCAPTCHA from "react-google-recaptcha";

/* eslint-disable no-unused-expressions */

/**
 * @class FormRenderer
 * @description Convert list of Field JSON to from/fields component with submission helpers
 */
class FormRenderer extends Component {
  state = { errors: [], visibleFields: [], fields: [] };

  UNSAFE_componentWillMount() {
    const { fields } = this.props;

    this.props.fields.forEach((field) => {
      this.setState(() => {
        // Backward compatibility for fields with no visibilityOptions
        field.visibility =
          field.visibility === undefined ? true : field.visibility;
        return { fields: [...fields] };
      });
    });
  }

  setErrors = (errors) => {
    this.setState({ errors });
  };

  clearErrors = () => {
    this.setErrors([]);
  };

  addError = (fieldId, fieldName, message) => {
    const { errors } = this.state;
    errors.push({ fieldId, message });

    this.setErrors(errors);
  };

  submit = () => {
    this.setState({ errors: [] }, () => {
      this.validate();

      if (this.state.errors.length > 0 || !this.props.onSubmit) {
        return;
      }

      const submissionData = this.serializeForm();
      this.props.onSubmit(submissionData);
    });
  };

  // FIXME: this method needs refactoring
  validate = () => {
    this.state.fields.map((fld) => {
      if (fld.visibility) {
        this.validateRegex(fld);
        this.validatePresence(fld);
        this.validateCity(fld);
        this.validateNumberRange(fld);
      }

      return null;
    });

    const result = Object.create(null);

    result.haveErrors = false;

    if (this.state.errors.length) {
      result.haveErrors = true;
      result.errors = this.state.errors;
    }

    return result;
  };

  validateRegex = (field) => {
    if (field.validation && field.validation.regex) {
      const re = new RegExp(field.validation.regex);

      if (!re.test(field.value)) {
        this.addError(
          field.id,
          field.title,
          translate(field.validation.message)
        );
      }
    }
  };

  validatePresence = (field) => {
    if (field.validation && field.validation.required) {
      if (!field.value || field.value.toString().trim() === "") {
        this.addError(field.id, field.title, translate("required"));
      }
    }
  };

  validateCity = (field) => {
    // City validation, since the city value is concted with country with a dash character
    if (field.validation && field.validation.required) {
      if (!field.value) {
        return;
      }

      if (
        field.type.name === "Countries" &&
        field.value.split("-").filter(Boolean).length === 1 &&
        field.withCity
      ) {
        this.addError(field.id, field.cityTitle, translate("required"));
      }
    }
  };

  validateNumberRange = (field) => {
    if (field.validation && field.validation.required) {
      if (!field.value) {
        return;
      }

      if (
        field.type.name === "Number" &&
        !(field.value.length >= field.min && field.value.length <= field.max)
      ) {
        if (field.min === field.max) {
          this.addError(
            field.id,
            field.title,
            translate("requiredLength", field.max)
          );
          return;
        }
        this.addError(
          field.id,
          field.title,
          translate("requiredRange", field.min, 10)
        );
      }
    }
  };

  serializeForm = () => {
    const FormData = Object.create(null);
    this.props.fields.forEach((fld) => {
      fld.id && (FormData[fld.title.toLowerCase()] = fld.value);
    });

    return FormData;
  };

  fieldValidationObj = (field) => ({
    ...field.validation,
    required: field.validation.required,
    maxFileSize: field.validation.max_file_size || field.validation.maxFileSize,
    fileTypes: field.validation.file_types || field.validation.fileTypes,
  });

  errorsByFieldTitle = (field) =>
    this.state.errors.filter((error) => error.fieldId === field.id);

  findFieldObject = (id) => this.props.fields.findIndex((i) => i.id === id);

  getHiddenFields = () =>
    this.props.fields.filter((i) => {
      const { visibilityOptions } = i;
      return visibilityOptions && visibilityOptions.length;
    });

  isMeetingVisibilityLogic = (field) => {
    const { fields } = this.state;
    let countOfMetConditions = 0;

    return field.visibilityOptions.every((item) => {
      const index = this.findFieldObject(item.id);

      switch (item.operator.toUpperCase()) {
        case "IS_EXACTLY":
          if (
            item.value === fields[index].value ||
            fields[index].value.includes(item.value)
          ) {
            countOfMetConditions += 1;
          }
          break;
        default:
          throw Error("Condition type is not defined.");
      }
      return !(countOfMetConditions === field.visibilityOptions.length);
    });
  };

  updateFieldsVisibility = () => {
    const hiddenFields = this.getHiddenFields();
    const { fields } = this.state;

    hiddenFields.forEach((field) => {
      const index = this.findFieldObject(field.id);
      const visible = this.isMeetingVisibilityLogic(field);
      fields[index].visibility = !visible;
      // Set field value to empty when teh field is hidden
      // To reset the condition of fields depending on it
      if (visible) {
        fields[index].value = "";
      }
    });

    this.setState({ fields });
  };

  handleFieldChange = (field, value) => {
    this.setState(
      () => {
        const { fields } = this.props;

        // Set index to zero when only a single field
        const index = fields.findIndex((f) => f.id === field.id);

        fields[index].value = value;
        return { fields };
      },
      () => {
        // Only check visibility if the filed is not in preview mode
        if (this.props.allowSubmission) {
          this.updateFieldsVisibility();
        }
      }
    );
  };

  renderFields = () =>
    this.props.fields.map((fld) => {
      if (fld.type.name !== "Divider") {
        fld.validation = this.fieldValidationObj(fld);
        fld.errors = this.errorsByFieldTitle(fld);
      }

      fld.previewMode = !this.props.allowSubmission;

      return (fld.previewMode ? fld.previewMode : fld.visibility) ? (
        <FieldFactory
          key={fld.id || +new Date()}
          settings={fld}
          onChange={this.handleFieldChange}
          value={this.state[fld.id]}
        />
      ) : null;
    });

  renderSubmitControls = () => {
    const minimumFieldsLength = 3;

    return (
      <div className="form-footer">
        <div className="actions">
          <button
            id="submit-form"
            className="button is-link"
            onClick={() => this.submit()}
            type="button"
          >
            {this.props.submitBtnText}
          </button>
        </div>
        {/* Don't show this error if all fields and other errors are visible to the user */}
        {this.state.fields.length >= minimumFieldsLength &&
          this.state.errors.length !== 0 && (
            <div className="validation-error">
              {translate("validateAllQuestions")}
            </div>
          )}
      </div>
    );
  };

  render() {
    const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
    const { allowSubmission, setCaptchaValue, showCaptcha } = this.props;
    return (
      <div className="form-builder">
        <div>
          {this.renderFields()}
          {siteKey && showCaptcha ? (
            <ReCAPTCHA sitekey={siteKey} onChange={setCaptchaValue} />
          ) : null}
          {allowSubmission ? this.renderSubmitControls() : null}
        </div>
      </div>
    );
  }
}

FormRenderer.propTypes = {
  fields: PropTypes.array.isRequired,
  onSubmit: PropTypes.func,
  allowSubmission: PropTypes.bool,
  submitBtnText: PropTypes.string,
};

FormRenderer.defaultProps = {
  onSubmit: null,
  allowSubmission: true,
  submitBtnText: "Submit",
};

export default FormRenderer;
