import React, { useEffect } from "react";
import axios from "axios";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import fetchPrivileges from "./actions/auth.actions";
import App from "./App";
import { configureStore } from "./configureStore";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createHashHistory } from "history";
import ReactGA from "react-ga";
import "./index.css";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
const history = createHashHistory();

if (process.env.GA_CODE) {
  ReactGA.initialize(process.env.GA_CODE);
}

export const store = configureStore();

// if (process.env.SENTRY_DSN_REACT && process.env.SENTRY_ENV) {
//   var { user } = jwt_decode(token);
//   const { sid: id, name, email } = user;

//   Sentry.init({
//     dsn: process.env.SENTRY_DSN_REACT,
//     release: process.env.APP_VERSION,
//     environment: process.env.SENTRY_ENV,
//     integrations: [
//       new Integrations.BrowserTracing({
//         routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
//       }),
//     ],
//     tracesSampleRate: 1.0,
//   });

//   if (id) {
//     Sentry.configureScope((scope) => {
//       scope.setUser({ id });
//     });

//     Sentry.setContext("User Details", {
//       id,
//       name,
//       email,
//     });
//   }
// }

const FallbackComponent = () => (
  <div className="error p-5 text-left">
    <h2 className="font-bold text-5xl">500</h2>
    <p className="pb-3 text-3xl">Oops, an error happened.</p>
    <p>You may want to head back to the homepage.</p>
    <a
      href="/"
      className="inline-block border bg-brand-gray text-white py-2 px-4 my-2"
    >
      Go to Homepage
    </a>
  </div>
);

render(
  <Sentry.ErrorBoundary fallback={FallbackComponent}>
    <Router history={history}>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);
