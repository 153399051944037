import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import { Input, Icon } from "../../../components";
import TextLength from "../../../utils/settings/text-length";
import updateReason from "../../../actions/reason.actions";
import "./Reason.scss";
import translate from "../texts";

function getReasonStatus(saving, saved, error, unsaved) {
  if (saving) {
    return "SAVING";
  }

  if (saved) {
    return "SAVED";
  }

  if (error) {
    return "ERROR";
  }

  if (unsaved) {
    return "UNSAVED";
  }

  return "";
}

const Indicator = ({ status, retry, cancel }) => {
  if (status === "SAVING") {
    return (
      <div className="reason-indicator">
        <div className="loader" />
        Saving...
        <button type="button" onClick={cancel}>
          Cancel
        </button>
      </div>
    );
  }
  if (status === "ERROR") {
    return (
      <div className="reason-indicator has-text-danger">
        <Icon name="alert" />
        Error saving.
        <button type="button" onClick={retry}>
          Retry
        </button>
      </div>
    );
  }
  if (status === "UNSAVED") {
    return (
      <div className="reason-indicator has-text-grey">
        <Icon name="alert" />
        Unsaved changes.
        <button type="button" onClick={retry}>
          Save Now
        </button>
      </div>
    );
  }
  if (status === "SAVED") {
    return (
      <div className="reason-indicator has-text-success">
        <Icon name="check" />
        Saved.
      </div>
    );
  }

  return null;
};

Indicator.propTypes = {
  status: PropTypes.string.isRequired,
  retry: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

class Reason extends Component {
  state = {
    reasonInput: this.props.answer.reason || "",
    isSaving: false,
    isUnsaved: false,
  };

  reasonRef = React.createRef();

  throttledUpdateReason = debounce(this.updateReason.bind(this), 2000, {
    leading: false,
  });

  componentDidMount() {
    this.resizeTextArea();
  }

  componentDidUpdate(prevProps) {
    if (this.props.reason.isSaved && !prevProps.reason.isSaved) {
      this.setState({
        isSaving: false,
        isUnsaved: false,
      });
    }

    if (this.props.reason.error && !prevProps.reason.error) {
      this.setState({
        isSaving: false,
        isUnsaved: false,
      });
    }
  }

  resizeTextArea = () => {
    const { current } = this.reasonRef;

    if (current) {
      const textarea = current.querySelector("textarea:not(:empty)");

      if (textarea) {
        textarea.style.height = "auto";
        const { scrollHeight, offsetHeight } = textarea;
        textarea.style.height =
          scrollHeight > offsetHeight + 2
            ? `${scrollHeight + 8}px`
            : `${offsetHeight}px`;
      }
    }
  };

  retry = () => {
    this.updateReason(this.state.reasonInput);
    this.setState({
      isSaving: true,
    });
  };

  cancel = () => {
    this.throttledUpdateReason.cancel();
    this.setState({
      isSaving: false,
      isUnsaved: true,
    });
  };

  onChange = (event) => {
    const { value: reason } = event.target;

    this.setState({
      reasonInput: reason,
      isSaving: true,
      isUnsaved: false,
    });

    this.throttledUpdateReason(reason);
  };

  updateReason(newReason) {
    const { dispatch, funnelId, submissionId, questionId, answer } = this.props;

    dispatch(
      updateReason(
        submissionId,
        {
          questionId,
          reason: newReason,
          weight: answer.weight,
          value: answer.value,
        },
        funnelId
      )
    );
  }

  render() {
    const { reasonInput, isSaving, isUnsaved } = this.state;

    const { isSaved, error } = this.props.reason;

    const status = getReasonStatus(isSaving, isSaved, error, isUnsaved);

    this.resizeTextArea();

    return (
      <div className="reason" ref={this.reasonRef}>
        <Input
          inputName="content"
          onChange={this.onChange}
          placeholder={translate("reason_placeholder")}
          maxlength={TextLength.VERY_LONG}
          value={reasonInput}
          multiline
        />

        <Indicator status={status} retry={this.retry} cancel={this.cancel} />
      </div>
    );
  }
}

Reason.propTypes = {
  dispatch: PropTypes.func.isRequired,
  funnelId: PropTypes.string.isRequired,
  submissionId: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  answer: PropTypes.object.isRequired,
  reason: PropTypes.shape({
    questionId: PropTypes.string,
    isSaved: PropTypes.bool,
    isSaving: PropTypes.bool,
    error: PropTypes.bool,
  }),
};

Reason.defaultProps = {
  reason: {
    questionId: "",
    isSaved: false,
    isSaving: false,
    error: false,
  },
};

const initialReason = {
  questionId: "",
  submissionId: "",
  isSaved: false,
  isSaving: false,
  error: false,
};

const mapStateToProps = (state, props) => {
  const {
    program: { program },
  } = state;
  const { reasons } = state;
  const { funnelId, submissionId } = props.match.params;
  const { questionId } = props;

  const reason = reasons.filter(
    (r) => r.questionId === questionId && r.submissionId === submissionId
  );

  return {
    funnelId,
    submissionId,
    question: program.questions.filter(
      (question) => question.id === questionId
    )[0],
    reason: reason[0] || initialReason,
  };
};

export default withRouter(connect(mapStateToProps)(Reason));
