// FIXME: Potential bad use of snake case, switch everything to camel case
import React from "react";
import PropTypes from "prop-types";

const SubmissionScore = ({ funnelId, funnelScores }) => (
  <span id="submissionScore">
    {(() => {
      if (funnelScores.length > 0) {
        const submissionScore = funnelScores.find(
          (item) => item.funnelId === funnelId
        );

        return submissionScore ? submissionScore.score : 0;
      }
      return 0;
    })()}
  </span>
);

SubmissionScore.propTypes = {
  funnelId: PropTypes.string.isRequired,
  funnelScores: PropTypes.array,
};

SubmissionScore.defaultProps = {
  funnelScores: [],
};

export default SubmissionScore;
