import React, { Component } from "react";
import PropTypes from "prop-types";
import { fetchProgram } from "../../actions/program.actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import { Wallpaper } from "../../components";
import * as promptMessageActions from "../../actions/prompt-message.actions";
import FormBuilder from "./FormBuilder";
import PublishFormModal from "./PublishFormModal";
import translate from "./texts";
import "./FormBuilderContainer.scss";
import "./FormFields.scss";

class FormBuilderContainer extends Component {
  constructor(props) {
    super(props);

    // To cache the old backgroundColor value, so can be used in validation
    this.oldBackgroundColor = "";
  }

  state = {
    publishModalActive: false,
    expandedEmbedded: false,
    fieldsList: [],
    backgroundColor: "",
  };

  UNSAFE_componentWillMount = () => {
    const { actions, programId } = this.props;
    actions.fetchProgram(programId);
  };

  UNSAFE_componentWillReceiveProps(props) {
    const settings = props.form.settings || {};
    this.setState(
      {
        fieldsList: [...Object.values(props.form.formFields)],
        backgroundColor: settings.backgroundColor || "",
      },
      () => {
        this.oldTitle = settings.title || "";
        this.oldBackgroundColor = this.state.backgroundColor;
      }
    );
  }

  togglePublishModal = () => {
    this.setState((prevState) => ({
      publishModalActive: !prevState.publishModalActive,
    }));
  };

  collapseHandler = () => {
    this.setState((prevState) => ({
      expandedEmbedded: !prevState.expandedEmbedded,
    }));
  };

  displayPromptMessage = (field) => {
    this.props.actions.activatePromptMessage({
      title: translate("remove"),
      message: translate("delete_confirmation_message"),
      buttonContent: translate("yes"),
      confirmationHandler: () => this.deleteField(field),
    });
  };

  deleteField = (field) => {
    const {
      form: { id },
      programId,
      actions,
    } = this.props;
    const data = {
      field_id: field.id,
    };
    axios
      .put(`${process.env.REACT_APP_API_URL}/forms/${id}/delete_field`, data)
      .then(() => {
        actions.deactivatePromptMessage();
        actions.fetchProgram(programId);
      });
  };

  toggleEnabled = () => {
    const {
      form: { id },
      actions,
      programId,
    } = this.props;

    axios
      .put(`${process.env.REACT_APP_API_URL}/forms/${id}/toggle_enabled`)
      .then(() => actions.fetchProgram(programId));
  };

  updateSettings = (data) => {
    const settings = {
      settings: data,
    };
    axios.put(
      `${process.env.REACT_APP_API_URL}/forms/${this.props.form.id}/update_settings`,
      settings
    );
  };

  saveField = (field) => {
    const {
      form: { id },
      actions,
      programId,
    } = this.props;

    axios
      .put(`${process.env.REACT_APP_API_URL}/forms/${id}`, {
        field,
      })
      .then(() => actions.fetchProgram(programId));
  };

  saveSortingFields = (sortedFields) => {
    this.setState(
      {
        fieldsList: sortedFields,
      },
      () => {
        const newSortedIDs = this.state.fieldsList.map((field) => field.id);
        axios.put(
          `${process.env.REACT_APP_API_URL}/forms/${this.props.form.id}/sort_fields`,
          {
            fieldIds: newSortedIDs,
          }
        );
      }
    );
  };

  render = () => {
    const { form } = this.props;
    const publicFormLink = `${process.env.REACT_APP_FRONTEND_URI}/#/forms/${form.id}`;
    return (
      <Wallpaper>
        <div id="form-builder-container" className="container">
          <FormBuilder
            onShareButtonClick={this.togglePublishModal}
            fieldsList={this.state.fieldsList}
            saveField={this.saveField}
            onDeleteField={this.displayPromptMessage}
            onSortingEnd={this.saveSortingFields}
            onSettingsChanged={this.updateSettings}
            formSettings={form.settings}
          />
          <PublishFormModal
            publishLink={publicFormLink}
            collapseHandler={this.collapseHandler}
            expandedEmbedded={this.state.expandedEmbedded}
            publishModalActive={this.state.publishModalActive}
            togglePublishModal={this.togglePublishModal}
            isFormEnabled={this.props.form.enabled}
            toggleEnabled={this.toggleEnabled}
          />
        </div>
      </Wallpaper>
    );
  };
}

FormBuilderContainer.propTypes = {
  form: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  programId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { program } = state.program;
  const { form, id: programId } = program;

  return {
    program,
    form,
    programId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...promptMessageActions,
      fetchProgram,
    },
    dispatch
  ),
});

const container = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormBuilderContainer);

export default container;
