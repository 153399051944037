import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function Home() {
  useEffect(() => {
    window.location.href = `${process.env.REACT_APP_FRONTEND_URI}/#/dashboard`;
  }, []);

  return (
    <div
      className="flex justify-center h-screen text-white text-4xl items-center"
      style={{ backgroundColor: "#268aff", opacity: 0.6 }}
    >
      Welcome to Razeen
    </div>
  );
}
