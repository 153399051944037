import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import translate from "./texts";

const RoleSelect = ({ onChange, roles, value }) => (
  <Select
    placeholder={translate("Select")}
    name="role"
    searchable={false}
    onChange={onChange}
    options={roles.map((role) => ({ ...role, name: translate(role.name) }))}
    valueKey="id"
    labelKey="name"
    clearable={false}
    value={{ ...value, name: translate(value.name) }}
  />
);

RoleSelect.propTypes = {
  roles: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};

export default RoleSelect;
