/* eslint-disable react/no-this-in-sfc */
// FIXME: Potential bad use of snake case, switch everything to camel case

import React from "react";
import axios from "axios";
import BaseField from "./BaseField";

/**
 * @class Countries
 * @extends BaseField
 * @description Countries/Cities field component
 */
export default class Countries extends BaseField {
  /* eslint-disable no-console */
  state = {
    value: "",
    city: "",
    country: "",
    countries: [],
    cities: [],
  };

  onCountryChange = async (e) => {
    const {
      target: { value },
    } = e;
    let cities;
    let newCountryName = "";
    if (value !== "") {
      newCountryName = this.state.countries.filter((c) => c.code === value)[0]
        .name;
      if (this.props.settings.withCity) {
        cities = await this.getCities(value);
      }
    }

    this.setState({
      country: { code: value, name: newCountryName },
      city: "",
      value: newCountryName,
      cities,
    });
  };

  onCityChange = (e) => {
    const {
      target: { value },
    } = e;

    this.setState(
      {
        city: value,
      },
      () => {
        this.setState({ value: this.getCombinedFieldValues() });
      }
    );
  };

  componentDidMount = () => {
    super.componentDidMount();
    this.getCountries();
  };

  getCombinedFieldValues = () => {
    if (this.state.city) {
      return `${this.state.country.name} - ${this.state.city}`;
    }
    return `${this.state.country.name}`;
  };

  getCountryValue = (countrySettings) => {
    const combinedValue = countrySettings.value;
    if (countrySettings.withCity && combinedValue.search(" - ") !== -1) {
      return combinedValue.split(" - ")[0];
    }
    return combinedValue;
  };

  getCityValue = (countrySettings) => {
    const combinedValue = countrySettings.value;
    if (countrySettings.withCity && combinedValue.search(" - ") !== -1) {
      return combinedValue.split(" - ")[1];
    }
    return "";
  };

  getCountryCode = (countryName) => {
    let countryObj = {};
    if (countryName !== "") {
      countryObj = this.state.countries.find((obj) => obj.name === countryName);
    }
    return countryObj ? countryObj.code : "";
  };

  setCountriesValue = () => {
    const countryName = this.getCountryValue(this.Settings);
    const countryCode = this.getCountryCode(countryName);
    this.onCountryChange({ target: { value: countryCode } });
    if (this.Settings.withCity) {
      this.onCityChange({
        target: { value: this.getCityValue(this.Settings) },
      });
    }
  };

  renderCountries = (id) =>
    this.state.countries.map((op) => (
      <option key={`${id}_${op.code}`} data-code={op.code} value={op.code}>
        {op.name}
      </option>
    ));

  renderCities = (id) =>
    this.state.cities.map((op) => (
      <option key={`${id}_${op.name}`} value={op.name}>
        {op.name}
      </option>
    ));

  getCountries = () => {
    axios
      .get(`${this.Settings.countriesUrl}?locale=${"en"}`)
      .then((countries) =>
        this.setState(
          () => ({ countries: countries.data.countries }),
          () => {
            if (this.Settings.value) {
              this.setCountriesValue();
            }
          }
        )
      )
      .catch((err) => console.error(err, "could not fetch countries"));
  };

  getCities = (countryCode) => {
    const url = this.Settings.citiesUrl.replace(":country_code", countryCode);
    return axios
      .get(`${url}?locale=${"en"}`)
      .then((response) => response.data)
      .catch((err) => console.error(err, "could not fetch cities"));
  };

  renderCountryField = () => {
    const { id, validation } = this.Settings;
    const newID = `${id}_$country`;
    return (
      <div className="field">
        {this.renderLabel()}
        <div className="control">
          <div className="select">
            <select
              id={newID}
              name={newID}
              onChange={this.onCountryChange}
              value={this.state.country.code}
              required={validation.required}
            >
              <option />
              {this.renderCountries(id)}
            </select>
          </div>
        </div>
      </div>
    );
  };

  renderCityField = () => {
    const { id, validation } = this.Settings;
    const newID = `${id}_$city`;
    return (
      <div className="field">
        {this.renderLabel(this.props.settings.cityTitle)}
        <div className="control">
          <div className="select">
            <select
              id={newID}
              name={newID}
              onChange={this.onCityChange}
              value={this.state.city}
              required={validation.required}
            >
              <option />
              {this.renderCities(id)}
            </select>
          </div>
        </div>
      </div>
    );
  };

  /**
   * @override
   */
  render() {
    return (
      <div className="country-city-field">
        {this.renderCountryField()}
        <br />
        {this.props.settings.withCity && this.state.country.code
          ? this.renderCityField()
          : null}
        {this.renderErrors()}
      </div>
    );
  }
}
