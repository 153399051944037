import React from "react";
import PropTypes from "prop-types";

const Paragraph = ({ settings }) => (
  <div className="paragraph">{settings.title}</div>
);

Paragraph.propTypes = {
  settings: PropTypes.object,
};

Paragraph.defaultProps = {
  settings: {
    title: "",
  },
};

export default Paragraph;
