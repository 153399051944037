import * as types from "../types/auth.types";
import Cookies from "universal-cookie";
import { setToken } from "../utils/auth-utils";

const cookies = new Cookies();

const initialState = {
  isFetching: false,
  fetched: false,
  privileges: {},
  signInLoading: false,
  signInError: null,
  signUpLoading: false,
  signUpError: null,

  user: cookies.get("user") ? cookies.get("user") : null,
  token: cookies.get("Rtoken") ? cookies.get("Rtoken") : null,

  forgotPasswordLoading: false,
  forgotPasswordError: null,
  forgotPasswordSuccess: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.RESET_PRIVILEGES:
      return initialState;
    case types.FETCH_PRIVILEGES:
      return {
        ...state,
        isFetching: true,
        fetched: false,
      };
    case types.FETCH_PRIVILEGES_PENDING:
      return {
        ...state,
        isFetching: true,
        fetched: false,
      };
    case types.FETCH_PRIVILEGES_REJECTED:
      return {
        ...state,
        isFetching: false,
        fetched: false,
      };
    case types.FETCH_PRIVILEGES_FULFILLED:
      return {
        ...state,
        ...action.payload.data,
        isFetching: false,
        fetched: true,
      };

    case types.SIGNIN_PENDING:
      return {
        ...state,
        signInLoading: true,
        signInError: null,
      };
    case types.SIGNIN_FULFILLED:
      setToken(
        action.payload.data.rtoken,
        action.payload.data.expiry,
        action.payload.data.user
      );
      return {
        ...state,
        signInLoading: false,
        signInError: null,
        user: action.payload.data.user,
        token: action.payload.data.rtoken,
      };
    case types.SIGNIN_REJECTED:
      return {
        ...state,
        signInLoading: false,
        signInError: action.payload.response?.data || action.payload,
      };
    case types.SIGNUP_PENDING:
      return {
        ...state,
        signUpLoading: true,
        signUpError: null,
      };
    case types.SIGNUP_FULFILLED:
      setToken(
        action.payload.data.rtoken,
        action.payload.data.expiry,
        action.payload.data.user
      );
      return {
        ...state,
        signUpLoading: false,
        signUpError: null,
        user: action.payload.data,
        token: action.payload.data.rtoken,
      };
    case types.SIGNUP_REJECTED:
      return {
        ...state,
        signUpLoading: false,
        signUpError: action.payload,
      };

    case types.FORGOT_PASSWORD_PENDING:
      return {
        ...state,
        forgotPasswordLoading: true,
        forgotPasswordError: null,
      };

    case types.FORGOT_PASSWORD_FULFILLED:
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPasswordError: null,
        forgotPasswordSuccess: true,
      };

    case types.FORGOT_PASSWORD_REJECTED:
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPasswordError: true,
        forgotPasswordSuccess: false,
      };

    default:
      return state;
  }
}
