import { combineReducers } from "redux";
import labels from "./reducers/labels.reducer";
import program from "./reducers/program.reducer";
import submissions, {
  filtersReducer as filters,
} from "./reducers/submissions.reducer";
import workspaces from "./reducers/workspaces.reducer";
import notifications from "./reducers/notifications.reducer";
import membersInvitation from "./reducers/members-invitation.reducer";
import membersManagement from "./reducers/members-management.reducer";
import programMembersModal from "./reducers/program-members-modal.reducer";
import privileges from "./reducers/auth.reducer";
import questions, { settings } from "./reducers/settings.reducer";
import promptMessage from "./reducers/prompt-message.reducer";
import alertMessage from "./reducers/alert-message.reducer";
import funnels from "./reducers/funnels.reducer";
import reasons from "./reducers/reasons.reducer";
import funnelAccess from "./reducers/funnelAccess.reducer";

export default function reducers(asyncReducers) {
  return combineReducers({
    submissions,
    labels,
    program,
    workspaces,
    membersInvitation,
    membersManagement,
    programMembersModal,
    privileges,
    filters,
    questions,
    settings,
    promptMessage,
    notifications,
    alertMessage,
    ...asyncReducers,
    funnels,
    reasons,
    funnelAccess,
  });
}
