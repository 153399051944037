import React from "react";
import { convertHinduNumerals } from "../../../utils/utils";
import BaseField from "./BaseField";

/**
 * @class Number
 * @extends BaseField
 * @description A field which accept number as avalue
 */
export default class Number extends BaseField {
  handleChange(event) {
    const value = convertHinduNumerals(event.target.value);
    if (
      value.length <= this.Settings.max &&
      (value === "" || /^[0-9\b]+$/.test(value))
    ) {
      this.setState({ value });
    }
  }

  /**
   * @override
   */
  render() {
    const { id, placeholder, validation } = this.Settings;
    return (
      <div className="field">
        {this.renderLabel()}
        <div className="control">
          <input
            type="text"
            className="input"
            name={id}
            id={id}
            placeholder={placeholder}
            value={this.state.value}
            onChange={(e) => this.handleChange(e)}
            required={validation.required}
            min={validation.min}
            max={validation.max}
          />
        </div>
        {this.renderErrors()}
      </div>
    );
  }
}
