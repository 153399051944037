import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { Box, Input, FooterActions } from "../../../components";
import translate from "../texts";

class EditGroup extends React.Component {
  state = {
    groupName: "",
    groupMembers: [],
  };

  UNSAFE_componentWillMount = () => {
    const { groupName, groupMembers } = this.props;

    this.setState({
      groupName,
      groupMembers: groupMembers.map((member) => member.user),
    });
  };

  onGroupNameChange = (event) => {
    const {
      target: { value },
    } = event;

    this.setState({
      groupName: value,
    });
  };

  onGroupMembersChange = (members) => {
    this.setState({
      groupMembers: members,
    });
  };

  saveGroup = () => {
    const { saveGroupEdits } = this.props;
    const { groupName, groupMembers } = this.state;

    saveGroupEdits(groupName, groupMembers);
  };

  footerActions() {
    const { cancelGroupEdit } = this.props;

    return [
      {
        content: translate("Save"),
        flat: true,
        onClick: this.saveGroup,
      },
      {
        content: translate("Cancel"),
        flat: true,
        onClick: cancelGroupEdit,
      },
    ];
  }

  render = () => {
    const { groupName, groupMembers } = this.state;
    const { programMembers } = this.props;

    return (
      <div id="edit-group-container">
        <Box dark>
          <div className="create-group-form">
            <div className="form-inputs">
              <Input
                value={groupName}
                onChange={this.onGroupNameChange}
                placeholder={translate("group_name")}
              />
              <Select
                multi
                searchable
                clearable={false}
                valueKey="id"
                labelKey="email"
                placeholder={translate("group_members")}
                arrowRenderer={null}
                noResultsText={null}
                onChange={this.onGroupMembersChange}
                options={programMembers.map((member) => member.user)}
                value={groupMembers}
              />
            </div>
            <FooterActions actions={this.footerActions()} />
          </div>
        </Box>
      </div>
    );
  };
}

EditGroup.propTypes = {
  groupName: PropTypes.string.isRequired,
  groupMembers: PropTypes.array.isRequired,
  programMembers: PropTypes.array.isRequired,
  cancelGroupEdit: PropTypes.func.isRequired,
  saveGroupEdits: PropTypes.func.isRequired,
};

export default EditGroup;
