import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Input, Icon } from "../../../components";
import TextLength from "../../../utils/settings/text-length";
import { isEqual } from "lodash";
import translate from "../texts";
/**
 * @class CreateChoices
 * @extends Component
 * @description A choices component for a multiple value fields
 */
class CreateChoices extends Component {
  state = {
    options: this.props.choices,
    listInput: "",
    listInputVisibility: false,
  };

  UNSAFE_componentWillMount = () =>
    this.setState({ options: this.props.choices });

  onSingleOptionChange = (e, i) => {
    const { target } = e;

    this.setState((prevState) => {
      const newOptions = [...prevState.options];
      newOptions[i] = target.value;
      this.triggerOnChange(newOptions);
      return { options: newOptions };
    });
  };

  addOption = () => {
    this.setState((prevState) => ({
      options: [...prevState.options, ""],
    }));
  };

  removeOption = (i) => {
    this.setState((prevState) => {
      const newOptions = [...prevState.options];
      newOptions.splice(i, 1);
      this.triggerOnChange(newOptions);
      return { options: newOptions };
    });
  };

  getValidChoices = (options) => [...new Set(options)].filter((c) => c);

  triggerOnChange = (options) => {
    const validChoices = this.getValidChoices(options);
    this.props.onOptionChange(validChoices);
  };

  toggleListInput = () => {
    this.setState((prevState) => ({
      listInputVisibility: !prevState.listInputVisibility,
      listInput: "",
    }));
  };

  appendToOptionsList = () => {
    const newOptionsArry = this.state.listInput.split(/\n/);
    this.toggleListInput();
    this.setState((prevState) => {
      // If the previous options have only the initial empty option then remove it
      const prevOptions =
        prevState.options.length === 1 && prevState.options[0] === ""
          ? []
          : [...prevState.options];
      const newOptions = [...prevOptions, ...newOptionsArry];
      return { options: newOptions };
    });
  };

  render() {
    const { listInputVisibility, listInput, options } = this.state;

    const ops = options.map((op, i) => (
      /* eslint-disable react/no-array-index-key */
      <div key={`${i}_op`} className="single-option">
        <Input
          key={`${i}_op`}
          onChange={(e) => this.onSingleOptionChange(e, i)}
          value={options[i]}
          maxlength={TextLength.MEDIUM}
          className={
            options.filter((e) => e === op).length !== 1 ? "error" : ""
          }
        />
        <div
          role="button"
          tabIndex={0}
          className="remove-icon"
          onClick={() => this.removeOption(i)}
          onKeyPress={() => this.removeOption(i)}
        >
          <Icon className="remove-option" name="close-circle" />
        </div>
      </div>
    ));

    return (
      <div id="options-list" className="options-list">
        <div className="label-option flex items-center justify-start">
          <span>{translate("options")}</span>
          <Button
            flat
            onClick={this.toggleListInput}
            content={translate("appendList")}
            tiny
            iconName="format-list-text"
            className="my-auto p-0 m-0 ms-4 h-auto justify-start text-brand-gray"
          />
        </div>
        {listInputVisibility ? (
          <div className="mt-3">
            <span className="label-option text-brand-gray">
              {translate("optionsListPlaceholder")}
            </span>
            <Input
              className="input-component append-list-options pt-1"
              value={listInput}
              onChange={(e) => {
                this.setState({ listInput: e.target.value });
              }}
              multiline
              placeholder={translate("optionsListPlaceholder")}
            />
            <div className="flex">
              <Button
                flat
                onClick={this.appendToOptionsList}
                content={translate("appendList")}
                iconName="plus-circle"
              />
              <Button
                flat
                onClick={this.toggleListInput}
                content={translate("cancel")}
              />
            </div>
          </div>
        ) : (
          <div>
            {!isEqual(
              this.getValidChoices(options),
              options.filter((i) => i !== "")
            ) && (
              <>
                <div className="errors-container mt-4">
                  <p className="rejected me-6">
                    {translate("duplacteOptions")}
                  </p>
                </div>
              </>
            )}
            {ops}
            <Button
              flat
              onClick={this.addOption}
              content={translate("addNewOption")}
              iconName="plus-circle"
            />
          </div>
        )}
      </div>
    );
  }
}

CreateChoices.propTypes = {
  onOptionChange: PropTypes.func,
  choices: PropTypes.array,
};

CreateChoices.defaultProps = {
  onOptionChange: () => {},
  choices: [""],
};

export default CreateChoices;
