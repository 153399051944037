import React from "react";
import BaseField from "./BaseField";

/**
 * @class Select
 * @extends BaseField
 * @description Select field component
 */
export default class Select extends BaseField {
  renderOptions(id) {
    return this.props.settings.choices.map((op, i) => {
      const uKey = `${id}_${i}`;
      return (
        <option key={uKey} value={op}>
          {op}
        </option>
      );
    });
  }

  /**
   * @override
   */
  render() {
    const { id, validation } = this.Settings;
    return (
      <div className="field">
        {this.renderLabel()}
        <div className="control">
          <div className="select">
            <select
              id={id}
              name={id}
              onChange={this.onChange}
              value={this.state.value}
              required={validation.required}
            >
              <option />
              {this.renderOptions(id)}
            </select>
          </div>
        </div>
        {this.renderErrors()}
      </div>
    );
  }
}
