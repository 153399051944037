export const FETCH_PROGRAM = "FETCH_PROGRAM";
export const RESET_PROGRAM = "RESET_PROGRAM";
export const FETCH_PROGRAM_PENDING = "FETCH_PROGRAM_PENDING";
export const FETCH_PROGRAM_REJECTED = "FETCH_PROGRAM_REJECTED";
export const FETCH_PROGRAM_FULFILLED = "FETCH_PROGRAM_FULFILLED";

export const DISTRIBUTE_SUBMISSIONS = "DISTRIBUTE_SUBMISSIONS";
export const DISTRIBUTE_SUBMISSIONS_PENDING = "DISTRIBUTE_SUBMISSIONS_PENDING";
export const DISTRIBUTE_SUBMISSIONS_REJECTED =
  "DISTRIBUTE_SUBMISSIONS_REJECTED";
export const DISTRIBUTE_SUBMISSIONS_FULFILLED =
  "DISTRIBUTE_SUBMISSIONS_FULFILLED";

export const SEND_EMAIL = "SEND_EMAIL";
export const SEND_EMAIL_PENDING = "SEND_EMAIL_PENDING";
export const SEND_EMAIL_REJECTED = "SEND_EMAIL_REJECTED";
export const SEND_EMAIL_FULFILLED = "SEND_EMAIL_FULFILLED";
export const ASSIGN_SUBMISSIONS = "ASSIGN_SUBMISSIONS";

export const FETCH_EMAIL = "FETCH_EMAIL";
export const FETCH_EMAIL_PENDING = "FETCH_EMAIL_PENDING";
export const FETCH_EMAIL_REJECTED = "FETCH_EMAIL_REJECTED";
export const FETCH_EMAIL_FULFILLED = "FETCH_EMAIL_FULFILLED";

export const UPDATE_PROGRAM_NAME = "UPDATE_PROGRAM_NAME";
export const UPDATE_PROGRAM_NAME_PENDING = "UPDATE_PROGRAM_NAME_PENDING";
export const UPDATE_PROGRAM_NAME_REJECTED = "UPDATE_PROGRAM_NAME_REJECTED";
export const UPDATE_PROGRAM_NAME_FULFILLED = "UPDATE_PROGRAM_NAME_FULFILLED";

export const FETCH_INPUT_SOURCES = "FETCH_INPUT_SOURCES";
export const FETCH_INPUT_SOURCES_PENDING = "FETCH_INPUT_SOURCES_PENDING";
export const FETCH_INPUT_SOURCES_REJECTED = "FETCH_INPUT_SOURCES_REJECTED";
export const FETCH_INPUT_SOURCES_FULFILLED = "FETCH_INPUT_SOURCES_FULFILLED";
