import React from "react";
import BaseField from "./BaseField";

/**
 * @class Text
 * @extends BaseField
 * @description Simple text field component
 */
export default class Text extends BaseField {
  /**
   * @override
   */
  render() {
    const { id, placeholder, validation } = this.Settings;
    return (
      <div className="field">
        {this.renderLabel()}
        <div className="control">
          <input
            type="text"
            className="input"
            name={id}
            id={id}
            placeholder={placeholder}
            value={this.state.value}
            onChange={this.onChange}
            required={validation.required}
            maxLength={validation.maxlength}
            minLength={validation.minlength}
          />
        </div>
        {this.renderErrors()}
      </div>
    );
  }
}
