import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import translate from "../texts";
import "./ProgramDashboardStatistics.scss";

// TODO: Rename to ProgramDetails
class ProgramDashboardStatistics extends PureComponent {
  render() {
    const { submissionCount, archived, createdAt } = this.props;
    return (
      <div className="statistics-container">
        <div className="item">
          <p className="item-title">{translate("Submissions")}</p>
          <p className="item-value">{submissionCount}</p>
        </div>
        <div className="item">
          <p className="item-title">{translate("Created")}</p>
          <p className="item-value">
            {dayjs(createdAt).format("dddd DD MMM YYYY")}
          </p>
        </div>
        {archived && (
          <div className="item">
            <p className="item-value label-archived">{translate("Archived")}</p>
          </div>
        )}
      </div>
    );
  }
}

ProgramDashboardStatistics.propTypes = {
  submissionCount: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  archived: PropTypes.bool.isRequired,
};

export default ProgramDashboardStatistics;
