import { enhanceRoleObj } from "../utils/role-helper";
import * as types from "../types/members-invitation.types";

const initialState = {
  isFetching: false,
  rolesFetched: false,
  membersFetched: false,
  error: {},
  roles: [],
  workspaceMembers: { members: [], workspace: {} },
  members: [],
  showMembersWithCustomRoles: false,
  globalMembersRole: {},
  defaultMemberRole: {},
};

export default function membersInvitationReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ROLES_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_ROLES_REJECTED:
      return {
        ...state,
        isFetching: false,
        rolesFetched: false,
        error: { ...action.payload },
      };
    case types.FETCH_ROLES_FULFILLED: {
      const { roles } = action.payload.data;
      const filtererRole = enhanceRoleObj(
        roles.find((role) => role.name === "filterer")
      );
      // TODO: refactor this case of setting custom role then go to
      // members management and then go back to invite.
      const nextGlobalMemberRole = state.globalMembersRole.name
        ? state.globalMembersRole
        : filtererRole;

      return {
        ...state,
        isFetching: false,
        rolesFetched: true,
        globalMembersRole: nextGlobalMemberRole,
        defaultMemberRole: filtererRole,
        roles,
      };
    }
    case types.INVITE_MEMBERS_PENDING:
      return {
        ...state,
        isPosting: true,
      };
    case types.INVITE_MEMBERS_REJECTED:
      return {
        ...state,
        isPosting: false,
        posted: false,
        error: { ...action.payload },
      };
    case types.INVITE_MEMBERS_FULFILLED:
      return {
        ...state,
        isPosting: false,
        posted: true,
      };
    case types.FETCH_MEMBERS_PENDING:
      return {
        ...state,
      };
    case types.FETCH_MEMBERS_REJECTED:
      return {
        ...state,
        membersFetched: true,
        error: { ...action.payload },
      };
    case types.FETCH_MEMBERS_FULFILLED:
      return {
        ...state,
        workspaceMembers: { ...action.payload.data },
        fetched: true,
        membersFetched: true,
      };
    case types.SET_INVITATION_DEFAULT_ROLE:
      return {
        ...state,
        globalMembersRole: action.role,
        defaultMemberRole: action.role,
      };
    case types.CHANGE_GLOBAL_ROLE:
      return {
        ...state,
        ...action.nextGlobalRoleSettings,
      };
    case types.ADD_MEMBERS:
      return {
        ...state,
        members: action.members,
      };
    case types.UPDATE_INDIVIDUAL_MEMBER_ROLE:
      return {
        ...state,
        members: action.members,
      };
    case types.RESET_INVITATION_MEMBERS:
      return {
        ...state,
        members: [],
        globalMembersRole: state.defaultMemberRole,
      };
    default:
      return state;
  }
}
