import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    view_announcement: "View Announcement",
    go_to_submission: "Go to submission",
    go_to_submissions: "Go to submissions",
    has_commented: "has commented on submission",
    have_been_assigned: "You have been assigned to",
    submissions: "submissions",
    Notifications: "Notifications",
    New: "New",
    Read: "Read",
    mark_read: "Mark read",
    notifications_description:
      "Here you will find all notifications relevant to you whether they are submission or announcement notifications or comments made on submissions.",
    scoring_card_updated:
      "The scoring card for this program has been updated, You need to review your assigned submissions again.",
    scoring_card_title: "Scoring card updated",
  },
  ar: {
    view_announcement: "عرض الاعلان",
    has_commented: "قام بالتعليق على المشاركة",
    go_to_submission: "اذهب إلى المشاركة",
    go_to_submissions: "اذهب إلى المشاركات",
    Notifications: "تنبيهات",
    New: "جديد",
    Read: "اقرأ",
    mark_read: "تمّ قراءته",
    notifications_description:
      "هنا ستجد جميع الإشعارات الخاصة بك تتضمن المشاركات المعينة لك والاعلانات و التعليقات على مشاركات معينة لك.",
    have_been_assigned: "لقد تم تعيينك على عدد",
    submissions: "مشاركات",
    scoring_card_updated:
      "لقد تم تحديث نموذج التقييم للبرنامج، الرجاء اعادة تقييم المشاركات التي تم تعيينك عليها.",
    scoring_card_title: "تم تحديث نموذج التقييم",
  },
});

strings.setLanguage(locale || "en");

const translate = (string) => strings[string] || string;

export default translate;
