import React from "react";
import PropTypes from "prop-types";

/**
 * Label component
 * @description Used with the field
 */
const Label = ({ text, required }) => (
  <label className={required ? "label required-label" : "label"}> {text}</label>
);

Label.propTypes = {
  text: PropTypes.string,
  required: PropTypes.bool,
};

Label.defaultProps = {
  required: false,
  text: "",
};

export default Label;
