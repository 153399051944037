/**
 * Text length enum
 */
export default {
  SHORT: 64,
  MEDIUM: 128,
  LONG: 300,
  VERY_LONG: 80000,
  VERY_SHORT: 16,
};
