import LocalizedStrings from "react-localization";

const strings = new LocalizedStrings({
  en: {
    form_submit_button: "Submit",
    loading: "Loading...",
    success_msg: "Thanks! Your submission has been saved successfully.",
    error_msg: "Please try again later",
    enable_rtl_direction: "Enable right alignment",
  },
  ar: {
    form_submit_button: "إرسال",
    loading: "تحميل ...!",
    success_msg: "شكرا لك , لقد تم استلام مشاركتك بنجاح",
    error_msg: "خطأ, رجاء المحاولة مرة أخري",
    "Form is disabled": "هذا النّموذج غير متاح للمشاركة حاليًا",
    enable_rtl_direction: "تفعيل المحاذاة لليمين",
  },
});

// strings.setLanguage(global.formSettings.rtlDirectionEnabled ? "ar" : "en");

const embeddedFormTranslate = (string) => strings[string] || string;

export default embeddedFormTranslate;
