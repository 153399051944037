import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button, Switch, Input, spinner } from "../../components";
import { toast } from "react-toastify";

import "./FunnelAccess.scss";
import translate from "./texts";

class FunnelAccess extends PureComponent {
  wrapperRef = React.createRef();

  baseUrl = process.env.REACT_APP_API_URL.replace("/api", "");

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const targetClasses = event.target.parentNode.className.split(" ");
    if (
      this.wrapperRef &&
      !this.wrapperRef.current.contains(event.target) &&
      !targetClasses.includes("share-btn")
    ) {
      this.props.toggleFunnelAccessPreview();
    }
  };

  toggleEnableFunnelSharing = () => {
    spinner.show();
    const { funnelId, setFunnelPublicAccess } = this.props;
    setFunnelPublicAccess(funnelId).then(() => {
      spinner.hide();
    });
  };

  CopyToClipboard = (url) => {
    toast(translate("copied_successfully"));
    navigator.clipboard.writeText(url);
  };

  render() {
    const {
      toggleFunnelAccessPreview,
      funnelAccess: { funnelPublicAccessToken, enablePublic },
    } = this.props;

    return (
      <div className="share-funnel-container" ref={this.wrapperRef}>
        <div className="share-funnel-header">
          <h1 className="capitalize font-bold">{`${translate(
            "share_funnel"
          )}`}</h1>
          <Button
            iconName="close"
            tiny
            flatWhite
            className="close-icon text-base"
            onClick={toggleFunnelAccessPreview}
          />
        </div>

        <div className="share-funnel-wrapper">
          <Switch
            name="direction-controller"
            wide
            checked={enablePublic}
            onChange={this.toggleEnableFunnelSharing}
            value={translate("enable_public_link")}
            className="h-4 mb-1"
          />
          <p>{`${translate("description")}`}</p>

          {enablePublic && funnelPublicAccessToken && (
            <div className="flex mt-3">
              <Input
                inputName="search"
                onChange={() => {}}
                value={`${this.baseUrl}/funnels/${funnelPublicAccessToken}/`}
                onFocus={(event) => event.target.select()}
                className="w-full me-3"
              />
              <Button
                content={`${translate("copy_link")}`}
                onClick={() => {
                  this.CopyToClipboard(
                    `${this.baseUrl}/funnels/${funnelPublicAccessToken}/`
                  );
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

FunnelAccess.propTypes = {
  toggleFunnelAccessPreview: PropTypes.func.isRequired,
  setFunnelPublicAccess: PropTypes.func.isRequired,
  funnelAccess: PropTypes.object.isRequired,
  funnelId: PropTypes.string.isRequired,
};

export default FunnelAccess;
