import React from "react";
import { Box, Columns, Button, Modal } from "../../components";
import PropTypes from "prop-types";

import "./AlertMessage.scss";

const AlertMessage = ({ active, message, title, okHandler }) => (
  <Modal className="alert-message" active={active} id="AlertMessage">
    <Box>
      <h3>{title}</h3>
      <p>{message}</p>
      <Columns>
        <Button id="okBtn" wide content="OK" onClick={okHandler} />
      </Columns>
    </Box>
  </Modal>
);

AlertMessage.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  okHandler: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};

export default AlertMessage;
