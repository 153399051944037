export const FETCH_FUNNEL_SETTINGS = "FETCH_FUNNEL_SETTINGS";
export const FETCH_FUNNEL_PROGRESS = "FETCH_FUNNEL_PROGRESS";
export const FETCH_FUNNEL_SETTINGS_PENDING = "FETCH_FUNNEL_SETTINGS_PENDING";
export const FETCH_FUNNEL_SETTINGS_REJECTED = "FETCH_FUNNEL_SETTINGS_REJECTED";
export const FETCH_FUNNEL_SETTINGS_FULFILLED =
  "FETCH_FUNNEL_SETTINGS_FULFILLED";
export const RESET_FUNNEL_STATE = "RESET_FUNNEL_STATE";
export const CREATE_FUNNEL = "CREATE_FUNNEL";
export const CREATE_FUNNEL_PENDING = "CREATE_FUNNEL_PENDING";
export const CREATE_FUNNEL_REJECTED = "CREATE_FUNNEL_REJECTED";
export const CREATE_FUNNEL_FULFILLED = "CREATE_FUNNEL_FULFILLED";
export const SAVE_FUNNEL = "SAVE_FUNNEL";
export const SAVE_FUNNEL_PENDING = "SAVE_FUNNEL_PENDING";
export const SAVE_FUNNEL_FULFILLED = "SAVE_FUNNEL_FULFILLED";
export const SAVE_FUNNEL_REJECTED = "SAVE_FUNNEL_REJECTED";
