import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Icon, Tab, IconButton, Modal } from "../../components";
import Permit from "../../layouts/Authorization";
import uuid from "uid";
import { ProgramMembersButton } from "../../layouts/ProgramMembers";
import UserInfoAndPreferences from "../../layouts/UserInfoAndPreferences";
import translate from "./texts";
import "./FixedHeader.scss";
import releases from "./Releases.json";
import ProgramSettingsModal from "../../layouts/Program/ProgramSettingsModal/ProgramSettingsModal";
import useFindPrivileges from "../../hooks/useFindPrivileges";

const FixedHeader = ({
  program,
  tabsSettings,
  isWorkspaceHeader,
  onUpdateProgramName,
  canUpdateProgramName,
}) => {
  const [activeTab, setActiveTab] = useState("");
  const [newProgramName, setNewProgramName] = useState("");
  const [editProgramNameEnabled, setEditProgramNameEnabled] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  // Add new version info as an object at index 0 in ./Releases.json
  const [releaseUpdates] = useState(releases);

  const programNameRef = useRef(null);

  // OR is added to mark Submissions tab as active in these cases:
  // 1. Open All Submission tab.
  // 2. Open any Funnel tab.
  // 3. Open a single submission.
  // Since the equality will not return the tab unless All Submission
  // tab is selected
  const getTabItem = (pathname) =>
    tabsSettings.tabsList.filter(
      (tab) =>
        pathname === tab.link ||
        (tab.link.includes("funnels") && pathname.includes("funnels"))
    )[0];

  // return the suitable privilege for the tab
  const getPrivilege = (tabName) => {
    switch (tabName) {
      case "overview":
        return "submission_report.view";

      case "fields":
        return "fields.edit";

      case "formBuilder":
        return "programs.form.edit";

      default:
        return null;
    }
  };

  const activateEditProgramName = () => {
    // Check if the user has the privilege to update the program name
    if (!canUpdateProgramName) {
      return;
    }

    setEditProgramNameEnabled(!editProgramNameEnabled);
    setNewProgramName(program.name);

    if (programNameRef && programNameRef.current) {
      programNameRef.current.focus();
    }
  };

  const cancelEditingProgramName = (e) => {
    e.stopPropagation();

    setNewProgramName(program.name);
    setEditProgramNameEnabled(false);
  };

  const updateProgramName = (e) => {
    e.stopPropagation();

    const newProgramNameTrimmed = newProgramName.trim();

    if (!newProgramNameTrimmed) {
      return;
    }

    if (newProgramNameTrimmed === program.name) {
      setEditProgramNameEnabled(false);
      return;
    }

    onUpdateProgramName(newProgramNameTrimmed);
    setEditProgramNameEnabled(false);
  };

  // const setNewVersion = () => {
  //   //  If new version & modal is closed => set new version into LocalStorage
  //   const latestRelease = releaseUpdates[0];
  //   const ViewedUpdates = localStorage.getItem(`razeen_release_version_${latestRelease.num}`);
  //   if (!modalActive && !ViewedUpdates) {
  //     localStorage.setItem(`razeen_release_version_${latestRelease.num}`, true);
  //   }
  // };

  const toggleModal = () => {
    setModalActive(!modalActive);
    // setNewVersion();
  };

  // const versionTracking = () => {
  //   //  If new version, toggle modal
  //   const latestRelease = releaseUpdates[0];
  //   const ViewedUpdates = localStorage.getItem(`razeen_release_version_${latestRelease.num}`);
  //   if (!ViewedUpdates) {
  //     toggleModal();
  //   }
  // };

  // This function will render tabs based on the number of tabs on the array
  const renderTabs = () => (
    <Tab>
      {tabsSettings.tabsList.map((tab) => {
        const tabItem = (
          <Tab.Item
            key={tab.name}
            title={translate(tab.name)}
            link={tab.link}
            className={activeTab === tab.name ? "active-tab" : ""}
          />
        );
        // Check if there a privilege for the tab.
        const privilege = getPrivilege(tab.name);

        // If there is a privilege, add Permit wrapper to the tab item
        if (privilege) {
          return (
            <Permit
              key={tab.name}
              targetPrivilege={privilege}
              targetId={program.id}
            >
              {tabItem}
            </Permit>
          );
        }
        return tabItem;
      })}
    </Tab>
  );

  const renderList = (list) => list.map((item) => <li key={uuid()}>{item}</li>);

  const renderSection = (type, content) => (
    <section>
      <h3>
        <Icon name={content.icon} />
        {` ${type}`}
      </h3>
      <ul>{renderList(content.data)}</ul>
    </section>
  );

  const renderReleases = () =>
    releaseUpdates.map((version) => (
      <div key={uuid()} className="v border-b border-zinc-300 pb-5">
        <span className="version-date">{version.date}</span>
        <h2>{`Version ${version.num}`}</h2>
        {version.new && renderSection("new", version.new)}
        {version.improved && renderSection("improved", version.improved)}
        {version.fixed && renderSection("fixed", version.fixed)}
      </div>
    ));

  useEffect(() => {
    const tabItem = getTabItem(tabsSettings.pathname);
    if (tabItem) {
      setActiveTab(tabItem.name);
    }

    if (editProgramNameEnabled && programNameRef && programNameRef.current) {
      programNameRef.current.focus();
    }

    // versionTracking();
  }, [getTabItem]);

  const [editModal, setEditModal] = useState(false);

  const canEditProgram = useFindPrivileges("programs.edit", program?.id);

  return (
    <div className="sticky-header">
      <Permit targetPrivilege="programs.edit" targetId={program?.id}>
        <ProgramSettingsModal
          modalActive={editModal}
          setModalActive={setEditModal}
        />
      </Permit>
      <div className="header-start">
        {/* Using state to keep track of workspace foldering */}
        <Link className="link-button" to={{ pathname: "/dashboard" }}>
          <i className="razeen" />
        </Link>
        {!isWorkspaceHeader && (
          <>
            <div
              className="program-title"
              onClick={() => {
                if (canEditProgram) {
                  setEditModal(true);
                }
              }}
              onKeyUp={activateEditProgramName}
              role="presentation"
            >
              {program.name}
            </div>

            <Permit targetPrivilege="programs.settings" targetId={program.id}>
              <ProgramMembersButton programId={program.id} />
            </Permit>
          </>
        )}
      </div>
      <div className="header-middle">
        {!isWorkspaceHeader && (
          <div className="tabs-container">
            {tabsSettings.canRenderTabs &&
              Boolean(tabsSettings.tabsList.length) &&
              renderTabs()}
          </div>
        )}
      </div>
      <div className="header-end">
        <button
          type="button"
          onClick={() => toggleModal()}
        >{`V ${releaseUpdates[0].num}`}</button>
        <UserInfoAndPreferences />
      </div>

      <Modal active={modalActive} toggle={toggleModal}>
        <header>
          <h1>Changelog</h1>
          <IconButton iconName="close" onClick={() => toggleModal()} />
        </header>
        <div
          className="body flex flex-col w-full"
          style={{
            backgroundImage: "none",
          }}
        >
          {renderReleases()}
        </div>
      </Modal>
    </div>
  );
};

FixedHeader.propTypes = {
  program: PropTypes.object,
  tabsSettings: PropTypes.shape({
    tabsList: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string,
      })
    ),
    pathname: PropTypes.string,
    canRenderTabs: PropTypes.bool,
  }),
  isWorkspaceHeader: PropTypes.bool,
  onUpdateProgramName: PropTypes.func,
  canUpdateProgramName: PropTypes.bool,
};

FixedHeader.defaultProps = {
  program: null,
  tabsSettings: {
    tabsList: [],
    pathname: "",
    canRenderTabs: false,
  },
  isWorkspaceHeader: false,
  onUpdateProgramName: () => {},
  canUpdateProgramName: false,
};

export default FixedHeader;
