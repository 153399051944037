import React, { Component } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";

import { Manager, Reference, Popper } from "react-popper";

import { Icon } from "../../components";

import { eventPropagationPath } from "../../utils/utils";

import "./Dropdown.scss";

// Prevent click from propogating outside the dropdown.
const stopPropagation = (event) => {
  event.stopPropagation();
};

const renderItem = (item, index, closeOnItemClick, toggleDropDown) =>
  item ? (
    <div
      key={index}
      className="dd-item"
      onClick={closeOnItemClick ? toggleDropDown : stopPropagation}
      onKeyUp={closeOnItemClick ? toggleDropDown : stopPropagation}
      role="presentation"
    >
      {item}
    </div>
  ) : null;

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.portals = document.getElementById("portals-root");
  }

  state = {
    active: false,
  };

  onTriggerClick = (event) => {
    this.setState(
      (state) => ({
        active: !state.active,
      }),
      () => {
        if (this.state.active) {
          document.addEventListener("mousedown", this.handleClickOnDocument);
        } else {
          document.removeEventListener("mousedown", this.handleClickOnDocument);
        }
      }
    );

    // Prevent click from propogating outside the dropdown.
    if (event) {
      event.stopPropagation();
    }
  };

  handleClickOnDocument = (event) => {
    const { active } = this.state;
    const path = eventPropagationPath(event);
    const { dropdownRef, triggerRef } = this;

    // Don't close the dropdown if the click is inside it
    const isClickOutside = path.indexOf(dropdownRef) === -1;

    // Don't close the dropdown and reopen it again if I click on the trigger
    const isClickNotOnTrigger = path.indexOf(triggerRef) === -1;

    const dropdownIsVisible = dropdownRef && active;

    const shouldCloseOnOutsideClick =
      dropdownIsVisible && isClickOutside && isClickNotOnTrigger;

    if (shouldCloseOnOutsideClick) {
      this.closeDropDown(event);
    }
  };

  closeDropDown = (event) => {
    const path = eventPropagationPath(event);
    const { dropdownRef } = this;
    const isClickInside = path.indexOf(dropdownRef) !== -1;

    document.removeEventListener("mousedown", this.handleClickOnDocument);

    this.setState({
      active: false,
    });

    // Prevent click from propogating outside the dropdown.
    if (isClickInside) {
      event.stopPropagation();
    }
  };

  render() {
    const { children, className, autoClose, label, icon, trigger } = this.props;

    const { active } = this.state;

    return (
      <Manager>
        <Reference
          innerRef={(ref) => {
            this.triggerRef = ref;
          }}
        >
          {({ ref }) => {
            if (trigger) {
              return (
                // We can't add refs to the trigger when it's a stateless components
                // that's why we will wrap the trigger in a div
                <div ref={ref} className="dd-trigger">
                  {React.cloneElement(trigger, {
                    onClick: this.onTriggerClick,
                  })}
                </div>
              );
            }
            return (
              <button
                ref={ref}
                onClick={this.onTriggerClick}
                className="dd-trigger"
                type="button"
              >
                <Icon name={`${icon} mdi-24px`} />
                <span>{label}</span>
              </button>
            );
          }}
        </Reference>
        {active && (
          <Popper
            innerRef={(ref) => {
              this.dropdownRef = ref;
            }}
            placement="bottom"
          >
            {({ ref, style, placement }) => (
              <div
                ref={ref}
                className={`dd ${className}`}
                style={{ ...style, ...this.props.style }}
                data-placement={placement}
              >
                <div className="dd-menu">
                  <div className="dd-content">
                    {React.Children.map(children, (child, index) =>
                      renderItem(child, index, autoClose, this.closeDropDown)
                    )}
                  </div>
                </div>
              </div>
            )}
          </Popper>
        )}
      </Manager>
    );
  }
}

Dropdown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  label: PropTypes.string,
  icon: PropTypes.string,
  trigger: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
  ]),
  className: PropTypes.string,
  autoClose: PropTypes.bool,
  style: PropTypes.object,
};

Dropdown.defaultProps = {
  label: "",
  icon: "",
  trigger: null,
  className: "",
  autoClose: false,
  style: {},
};

export default Dropdown;
