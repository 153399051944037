import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as promptMessageActions from "../../actions/prompt-message.actions";

import PromptMessage from "./PromptMessage";

const PromptMessageContainer = (props) => (
  <PromptMessage
    active={props.activated}
    message={props.message}
    title={props.title}
    buttonContent={props.buttonContent}
    confirmationHandler={props.confirmationHandler}
    cancelingHandler={props.actions.deactivatePromptMessage}
    disabledButton={props.disabledButton}
  />
);

PromptMessageContainer.propTypes = {
  activated: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
  confirmationHandler: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  buttonContent: PropTypes.string.isRequired,
  disabledButton: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { promptMessage } = state;

  return {
    activated: promptMessage.activated,
    confirmationHandler: promptMessage.confirmationHandler,
    title: promptMessage.title,
    message: promptMessage.message,
    buttonContent: promptMessage.buttonContent,
    disabledButton: promptMessage.disabledButton,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...promptMessageActions,
    },
    dispatch
  ),
});

const container = connect(
  mapStateToProps,
  mapDispatchToProps
)(PromptMessageContainer);

export default withRouter(container);
