import React, { Component } from "react";
import PropTypes from "prop-types";
import Text from "./fields/Text";
import Textarea from "./fields/Textarea";
import Radio from "./fields/Radio";
import Checkbox from "./fields/Checkbox";
import Select from "./fields/Select";
import Divider from "./fields/Divider";
import Number from "./fields/Number";
import Countries from "./fields/Countries";
import FileUploader from "./fields/FileUploader";
import StarRating from "./fields/StarRating";
import DateField from "./fields/DateField";
import TimeField from "./fields/TimeField";
import Paragraph from "./fields/Paragraph";

/**
 * @class FieldFactory
 * @description Works as component factory for the defined component in FieldTypes
 */

class FieldFactory extends Component {
  componentFactory() {
    const componentName = this.props.settings.type.name.toUpperCase();
    switch (componentName) {
      case "TEXT":
        return Text;
      case "TEXTAREA":
        return Textarea;
      case "RADIO":
        return Radio;
      case "CHECKBOX":
        return Checkbox;
      case "SELECT":
        return Select;
      case "DIVIDER":
        return Divider;
      case "NUMBER":
        return Number;
      case "COUNTRIES":
        return Countries;
      case "FILEUPLOADER":
        return FileUploader;
      // The check for Linear Scale is only for backward compatibility
      case "STARRATING":
      case "LINEARSCALE":
        return StarRating;
      case "DATEFIELD":
        return DateField;
      case "TIMEFIELD":
        return TimeField;
      case "PARAGRAPH":
        return Paragraph;
      default:
        throw Error(
          "component name is missing or is not a valid component, could not generate component."
        );
    }
  }

  renderFactory() {
    const { settings, onChange, value } = this.props;
    const Field = settings.type.component || this.componentFactory();
    return <Field settings={settings} onChange={onChange} value={value} />;
  }

  render() {
    return <div>{this.renderFactory()}</div>;
  }
}

FieldFactory.propTypes = {
  settings: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
};

FieldFactory.defaultProps = {
  value: "",
};

export default FieldFactory;
