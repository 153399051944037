import React from "react";
import BaseOptions from "./BaseOptions";

/**
 * @class MultibleFieldOptions
 * @extends BaseOptions
 * @description A component which contains a title, required and create choices field options
 */
export default class MultibleFieldOptions extends BaseOptions {
  render() {
    return (
      <div>
        {this.renderTitle()}
        {this.renderRequiredOption()}
        {this.renderVisibilityOption()}
        {this.renderCreateChoices()}
      </div>
    );
  }
}
