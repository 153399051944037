import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    "Manage Program Members": "Manage Program Members",
    "Manage Program Members Roles and Groups":
      "Manage Program Members Roles and Groups",
    Done: "Done",
    invite_users: "INVITE USERS",
    Select: "select..",
    Save: "save",
    Cancel: "cancel",
    group_name: "Group name",
    group_members: "Group members",
    add: "Add",
    all_members: "All Members",
    create_new_group: "new group",
    "delete-group": "Delete Group",
    "edit-group": "Edit Group",
    "score-card-edit-prompt-title": "Are you sure?",
    "editing-score-card-message": "This will delete the group.",
    remove: "Remove",
    remove_member_modal_message_part_1:
      "All submission assign to this member will be unassigned,",
    remove_member_modal_message_part_2: "will be removed from all groups",
    question_mark: "?",
    contest_manager: "Contest Manager",
    program_manager: "Program Manager",
    filterer: "Filterer",
    auditor: "Auditor",
    "Contest Manager": "Contest Manager",
    "Program Manager": "Program Manager",
    Filterer: "Filterer",
    Auditor: "Auditor",
    Judge: "Judge",
    senior_judge: "Senior Judge",

    custom_role: "Custom Role",
    "Custom Role": "Custom Role",
  },
  ar: {
    "Manage Program Members": "ادارة اعضاء البرنامج",
    "Manage Program Members Roles and Groups":
      "تستطيع ادارة الاعضاء الحاليين فقط.",
    Done: "تم",
    invite_users: "اضافة الاعضاء",
    Select: "اختر",
    Save: "حفظ",
    Cancel: "إلغاء",
    group_name: "اسم المجموعة",
    group_members: "أعضاء المجموعة",
    add: "أضف",
    all_members: "جميع الأعضاء",
    create_new_group: "مجموعة جديدة",
    "delete-group": "حذف المجموعة",
    "edit-group": "تعديل المجموعة",
    "score-card-edit-prompt-title": "هل أنت متأكد؟",
    "editing-score-card-message": "عند حفظ التغييرات سيتم حذف المجموعة.",
    remove: "حذف",
    remove_member_modal_message_part_1:
      "جميع المشاركات المعينة لهذا العضو سيتم الغاء تعيينها، سيتم حذف ",
    remove_member_modal_message_part_2: "من جميع المجموعات",
    question_mark: "؟",
    contest_manager: "مدير مسابقة",
    program_manager: "مدير برنامج",
    filterer: "فارز",
    auditor: "مدقق",
    "Contest Manager": "مدير مسابقة",
    "Program Manager": "مدير برنامج",
    Filterer: "فارز",
    Auditor: "مدقق",
    custom_role: "دور خاص",
    "Custom Role": "دور خاص",
    Judge: "محكم",
    senior_judge: "محكم أول",
  },
});

strings.setLanguage(locale || "en");

const translate = (string) => strings[string];

export default translate;
