import React, { useState } from "react";
import { useSelector } from "react-redux";
import Modal from "../Modal/Modal";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import translate from "./texts";

import "./EditFields.scss";

const cookies = new Cookies();

function EditFields({ fetchSubmissions }) {
  const { programId } = useParams();

  const fields = useSelector((state) => state.program.program.programFields);

  const privileges = useSelector((state) => state.privileges.privileges);

  const canEditFields = Object.values(privileges[programId])?.includes(
    "fields.edit"
  );

  const [modalActive, setModalActive] = useState(false);
  const [value, setValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = () => {
    setIsSubmitting(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/programs/${programId}/rename_field`,
        {
          field: {
            id: selectedOption,
            name: value,
          },
        },
        {
          headers: {
            Authorization: cookies.get("Rtoken"),
          },
        }
      )
      .then((res) => {
        fetchSubmissions();
        setIsSubmitting(false);
        setModalActive(false);
        setValue("");
        setSelectedOption("");
      })
      .catch((err) => {
        console.log("err:", err);
        setIsSubmitting(false);
        toast("Something went wrong", { type: "error" });
      });
  };

  if (!canEditFields) {
    return null;
  }

  return (
    <div>
      <button className="edit-field-btn" onClick={() => setModalActive(true)}>
      {translate("Edit Fields")}
      </button>
      <Modal active={modalActive} toggle={() => setModalActive(false)}>
        <div className="bg-white rounded-2xl py-20 px-24">
          <h3 className="text-2xl font-bold mb-4">
            {translate("Edit Fields")}
          </h3>
          <div className="flex flex-col gap-4">
            <div className="control w-full">
              <label className="label">{translate("Select Field")}</label>
              <div className="select w-full">
                <select
                  className="w-full"
                  id={"id"}
                  name={"id"}
                  onChange={(e) => {
                    setSelectedOption(e.target.value);
                  }}
                  value={selectedOption}
                  required={true}
                >
                  <option />
                  {fields.map((field) => (
                    <option key={field.id} value={field.id}>
                      {field.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div>
              <label className="label">{translate("Field Name")}</label>
              <div>
                <input
                  className="border border-gray-400 p-2 rounded-lg w-full"
                  type="text"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
            </div>
          </div>

          <button
            onClick={handleSubmit}
            disabled={!value || !selectedOption || isSubmitting}
            className="edit-field-btn mt-4 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {translate("submit")}
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default EditFields;
