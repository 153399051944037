import React from "react";
import PropTypes from "prop-types";
import ReactPlaceholder from "react-placeholder";
// import "react-placeholder/lib/reactPlaceholder.css";
import { getScoreTitle } from "../../../utils/utils";
import translate from "../texts";

const Score = ({
  submission,
  funnelId,
  rootFunnelId,
  isFetching,
  calcTotalScore,
}) => (
  <div className="submission-score">
    <ReactPlaceholder
      color="#dcdcdc"
      showLoadingAnimation
      type="text"
      rows={1}
      ready={!isFetching}
    >
      {translate(getScoreTitle(funnelId, rootFunnelId))}
      &nbsp; &nbsp;
      <span id="submissionScore">
        {(() => {
          if (funnelId === rootFunnelId) {
            return submission.score;
          }
          if (submission.funnelScores.length > 0) {
            const submissionScore = submission.funnelScores.find(
              (item) => item.funnelId === funnelId
            );

            return submissionScore ? submissionScore.score : 0;
          }
          return 0;
        })()}
      </span>
      &nbsp; {translate("out_of")} &nbsp;
      {calcTotalScore()}
    </ReactPlaceholder>
  </div>
);

Score.propTypes = {
  submission: PropTypes.object.isRequired,
  funnelId: PropTypes.string.isRequired,
  rootFunnelId: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  calcTotalScore: PropTypes.func.isRequired,
};

export default Score;
