import React from "react";
import PropTypes from "prop-types";
import { Icon, Dropdown } from "../../../components";
import translate from "./texts";

const ProgramActions = ({
  archived,
  onUnArchive,
  actions,
  programId,
  isActionAuthorized,
}) => (
  <div className="program-card-actions">
    {!archived ? (
      <Dropdown icon="dots-vertical shadow" autoClose>
        {actions.map(
          (item) => isActionAuthorized(programId, item) && item.component
        )}
      </Dropdown>
    ) : (
      <Dropdown icon="dots-vertical" autoClose>
        <button
          onClick={onUnArchive}
          className="dropdown-item is-text program-action"
          type="button"
        >
          <Icon name="package-up" />
          &nbsp; {translate("unarchive")}
        </button>
      </Dropdown>
    )}
  </div>
);

ProgramActions.propTypes = {
  archived: PropTypes.bool.isRequired,
  onUnArchive: PropTypes.func.isRequired,
  actions: PropTypes.array.isRequired,
  programId: PropTypes.string.isRequired,
  isActionAuthorized: PropTypes.func.isRequired,
};

export default ProgramActions;
