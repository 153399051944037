import React from "react";
import MembersGear from "../../../components/assets/images/manage-members-gear.svg";
import "./MembersManagementHeader.scss";
import translate from "../texts";

const NewGroup = () => (
  <div className="manage-members-header">
    <img
      src={MembersGear}
      alt="Members Gear"
      className="program-members-modal-icon m-auto mb-4"
    />
    <h6 className="program-members-modal-title">
      {translate("Manage Program Members")}
    </h6>
    <p className="program-members-modal-subtitle">
      {translate("Manage Program Members Roles and Groups")}
    </p>
  </div>
);

export default NewGroup;
