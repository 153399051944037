// FIXME: Potential bad use of snake case, switch everything to camel case
import React from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import BaseField from "./BaseField";

/**
 * @class DateField
 * @extends BaseField
 * @description Simple Date field component
 */
export default class DateField extends BaseField {
  state = {
    date: this.props.settings.value
      ? new Date(this.props.settings.value)
      : null,
    value: "",
  };

  handleChange = (date) => {
    this.setState({ date }, () => {
      date = dayjs(date).isValid() ? dayjs(date).format("YYYY-MM-DD") : "";
      this.onChange({ target: { value: date } });
    });
  };

  /**
   * @override
   */
  render() {
    const { validation } = this.props.settings;
    const startDate = validation.startDate
      ? new Date(validation.startDate)
      : null;
    const endDate = validation.endDate ? new Date(validation.endDate) : null;

    return (
      <div className="field">
        {this.renderLabel()}
        <div className="control">
          <DatePicker
            className="date-field"
            onChange={this.handleChange}
            dateFormat="yyyy-MM-dd"
            selected={this.state.date}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            isClearable
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={endDate}
            popperPlacement="top-end"
            popperClassName="custom-date-picker"
          />
        </div>
        {this.renderErrors()}
      </div>
    );
  }
}
