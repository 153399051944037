import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    Download: "Download",
    Previous: "Previous",
    Next: "Next",
    Close: "Close",
    "Error downloading file": "Error downloading file",
  },
  ar: {
    Download: "تنزيل",
    Previous: "السابقة",
    Next: "التالية",
    Close: "إغلاق",
    "Error downloading file": "خطأ في تنزيل الملف",
  },
});

strings.setLanguage(locale || "en");

const translate = (string) => strings[string] || string;

export default translate;
