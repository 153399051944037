export const RESET_LABELS = "RESET_LABELS";
export const FETCH_LABELS = "FETCH_LABELS";
export const FETCH_LABELS_PENDING = "FETCH_LABELS_PENDING";
export const FETCH_LABELS_FULFILLED = "FETCH_LABELS_FULFILLED";
export const FETCH_LABELS_REJECTED = "FETCH_LABELS_REJECTED";

export const DELETE_LABEL_FROM_PROGRAM = "DELETE_LABEL_FROM_PROGRAM";
export const DELETE_LABEL_FROM_PROGRAM_PENDING =
  "DELETE_LABEL_FROM_PROGRAM_PENDING";
export const DELETE_LABEL_FROM_PROGRAM_FULFILLED =
  "DELETE_LABEL_FROM_PROGRAM_FULFILLED";
export const DELETE_LABEL_FROM_PROGRAM_REJECTED =
  "DELETE_LABEL_FROM_PROGRAM_REJECTED";
