import React from "react";
import PropTypes from "prop-types";
import { spinner } from "./Spinner.service";
import "./SpinnerComponent.scss";

class SpinnerComponent extends React.Component {
  constructor(props) {
    super(props);

    spinner.register(this);
  }

  state = {
    show: false,
  };

  show = (show) => {
    this.setState({ show });
  };

  render() {
    const { show } = this.state;
    const { small } = this.props;
    let spinnerItem;

    if (small) {
      spinnerItem = (
        <p
          className="spinner-small"
          style={{ display: show ? "block" : "none" }}
        >
          <svg
            width="16px"
            height="16px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="path"
              fill="none"
              strokeWidth="6"
              strokeLinecap="round"
              cx="33"
              cy="33"
              r="30"
            />
          </svg>
          <span>Saving...</span>
        </p>
      );
    } else {
      spinnerItem = (
        <div
          className="spinner-container"
          style={{ display: show ? "flex" : "none" }}
        >
          <div className="spinner">
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </div>
        </div>
      );
    }
    return spinnerItem;
  }
}

SpinnerComponent.propTypes = {
  small: PropTypes.bool,
};

SpinnerComponent.defaultProps = {
  small: false,
};

export default SpinnerComponent;
