import React from "react";
import PropTypes from "prop-types";
import "./Comment.scss";
import { IconButton } from "../../components";
import { scroller } from "react-scroll";
import {
  deleteComment,
  fetchSubmissionActivities,
} from "../../actions/submissions.actions";
import * as promptMessageActions from "../../actions/prompt-message.actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import translate from "../../layouts/SingleSubmission/texts";

const Comment = ({
  username,
  timestamp,
  children,
  className,
  id,
  submissionId,
  actions,
  programId,
  privileges,
}) => {
  const deleteHandler = () => {
    actions.deleteComment(submissionId, id).then(
      ({
        value: {
          data: { success },
        },
      }) => {
        if (success) {
          actions.fetchSubmissionActivities(submissionId).then(() => {
            scroller.scrollTo("ActivitiesDiv", {
              duration: 800,
              delay: 30,
              smooth: true,
              containerId: "SubmissionActivities",
            });
          });
        }
      }
    );
    actions.deactivatePromptMessage();
  };

  const deleteConfirmationPrompt = () => {
    actions.activatePromptMessage({
      title: translate("delete_message"),
      message: "",
      buttonContent: "delete",
      confirmationHandler: deleteHandler,
      disabledButton: false,
    });
  };

  return (
    <div className={`comment-outer-wrapper ${className}`}>
      <div className="flex">
        <div className="comment">
          <div className="comment-body">
            <div className="comment-text break-all ">{children}</div>
          </div>
        </div>
        {privileges[programId].includes("funnels.update") ? (
          <IconButton
            iconName="delete"
            onClick={deleteConfirmationPrompt}
            className="delete-button p-0 my-0 mx-2"
          />
        ) : null}
      </div>
      <p className="comment-footer">
        <span>{username}</span>
        <span> - </span>
        <span>{timestamp}</span>
      </p>
    </div>
  );
};

Comment.propTypes = {
  username: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  // children: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  submissionId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  programId: PropTypes.string.isRequired,
  privileges: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const privilegesState = state.privileges;
  const programState = state.program;

  return {
    privileges: privilegesState.privileges,
    programId: programState.program.id,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      deleteComment,
      fetchSubmissionActivities,
      ...promptMessageActions,
    },
    dispatch
  ),
});

const container = connect(mapStateToProps, mapDispatchToProps)(Comment);
export default container;
