import * as types from "../types/prompt-message.types";

// TODO: fix cryptic parameter.
export function activatePromptMessage(data) {
  return {
    type: types.ACTIVATE_PROMPT_MESSAGE,
    payload: data,
  };
}

export function deactivatePromptMessage() {
  return {
    type: types.DEACTIVATE_PROMPT_MESSAGE,
  };
}
