import React from "react";
import PropTypes from "prop-types";
import "./Modal.scss";

const Modal = ({ active, toggle, children, transparent, className }) => {
  return (
    <div className={`modal z-[9999] ${active ? "is-active" : ""} ${className}`}>
      {active && (
        <>
          <div
            className={
              transparent ? "modal-background transparent" : "modal-background"
            }
            onClick={() => toggle()}
            onKeyUp={() => toggle()}
            role="presentation"
          />
          <div className="modal-content">{children}</div>
        </>
      )}
    </div>
  );
};

Modal.propTypes = {
  active: PropTypes.bool,
  transparent: PropTypes.bool,
  toggle: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

Modal.defaultProps = {
  active: false,
  transparent: false,
  toggle: () => {},
  className: "",
  children: [],
};

export default Modal;
