import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    label: "Label",
    "Select Label": "Select Label",
  },
  ar: {
    label: "وسم",
    "Select Label": "اختر وسمًا",
  },
});

strings.setLanguage(locale || "en");

const translate = (string) => strings[string] || string;

export default translate;
