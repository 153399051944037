import * as types from "../types/notifications.types";

const initialState = {
  isFetching: false,
  fetched: false,
  error: {},
  notifications: [],
  errors: {},
  fetchHandler: () => {},
  paginationMetadata: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.MARK_READ_FULFILLED: {
      const { notifications, paginationMetadata } = state;
      const notificationIndex = notifications
        .map((notification) => notification.id)
        .indexOf(action.payload.data.userNotification.id);

      notifications[notificationIndex].status =
        action.payload.data.userNotification.status;

      return {
        ...state,
        notifications: [...notifications],
        paginationMetadata: {
          ...paginationMetadata,
          // TODO: That is a hacky solution for now, We should find a better way to
          // re-fetch paginationMetadata after this action to avoid displaying
          // the "show more" button without any new notification to show.
          // See more: AGON-564.
          totalCount: paginationMetadata.totalCount - 1,
          filteredItemCount: paginationMetadata.totalCount - 1,
        },
      };
    }
    case types.FETCH_NOTIFICATIONS_PENDING: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case types.FETCH_NOTIFICATIONS_REJECTED:
      return {
        ...state,
        isFetching: false,
        fetched: false,
        error: { ...action.payload },
      };
    case types.FETCH_NOTIFICATIONS_FULFILLED: {
      const { notifications, Metadata } = action.payload.data;

      return {
        ...state,
        isFetching: false,
        fetched: true,
        error: {},
        notifications: [...state.notifications, ...notifications],
        paginationMetadata: Metadata,
      };
    }
    case types.RESET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: [],
      };
    }
    default:
      return state;
  }
}
