import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");

const strings = new LocalizedStrings({
  en: {
    Logout: "Logout",
    "My Account": "My Account",
  },
  ar: {
    Logout: "تسجيل خروج",
    "My Account": "حسابي",
  },
});

strings.setLanguage(locale || "en");

const translate = (string) => strings[string];

export default translate;
