import React from "react";
import { lightAdjuster } from "../../utils/utils";
import PropTypes from "prop-types";
import "./UserView.scss";

const UserView = ({ user }) => {
  const username = user?.name ? user.name : user?.email;
  // if user doesn't have a color or the color of white we will set the color to #bed1e3
  
  const isMOC = process.env.REACT_APP_THEME === "moc" ? true : false;

  const userColor =
    !user?.color || user?.color === "#ffffff"
      ? isMOC
        ? "#EB5A3C"
        : "#268aff"
      : user?.color;

  if (!user) return null;

  return (
    <div className="user-view">
      <span
        className="user-avatar"
        style={{
          background: lightAdjuster(userColor),
          color: lightAdjuster(userColor, 40),
        }}
      >
        {user && username[0]}
      </span>
      <h5 className="capitalize">{username && username}</h5>
    </div>
  );
};
UserView.propTypes = {
  user: PropTypes.object,
};
export default UserView;
