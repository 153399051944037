import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { MembersButton } from "../../components";
import { toggleProgramMembersModal } from "../../actions/program-members-modal.actions";
import { fetchProgramMembers } from "../../actions/members-management.actions";
import "./ProgramMembersButton.scss";

const ProgramMembersButton = ({ dispatch, programId }) => (
  <MembersButton
    onClick={() => {
      dispatch(toggleProgramMembersModal());
      dispatch(fetchProgramMembers(programId));
    }}
  />
);

ProgramMembersButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
};

export default connect()(ProgramMembersButton);
