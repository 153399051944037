import React from "react";
import PropTypes from "prop-types";
import { Icon } from "../../components";
import { Link } from "react-router-dom";
import "./Tab.scss";

const Tab = ({ children, centered, className }) => (
  <div className={`tabs${centered ? " is-centered" : ""} ${className}`}>
    <ul>{children}</ul>
  </div>
);

const Item = ({ title, link, onClick, icon, className, button }) => (
  <li className={className}>
    {button ? (
      <button onClick={onClick} type="button">
        {icon && <Icon name={icon} />}
        <span>{title}</span>
      </button>
    ) : (
      <Link to={link}>
        <div onClick={onClick} onKeyUp={onClick} role="presentation">
          {title}{" "}
        </div>
      </Link>
    )}
  </li>
);

Tab.propTypes = {
  children: PropTypes.any,
  centered: PropTypes.bool,
  className: PropTypes.string,
};

Tab.defaultProps = {
  children: [],
  centered: true,
  className: "",
};

Item.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  link: PropTypes.string,
  icon: PropTypes.string,
  button: PropTypes.bool,
  onClick: PropTypes.func,
};

Item.defaultProps = {
  className: "",
  link: "",
  icon: "",
  button: false,
  onClick: () => {},
};

Tab.Item = Item;

export default Tab;
