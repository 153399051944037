import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Permit from "../../../layouts/Authorization";
import { Button, NormalSearch } from "../../../components";
import Uploader from "../../../components/Uploader/Uploader";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import { toast } from "react-toastify";
import SavedFilters from "../../SavedFilters/SavedFilters";
import FunnelAccess from "../../FunnelAccess/FunnelAccess";
import translate from "../texts";
import CSVicon from "../../../components/assets/images/csv.png";
import XLSXicon from "../../../components/assets/images/xlsx.png";

import uuid from "uid";

import "./SubmissionTableHeader.scss";

let fileName = "";

function getUniqFileName(name) {
  const fileExtension = name.split(".").pop();
  return `${uuid(32)}${new Date().getTime()}.${fileExtension}`;
}

class SubmissionHeader extends PureComponent {
  uppy = "";

  state = {
    importActive: false,
    exportActive: false,
    showActions: false,
    showFunnelShare: false,
    exportLater: false,
    emailForExportLater: "",
  };

  triggerFileUpload = (fileInput) => {
    fileInput.click();
  };

  importFromCSV = () => {
    this.toggleImportModal();
    this.uppy = Uppy({
      debug: true,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: [
          "text/csv",
          "application/csv",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ],
      },
      autoProceed: true,
    });
    this.uppy.use(AwsS3, {
      id: "AWsS3",
      async getUploadParameters(file) {
        fileName = encodeURI(getUniqFileName(file.name));
        // Send a request to our PHP signing endpoint.
        return await fetch(
          `${process.env.REACT_APP_AWS_S3_PRE_SIGN_URL}${fileName}`,
          {
            method: "get",
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return {
              method: data.method,
              url: data.url,
              fields: {},
            };
          })
          .catch((err) => toast.error(err.message));
      },
    });

    this.uppy.on("complete", (result) => {
      this.props.importComplete(fileName);
      this.toggleImportModal();
    });
  };

  toggleImportModal = () => {
    const { importActive } = this.state;
    this.setState({ importActive: !importActive });
  };

  toggleExportModal = () => {
    const { exportActive } = this.state;
    this.setState({
      emailForExportLater: "",
    });
    this.setState({ exportLater: false });
    this.setState({ exportActive: !exportActive });
  };

  handleOverlayClick = (target) => {
    if (target === "import-modal") this.toggleImportModal();
    if (target === "export-modal") this.toggleExportModal();
  };

  toggleFunnelAccessPreview = () => {
    this.setState((prevState) => ({
      showFunnelShare: !prevState.showFunnelShare,
    }));
  };

  toggleActionPreview = () => {
    this.setState((prevState) => ({ showActions: !prevState.showActions }));
  };

  clearActiveFilters = (selectedfilters) => {
    const { removeFilter, setAdvanceSearchState } = this.props;
    const filterIds = Object.keys(selectedfilters);
    filterIds.forEach((filterid) => removeFilter(filterid));
    setAdvanceSearchState(false);
  };

  getEmailFromLocalStorage = () => {
    const email = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).email
      : "";
    return email;
  };

  render() {
    const {
      programId,
      submissions,
      exportToCSV,
      exportToXLSX,
      doNormalSearch,
      updateQuery,
      submissionsRange,
      isRootFunnel,
      filters,
      deleteFilter,
      isAdvanceSearchActive,
      updateSelectedFilters,
      filtrationFields,
      searchForSubmissions,
      operators,
      setAdvanceSearchState,
      resetFilters,
      updateAdvancedSearchCollapsedState,
      setFunnelPublicAccess,
      funnelId,
      funnelAccess,
      programFields,
    } = this.props;
    const { showActions, showFunnelShare } = this.state;
    const filtersCount = filters.length;
    const defaultFields = [
      { name: translate("ID"), id: "id" },
      { name: translate("Status"), id: "status" },
      { name: translate("Labels"), id: "labels" },
      { name: translate("CreatedAt"), id: "created_at" },
      { name: translate("Score"), id: "score" },
    ];

    return (
      <div className="submission-header">
        <div className="../../actions flex">
          <div className="search-container flex ">
            <NormalSearch
              doSearch={doNormalSearch}
              updateQuery={updateQuery}
              style={{ width: isAdvanceSearchActive ? "317px" : "" }}
              placeholder={translate("search")}
              autocomplete
              suggestions={[...programFields, ...defaultFields]}
            />
            {isAdvanceSearchActive && (
              <Button
                small
                iconName="close"
                content={translate("clear")}
                className="clear"
                onClick={() => resetFilters()}
              />
            )}
            <Button
              tiny
              flatWhite
              onClick={updateAdvancedSearchCollapsedState}
              iconName="chevron-down"
              className="chevron-down"
              color="#5b718d"
            />
          </div>
          <Button
            tiny
            flatWhite
            content={
              <>
                <span>{`${translate("filters")}`}</span>
                <span className="filters-count ml-2">{filtersCount}</span>
              </>
            }
            onClick={this.toggleActionPreview}
            iconName="filter"
            color="#5b718d"
            className="filters-btn"
            disabled={filtersCount === 0}
          />
          {/* share funnel button & TODO: enable later */}
          {/* {!isRootFunnel && (
            <Permit targetPrivilege="funnels.index" targetId={programId}>
              <Button
                tiny
                flat
                content={<span>{`${translate("share")}`}</span>}
                onClick={this.toggleFunnelAccessPreview}
                iconName="link"
                color="#5b718d"
                className="share-btn"
              />
            </Permit>
          )} 
          {!isRootFunnel && funnelAccess.enablePublic && (
            <p className="flex h-6 items-center m-auto ms-4 px-3 rounded-lg uppercase font-medium bg-warning-yellow text-warning-brown whitespace-no-wrap">
              {translate("public_funnel")}
            </p>
          )}*/}
        </div>
        <div className="advanced-options">
          <div className="submissions-range">{submissionsRange}</div>
          {submissions.length > 0 && (
            <Permit
              targetPrivilege="programs.export_to_csv"
              targetId={programId}
            >
              <Button
                small
                flatWhite
                iconName="file-excel"
                content={translate("export")}
                onClick={this.toggleExportModal}
              />
              {this.state.exportActive && (
                <div
                  id="export-modal"
                  role="presentation"
                  onClick={(e) => this.handleOverlayClick(e.target.id)}
                  className="export-modal"
                >
                  <div className="export-container">
                    <p className="export-text">
                      {translate("Choose the type of export")}
                    </p>

                    <div className="EXPORT_LATER mb-3">
                      <div className="export-checkbox flex gap-2">
                        <input
                          type="checkbox"
                          id="export-checkbox"
                          onChange={(e) => {
                            this.setState({ exportLater: e.target.checked });
                          }}
                        />
                        <label htmlFor="export-checkbox">
                          {translate("Export to email")}
                        </label>
                      </div>
                      {this.state.exportLater && (
                        <div className="mt-1">
                          <input
                            className="email-input border border-slate-200 py-1 px-2 w-full invalid:bg-red-100 invalid:border-red-500 invalid:text-red-950"
                            type="email"
                            required
                            // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}"
                            placeholder={translate("Enter email")}
                            onChange={(e) => {
                              this.setState({
                                emailForExportLater: e.target.value,
                              });
                            }}
                            defaultValue={this.getEmailFromLocalStorage()}
                          />

                          <p className="text-xs">
                            {translate("email_warning")}
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="">
                      <p className="font-bold text-center">
                        {translate("Razeen field names")}
                      </p>
                      <div className="flex mt-2">
                        <button
                          className="export-icon"
                          type="button"
                          onClick={() =>
                            exportToCSV({
                              exportLater: this.state.exportLater,
                              email: this.state.emailForExportLater,
                            })
                          }
                        >
                          <img src={CSVicon} alt="" />
                        </button>
                        <button
                          className="export-icon"
                          type="button"
                          onClick={() =>
                            exportToXLSX({
                              exportLater: this.state.exportLater,
                              email: this.state.emailForExportLater,
                            })
                          }
                        >
                          <img src={XLSXicon} alt="" />
                        </button>
                      </div>
                    </div>
                    <div className=" mt-8">
                      <p className="font-bold text-center">
                        {translate("Original field names")}
                      </p>
                      <div className="flex mt-2">
                        <button
                          className="export-icon"
                          type="button"
                          onClick={() => {
                            exportToCSV({
                              exportLater: this.state.exportLater,
                              email: this.state.emailForExportLater,
                              original: true,
                            });
                          }}
                        >
                          <img src={CSVicon} alt="" />
                        </button>
                        <button
                          className="export-icon"
                          type="button"
                          onClick={() => {
                            exportToXLSX({
                              exportLater: this.state.exportLater,
                              email: this.state.emailForExportLater,
                              original: true,
                            });
                          }}
                        >
                          <img src={XLSXicon} alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Permit>
          )}
          {isRootFunnel && (
            <Permit
              targetPrivilege="programs.import_from_csv"
              targetId={programId}
            >
              <Button
                small
                flatWhite
                iconName="file-import"
                content={translate("import")}
                onClick={() => this.importFromCSV()}
              />
              {this.state.importActive && (
                <div
                  id="import-modal"
                  role="presentation"
                  onClick={(e) => this.handleOverlayClick(e.target.id)}
                  className="import-modal"
                >
                  <Uploader uppy={this.uppy} />
                </div>
              )}
            </Permit>
          )}
        </div>
        {showActions && (
          <SavedFilters
            toggleActionPreview={this.toggleActionPreview}
            filters={filters}
            deleteFilter={deleteFilter}
            programId={programId}
            updateSelectedFilters={updateSelectedFilters}
            filtrationFields={filtrationFields}
            searchForSubmissions={searchForSubmissions}
            operators={operators}
            setAdvanceSearchState={setAdvanceSearchState}
            clearActiveFilters={this.clearActiveFilters}
          />
        )}
        {/* share funnel button & TODO: enable later */}
        {/* {showFunnelShare && (
          <FunnelAccess
            toggleFunnelAccessPreview={this.toggleFunnelAccessPreview}
            setFunnelPublicAccess={setFunnelPublicAccess}
            funnelId={funnelId}
            funnelAccess={funnelAccess}
          />
        )} */}
      </div>
    );
  }
}

SubmissionHeader.propTypes = {
  updateAdvancedSearchCollapsedState: PropTypes.func.isRequired,
  submissions: PropTypes.array.isRequired,
  exportToCSV: PropTypes.func.isRequired,
  exportToXLSX: PropTypes.func.isRequired,
  importComplete: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
  doNormalSearch: PropTypes.func.isRequired,
  updateQuery: PropTypes.func.isRequired,
  submissionsRange: PropTypes.string.isRequired,
  isRootFunnel: PropTypes.bool.isRequired,
  removeFilter: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  deleteFilter: PropTypes.func.isRequired,
  setAdvanceSearchState: PropTypes.func.isRequired,
  isAdvanceSearchActive: PropTypes.bool.isRequired,
  updateSelectedFilters: PropTypes.func.isRequired,
  filtrationFields: PropTypes.array.isRequired,
  searchForSubmissions: PropTypes.func.isRequired,
  operators: PropTypes.object.isRequired,
  resetFilters: PropTypes.func.isRequired,
  setFunnelPublicAccess: PropTypes.func.isRequired,
  funnelAccess: PropTypes.object.isRequired,
  funnelId: PropTypes.string.isRequired,
  programFields: PropTypes.array.isRequired,
};

export default SubmissionHeader;
