import React from "react";
import PropTypes from "prop-types";
import "./Header.scss";

function renderItems(children) {
  return children.map((child, index) => (
    <div key={`header-item-${index}`}>{child}</div>
  )); // eslint-disable-line react/no-array-index-key
}

const Header = ({ children, transparent }) => {
  children = Array.isArray(children) ? children : [children];

  return (
    <div className={`header ${transparent ? "transparent" : ""}`}>
      {children}
    </div>
  );
};

const Center = ({ children }) => {
  children = Array.isArray(children) ? children : [children];

  return <div className="header-center">{renderItems(children)}</div>;
};

const Right = ({ children }) => {
  children = Array.isArray(children) ? children : [children];

  return <div className="header-right">{renderItems(children)}</div>;
};

const Left = ({ children }) => {
  children = Array.isArray(children) ? children : [children];

  return <div className="header-left">{renderItems(children)}</div>;
};

Header.propTypes = {
  children: PropTypes.any,
  transparent: PropTypes.bool,
};

Header.defaultProps = {
  children: [],
  transparent: false,
};

Right.propTypes = {
  children: PropTypes.any,
};

Right.defaultProps = {
  children: [],
};

Left.propTypes = {
  children: PropTypes.any,
};

Left.defaultProps = {
  children: [],
};

Center.propTypes = {
  children: PropTypes.any,
};

Center.defaultProps = {
  children: [],
};

Header.Right = Right;
Header.Left = Left;
Header.Center = Center;

export default Header;
