const formatOperation = (operation) => operation.substr(1).toUpperCase();

/*
  Returns a MermaidJS graph
  @returns {string}
  @param {Object[]} funnels An array of funnel objects in the same format that's
                    that's returned by `FunnelsController#index`.

  @param {string} baseFunnelsLink The link to a program (Without a trailing slash),
                  used to add funnel links to each node in the graph. Defaults
                  to an empty string.
                  e.g. https://razeen.io/#/programs/5be44abea7d10e0004cb5bb4/funnels

  @param {string} direction A MermaidJS direction, either `LR` (left to right)
                  or `RL` (right to left). Defaults to `LR`.
*/
const buildGraphString = (funnels, inputSources, direction = "LR") => {
  const maxFunnels = 90;
  let graph = `graph ${direction}\n`;

  funnels.forEach((funnel) => {
    // Handle new added funnels before input sources are fetched
    if (!inputSources[funnel.id]) {
      return;
    }

    const showOperation = inputSources[funnel.id].length > 1;
    const operation = funnel.operationBetweenInputSources;

    inputSources[funnel.id].forEach((inputSource, index) => {
      const { sourceFunnel } = inputSource;

      const sourceFunnelName =
        funnels.length > maxFunnels
          ? sourceFunnel.name.substring(1, 30)
          : sourceFunnel.name;
      const funnelName =
        funnels.length > maxFunnels
          ? funnel.name.substring(1, 30)
          : funnel.name;

      const sourceNode = `${sourceFunnel.id}`;
      const sourceNodeWithName = `${sourceNode}("${sourceFunnelName}")`;
      const destinationNode = `${funnel.id}`;
      const destinationNodeWithName = `${destinationNode}("${funnelName}")`;
      const isLastInputSource = index === inputSources[funnel.id].length - 1;
      const isPublic =
        funnel.funnelAccess && funnel.funnelAccess.enablePublic === true;

      if (showOperation) {
        const op = formatOperation(operation);
        const opNodeId = `Funnel-${funnel.id}`;
        const opNode = `${opNodeId}((${op}))`;

        graph += `${sourceNodeWithName} --> ${opNode};\n`;

        if (isLastInputSource) {
          graph += `${opNode} --> ${destinationNodeWithName};\n`;

          if (isPublic) {
            graph += `class ${destinationNode} public_funnel;\n`;
          }
          graph += `class ${opNodeId} operator\n`;
          graph += `class ${opNodeId} operator-${op.toLowerCase()}\n`;

          graph += `click ${destinationNode} onMermaidNodeClick\n`;
        }
      } else {
        graph += `${sourceNodeWithName} --> ${destinationNodeWithName};\n`;

        if (isPublic) {
          graph += `class ${destinationNode} public_funnel;\n`;
        }
        graph += `click ${sourceNode} onMermaidNodeClick\n`;
        graph += `click ${destinationNode} onMermaidNodeClick\n`;
      }
    });
  });

  return graph;
};

export default buildGraphString;
