import React from "react";
import PropTypes from "prop-types";
import { lightAdjuster } from "../../../utils/utils";
import ProgramActions from "./ProgramActions";
import translate from "./texts";

import "./ProgramCard.scss";

const ProgramCard = ({
  programName,
  programId,
  actions,
  submissionsCount,
  onClick,
  logo,
  backgroundColor,
  archived,
  onUnArchive,
  isActionAuthorized,
}) => (
  <div
    className="program-card has-text-centered"
    onClick={onClick}
    onKeyUp={(e) => {
      if (e.which === 13) onClick();
    }}
    role="button"
    tabIndex={0}
  >
    <div
      className="program-card-header"
      style={{
        backgroundImage: logo
          ? `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .01)), url("${logo}")`
          : "",
        backgroundColor: !logo ? lightAdjuster(backgroundColor, 70) : "",
      }}
    >
      {actions.some((item) => isActionAuthorized(programId, item)) && (
        <ProgramActions
          actions={actions}
          archived={archived}
          onUnArchive={onUnArchive}
          programId={programId}
          isActionAuthorized={isActionAuthorized}
        />
      )}
    </div>

    <div className="program-card-body">
      <h2 className="program-card-title sub-heading ellipses">{programName}</h2>
      <span className="ellipses submissions">
        {submissionsCount}
        {submissionsCount === 1
          ? translate("submission")
          : translate("submissions")}
      </span>
    </div>
  </div>
);

ProgramCard.propTypes = {
  programName: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
  submissionsCount: PropTypes.number,
  onClick: PropTypes.func,
  logo: PropTypes.string,
  backgroundColor: PropTypes.string,
  archived: PropTypes.bool.isRequired,
  onUnArchive: PropTypes.func.isRequired,
  actions: PropTypes.array.isRequired,
  isActionAuthorized: PropTypes.func.isRequired,
};

ProgramCard.defaultProps = {
  submissionsCount: 0,
  onClick: () => {},
  logo: null,
  backgroundColor: null,
};

export default ProgramCard;
