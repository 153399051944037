import React, { Component, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { Dropdown, Radio, Button, Icon } from "../../components";
import { availableLocales, direction } from "../../utils/utils";
import UserView from "../../layouts/UserView/UserView";
import "./UserInfoAndPreferences.scss";
import translate from "./texts";
import { Helmet } from "react-helmet";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { useAuthContext } from "../../hooks/useAuthContext";

const UserInfoAndPreferences = () => {
  const cookies = new Cookies();
  const Localuser = cookies?.get("user");
  const [user, setUser] = useState(Localuser ? Localuser : null);
  const locale = cookies.get("locale");

  const [signoutLoading, setsignoutLoading] = useState(false);
  const [localeCode, setlocaleCode] = useState(locale || "en");
  const { dispatch } = useAuthContext();

  useEffect(() => {
    if (locale) setlocaleCode(locale);
  }, [locale]);

  const switchLocale = (localeCode) => {
    setlocaleCode({ localeCode });
    cookies.set("locale", localeCode, { path: "/" });
    window.location.reload();
  };

  const signout = () => {
    // setsignoutLoading({ signoutLoading: true });
    cookies.remove("Rtoken");
    cookies.remove("refreshToken");
    dispatch({ type: "SIGN_OUT" });
    window.location.href = `${process.env.REACT_APP_API_URL}/sign_out/?user_id=${Localuser?.id}`;
  };

  const trigger = (
    <div className="username">
      {user && user !== null && <UserView user={user} />}
      <Icon name="chevron-down" />
    </div>
  );
  return (
    <div className="user-info">
      <Helmet
        htmlAttributes={{
          lang: localeCode,
          dir: localeCode === "ar" ? "rtl" : "ltr",
        }}
      />
      <Dropdown
        trigger={trigger}
        className={direction() === "rtl" ? "rtl" : ""}
      >
        {availableLocales.map((locale) => (
          <Radio
            wide
            key={locale.code}
            name={locale.code}
            value={locale.language}
            checked={locale.code === localeCode}
            onChange={() => switchLocale(locale.code)}
          />
        ))}

        <Button
          onClick={signout}
          content={translate("Logout")}
          loading={signoutLoading}
          flat
          small
        />
      </Dropdown>
    </div>
  );
};

export default UserInfoAndPreferences;

// import React, { Component } from "react";
// import Cookies from "universal-cookie";
// import { Dropdown, Radio, Button, Icon } from "../../components";
// import { availableLocales, direction } from "../../utils/utils";
// import UserView from "../../layouts/UserView/UserView";
// import "./UserInfoAndPreferences.scss";
// import translate from "./texts";

// const user = {
//   id: "6356948c73158400010cb64c",
//   user_id: "63569547d4961b000148f55e",
//   name: "mohamed",
//   email: "q65@msn.com",
//   verified_email: false,
//   color: "#1c1906",
// };

// class UserInfoAndPreferences extends Component {
//   constructor(props) {
//     super(props);
//     this.cookies = new Cookies();
//     //     this.locale = this.cookies.get("locale");
//     this.locale = JSON.parse(localStorage.getItem("locale"));
//   }

//   state = {
//     signoutLoading: false,
//     localeCode: this.locale || "en",
//   };

//   switchLocale = (localeCode) => {
//     this.setState({ localeCode });
//     this.cookies.set("locale", localeCode, { path: "/" });
//     window.location.reload();
//   };

//   signout = () => {
//     this.setState({ signoutLoading: true });
//     window.location.href = `${process.env.REACT_APP_API_URL}/sign_out`;
//   };

//   render() {
//     const trigger = (
//       <div className="username">
//         <UserView user={user} />
//         <Icon name="chevron-down" />
//       </div>
//     );

//     return (
//       <div className="user-info">
//         <Dropdown
//           trigger={trigger}
//           className={direction() === "rtl" ? "rtl" : ""}
//         >
//           {availableLocales.map((locale) => (
//             <Radio
//               wide
//               key={locale.code}
//               name={locale.code}
//               value={locale.language}
//               checked={locale.code === this.state.localeCode}
//               onChange={() => this.switchLocale(locale.code)}
//             />
//           ))}
//           <Button
//             onClick={this.signout}
//             content={translate("Logout")}
//             loading={this.state.signoutLoading}
//             flat
//             small
//           />
//         </Dropdown>
//       </div>
//     );
//   }
// }

// export default UserInfoAndPreferences;
