import React from "react";
import PropTypes from "prop-types";
import "./Chip.scss";

/*
use this component as follows:
1- by default it have a light-grey bg and black text color.
2- you can give it "notifier" property and it'll change the bg color to green and the text
color to white.
3- you can give add the icons and rest of nested components as children inside the Chip tag.
*/
const Chip = ({ notifier, content, children }) => (
  <span className={`chipp tag ${notifier ? "is-notifier" : ""}`}>
    {content}
    {children}
  </span>
);

Chip.propTypes = {
  notifier: PropTypes.bool,
  content: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Chip.defaultProps = {
  content: "",
  notifier: false,
  children: <div />,
};

export default Chip;
