import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "../../../components";
import { withRouter } from "react-router-dom";
import translate from "../texts";

import "./Scores.scss";

class Scores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
    this.collapse = this.collapse.bind(this);
  }

  goToSubmission = () => {
    const {
      history,
      match: { params },
      funnel: { id: funnelId },
    } = this.props;
    history.push(
      `/dashboard/programs/${params.programId}/funnels/${funnelId}/submissions/page/1/${params.submissionId}/`
    );
  };

  renderEvaluations = () => {
    const isArabic = /[\u0600-\u06FF]/;
    return this.props.evaluations.map((item, index) => (
      <div
        className="item-row"
        style={{ direction: isArabic.test(item.questionTitle) ? "rtl" : "ltr" }}
        key={`score-${index + 1}`}
      >
        <p className="question-title">{item.questionTitle}</p>
        <p className="answer-value">
          {item.value}
          {item.reason && `(Reason: ${item.reason})`}
        </p>
        {}
      </div>
    ));
  };

  collapse = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  render() {
    const {
      index,
      score,
      funnel: { name, id },
      questionsCount,
      evaluations,
      match: { params },
      showLinkToSubmission,
    } = this.props;

    const { expanded } = this.state;

    return (
      <div
        className={`score-list-item ${expanded ? "active" : ""}`}
        key={index}
      >
        <div className="item-header">
          <div
            className="funnel-score-container"
            onClick={this.collapse}
            onKeyUp={(e) => {
              if (e.which === 13) this.collapse();
            }}
            role="button"
            tabIndex={-1}
          >
            <div className="group-flex">
              <p className="funnel-name">{name}</p>
              <div className="item-detail-group">
                <div className="item-detail">
                  <p className="item-detail-tag">score</p>
                  <p className="score-value">{score.score}</p>
                </div>
                <div className="item-detail">
                  <p className="item-detail-tag">|</p>
                </div>
                <div className="item-detail">
                  <p className="item-detail-tag">status</p>
                  <p className="score-value uppercase">
                    {questionsCount === evaluations.length
                      ? translate("reviewed")
                      : translate("inProgress")}
                  </p>
                </div>
              </div>
            </div>
            <Icon
              name={`${expanded ? "menu-up" : "menu-down"}`}
              className="arrow-icon"
            />
          </div>
        </div>

        <div className="item-body">
          {this.renderEvaluations()}

          {id !== params.funnelId && showLinkToSubmission !== false && (
            <div
              className="goto"
              onClick={() => this.goToSubmission()}
              onKeyUp={(e) => {
                if (e.which === 13) this.collapse();
              }}
              role="button"
              tabIndex={0}
            >
              <p className="uppercase">{translate("go_to_submission")}</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Scores.propTypes = {
  evaluations: PropTypes.array.isRequired,
  funnel: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  score: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  questionsCount: PropTypes.number.isRequired,
  showLinkToSubmission: PropTypes.bool,
};

export default withRouter(Scores);
