import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import EditGroup from "./EditGroup";
import GroupMenuCollapsible from "./GroupMenuCollapsible";
import "./GroupManagement.scss";
import translate from "../texts";

const ALL_MEMBERS_GROUP_ID = "all_members";

class GroupManagement extends PureComponent {
  render() {
    const {
      groups,
      members,
      roles,
      groupsExpandStatus,
      groupToEditId,
      deleteMember,
    } = this.props;

    return (
      <div className="groups">
        {GroupMenuCollapsible(
          ALL_MEMBERS_GROUP_ID,
          translate(ALL_MEMBERS_GROUP_ID),
          members,
          groupsExpandStatus[ALL_MEMBERS_GROUP_ID],
          this.props.onGroupCollapseClick,
          roles,
          this.props.updateMemberRole,
          "",
          "",
          true,
          deleteMember
        )}

        {groups.map((group) => {
          if (group.id === groupToEditId) {
            return (
              <EditGroup
                key={group.id}
                groupName={group.name}
                groupMembers={group.members}
                programMembers={members}
                saveGroupEdits={this.props.saveGroupEdits}
                cancelGroupEdit={this.props.cancelGroupEdit}
              />
            );
          }
          return GroupMenuCollapsible(
            group.id,
            group.name,
            group.members,
            groupsExpandStatus[group.id],
            this.props.onGroupCollapseClick,
            roles,
            this.props.updateMemberRole,
            this.props.deleteGroup,
            this.props.editGroup
          );
        })}
      </div>
    );
  }
}

GroupManagement.propTypes = {
  groups: PropTypes.array.isRequired,
  members: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  groupsExpandStatus: PropTypes.object.isRequired,
  groupToEditId: PropTypes.string.isRequired,

  saveGroupEdits: PropTypes.func.isRequired,
  cancelGroupEdit: PropTypes.func.isRequired,
  onGroupCollapseClick: PropTypes.func.isRequired,
  updateMemberRole: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  editGroup: PropTypes.func.isRequired,
  deleteMember: PropTypes.func,
};

GroupManagement.defaultProps = {
  deleteMember: () => {},
};

export default GroupManagement;
