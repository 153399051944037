import { createContext, useReducer, useEffect } from "react";
import Cookies from "universal-cookie";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        user: action.payload,
      };
    case "LOGOUT":
      return {
        user: null,
      };
    default:
      return state;
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
  });
  const cookies = new Cookies();

  useEffect(() => {
    const user = cookies.get("Rtoken");
    console.log(window.location.href.includes("/forms/"), "forms???");
    if (user) {
      dispatch({ type: "LOGIN", payload: user });
    } else {
      if (window.location.href.includes("/forms/")) {
        console.log("forms");
      } else {
      }
    }
  }, []);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
