import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Button } from "../../components";
import { debounce } from "lodash";
import uuid from "uid";
import "./SavedFilters.scss";
import translate from "./texts";

class SavedFilters extends PureComponent {
  throttledFetchSubmissions = debounce(this.props.searchForSubmissions, 400, {
    leading: false,
  });

  wrapperRef = React.createRef();

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  updateSelectedFiltersState = (filters) => {
    const { updateSelectedFilters } = this.props;

    this.setState(updateSelectedFilters(filters));
  };

  getOperatorLabel = (type, operator) => {
    const { operators } = this.props;

    const fieldOperators = (operators[type] || operators.default).operators;
    return fieldOperators.find((op) => op.value === operator).label;
  };

  expandSavedFilter = (filters) => {
    const { setAdvanceSearchState, toggleActionPreview } = this.props;

    const expandedFilters = filters.reduce((acc, cur) => {
      const newFilterId = uuid();

      // eslint-disable-next-line no-unused-vars
      const [fieldId, operator, value, category, type] = cur;
      const [field] = this.props.filtrationFields.filter(
        (f) => f.id === fieldId
      );
      const newCurrent = {
        id: newFilterId,
        field,
        value,
        operator,
        operatorLabel: this.getOperatorLabel(type, operator),
      };

      return { ...acc, [newFilterId]: { ...newCurrent } };
    }, {});

    const nextFilters = { ...expandedFilters };
    setAdvanceSearchState(true);
    toggleActionPreview();
    this.updateSelectedFiltersState(nextFilters);
    this.throttledFetchSubmissions();
  };

  handleClickOutside = (event) => {
    const targetClasses = event.target.parentNode.className.split(" ");
    if (
      this.wrapperRef &&
      !this.wrapperRef.current.contains(event.target) &&
      !targetClasses.includes("filters-btn")
    ) {
      this.props.toggleActionPreview();
    }
  };

  render() {
    const { toggleActionPreview, filters, deleteFilter, programId } =
      this.props;

    return (
      <div className="actions-container" ref={this.wrapperRef}>
        <div className="actions-header">
          <h1 className="capitalize font-bold">{`${translate("filters")}`}</h1>
          <Button
            iconName="close"
            tiny
            flat
            className="close-icon text-base"
            onClick={toggleActionPreview}
          />
        </div>

        <div className="actions-list pb-0">
          <div>
            {filters.map((filter) => (
              <div
                className="flex mb-3 pb-3 last:mb-0 border-b border-gray-200"
                key={filter.id}
              >
                <div className="justify-center flex flex-col me-auto">
                  <span>{filter.name}</span>
                </div>
                <div className="buttons">
                  <Button
                    content={translate("search")}
                    tiny
                    className="grey"
                    onClick={() => this.expandSavedFilter(filter.groups)}
                  />
                  <Button
                    content={translate("delete")}
                    tiny
                    className="grey"
                    onClick={() => deleteFilter(programId, filter.id)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="actions-footer">
          <Button
            id="add-button"
            onClick={toggleActionPreview}
            iconName="plus-circle"
            content={translate("add-more")}
            small
            wide
          />
        </div>
      </div>
    );
  }
}

SavedFilters.propTypes = {
  toggleActionPreview: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  deleteFilter: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
  updateSelectedFilters: PropTypes.func.isRequired,
  filtrationFields: PropTypes.array.isRequired,
  searchForSubmissions: PropTypes.func.isRequired,
  operators: PropTypes.object.isRequired,
  setAdvanceSearchState: PropTypes.func.isRequired,
};

export default SavedFilters;
