import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    share_funnel: "Share Funnel",
    description: "Anyone with this link can access this funnel",
    enable_public_link: "Enable public link",
    copy_link: "Copy Link",
    copied_successfully: "Copied Successfully",
  },
  ar: {
    share_funnel: "شارك المرحلة",
    description: "يمكن لأي شخص لديه هذا الرابط الوصول إلى هذه المرحلة",
    enable_public_link: "تمكين الرابط الخارجي",
    copy_link: "نسخ الرابط",
    copied_successfully: "تم النسخ بنجاح",
  },
});

strings.setLanguage(locale || "en");

const translate = (string) => strings[string];

export default translate;
