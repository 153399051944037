import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Cookies from "universal-cookie";

import "./style.scss";

import TamLogo from "./images/tam-logo-sq.png";
import t from "./texts";

function AuthLayout(props) {
  const cookies = new Cookies();

  const locale = cookies.get("locale");

  const [localeCode, setlocaleCode] = useState(locale || "en");

  const switchLocale = (localeCode) => {
    setlocaleCode({ localeCode });
    cookies.set("locale", localeCode, { path: "/" });
    window.location.reload();
  };

  useEffect(() => {
    if (locale) setlocaleCode(locale);
  }, [locale]);

  return (
    <div className="body">
      <nav>
        <i className="razeen" />
        {localeCode === "en" ? (
          <button className="lang-switcher" onClick={() => switchLocale("ar")}>
            عربي
          </button>
        ) : (
          <button className="lang-switcher" onClick={() => switchLocale("en")}>
            English
          </button>
        )}
      </nav>
      <div className="txt">
        <h2>{t("collect_data")}.</h2>
        <h2>{t("make_better_decisions")}.</h2>
        <p>{t("description")}.</p>
      </div>
      <div className="box">{props.children}</div>
      <div className="footer-sign">
        <div>
          <img src={TamLogo} alt="tam-logo" className="tam-logo" />
        </div>
      </div>
    </div>
  );
}

export default AuthLayout;
