import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * @class OptionsFactory
 * @description To generate the options component on the fly
 * @extends Component
 */
class OptionsFactory extends Component {
  factory = () => {
    if (this.props.options && this.props.options.component) {
      const OptionComponent = this.props.options.component;
      return <OptionComponent {...this.props} />;
    }
    return null;
  };

  render() {
    return <div>{this.factory()}</div>;
  }
}

OptionsFactory.propTypes = {
  options: PropTypes.object,
  onChange: PropTypes.func,
  errors: PropTypes.object,
};

OptionsFactory.defaultProps = {
  options: {},
  onChange: () => {},
  errors: {},
};

export default OptionsFactory;
