import React from "react";
import BaseField from "./BaseField";

/**
 * @class Radio
 * @extends BaseField
 * @description Radio field component
 */
export default class Radio extends BaseField {
  renderRadios(id) {
    return this.props.settings.choices.map((op, i) => {
      const uKey = `${id}_${i}`;
      return (
        <label className="radio side-padding block" key={uKey} htmlFor={uKey}>
          <input
            type="radio"
            name={id}
            id={uKey}
            value={op}
            checked={this.state.value === op}
            onChange={this.onChange}
            required={this.Settings.validation.required}
          />
          <span>{op}</span>
        </label>
      );
    });
  }

  /**
   * @override
   */
  render() {
    const { id } = this.Settings;
    return (
      <div className="field">
        {this.renderLabel()}
        <div className="control">{this.renderRadios(id)}</div>
        {this.renderErrors()}
      </div>
    );
  }
}
