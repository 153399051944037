import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import FilterField from "../FilterField/FilterField";
import { Select } from "../../../../components";

class InputSourceFilters extends PureComponent {
  render() {
    const {
      fields,
      operators,
      questions,
      submissionStatuses,
      onFilterChange,
      removeFilter,
      selectedFilters,
      onSameInputSourceOperationChange,
    } = this.props;
    const fieldsWithoutQuestions = fields.filter(
      (field) =>
        field.category !== "questions" ||
        !Object.prototype.hasOwnProperty.call(field, "category")
    );
    const validQuestions = fields
      .filter((field) => field.category === "questions")
      .filter((question) =>
        Object.prototype.hasOwnProperty.call(question, "funnelId")
      );
    const validFields = fieldsWithoutQuestions.concat(validQuestions);

    const operatorOptions = [
      { value: "$or", label: "or" },
      { value: "$and", label: "and" },
    ];

    return (
      <div>
        {Object.values(selectedFilters)
          .filter((filter) => filter.field !== undefined)
          .map((filter) => (
            <FilterField
              filter={filter}
              onFilterChange={onFilterChange}
              removeFilter={removeFilter}
              fetchSubmissions={() => {}}
              fields={validFields}
              operatorsMap={operators}
              submissionStatuses={submissionStatuses}
              questions={questions}
              key={filter.id}
            />
          ))}

        <div className="label-with-line mt-10">
          <div className="operator-dropdown">
            <Select
              style={{
                boxShadow: "none",
                outline: "none",
                background: "transparent",
              }}
              options={operatorOptions}
              value={this.props.operationOnSameInputSource || "$and"}
              clearable={false}
              onChange={(e) => {
                onSameInputSourceOperationChange(e.value);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

InputSourceFilters.propTypes = {
  fields: PropTypes.array.isRequired,
  operators: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  submissionStatuses: PropTypes.array.isRequired,
  removeFilter: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired,
};

export default InputSourceFilters;
