// FIXME: Potential bad use of snake case, switch everything to camel case

import TextLength from "../../utils/settings/text-length";
import FieldTypes from "../../components/FormRenderer/FieldTypes";
import translate from "./texts";
import NormalFieldOptions from "./FieldOptions/NormalFieldOptions";
import EmailFieldOptions from "./FieldOptions/EmailFieldOptions";
import MultibleFieldOptions from "./FieldOptions/MultibleFieldOptions";
import TitleFieldOption from "./FieldOptions/TitleFieldOption";
import FileFieldOptions from "./FieldOptions/FileFieldOptions";
import StarRatingFieldOptions from "./FieldOptions/StarRatingFieldOptions";
import NumberFieldOptions from "./FieldOptions/NumberFieldOptions";
import CountryFieldOptions from "./FieldOptions/CountryOptions";
import DateFieldOpions from "./FieldOptions/DateFieldOpions";
import FileTypes from "./FieldOptions/fileTypes";
import ParagraphFieldOptions from "./FieldOptions/ParagraphFieldOptions";

/* eslint-disable no-useless-escape */
export default {
  F1: {
    name: translate("textBox"),
    iconName: "text-short",
    options: {
      component: NormalFieldOptions,
      title: "",
      required: false,
      visibilityOptions: [],
      visibility: true,
    },
    schema: {
      type: FieldTypes.Text,
      title: "",
      id: "",
      validation: {
        maxlength: TextLength.LONG,
      },
      ref: "F1",
    },
  },
  F2: {
    name: translate("textBoxMultiline"),
    iconName: "text",
    options: {
      component: NormalFieldOptions,
      title: "",
      required: false,
      visibilityOptions: [],
      visibility: true,
    },
    schema: {
      type: FieldTypes.Textarea,
      title: "",
      id: "",
      validation: {
        maxlength: TextLength.VERY_LONG,
      },
      ref: "F2",
    },
  },
  F3: {
    name: translate("radioButton"),
    iconName: "checkbox-marked-circle-outline",
    options: {
      component: MultibleFieldOptions,
      title: "",
      required: false,
      choices: [],
      visibilityOptions: [],
      visibility: true,
    },
    schema: {
      type: FieldTypes.Radio,
      title: "",
      id: "",
      validation: {},
      choices: [],
      ref: "F3",
    },
  },
  F4: {
    name: translate("checkBox"),
    iconName: "checkbox-marked-outline",
    options: {
      component: MultibleFieldOptions,
      title: "",
      required: false,
      choices: [],
      visibilityOptions: [],
      visibility: true,
    },
    schema: {
      type: FieldTypes.Checkbox,
      title: "",
      id: "",
      validation: {},
      choices: [],
      ref: "F4",
    },
  },
  F5: {
    name: translate("select"),
    iconName: "menu",
    options: {
      component: MultibleFieldOptions,
      title: "",
      required: false,
      choices: [],
      visibilityOptions: [],
      visibility: true,
    },
    schema: {
      type: FieldTypes.Select,
      title: "",
      id: "",
      validation: {},
      choices: [],
      ref: "F5",
    },
  },
  F6: {
    name: translate("fileUploader"),
    iconName: "upload",
    options: {
      component: FileFieldOptions,
      title: "",
      required: false,
      maxFileSize: "1000",
      fileTypes: FileTypes.images.split(",").concat(FileTypes.docs.split(",")),
      visibilityOptions: [],
      visibility: true,
    },
    schema: {
      type: FieldTypes.FileUploader,
      validation: {
        maxFileSize: "1000",
        fileTypes: [],
        message: translate("_props").en["Please upload file"],
      },
      title: "",
      id: "",
      ref: "F6",
    },
  },
  F7: {
    name: translate("country"),
    iconName: "city",
    options: {
      component: CountryFieldOptions,
      title: "",
      required: false,
      withCity: false,
      cityTitle: "",
      visibilityOptions: [],
      visibility: true,
    },
    schema: {
      type: FieldTypes.Countries,
      title: "",
      id: "",
      countriesUrl: `${process.env.REACT_APP_API_URL}/countries`,
      citiesUrl: `${process.env.REACT_APP_API_URL}/countries/:country_code/cities`,
      withCity: false,
      cityTitle: "",
      validation: {},
      ref: "F7",
    },
  },
  F8: {
    name: translate("nameField"),
    iconName: "account-box-outline",
    options: {
      component: NormalFieldOptions,
      title: "",
      required: false,
      visibilityOptions: [],
      visibility: true,
    },
    schema: {
      type: FieldTypes.Text,
      title: "",
      id: "",
      validation: {
        minlength: 2,
        maxlength: TextLength.SHORT,
        regex: "^(?!.*[-!$%^&#*@()_+|~=`{}\\[\\]:\";'<>?,.\\/0-9٠-٩])",
        message:
          translate("_props").en[
            "The value should not contain numbers and special characters"
          ],
      },
      ref: "F8",
    },
  },
  F9: {
    name: translate("phone"),
    iconName: "cellphone",
    options: {
      component: NormalFieldOptions,
      title: "",
      required: false,
      visibilityOptions: [],
      visibility: true,
    },
    schema: {
      type: FieldTypes.Text,
      title: "",
      id: "",
      validation: {
        maxlength: TextLength.VERY_SHORT,
        regex: "^([0-9()/+ -]*)$",
        message:
          translate("_props").en["The value should be a valid phone number"],
      },
      ref: "F9",
    },
  },
  F10: {
    name: translate("email"),
    iconName: "email-outline",
    options: {
      component: EmailFieldOptions,
      title: "",
      required: false,
      primary_email: false,
      visibilityOptions: [],
      visibility: true,
    },
    schema: {
      type: FieldTypes.Text,
      title: "",
      id: "",
      primary_email: false,
      validation: {
        maxlength: TextLength.MEDIUM,
        // (^$) expression will prevent implementing email validation if it's empty
        regex: "(^$)|(^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,15}$)",
        message:
          translate("_props").en["The value should be a valid email address"],
      },
      ref: "F10",
    },
  },
  F11: {
    name: translate("address"),
    iconName: "map-marker",
    options: {
      component: NormalFieldOptions,
      title: "",
      required: false,
      visibilityOptions: [],
      visibility: true,
    },
    schema: {
      type: FieldTypes.Textarea,
      title: "",
      id: "",
      validation: {},
      ref: "F11",
    },
  },
  F12: {
    name: translate("Divider"),
    iconName: "border-horizontal",
    options: {
      component: TitleFieldOption,
      title: "",
      visibilityOptions: [],
      visibility: true,
    },
    schema: {
      type: FieldTypes.Divider,
      title: "",
      ref: "F12",
    },
  },
  F13: {
    name: translate("dateField"),
    iconName: "calendar",
    options: {
      component: DateFieldOpions,
      title: "",
      required: false,
      startDate: null,
      endDate: null,
      visibilityOptions: [],
      visibility: true,
    },
    schema: {
      type: FieldTypes.DateField,
      title: "",
      id: "",
      validation: {},
      ref: "F13",
    },
  },
  F14: {
    name: translate("starRating"),
    iconName: "star",
    options: {
      component: StarRatingFieldOptions,
      title: "",
      required: false,
      size: 2,
      start_label: "",
      end_label: "",
      visibilityOptions: [],
      visibility: true,
    },
    schema: {
      type: FieldTypes.StarRating,
      title: "",
      id: "",
      size: 2,
      start_label: "",
      end_label: "",
      validation: {
        required: false,
      },
      ref: "F14",
    },
  },
  F15: {
    name: translate("timeField"),
    iconName: "clock-outline",
    options: {
      component: NormalFieldOptions,
      title: "",
      required: false,
      visibilityOptions: [],
      visibility: true,
    },
    schema: {
      type: FieldTypes.TimeField,
      title: "",
      id: "",
      validation: {},
      ref: "F15",
    },
  },
  F16: {
    name: translate("number"),
    iconName: "numeric",
    options: {
      component: NumberFieldOptions,
      title: "",
      required: false,
      min: "1",
      max: "15",
      visibilityOptions: [],
      visibility: true,
    },
    schema: {
      type: FieldTypes.Number,
      title: "",
      id: "",
      validation: {},
      ref: "F16",
    },
  },
  F17: {
    name: translate("paragraph"),
    iconName: "format-paragraph",
    options: {
      component: ParagraphFieldOptions,
      title: "",
      visibilityOptions: [],
      visibility: true,
    },
    schema: {
      type: FieldTypes.Paragraph,
      title: "",
      id: "",
      validation: {},
      ref: "F17",
    },
  },
};
