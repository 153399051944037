const imageExtensions = ["jpg", "jpeg", "png", "gif"];
const videoExtensions = ["mp4", "mkv", "mov", "wmv", "avi"];
const pdfExtensions = ["pdf"];

function fileExtension(file) {
  if (Array.isArray(file)) return null;
  return file ? file.split(".").pop().toLowerCase() : null;
}

export function isPdf(file) {
  return pdfExtensions.indexOf(fileExtension(file)) > -1;
}

export function isImage(file) {
  if (!file) {
    return false;
  }
  return imageExtensions.indexOf(fileExtension(file)) > -1;
}

export function isVideo(file) {
  if (!file) {
    return false;
  }
  return videoExtensions.indexOf(fileExtension(file)) > -1;
}

export function isUnknownFile(file) {
  return !isPdf(file) && !isImage(file);
}
