import React, { Component } from "react";
import PropTypes from "prop-types";
import Label from "./utils/Label";

/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */

/**
 * @class BaseField
 * @description Every field from from builder should extend this class
 */
class BaseField extends Component {
  defaultValues = {
    value: "",
    required: false,
    placeholder: "",
    validation: Object.create(null),
    errors: [],
  };

  constructor(props) {
    super(props);
    this.Settings = { ...this.defaultValues, ...this.props.settings };
  }

  state = { value: this.props.settings.value || "" };

  getMinMaxLengthValidation() {
    let min = "";
    let max = "";

    const { minLength, maxLength } = this.Settings.validation;
    !isNaN(minLength) && (min = minLength);
    !isNaN(maxLength) && (max = maxLength);
    return {
      pattern: `.{${min},${max}}`,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.Settings = { ...this.defaultValues, ...nextProps.settings };
  }

  componentDidMount() {
    this.setState({ value: this.props.value || "" });
  }

  onChange = (e) => {
    this.setState({ value: e.target.value });
    this.props.onChange(this.Settings, e.target.value);
  };

  setRefValue(val) {
    this.props.settings.value = val;
  }

  /**
   * @override
   */
  setState(newState, cb) {
    super.setState(newState, () => {
      this.setRefValue(newState.value);
      cb && cb();
    });
  }

  renderLabel(label = "", pre = "", post = "") {
    return (
      <Label
        text={`${pre} ${label || this.props.settings.title} ${post}`}
        required={this.props.settings.validation.required}
      />
    );
  }

  renderErrors = () => (
    <div className="validation-error">
      {this.props.settings.errors.map((error) => (
        <div key={error.fieldId}>
          {error.fieldName} {error.message}
        </div>
      ))}
    </div>
  );

  render() {
    return <div />;
  }
}

BaseField.propTypes = {
  settings: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
};

BaseField.defaultProps = {
  value: "",
};

export default BaseField;
