import axios from "axios";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import { destroyToken } from "./auth-utils";

const cookies = new Cookies();

axios.interceptors.request.use((config) => {
  config.headers.locale = cookies.get("locale") || "en";

  if (cookies.get("Rtoken")) {
    config.headers.Authorization = cookies.get("Rtoken");
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    if (response.config.method !== "get" && response.data.message) {
      toast(response.data.message, {
        toastId: "success",
        autoClose: 3000,
      });
    }
    return response;
  },
  (error) => {
    const status = error.response ? error.response.status : null;
    const Rtoken = cookies.get("Rtoken");

    if (status === 401) {
      if (
        error.response?.config?.url ===
        `${process.env.REACT_APP_serverUrl}/users/sign_in`
      ) {
        return Promise.reject(error);
      }
      destroyToken();
      window.location.href = `${process.env.REACT_APP_serverUrl}`;
    }

    if (status === 403) {
      toast(error.response?.data?.message || "Not Allowed!", {
        toastId: "error",
        autoClose: 3000,
      });
    }

    if (status >= 400 && status !== 401 && status !== 403 && status < 600) {
      toast(
        error.response?.data?.message ||
          error.response?.data?.errors?.[0] ||
          "An unexpected error occurred!",
        {
          toastId: "error",
          autoClose: 3000,
        }
      );
    }

    return Promise.reject(error);
  }
);
