import React from "react";
import PropTypes from "prop-types";
import PDFObject from "pdfobject";
import { Icon } from "../../../components";
import { isImage, isPdf, isVideo } from "../../../utils/file-extensions";
import "./FileViewer.scss";
import translate from "./texts";
import { toast } from "react-toastify";

class FileViewer extends React.Component {
  state = {
    maxWidth: "calc(100vw - 80px)",
    maxHeight: "unset",
    transform: "rotate(0deg)",
    imageRotation: 0,
  };

  attachmentRef = React.createRef();

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.src !== this.props.src || nextState !== this.state;
  }

  rotate = () => {
    this.setState((prevState) => {
      const rotation = prevState.imageRotation + 1;
      const degrees = 90 * rotation;
      const maxWidth =
        degrees % 180 === 0 ? "calc(100vw - 80px)" : "calc(100vh - 224px)";
      const maxHeight =
        degrees % 180 === 0 ? "calc(100vh - 224px)" : "calc(100vw - 80px)";
      return {
        maxWidth,
        maxHeight,
        transform: `rotate(${degrees}deg)`,
        imageRotation: rotation,
      };
    });
  };

  downloadFile = (src) => {
    const fileName = src.split("/").pop();

    const fileExtension = fileName.split(".").pop();

    fetch(src, {
      headers: {
        "Cache-Control": "no-cache",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style = "display: none";
        a.href = url;
        a.download = `${fileName}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      })
      .catch((error) => {
        console.log(error);
        toast.error(translate("Error downloading file"));
      });
  };

  render() {
    const { src, isModalActive, close } = this.props;
    const { maxHeight, maxWidth, transform } = this.state;

    return (
      <div className={`file-viewer ${isModalActive ? "is-active" : ""}`}>
        <div className="navbar">
          <div className="navbar-start">
            <div className="navbar-item">
              <button
                className="button is-clear"
                onClick={() => this.downloadFile(src)}
                type="button"
              >
                <span>
                  <Icon name="download" />
                </span>
                <span>{translate("Download")}</span>
              </button>
            </div>
          </div>
          <div className="navbar-end">
            <div className="navbar-item">
              {isImage(src) && (
                <button
                  className="button is-clear"
                  onClick={this.rotate}
                  type="button"
                >
                  <span>
                    <Icon name="rotate-right" />
                  </span>
                  <span>{translate("Rotate")}</span>
                </button>
              )}
              <button className="button is-clear" onClick={close} type="button">
                <span>
                  <Icon name="close" />
                </span>
                <span>{translate("Close")}</span>
              </button>
            </div>
          </div>
        </div>

        <div
          className={`file-viewer-content ${
            isPdf(src) ? "is-pdf-content" : ""
          } ${isVideo(src) ? "is-video-content" : ""}`}
        >
          <div ref={this.attachmentRef} />
          <div>
            {isImage(src) && (
              <img
                src={src}
                alt=""
                style={{
                  width: "auto",
                  maxWidth,
                  maxHeight,
                  transform,
                }}
              />
            )}

            {isPdf(src) &&
              setTimeout(() => {
                PDFObject.embed(src, this.attachmentRef.current);
              }, 1)}
            {isVideo(src) && (
              <video controls autoPlay>
                <source src={src} />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </div>
      </div>
    );
  }
}

FileViewer.propTypes = {
  src: PropTypes.string.isRequired,
  isModalActive: PropTypes.bool,
  close: PropTypes.func.isRequired,
};

FileViewer.defaultProps = {
  isModalActive: false,
};

export default FileViewer;
