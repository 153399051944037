import React from "react";
import AuthLayout from "./AuthLayout";

import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";

import t from "./texts";
import { useForm } from "react-hook-form";
import { ForgotPasswordAction } from "../../actions/auth.actions";

function ForgotPasswordPage(props) {
  const cookies = new Cookies();

  const history = useHistory();
  const dispatch = useDispatch();

  const user = cookies.get("user");
  const token = cookies.get("Rtoken");

  if (user && token) {
    history.push("/dashboard");
  }

  const form = useForm({
    defaultValues: {
      email: "",
    },
  });

  const { register, handleSubmit, formState, watch } = form;

  const submitSuccess = (data, e) => {
    dispatch(ForgotPasswordAction(data));
  };

  const submitError = (errors, e) => {
    console.log("submit error:", errors);
  };

  const forgotPasswordSuccess = useSelector(
    (state) => state.privileges.forgotPasswordSuccess
  );
  const forgotPasswordError = useSelector(
    (state) => state.privileges.forgotPasswordError
  );
  return (
    <AuthLayout>
      <div>
        <h2>{t("devise.shared_links.forgot_your_password")}</h2>
        <form onSubmit={handleSubmit(submitSuccess, submitError)}>
          <div className="field">
            <input
              type="email"
              placeholder={t("devise.registrations.email")}
              autoFocus={true}
              {...register("email", {
                required: {
                  value: true,
                  message: t("errors.messages.email_required"),
                },
                maxLength: 128,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: t("errors.messages.email_validation"),
                },
              })}
            />
          </div>
          {formState.errors.email && (
            <p className="text-xs text-red-600 mt-1">
              {formState.errors.email.message}
            </p>
          )}

          <div className="actions">
            <button type="submit">
              {t("devise.passwords.send_instructions_button")}
            </button>
          </div>

          {forgotPasswordSuccess && (
            <p className="text-green-600 mt-1">
              {t("devise.passwords.send_instructions")}
            </p>
          )}

          {forgotPasswordError && (
            <p className="text-red-600 mt-1">an error happened! </p>
          )}
        </form>
      </div>
    </AuthLayout>
  );
}

export default ForgotPasswordPage;
