import React from "react";
import PropTypes from "prop-types";

/**
 * Divider
 * @description separator between fields
 */
const Divider = ({ settings }) => (
  <div className="divider">{settings.title}</div>
);

Divider.propTypes = {
  settings: PropTypes.object,
};

Divider.defaultProps = {
  settings: {
    title: "",
  },
};

export default Divider;
