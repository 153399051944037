import React from "react";
import { Route as RouteDOM, Switch, useLocation } from "react-router-dom";
import { SpinnerComponent } from "./components";
import Toast from "./layouts/Toast";
import PromptMessageContainer from "./layouts/PromptMessage";
import AlertMessageContainer from "./layouts/AlertMessage/AlertMessageContainer";
import * as Sentry from "@sentry/react";
import Dashboard from "./layouts/Dashboard";
import FormPage from "./layouts/FormBuilder/FormPage";
import Home from "./layouts/Home";
import { AuthProvider } from "./context/AuthContext";
import SignIn from "./layouts/Auth/SignIn";
import SignUp from "./layouts/Auth/SignUp";
import ForgotPasswordPage from "./layouts/Auth/ForgotPassword";
const Route = Sentry.withSentryRouting(RouteDOM);

import "./utils/axiosConfig";

const App = () => {
  const location = useLocation();

  const isMOC = process.env.REACT_APP_THEME === "moc" ? true : false;

  return (
    <div>
      <SpinnerComponent />
      <PromptMessageContainer />
      <AlertMessageContainer />
      <Toast />

      <Switch>
        <Route path="/signin" component={SignIn} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <AuthProvider>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/" exact component={Home} />
        </AuthProvider>
      </Switch>
      <Switch>
        <Route exact path="/forms/:formId" component={FormPage} />
      </Switch>

      {isMOC ? (
        <img
          src="/moc_pattern.png"
          alt="pattern"
          className=" fixed left-0 bottom-0 xl:translate-y-2 patternMoc  transform  w-screen z-10"
        />
      ) : null}
    </div>
  );
};

export default App;
