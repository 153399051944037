import React from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.min.css";
import BaseField from "./BaseField";

/**
 * @class TimeField
 * @extends BaseField
 * @description Simple time field component
 */
const timeObject = (time) => {
  const anyDateStr = new Date().toDateString();
  return new Date(anyDateStr.concat(` ${time}`));
};

export default class TimeField extends BaseField {
  state = {
    time: this.props.settings.value
      ? timeObject(this.props.settings.value)
      : null,
    value: "",
  };

  handleChange = (time) => {
    this.setState({ time }, () => {
      time = dayjs(time).isValid() ? dayjs(time).toDate() : "";
      this.onChange({
        target: { value: time ? dayjs(time).format("hh:mm A") : "" },
      });
    });
  };

  /**
   * @override
   */
  render() {
    return (
      <div className="field">
        {this.renderLabel()}
        <div className="control">
          <DatePicker
            className="date-field"
            onChange={this.handleChange}
            selected={this.state.time}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat="h:mm a"
            timeCaption="Time"
            isClearable
          />
        </div>
      </div>
    );
  }
}
