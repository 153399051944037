import { uniqueId } from "lodash";
import React from "react";

function FormInput({ label = "", className = "", ...rest }, ref) {
  const hasLabel = label.length > 0 ? uniqueId() : undefined;

  return (
    <div className={`input-component control ${className}`}>
      {hasLabel ? <label htmlFor={hasLabel}>{label}</label> : null}

      <input id={hasLabel} className="input" {...rest} ref={ref} />
    </div>
  );
}

export default React.forwardRef(FormInput);
