import React from "react";
import PropTypes from "prop-types";
import "./Box.scss";

const Box = ({ children, className, dark, comfortable, cozy, style }) => (
  <div
    className={`boxy ${className} ${comfortable ? "comfortable" : ""} ${
      cozy ? "cozy" : ""
    } ${dark ? "dark" : ""}`}
    style={{ ...style }}
  >
    {children}
  </div>
);

Box.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  className: PropTypes.string,
  comfortable: PropTypes.bool,
  cozy: PropTypes.bool,
  dark: PropTypes.bool,
  style: PropTypes.object,
};

Box.defaultProps = {
  children: [],
  className: "",
  comfortable: false,
  cozy: false,
  dark: false,
  style: {},
};

export default Box;
