import React, { useEffect } from "react";
import mermaid from "mermaid";
import PropTypes from "prop-types";

function Mermaid({ config, diagram }) {
  useEffect(() => {
    mermaid.initialize(config);
    mermaid.contentLoaded();
  }, [config]);

  return (
    <div className="mermaid" name="funnel-visualization">
      {diagram}
    </div>
  );
}

Mermaid.propTypes = {
  diagram: PropTypes.string.isRequired,
  config: PropTypes.object,
};

Mermaid.defaultProps = {
  config: {},
};

export default Mermaid;
