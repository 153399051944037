import React from "react";
import { useSelector } from "react-redux";

export default function useFindPrivileges(privilege, programId) {
  const privileges = useSelector((state) => state.privileges.privileges);

  const hasPrivilege = (privilege) =>
    privileges[programId]?.includes(privilege);

  if (!programId || !privilege) {
    return false;
  }

  if (Array.isArray(privilege)) {
    return privilege.every((priv) => hasPrivilege(priv));
  } else {
    return !!hasPrivilege(privilege);
    //double negation to convert to boolean
  }
}
