export class SpinnerService {
  constructor() {
    this.spinner = null;
  }

  register(spinner) {
    this.spinner = spinner;
  }

  show() {
    this.spinner.show(true);
  }

  hide() {
    this.spinner.show(false);
  }

  isShowing() {
    return this.spinner.show;
  }
}

const spinner = new SpinnerService();
export { spinner };
