import React from "react";
import PropTypes from "prop-types";
import { Button, Tab } from "../../../components";
import Permit from "../../../layouts/Authorization";
import SubmissionActivities from "./SubmissionActivities";
import SubmissionComments from "./SubmissionComments";
import Scores from "./Scores";
import Questions from "./Questions";
import translate from "../texts";
import useFindPrivileges from "../../../hooks/useFindPrivileges";

const ScoringPane = ({
  submission,
  isAllSubmissionsTabSelected,
  scoringTabActive,
  activityTabActive,
  commentsTabActive,
  questions,
  evaluations,
  submissionActivities,
  pendingComment,
  nextBtnShow,
  openScoringTab,
  openActivityTab,
  openCommentsTab,
  changeQuestionAnswer,
  goToNextSubmission,
  updatePendingComment,
  addComment,
  scoresHistoryTabActive,
  previousScoresTabActive,
  openPreviousScoresTab,
  openScoresHistoryTab,
  funnelScores,
  funnels,
  withReason,
  clearEvaluation,
  funnelId,
  programId,
}) => {
  const hasEvaluations = evaluations.some(
    (evaluation) => evaluation.funnel === funnelId
  );
  const filteredEvaluations = evaluations.filter(
    (evaluation) => evaluation.funnel === funnelId
  );
  const filteredActivities = submissionActivities
    .slice(0)
    .reverse()
    .filter((activity) => activity.activityType !== "Add_comment");

  const seniorJudge = useFindPrivileges(
    "submission.view_all_scores",
    programId
  );
  return (
    <div id="scoring-pane" className="scoring-pane">
      <Tab>
        {!isAllSubmissionsTabSelected && (
          <Tab.Item
            button
            title={translate("Filter and Score")}
            className={scoringTabActive ? "is-active" : ""}
            onClick={openScoringTab}
          />
        )}
        <Tab.Item
          button
          title={translate("Previous Scores")}
          className={previousScoresTabActive ? "is-active" : ""}
          onClick={() => openPreviousScoresTab(submission.id)}
        />
        <Tab.Item
          button
          title={translate("score_overview")}
          className={scoresHistoryTabActive ? "is-active" : ""}
          onClick={() => openScoresHistoryTab(submission.id)}
        />
        <Permit targetPrivilege="funnels.update" targetId={programId}>
          <Tab.Item
            button
            title={translate("Activity")}
            className={activityTabActive ? "is-active" : ""}
            onClick={() => openActivityTab(submission.id)}
          />
        </Permit>
        <Tab.Item
          button
          title={translate("Comments")}
          className={commentsTabActive ? "is-active" : ""}
          onClick={() => openCommentsTab(submission.id)}
        />
      </Tab>
      {scoringTabActive && (
        <div className="scoring-tab">
          <div className="field">
            <Questions
              questions={questions}
              evaluations={filteredEvaluations}
              onChange={changeQuestionAnswer}
              withReason={withReason}
              submissionId={submission.id}
              funnelId={funnelId}
            />
          </div>
          {hasEvaluations && (
            <div className="mb-3 m-auto">
              <Button
                onClick={clearEvaluation}
                content={translate("clear_evaluation")}
                flatWhite
                small
                className="text-brand-gray"
              />
            </div>
          )}
          <div className={`button-wrapper ${!hasEvaluations ? "mt-auto" : ""}`}>
            {nextBtnShow && (
              <Button
                wide
                content={translate("next")}
                onClick={goToNextSubmission}
              />
            )}
          </div>
        </div>
      )}
      {activityTabActive && (
        <div className="activity-tab">
          <SubmissionActivities submissionActivities={filteredActivities} />
        </div>
      )}
      {commentsTabActive && (
        <div className="activity-tab">
          <SubmissionComments
            pendingComment={pendingComment}
            onCommentChange={updatePendingComment}
            onCommentAdd={addComment}
            submissionActivities={submissionActivities}
            submissionId={submission.id}
          />
        </div>
      )}
      {scoresHistoryTabActive && (
        <div className="activity-tab">
          <div id="scoresList">
            {seniorJudge
              ? funnelScores.map((score, index) => (
                  <Scores
                    key={`score-${index + 1}`}
                    evaluations={evaluations.filter(
                      (evaluation) => evaluation.funnel === score.funnelId
                    )}
                    index={index}
                    funnel={{
                      id: score.funnelId,
                      name: submission.funnelNames.find(
                        (funnel) => funnel.id === score.funnelId
                      )?.name,
                    }}
                    submissionId={submission.id}
                    score={score}
                    questionsCount={questions.length}
                    showLinkToSubmission={funnels.some(
                      (funnel) => funnel.id === score.funnelId
                    )}
                  />
                ))
              : funnels.map(
                  (item, index) =>
                    !item.rootFunnel &&
                    funnelScores.find(
                      (scoreItem) => scoreItem.funnelId === item.id
                    ) && (
                      <Scores
                        key={`score-${index + 1}`}
                        evaluations={evaluations.filter(
                          (evaluation) => evaluation.funnel === item.id
                        )}
                        index={index}
                        funnel={item}
                        submissionId={submission.id}
                        score={funnelScores.find(
                          (scoreItem) => scoreItem.funnelId === item.id
                        )}
                        questionsCount={questions.length}
                      />
                    )
                )}
            {}
          </div>
        </div>
      )}

      {previousScoresTabActive && (
        <div className="activity-tab">
          <div id="scoresList">
            {submission.averageScore && (
              <div class="score-list-item">
                <div class="item-header cursor-default">
                  <div class="funnel-score-container">
                    <div class="group-flex">
                      <p class="funnel-name">{translate("Average score")}</p>
                      <div class="">
                        <div class="item-detail">
                          <p class="item-detail-tag">{translate("score")}</p>
                          <p class="score-value">{submission.averageScore}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {submission.averageScore && (
              <div class="score-list-item">
                <div class="item-header cursor-default">
                  <div class="funnel-score-container">
                    <div class="group-flex">
                      <p class="funnel-name">
                        {translate("Max Previous Score")}
                      </p>
                      <div class="">
                        <div class="item-detail">
                          <p class="item-detail-tag">{translate("score")}</p>

                          <p class="score-value">{submission.averageScore}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {submission.previousFunnelScores?.map((item, index) => (
              <div class="score-list-item">
                <div class="item-header cursor-default">
                  <div class="funnel-score-container">
                    <div class="group-flex">
                      <p class="funnel-name">{item.funnelName}</p>
                      <div class="">
                        <div class="item-detail">
                          <p class="item-detail-tag">{translate("score")}</p>
                          <p class="score-value">{item.score}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

ScoringPane.propTypes = {
  submission: PropTypes.object.isRequired,
  isAllSubmissionsTabSelected: PropTypes.bool.isRequired,
  scoringTabActive: PropTypes.bool.isRequired,
  activityTabActive: PropTypes.bool.isRequired,
  commentsTabActive: PropTypes.bool.isRequired,
  questions: PropTypes.array.isRequired,
  evaluations: PropTypes.array.isRequired,
  submissionActivities: PropTypes.array.isRequired,
  pendingComment: PropTypes.string.isRequired,
  nextBtnShow: PropTypes.bool.isRequired,
  openScoringTab: PropTypes.func.isRequired,
  openActivityTab: PropTypes.func.isRequired,
  openCommentsTab: PropTypes.func.isRequired,
  changeQuestionAnswer: PropTypes.func.isRequired,
  goToNextSubmission: PropTypes.func.isRequired,
  updatePendingComment: PropTypes.func.isRequired,
  addComment: PropTypes.func.isRequired,
  scoresHistoryTabActive: PropTypes.bool.isRequired,
  openScoresHistoryTab: PropTypes.func.isRequired,
  funnelScores: PropTypes.array.isRequired,
  funnels: PropTypes.array.isRequired,
  withReason: PropTypes.array.isRequired,
  clearEvaluation: PropTypes.func.isRequired,
  funnelId: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
};

export default ScoringPane;
