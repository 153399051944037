import React from "react";
import PropTypes from "prop-types";

import "./Wallpaper.scss";

const Wallpaper = ({ children, hide }) => (
  <div className={hide ? "" : "wallpaper"}>{children}</div>
);

Wallpaper.propTypes = {
  children: PropTypes.any,
  hide: PropTypes.bool,
};

Wallpaper.defaultProps = {
  children: <div />,
  hide: false,
};

export default Wallpaper;
