import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { spinner } from "../../components";
import axios from "axios";
import SubmissionsContainer from "../../layouts/Submissions";
import * as promptMessageActions from "../../actions/prompt-message.actions";
import * as funnelSettingsActions from "../../actions/funnels.actions";
import { fetchProgram as fetchProgramAction } from "../../actions/program.actions";
import FunnelsTabs from "./FunnelsTabs/FunnelsTabs";
import FunnelsSettingsContainer from "./FunnelsSettings/FunnelsSettingsContainer";
import translate from "./texts";

import "./FunnelsContainer.scss";
import { toast } from "react-toastify";

class FunnelsContainer extends Component {
  submissionsPagePath = "submissions/page";

  state = {
    isSettingsOpen: false,
    isEditing: false,
    isSaved: false,
  };

  UNSAFE_componentWillMount() {
    this.setDefaultFunnel();
  }

  setDefaultFunnel() {
    const { privileges, programId, history, funnels } = this.props;
    // Navigate user to the first funnel
    const firstFunnelId = funnels.length && funnels[0].id;
    if (firstFunnelId && !privileges.includes("funnels.index")) {
      history.push(
        `/dashboard/programs/${programId}/funnels/${firstFunnelId}/${this.submissionsPagePath}/1`
      );
    }
  }

  addNewFunnel = () => {
    const { isEditing, isSettingsOpen } = this.state;

    if (!isSettingsOpen || isEditing) {
      // We don't want to clear the state if you clicked newFunnel again.
      this.props.actions.resetFunnelState();

      // Hack: had to forcefully close settings and open it.
      // The issue is when you toggleFunnelSettings() and then addNewFunnel(), react will
      // not remount <FunnelSettings> because isSettingsOpen is still `true`.  This prevented
      // funnelTitleInput.focus() from working because it's being done in componentDidMount() in
      // <FunnelSettings> and react was seeing that <FunnelSettings> is already mounted.

      this.setState({ isSettingsOpen: false, isEditing: false }, () => {
        this.setState({ isSettingsOpen: true, isEditing: false });
      });
    }
  };

  toggleFunnelSettings = () => {
    if (!this.state.isSettingsOpen) {
      spinner.show();

      const {
        actions: { fetchFunnelSettings },
        match: {
          params: { funnelId },
        },
      } = this.props;
      fetchFunnelSettings(funnelId).then(() => {
        spinner.hide();
        this.setState({
          isSettingsOpen: true,
          isEditing: true,
          isSaved: false,
        });
      });
    } else {
      this.props.actions.resetFunnelState();
      this.setState({ isSettingsOpen: false, isEditing: false });
    }
  };

  saveFunnelSettings = (
    name,
    inputSources,
    questions,
    hiddenColumns,
    fieldsOrder,
    assignedUsers,
    operationBetweenInputSources,
    questionsUpdated,
    showAverageAndPreviousScore,
    excludedSubmissions,
    isFinalFunnel,
    start_date,
    end_date
  ) => {
    const {
      actions: { saveFunnel, createFunnel },
      match: { params },
      funnelSettings: {
        id,
        funnelGroupId,
        superFunnelId,
        inheritAssignedUsers,
        inheritFieldsVisibility,
      },
      programId,
    } = this.props;
    if (id) {
      saveFunnel(
        params.funnelId,
        name,
        inputSources,
        questions,
        hiddenColumns,
        fieldsOrder,
        assignedUsers,
        operationBetweenInputSources,
        questionsUpdated,
        showAverageAndPreviousScore,
        excludedSubmissions,
        isFinalFunnel,
        start_date,
        end_date,
        funnelGroupId,
        superFunnelId,
        inheritAssignedUsers,
        inheritFieldsVisibility
      )
        // TODO: dry!
        .then(this.afterFunnelUpdate)
        .catch((err) => {
          // toast.error(this.props.funnelError || "Error saving funnel");
        });
    } else {
      createFunnel(
        programId,
        name,
        inputSources,
        questions,
        hiddenColumns,
        fieldsOrder,
        assignedUsers,
        operationBetweenInputSources,
        showAverageAndPreviousScore,
        excludedSubmissions,
        isFinalFunnel,
        start_date,
        end_date
      )
        // TODO: dry!
        .then(this.afterFunnelUpdate)
        .catch((err) => {
          // toast.error(this.props.funnelError || "Error saving funnel");
        });
    }
  };

  closeFunnelSettings = () => {
    this.props.actions.resetFunnelState();
    this.setState({ isSettingsOpen: false, isEditing: false });
  };

  afterFunnelUpdate = () => {
    const {
      programId,
      history,
      actions: { fetchProgram },
      funnelSettings: { id },
      match: { params },
    } = this.props;

    fetchProgram(programId).then(this.closeFunnelSettings);

    if (params.funnelId !== id) {
      history.push(
        `/dashboard/programs/${programId}/funnels/${id}/${this.submissionsPagePath}/1`
      );
    } else {
      this.setState({
        isSaved: true,
      });
    }
  };

  displayPromptMessage = () => {
    this.props.actions.activatePromptMessage({
      title: translate("delete_funnel"),
      message: translate("all_funnel_data_will_deleted"),
      buttonContent: translate("yes"),
      confirmationHandler: () => this.onDelete(),
    });
  };

  onDelete = () => {
    const {
      programId,
      rootFunnelId,
      match: { params },
      history,
      actions,
    } = this.props;
    actions.deactivatePromptMessage();
    spinner.show();
    axios
      .delete(`${process.env.REACT_APP_API_URL}/funnels/${params.funnelId}`)
      .then(() => {
        actions.fetchProgram(programId);
        history.push(
          `/dashboard/programs/${programId}/funnels/${rootFunnelId}/${this.submissionsPagePath}/1`
        );
        this.closeFunnelSettings();
      });
  };

  onCopy = () => {
    const {
      programId,
      match: { params },
      actions,
      history,
    } = this.props;
    spinner.show();
    axios
      .post(`${process.env.REACT_APP_API_URL}/funnels/${params.funnelId}/copy`)
      .then((response) => {
        actions.resetFunnelState();
        actions.fetchProgram(programId).then(() => {
          this.setState({ isSettingsOpen: false });
          history.push(
            `/dashboard/programs/${programId}/funnels/${response.data.id}/${this.submissionsPagePath}/1`
          );
          this.toggleFunnelSettings();
          spinner.hide();
        });
      });
  };

  render() {
    const {
      match,
      funnels,
      funnelSettings,
      fields,
      users,
      groups,
      rootFunnelId,
      programId,
    } = this.props;
    const { path, params } = match;
    const { isSettingsOpen, isEditing, isSaved } = this.state;

    const funnelId = params.funnelId || rootFunnelId;

    return (
      <div className="funnel-container">
        <FunnelsTabs
          funnels={funnels}
          activeFunnel={funnelId}
          programId={params.programId}
          onAddFunnelClick={this.addNewFunnel}
          onFunnelSettingsClick={this.toggleFunnelSettings}
          path={`${path}/${this.submissionsPagePath}/1/`}
          closeFunnelSettings={this.closeFunnelSettings}
          isEditing={isEditing}
          privileges={this.props.privileges}
        />
        <div className="setting_submissions">
          <div>
            {isSettingsOpen && (
              <FunnelsSettingsContainer
                funnelSettings={funnelSettings}
                fields={fields}
                users={users}
                groups={groups}
                saveFunnelSettings={this.saveFunnelSettings}
                closeFunnelSettings={this.closeFunnelSettings}
                isEditing={isEditing}
                onDelete={this.displayPromptMessage}
                onCopy={this.onCopy}
                programId={programId}
              />
            )}
          </div>
          <div className="submissions-list" key={`${funnelId}-${isSaved}`}>
            <Switch>
              <Route
                path={`${path}/${this.submissionsPagePath}/:page/:submissionId?`}
                component={SubmissionsContainer}
              />
              <Redirect
                from={`${path}/submissions/`}
                to={`${path}/${this.submissionsPagePath}/1`}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { funnelSettings, error } = state.funnels;
  const { members, groups } = state.membersManagement;
  const {
    privileges: { privileges },
  } = state;

  const {
    program: { funnels, id, programFields, rootFunnelId },
  } = state.program;

  const sortedProgramFields = [];
  Object.values(funnelSettings.fieldsOrder).forEach((element) => {
    sortedProgramFields.push(programFields.find((elem) => elem.id === element));
  });

  const newSorted = sortedProgramFields.concat(programFields);
  const uniqueSortedFields = Array.from(
    new Set(newSorted.map((item) => item.id))
  ).map((itemId) => newSorted.find((element) => element.id === itemId));
  return {
    funnelSettings,
    funnels,
    rootFunnelId,
    fields:
      Object.keys(funnelSettings.fieldsOrder).length === 0
        ? programFields
        : uniqueSortedFields,
    users: members,
    groups,
    programId: id,
    privileges: privileges[id],
    funnelError: error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...funnelSettingsActions,
      ...promptMessageActions,
      fetchProgram: fetchProgramAction,
    },
    dispatch
  ),
});

FunnelsContainer.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  funnels: PropTypes.array.isRequired,
  rootFunnelId: PropTypes.string.isRequired,
  funnelSettings: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  programId: PropTypes.string.isRequired,
  privileges: PropTypes.array,
};

FunnelsContainer.defaultProps = {
  privileges: [],
};

const container = connect(
  mapStateToProps,
  mapDispatchToProps
)(FunnelsContainer);

export default container;
