import React, { useEffect } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubmissionHistory } from "../../../actions/submissions.actions";
import dayjs from "dayjs";
import Select from "react-select";
import translate from "../texts";

function HistoryMenu({ submission }) {
  const dispatch = useDispatch();

  const params = useParams();

  const history = useHistory();

  const viewSingleSubmission = (id) => {
    const {
      submissionId: currentSubmissionId,
      page,
      funnelId,
      programId,
    } = params;

    if (id !== currentSubmissionId) {
      history.push(
        `/dashboard/programs/${programId}/funnels/${funnelId}/submissions/page/${page}/${id}`
      );
    }
  };

  const submissionHistory = useSelector(
    (state) => state.submissions.submissionHistory
  );

  useEffect(() => {
    dispatch(fetchSubmissionHistory(submission.id));
  }, []);

  return (
    <div className="">
      {/* {submissionHistory?.map((history) => (
        <button
          key={history.id}
          onClick={() => viewSingleSubmission(history.id)}
        >
          {dayjs(history.createdAt).format("YYYY-MM-DD")}
        </button>
      ))} */}

      <Select
        className="w-52"
        placeholder={translate("HistoryDate")}
        options={
          submissionHistory?.map((history, i, arr) => ({
            value: history.id,
            label:
              dayjs(history.createdAt).format("YYYY-MM-DD h:mm a") +
              " " +
              (i === arr.length - 1 ? "✅" : "❌"),
          })) || []
        }
        onChange={(value) => {
          viewSingleSubmission(value.value);
        }}
        searchable={false}
        clearable={false}
        // value={options.find((option) => option.value === value)}
      />
    </div>
  );
}

export default HistoryMenu;
