import axios from "axios";
import * as types from "../types/auth.types";

export default function fetchPrivileges() {
  return {
    type: types.FETCH_PRIVILEGES,
    payload: axios.get(`${process.env.REACT_APP_API_URL}/privileges`),
  };
}

export function SignInAction(data) {
  return {
    type: types.SIGNIN,
    payload: axios.post(`${process.env.REACT_APP_serverUrl}/users/sign_in`, {
      lower_email: data.email.toLowerCase(),
      password: data.password,
    }),
  };
}

export function SignUpAction(data) {
  return {
    type: types.SIGNUP,
    payload: axios.post(`${process.env.REACT_APP_serverUrl}/users`, {
      email: data.email,
      name: data.name,
      mobile: data.mobile,
      password: data.password,
      password_confirmation: data.password_confirmation,
      confirm_success_url: "",
    }),
  };
}

// forgot password action
export function ForgotPasswordAction(data) {
  return {
    type: types.FORGOT_PASSWORD,
    payload: axios.post(`${process.env.REACT_APP_serverUrl}/password`, {
      email: data.email,
    }),
  };
}
