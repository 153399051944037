import React from "react";
import PropTypes from "prop-types";

function renderItems(children) {
  return children.map((child, index) => (
    <div key={`column-${index}`} className="column">
      {child}
    </div>
  )); // eslint-disable-line react/no-array-index-key
}

/**
 * Will take all children and wrap each one of them in a .column div.
 */

const Columns = ({ children, gapless }) => {
  children = Array.isArray(children) ? children : [children];

  return (
    <div className={`columns ${gapless ? "is-gapless" : ""}`}>
      {renderItems(children)}
    </div>
  );
};

Columns.propTypes = {
  children: PropTypes.any,
  gapless: PropTypes.bool,
};

Columns.defaultProps = {
  children: <div />,
  gapless: false,
};

export default Columns;
