/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import uuid from "uid";
import { Box, Button } from "../../../../components";
import InputSource from "./InputSource";
import translate from "../../texts";

class InputSourcesContainer extends Component {
  state = {
    inputSources: this.props.value,
  };

  onSelectSource = (sourceIndex) => (funnel) => {
    if (!funnel) {
      return;
    }

    this.setState(
      (prevState) => {
        // clone input sources
        const inputSources = JSON.parse(JSON.stringify(prevState.inputSources));
        const index = sourceIndex;
        const sourceFunnelExist =
          inputSources[index] !== undefined &&
          inputSources[index].sourceFunnel !== undefined;
        if (!inputSources[index] || sourceFunnelExist === false) {
          inputSources[index] = { sourceFunnel: { id: funnel.value } };
        } else {
          inputSources[index].sourceFunnel.id = funnel.value;
        }

        inputSources[index].filters = [];
        return {
          inputSources,
          localChange: true,
        };
      },
      () => this.inputSourceChange()
    );
  };

  onUpdateFilter = (index) => (funnelId, filters) => {
    this.setState(
      (prevState) => {
        // clone input sources
        const inputSources = JSON.parse(JSON.stringify(prevState.inputSources));
        inputSources[index].filters = filters;
        return {
          inputSources,
          localChange: true,
        };
      },
      () => this.inputSourceChange()
    );
  };

  onSameInputSourceOperationChange = (index) => (operator) => {
    this.setState(
      (prevState) => {
        const inputSources = JSON.parse(JSON.stringify(prevState.inputSources));
        inputSources[index].filterOperator = operator;
        return {
          inputSources,
          localChange: true,
        };
      },
      () => this.inputSourceChange()
    );
  };

  removeInputSource = (index) => () => {
    this.setState(
      (prevState) => {
        // clone input sources
        const inputSources = JSON.parse(JSON.stringify(prevState.inputSources));
        inputSources.splice(index, 1);
        return {
          inputSources,
          localChange: true,
        };
      },
      () => this.inputSourceChange()
    );
  };

  inputSourceChange = () => {
    this.props.onChange({ target: { value: null } }, this.state.inputSources);
  };

  addNewInputSource = () => {
    this.setState((prevState) => {
      const sourceId = uuid();
      const inputSources = [...prevState.inputSources];
      const { rootFunnelId } = this.props;
      inputSources.push({
        id: sourceId,
        sourceFunnel: {
          id: rootFunnelId,
          name: translate("all_submissions_tab"),
        },
        filterOperator: "$and",
      });
      return {
        inputSources,
        localChange: true,
      };
    });
  };

  render() {
    const {
      fields,
      operators,
      submissionStatuses,
      funnels,
      questions,
      operationBetweenInputSources,
    } = this.props;

    return (
      <div className="input_sources_container">
        {this.state.inputSources.map((source, index) => (
          <InputSource
            key={source.id || index}
            fields={fields}
            inputSource={source}
            operators={operators}
            operationBetweenInputSources={operationBetweenInputSources}
            submissionStatuses={submissionStatuses}
            onSelectSource={this.onSelectSource(index)}
            onInputSourcesOperationChange={
              this.props.onInputSourcesOperationChange
            }
            funnels={funnels}
            onUpdateFilter={this.onUpdateFilter(index)}
            questions={questions}
            removeInputSource={this.removeInputSource(index)}
            onSameInputSourceOperationChange={this.onSameInputSourceOperationChange(
              index
            )}
            operationOnSameInputSource={source.filterOperator}
          />
        ))}
        <Box className="new_input_source_box">
          <div className="new_input_source_btn">
            <Button
              wide
              flat
              iconName="plus-circle"
              content={translate("funnel_new_input_source")}
              onClick={this.addNewInputSource}
            />
          </div>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const filtersState = state.filters;
  const submissionsState = state.submissions;
  const { program } = state.program;

  return {
    operators: filtersState.operators,
    fields: submissionsState.filtrationFields,
    submissionStatuses: submissionsState.submissionStatuses,
    funnels: program.funnels,
    questions: program.questions,
    rootFunnelId: program.rootFunnelId,
  };
};

InputSourcesContainer.propTypes = {
  fields: PropTypes.array.isRequired,
  operators: PropTypes.object.isRequired,
  operationBetweenInputSources: PropTypes.string.isRequired,
  submissionStatuses: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onInputSourcesOperationChange: PropTypes.func.isRequired,
  funnels: PropTypes.array.isRequired,
  questions: PropTypes.array.isRequired,
  rootFunnelId: PropTypes.string.isRequired,
};

const container = connect(mapStateToProps, null)(InputSourcesContainer);

export default container;
