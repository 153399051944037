import React, { useState } from "react";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import { humanizeField } from "../../utils/utils";
import { Icon } from "../../components";
import uuid from "uid";
import "./Input.scss";
import ReactDatePicker from "react-datepicker";

const Input = ({
  inputName,
  inputType,
  value,
  placeholder,
  className,
  iconName,
  onChange,
  onFocus,
  onBlur,
  onKeyUp,
  multiline,
  autoFocus,
  disabled,
  inputId,
  label,
  maxlength,
  focus,
  autocomplete,
  suggestions,
}) => {
  const [suggestionsState, setSuggestionsState] = useState([]);

  const escapeRegexCharacters = (str) =>
    str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  const removeSpaces = (str) => str.replace(/ /g, "_").toLowerCase();

  const getSuggestions = (array, v) => {
    const escapedValue = escapeRegexCharacters(v.trim());
    const regex = new RegExp(`^${escapedValue}`, "i");
    if (escapedValue === "") {
      return [];
    }
    return array.filter((item) => regex.test(removeSpaces(item.name)));
  };

  const getSuggestionValue = (suggestion) =>
    removeSpaces(`${suggestion.name}:`);

  const onSuggestionsFetchRequested = ({ value: v }) => {
    setSuggestionsState(getSuggestions(suggestions, v));
  };

  const renderSuggestion = (suggestion) => (
    <span>{humanizeField(suggestion.name)}</span>
  );

  return (
    <div
      className={`${multiline ? "multiline" : "input-component"} ${
        iconName.length > 0 ? "has-icons-right" : ""
      } control ${className}`}
    >
      {inputId.length > 0 && label.length > 0 ? (
        <label htmlFor={inputId}>{label}</label>
      ) : (
        ""
      )}
      {multiline && (
        <textarea
          id={inputId || uuid()}
          name={inputName}
          value={value}
          className="input"
          type={inputType}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
          disabled={disabled}
          maxLength={maxlength}
        />
      )}
      {!multiline && autocomplete && (
        <Autosuggest
          suggestions={suggestionsState}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={() => {
            setSuggestionsState([]);
          }}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{ placeholder, value, onChange }}
        />
      )}
      {!multiline && !autocomplete && (
        <>
          {inputType === "datePicker" ? (
            <div className="w-full">
              <ReactDatePicker
                className="input w-full"
                wrapperClassName="w-full"
                id={inputId || uuid()}
                selected={value ? new Date(value) : null}
                onChange={(date) => {
                  const offsetDate = new Date(
                    date.getTime() - date.getTimezoneOffset() * 60000
                  );

                  const formattedDate = offsetDate.toISOString().slice(0, 10);
                  onChange({ target: { value: formattedDate } });
                }}
                placeholderText={placeholder}
              />
            </div>
          ) : (
            <input
              id={inputId || uuid()}
              name={inputName}
              value={value}
              className="input"
              type={inputType}
              placeholder={placeholder}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyUp={onKeyUp}
              autoFocus={autoFocus}
              disabled={disabled}
              maxLength={maxlength}
              ref={(input) => {
                if (input && focus) input.focus();
              }}
            />
          )}
        </>
      )}

      {iconName.length > 0 && (
        <div className="icon is-right pr-2 mt-2">
          <Icon name={iconName} />
        </div>
      )}
    </div>
  );
};

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
  inputType: PropTypes.string,
  className: PropTypes.string,
  iconName: PropTypes.string,
  inputName: PropTypes.string,
  multiline: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  inputId: PropTypes.string,
  label: PropTypes.string,
  maxlength: PropTypes.number,
  focus: PropTypes.bool,
  autocomplete: PropTypes.bool,
  suggestions: PropTypes.array,
};

Input.defaultProps = {
  value: "",
  placeholder: "",
  inputType: "text",
  className: "",
  iconName: "",
  inputName: "",
  multiline: false,
  onFocus: () => {},
  onBlur: () => {},
  onKeyUp: () => {},
  autoFocus: false,
  disabled: false,
  inputId: "",
  label: "",
  maxlength: null,
  focus: false,
  autocomplete: false,
  suggestions: [],
};

export default Input;
