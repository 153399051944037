import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { lightAdjuster } from "../../utils/utils";
import * as workspaceActions from "../../actions/workspaces.actions";
import translate from "./texts";
import "./Workspaces.scss";

const Workspaces = ({ archivedView, refetch, filter }) => {
  const dispatch = useDispatch();
  const { workspaces, fetched, isFetching } = useSelector(
    (state) => state.workspaces
  );

  const setWorkspaceBackground = (programs) => {
    if (programs.length < 4) {
      return (
        <div
          className="one program"
          style={{
            background:
              programs[0].logo !== ""
                ? `no-repeat center / contain url("${programs[0].logo}")`
                : lightAdjuster(programs[0].backgroundColor),
          }}
        />
      );
    }
    return programs.map((program) => (
      <div
        key={`bg-${program.id}`}
        className="many program"
        style={{
          background:
            program.logo !== ""
              ? `no-repeat center / contain url("${program.logo}")`
              : lightAdjuster(program.backgroundColor),
        }}
      />
    ));
  };

  useEffect(() => {
    if (refetch) {
      dispatch(workspaceActions.fetchWorkspaces(archivedView, filter));
    }
  }, [dispatch, archivedView, filter, refetch]);

  return (
    <div>
      {workspaces.length !== 0 && (
        <div id="Workspaces">
          {workspaces.map(
            (workspace, index) =>
              workspace.programs.length > 0 && (
                <Link
                  to={{
                    pathname: `/dashboard/workspaces/${workspace.id}/${index}`,
                  }}
                  id={`w-${workspace.id}`}
                  className="w-container"
                  key={`w-${workspace.id}`}
                >
                  <div className="w-background">
                    {setWorkspaceBackground(workspace.programs.slice(0, 4))}
                  </div>
                  <div className="w-name">
                    <h2>{workspace.name}</h2>
                    <span>{`${
                      workspace.programsCount > 1
                        ? workspace.programsCount + translate("many_programs")
                        : translate("program")
                    }`}</span>
                  </div>
                </Link>
              )
          )}
        </div>
      )}
      {workspaces.length === 0 && fetched && !isFetching && (
        <p className="empty-state">
          {translate("no_workspace_found")}
          <span role="img" aria-label="face with head-bandage">
            {" "}
            🤕
          </span>
        </p>
      )}
    </div>
  );
};

Workspaces.propTypes = {
  archivedView: PropTypes.bool.isRequired,
  refetch: PropTypes.bool.isRequired,
  filter: PropTypes.string,
};

Workspaces.defaultProps = {
  filter: "",
};

export default Workspaces;
