import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { parseFilters } from "../../utils/utils";
import { spinner } from "../../components";
import axios from "axios";
import { fetchProgram } from "../../actions/program.actions";
import * as funnelPublicAccessActions from "../../actions/funnelAccess.actions";
import {
  fetchProgramMembers,
  fetchProgramGroups,
} from "../../actions/members-management.actions";
import * as submissionsActions from "../../actions/submissions.actions";
import * as labelsActions from "../../actions/labels.actions";
import AdvancedSearchContainer from "../AdvancedSearch";
import SubmissionsTable from "./SubmissionsTable";
import SingleSubmission from "../SingleSubmission/SingleSubmission";
import SubmissionHeader from "./SubmissionTableHeader/SubmissionTableHeader";
import translate from "./texts";
import Cookies from "universal-cookie";

import "./SubmissionContainer.scss";
import { toast } from "react-toastify";

class SubmissionsContainer extends Component {
  defaultFilters = {
    initialFilter: {
      id: "initialFilter",
      field: {
        id: "",
        type: "string",
        category: "fields",
      },
    },
  };

  constructor(props) {
    super(props);
    this.programId = this.props.program.id;
    this.page = this.props.match.params.page;
  }

  state = {
    query: "",
    queryFieldId: "",
    selectedFilters: this.defaultFilters,
    advancedSearchCollapsed: true,
    selectedSubmissions: {},
    allOfAllSelected: false,
    importActive: false,
    isAdvanceSearchActive: false,

    filterOperator: "$and",
  };

  componentDidMount() {
    const { actions, labelsActions: lActions, privileges } = this.props;
    const { funnelId, programId } = this.props.match.params;

    this.fetchSubmissions();
    actions.fetchOperators();
    actions.fetchFilters(programId);
    lActions.fetchLabels(programId);
    // actions.fetchFilterActions();
    if ((privileges[programId] || []).includes("funnels.index")) {
      actions.fetchFunnelPublicAccess(funnelId);
    }

    if ((privileges[programId] || []).includes("submissions.distribute")) {
      actions.fetchProgramGroups(programId);
    }
    actions.fetchProgramMembers(programId);
    actions.setFetchSubmissionsHandler(this.fetchSubmissions);
    actions.fetchSubmissionStatuses();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      openFirstSubmission,
      firstSubmissionIdFromCurrentList,
      match: { params },
      actions: { fetchSubmissions, firstSubmissionDidOpen },
    } = this.props;

    const { page, funnelId } = params;

    const { sortBy, direction } = nextProps;
    const nextPage = nextProps.match.params.page;

    if (page !== nextPage) {
      this.page = nextPage;
      this.deselectAll();
      this.deselectAllOfAll();
      fetchSubmissions(
        this.programId,
        nextPage,
        sortBy,
        direction,
        this.state.query,
        this.state.queryFieldId,
        this.parseFilters(),
        funnelId
      );
    }

    if (
      openFirstSubmission &&
      nextProps.submissions.length !== 0 &&
      firstSubmissionIdFromCurrentList !== nextProps.submissions[0].id
    ) {
      this.viewSingleSubmission(nextProps.submissions[0].id);
      firstSubmissionDidOpen();
    }
  }

  componentWillUnmount() {
    this.props.actions.resetSubmissions();
  }

  getRoute() {
    const { funnelId, programId } = this.props.match.params;

    return `/dashboard/programs/${programId}/funnels/${funnelId}/submissions/page`;
  }

  toggleSubmissionSelect = (id, checked) => {
    const { selectedSubmissions } = this.state;
    const {
      submissions,
      history,
      match: { params },
    } = this.props;
    const { submissionId, page } = params;

    this.deselectAllOfAll();
    if (id === "SELECT_ALL") {
      if (checked) {
        if (!submissionId && submissions.length > 0) {
          history.push(`${this.getRoute()}/${page}/${submissions[0].id}`);
        }
        this.selectAllSubmissions();
      } else {
        this.deselectAll();
      }
      return;
    }
    if (id === "ALL_OF_ALL") {
      this.selectAllOfAll();
      if (!submissionId && submissions.length > 0) {
        history.push(`${this.getRoute()}/${page}/${submissions[0].id}`);
      }
      return;
    }

    this.setState({
      selectedSubmissions: {
        ...selectedSubmissions,
        [id]: !selectedSubmissions[id],
      },
    });
  };

  selectAllSubmissions = () => {
    const selectAll = {};
    const { submissions } = this.props;

    submissions.forEach((item) => {
      selectAll[item.id] = true;
    });

    this.setState({
      selectedSubmissions: { ...selectAll },
    });
  };

  deselectAll = () => {
    this.setState({
      selectedSubmissions: {},
    });
  };

  selectAllOfAll = () => {
    this.setState({
      allOfAllSelected: true,
    });
    this.selectAllSubmissions();
  };

  deselectAllOfAll = () => {
    this.setState({
      allOfAllSelected: false,
      selectedSubmissions: {},
    });
  };

  anySubmissionsSelected = () =>
    Object.values(this.state.selectedSubmissions).some((value) => value);

  selectedSubmissionsIds = () => {
    const { selectedSubmissions } = this.state;
    return Object.keys(selectedSubmissions).filter(
      (id) => selectedSubmissions[id]
    );
  };

  selectedSubmissionCount = () => {
    const { selectedSubmissions, allOfAllSelected } = this.state;

    if (allOfAllSelected) {
      return this.props.metadata.filteredItemCount;
    }

    return Object.keys(selectedSubmissions).filter(
      (id) => selectedSubmissions[id]
    ).length;
  };

  // TODO: unify fetching submissions by using this function.
  fetchSubmissions = () => {
    const {
      sortBy,
      direction,
      actions,
      match: {
        params: { page, funnelId },
      },
    } = this.props;

    const { query, queryFieldId } = this.state;
    const nextPage = page || 1;

    actions
      .fetchSubmissions(
        this.programId,
        nextPage,
        sortBy,
        direction,
        query,
        queryFieldId,
        this.parseFilters(),
        funnelId,
        this.state.filterOperator
      )
      .then(() => {
        spinner.hide();
      });
  };

  toggleSorting = (sortParam) => {
    if (sortParam === "-") {
      return;
    }

    const {
      sortBy,
      history,
      match: {
        params: { page, funnelId },
      },
      actions: { setSortConfig, fetchSubmissions },
    } = this.props;

    let direction = 1;

    if (sortBy === sortParam) {
      direction = this.props.direction * -1;
    }

    setSortConfig(sortParam, direction);

    if (page > 1) {
      history.push(`${this.getRoute()}/1`);
    } else {
      fetchSubmissions(
        this.programId,
        1,
        sortParam,
        direction,
        this.state.query,
        this.state.queryFieldId,
        this.parseFilters(),
        funnelId
      );
    }
  };

  viewSingleSubmission = (id) => {
    const {
      history,
      match: {
        params: { submissionId: currentSubmissionId, page },
      },
    } = this.props;

    if (id !== currentSubmissionId) {
      history.push(`${this.getRoute()}/${page}/${id}`);
    }
  };

  closeTable = () => {
    const {
      history,
      match: {
        params: { page },
      },
    } = this.props;

    history.push(`${this.getRoute()}/${page}`);
    this.setState({ selectedSubmissions: {} });
  };

  exportToCSV = (params) => {
    const {
      match: {
        params: { funnelId },
      },
    } = this.props;
    const { query, queryFieldId } = this.state;
    const cookies = new Cookies();
    const rtoken = cookies.get("Rtoken");

    const exportLater = params.exportLater && params.email;
    const exportLaterEmail = exportLater
      ? encodeURIComponent(params.email)
      : "";

    if (exportLater) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/programs/${
            this.programId
          }/submissions/?funnelId=${funnelId}&query=${query}&fieldId=${queryFieldId}&filters=${JSON.stringify(
            this.parseFilters()
          )}&export_as=csv${
            params.original ? "-original" : ""
          }&export_later=1&export_email=${exportLaterEmail}&Rtoken=${rtoken}`
        )
        .then(() => {
          toast.success(translate("Export request sent successfully"));
        })
        .catch(() => {
          toast.error(translate("Export request failed"));
        });
      return;
    }

    window.open(
      `${process.env.REACT_APP_API_URL}/programs/${
        this.programId
      }/submissions/?funnelId=${funnelId}&query=${query}&fieldId=${queryFieldId}&filters=${JSON.stringify(
        this.parseFilters()
      )}&export_as=csv${params.original ? "-original" : ""}&Rtoken=${rtoken}`,
      "_blank"
    );
  };

  exportToXLSX = (params) => {
    const {
      match: {
        params: { funnelId },
      },
    } = this.props;
    const { query, queryFieldId } = this.state;
    const cookies = new Cookies();
    const rtoken = cookies.get("Rtoken");

    const exportLater = params.exportLater && params.email;
    const exportLaterEmail = exportLater
      ? encodeURIComponent(params.email)
      : "";

    if (exportLater) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/programs/${
            this.programId
          }/submissions/?funnelId=${funnelId}&query=${query}&fieldId=${queryFieldId}&filters=${JSON.stringify(
            this.parseFilters()
          )}&export_as=xlsx${
            params.original ? "-original" : ""
          }&export_later=1&export_email=${exportLaterEmail}&Rtoken=${rtoken}`
        )
        .then((res) => {
          toast.success(res.data.message);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
      return;
    }

    window.open(
      `${process.env.REACT_APP_API_URL}/programs/${
        this.programId
      }/submissions/?funnelId=${funnelId}&query=${query}&fieldId=${queryFieldId}&filters=${JSON.stringify(
        this.parseFilters()
      )}&export_as=xlsx${params.original ? "-original" : ""}&Rtoken=${rtoken}`,
      "_blank"
    );
  };

  importComplete = (name) => {
    const url = `${process.env.REACT_APP_API_URL}/programs/${this.programId}/submissions/import`;
    const file = `${process.env.REACT_APP_AWS_S3_BUCKET_PATH}/${name}`;

    axios
      .post(url, {
        file,
      })
      .then(() => {
        this.props.actions.fetchProgram(this.programId).then(() => {
          this.fetchSubmissions();
        });
      })
      .catch(() => {
        spinner.hide();
      });
  };

  updateQuery = (searchQuery, fieldId) => {
    this.setState({ query: searchQuery, queryFieldId: fieldId });
  };

  searchForSubmissions = () => {
    const {
      history,
      match: {
        params: { submissionId, page },
      },
    } = this.props;

    if (submissionId || page > 1) {
      history.push(`${this.getRoute()}/1`);
    }

    this.fetchSubmissions();
  };

  closeSearchControls = () => {
    this.setState({ advancedSearchCollapsed: true });
  };

  updateAdvancedSearchCollapsedState = () => {
    this.setState((prevState) => ({
      advancedSearchCollapsed: !prevState.advancedSearchCollapsed,
    }));
  };

  setFilterOperator = (operator) => {
    this.setState({ filterOperator: operator });
  };

  parseFilters = () => parseFilters(this.state.selectedFilters);

  updateSelectedFilters = (filters) => {
    this.setState({ selectedFilters: filters });
  };

  resetFilters = () => {
    const selectedFilters = {};
    selectedFilters.initialFilter = this.defaultFilters.initialFilter;
    this.setState({ selectedFilters }, this.fetchSubmissions);
    this.setAdvanceSearchState(false);
    this.closeSearchControls();
  };

  removeFilter = (filterId) => {
    const { selectedFilters } = this.state;
    if (filterId === "initialFilter") {
      selectedFilters.initialFilter = {
        id: "initialFilter",
        field: { id: "", type: "string", category: "fields" },
      };
    } else {
      delete selectedFilters[filterId];
    }

    this.setState({ selectedFilters }, this.fetchSubmissions);
  };

  setAdvanceSearchState = (flag) => {
    this.setState((prevState) => ({
      isAdvanceSearchActive: flag || !prevState.isAdvanceSearchActive,
    }));
  };

  render() {
    const url = this.props.match.url.split("/page")[0];

    const {
      match: {
        params: { page, submissionId, funnelId },
      },
      metadata: { filteredItemCount, limit },
      program: { rootFunnelId, funnels },
      submissions,
      programFieldNames,
      programFields,
      availableLabels,
      sortBy,
      direction,
      metadata,
      history,
      isFetching,
      fetched,
      filters,
      filtrationFields,
      operators,
      programId,
      questions,
      filterActions,
      actions: { deleteFilter, setFunnelPublicAccess, fetchFunnelPublicAccess },
      funnelAccess,
    } = this.props;

    const currentFunnel = funnels.filter((funnel) => funnel.id === funnelId);
    const isRootFunnel =
      currentFunnel.length > 0 && currentFunnel[0].rootFunnel;
    const {
      advancedSearchCollapsed,
      selectedSubmissions,
      allOfAllSelected,
      query,
      selectedFilters,
      importActive,
      isAdvanceSearchActive,
    } = this.state;

    const currentPage = parseInt(page, 10) || 1;

    const filteredTotal = filteredItemCount;

    // e.g. page 2 ends at submission 30 or less if the submissions are less than 30 ;)
    const currentPageEndsAt = Math.min(currentPage * limit, filteredTotal);

    // e.g. page 2 starts at submission 16
    const currentPageStartsAt = 1 + (currentPage - 1) * limit;
    // format submissions range like this: 1 - 15 from 100
    const submissionsRange =
      filteredTotal > 1
        ? `${translate("Showing")} ${currentPageStartsAt} ${translate(
            "to"
          )} ${currentPageEndsAt} ${translate("out_of")} ${filteredTotal}`
        : "";
    return (
      <div>
        <div className="submissions-container">
          <div className="submissions-content">
            <div
              className={`content-header ${
                submissionId ? "scroll-shadow" : ""
              }`}
            >
              {!advancedSearchCollapsed && (
                <div className="search-advanced">
                  <AdvancedSearchContainer
                    closeSearchControls={this.closeSearchControls}
                    filtrationFields={filtrationFields}
                    funnelId={funnelId}
                    operators={operators}
                    programId={programId}
                    questions={questions}
                    searchForSubmissions={this.searchForSubmissions}
                    setAdvanceSearchState={this.setAdvanceSearchState}
                    updateSelectedFilters={this.updateSelectedFilters}
                    userSelectedFilters={selectedFilters}
                    filterActions={filterActions}
                    setFilterOperator={this.setFilterOperator}
                    filterOperatorValue={this.state.filterOperator}
                  />
                </div>
              )}
            </div>

            <div className="submissions">
              <div
                className={`submission-table-container  ${
                  submissionId ? "collapsed" : ""
                }`}
              >
                <SubmissionHeader
                  updateAdvancedSearchCollapsedState={
                    this.updateAdvancedSearchCollapsedState
                  }
                  advancedSearchCollapsed={advancedSearchCollapsed}
                  submissions={submissions}
                  exportToCSV={this.exportToCSV}
                  exportToXLSX={this.exportToXLSX}
                  // NOTE: Not used.
                  importActive={importActive}
                  importComplete={this.importComplete}
                  programId={this.programId}
                  doNormalSearch={this.searchForSubmissions}
                  updateQuery={this.updateQuery}
                  submissionsRange={submissionsRange}
                  isRootFunnel={isRootFunnel}
                  removeFilter={this.removeFilter}
                  filters={filters}
                  deleteFilter={deleteFilter}
                  isAdvanceSearchActive={isAdvanceSearchActive}
                  setAdvanceSearchState={this.setAdvanceSearchState}
                  updateSelectedFilters={this.updateSelectedFilters}
                  filtrationFields={filtrationFields}
                  searchForSubmissions={this.searchForSubmissions}
                  operators={operators}
                  resetFilters={this.resetFilters}
                  setFunnelPublicAccess={setFunnelPublicAccess}
                  fetchFunnelPublicAccess={fetchFunnelPublicAccess}
                  funnelId={funnelId}
                  funnelAccess={funnelAccess}
                  programFields={programFields}
                />

                {isFetching && !fetched && (
                  <div className="no-submissions-found">
                    {translate("Loading submissions")}
                  </div>
                )}

                {filteredItemCount === 0 && fetched && !isFetching && (
                  <div className="no-submissions-found">
                    {translate("No submissions found")}
                    <span role="img" aria-label="face with head-bandage">
                      {" "}
                      🤕
                    </span>
                  </div>
                )}

                {filteredItemCount > 0 && fetched && !isFetching && (
                  <SubmissionsTable
                    submissions={submissions}
                    programFieldNames={programFieldNames}
                    programFields={programFields}
                    onClick={this.toggleSorting}
                    toggleSorting={this.toggleSorting}
                    sortConfig={{ sortBy, direction }}
                    viewSingleSubmission={this.viewSingleSubmission}
                    // double negation to convert any value to Boolean.
                    collapsed={!!submissionId}
                    currentSubmissionId={submissionId}
                    closeTable={this.closeTable}
                    pageUrl={url}
                    totalResult={filteredItemCount}
                    currentPage={currentPage}
                    itemsPerPage={limit}
                    selectedSubmissions={selectedSubmissions}
                    toggleSubmissionSelect={this.toggleSubmissionSelect}
                    deselectAll={this.deselectAll}
                    anySubmissionsSelected={this.anySubmissionsSelected()}
                    metadata={metadata}
                    funnelId={funnelId}
                    rootFunnelId={rootFunnelId}
                    fetchSubmissions={this.fetchSubmissions}
                    previousFunnelScores={this.props.previousFunnelScores}
                  />
                )}
              </div>

              {submissionId !== undefined && (
                <SingleSubmission
                  totalResult={filteredItemCount}
                  itemsPerPage={limit}
                  currentPage={currentPage}
                  history={history}
                  submissions={submissions}
                  closeTable={this.closeTable}
                  programId={this.programId}
                  programFields={programFields}
                  availableLabels={availableLabels}
                  submissionId={submissionId}
                  fetchSubmissions={this.fetchSubmissions}
                  anySubmissionsSelected={this.anySubmissionsSelected()}
                  selectedSubmissionCount={this.selectedSubmissionCount()}
                  selectedSubmissionsIds={this.selectedSubmissionsIds()}
                  isAllSubmissions={allOfAllSelected}
                  filters={this.parseFilters()}
                  query={query}
                  funnelId={funnelId}
                  rootFunnelId={rootFunnelId}
                  funnels={funnels}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SubmissionsContainer.propTypes = {
  program: PropTypes.object,
  submissions: PropTypes.array,
  programFieldNames: PropTypes.array,
  programFields: PropTypes.array,
  actions: PropTypes.object,
  match: PropTypes.object.isRequired,
  metadata: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  sortBy: PropTypes.string.isRequired,
  direction: PropTypes.any.isRequired,
  labelsActions: PropTypes.object,
  availableLabels: PropTypes.array.isRequired,
  questions: PropTypes.array.isRequired,
  openFirstSubmission: PropTypes.bool.isRequired,
  privileges: PropTypes.object.isRequired,
  firstSubmissionIdFromCurrentList: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  fetched: PropTypes.bool.isRequired,
  filters: PropTypes.array.isRequired,
  operators: PropTypes.object.isRequired,
  filtrationFields: PropTypes.array.isRequired,
  programId: PropTypes.string.isRequired,
  filterActions: PropTypes.array.isRequired,
  funnelAccess: PropTypes.object.isRequired,
};

SubmissionsContainer.defaultProps = {
  program: {},
  submissions: [],
  programFieldNames: [],
  programFields: [],
  actions: {},
  labelsActions: {},
  firstSubmissionIdFromCurrentList: "",
};

const mapStateToProps = (state) => {
  const programState = state.program;
  const submissionsState = state.submissions;
  const membersManagementState = state.membersManagement;
  const privilegesState = state.privileges;
  const filtersState = state.filters;
  const funnelAccessState = state.funnelAccess;

  return {
    program: programState.program,
    programId: programState.program.id,
    questions: programState.program.questions,
    submissions: submissionsState.submissions,
    funnelAccess: funnelAccessState.funnelAccess,
    // Remove this filter after removing the attachments logic.
    // Attachments will now be displayed in the field it self not in the bottom
    // of the single submission.
    programFields: submissionsState.programFields.filter(
      (field) => field !== "attachments"
    ),
    programFieldNames: submissionsState.programFieldNames.filter(
      (field) => field !== "attachments"
    ),
    error: submissionsState.error,
    pages: submissionsState.pages,
    sortBy: submissionsState.sortBy,
    direction: submissionsState.direction,
    metadata: submissionsState.metadata,
    openFirstSubmission: submissionsState.openFirstSubmission,
    firstSubmissionIdFromCurrentList:
      submissionsState.firstSubmissionIdFromCurrentList,
    closeFirstSubmission: submissionsState.closeFirstSubmission,
    isFetching: submissionsState.isFetching,
    fetched: submissionsState.fetched,
    filtrationFields: submissionsState.filtrationFields,
    members: membersManagementState.members,
    privileges: privilegesState.privileges,
    filters: filtersState.filters,
    operators: filtersState.operators,
    availableLabels: state.labels.availableLabels,
    filterActions: filtersState.filterActions,
    previousFunnelScores: submissionsState.previousFunnelScores,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchProgram,
      fetchProgramMembers,
      fetchProgramGroups,
      ...submissionsActions,
      ...funnelPublicAccessActions,
    },
    dispatch
  ),
  labelsActions: bindActionCreators(labelsActions, dispatch),
});

const container = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmissionsContainer);

export default container;
