import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { EMAIL_REGEX } from "../../utils/utils";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  toggleProgramMembersModal,
  displayMembersManagement,
} from "../../actions/program-members-modal.actions";
import { fetchProgram } from "../../actions/program.actions";
import { fetchProgramMembers } from "../../actions/members-management.actions";
import * as membersActions from "../../actions/members-invitation.actions";
import MembersInvitation from "./MembersInvitation";
import "./MembersInvitationContainer.scss";
import translate from "./texts";
import Cookies from "universal-cookie";

class MembersInvitationContainer extends Component {
  state = {
    loading: false,
    userData: {},
  };

  componentDidMount() {
    this.props.actions.fetchRoles();
    // get userData from local storage
    const cookies = new Cookies();

    const user = cookies?.get("user");
    this.setState({ userData: user });
  }

  onGlobalRoleChange = (role) => {
    const { members, defaultMemberRole, globalMembersRole, actions, roles } =
      this.props;

    if (role.id === globalMembersRole.id) {
      return;
    }

    let nextMembersState = {};
    if (role.id === "custom") {
      nextMembersState = {
        showMembersWithCustomRoles: true,
        globalMembersRole: { id: "custom", name: "Custom Role" },
        members: members.map((member) => ({
          ...member,
          role: defaultMemberRole,
        })),
      };
    } else {
      const originalRole = roles.filter(
        (roleItem) => roleItem.id === role.id
      )[0];
      nextMembersState = {
        showMembersWithCustomRoles: false,
        globalMembersRole: originalRole,
        members: members.map((member) => ({ ...member, role })),
      };
    }

    actions.onGlobalRoleChange(nextMembersState);
  };

  onIndividualRoleChange = (role, memberEmail) => {
    const { members, actions, roles } = this.props;
    const originalRole = roles.filter((roleItem) => roleItem.id === role.id)[0];
    const nextMembers = members.map((member) => {
      if (member.email === memberEmail) {
        member.role = originalRole;
      }
      return member;
    });
    actions.onIndividualRoleChange(nextMembers);
  };

  invitationBtnContent = () => {
    if (this.state.loading) {
      return translate("Inviting");
    }
    return `${translate("Invite")} ${this.props.members.length} ${translate(
      "Members"
    )}`;
  };

  membersInvitationActions = () => [
    {
      content: this.invitationBtnContent(),
      flat: false,
      onClick: this.inviteMembers,
      disabled: this.props.members.length === 0 || this.state.loading,
    },
  ];

  inviteMembers = () => {
    const { actions, members, programId } = this.props;

    this.setState({ loading: true });

    actions.inviteMembers(programId, members).then(() => {
      this.setState({ loading: false });
      actions.resetInvitationMembers();
      actions.fetchProgramMembers(programId);
    });
  };

  resetInvitationMembers = () => {
    const { actions } = this.props;
    actions.toggleProgramMembersModal();
    actions.resetInvitationMembers();
  };

  addMembers = (members) => {
    // Clone the last entered item, this will be used as a schema object
    const lastInput = { ...members[members.length - 1] };
    if (lastInput.email) {
      // split the emails by space, comma and semicolon
      const emails = lastInput.email.split(/,| |;/);
      // Remove the last item in the memebers array
      members.splice(-1, members.length);
      // validate and push the emails to the members array
      emails.forEach((item) => {
        if (!EMAIL_REGEX.test(item)) {
          toast(`${translate("invalid_email_format")} (${item})`);
          return;
        }
        const currentInput = { ...lastInput };
        currentInput.email = item;
        currentInput.id = item;
        members.push(currentInput);
      });
    }

    members = members
      .map((member, index) => this.verifyMember(member, index))
      .filter((member) => member);
    this.props.actions.onAddMembers(members);
  };

  assureMemberIsNew = (member, index) => {
    const { members: currentMembers, globalMembersRole } = this.props;

    if (this.memberIndex(member) === -1) {
      if (index >= currentMembers.length) {
        member.role = globalMembersRole;
      }
      return member;
    }

    toast(translate("The_user_already_exist"));
    return null;
  };

  preventSelfInvitation = (member) => {
    if (this.state?.userData?.email !== member.email) {
      return member;
    }

    toast(translate("self_invetation"));
    return null;
  };

  verifyMember = (member, index) => {
    let result = this.assureMemberIsNew(member, index);

    if (result != null) {
      result = this.preventSelfInvitation(member);
    }

    return result;
  };

  memberIndex = (member) => {
    const { programMembers } = this.props;
    return programMembers.findIndex((item) => item.user.email === member.email);
  };

  render() {
    const { showMembersWithCustomRoles, globalMembersRole, members, roles } =
      this.props;

    return (
      <MembersInvitation
        showMembersWithCustomRoles={showMembersWithCustomRoles}
        globalMembersRole={globalMembersRole}
        members={members}
        roles={roles}
        addMembers={this.addMembers}
        onGlobalRoleChange={this.onGlobalRoleChange}
        onIndividualRoleChange={this.onIndividualRoleChange}
        membersInvitationActions={this.membersInvitationActions()}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const membersInvitationState = state.membersInvitation;
  const { members } = state.membersManagement;
  const {
    program: { id: programId },
  } = state.program;

  return {
    roles: membersInvitationState.roles,
    workspaceMembers: membersInvitationState.workspaceMembers,
    membersFetched: membersInvitationState.membersFetched,
    members: membersInvitationState.members,
    showMembersWithCustomRoles:
      membersInvitationState.showMembersWithCustomRoles,
    globalMembersRole: membersInvitationState.globalMembersRole,
    defaultMemberRole: membersInvitationState.defaultMemberRole,
    programId,
    programMembers: members,
  };
};

MembersInvitationContainer.propTypes = {
  roles: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  defaultMemberRole: PropTypes.object.isRequired,
  globalMembersRole: PropTypes.object.isRequired,
  showMembersWithCustomRoles: PropTypes.bool.isRequired,
  members: PropTypes.array.isRequired,
  programId: PropTypes.string.isRequired,
  programMembers: PropTypes.array.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...membersActions,
      fetchProgram,
      toggleProgramMembersModal,
      displayMembersManagement,
      fetchProgramMembers,
    },
    dispatch
  ),
});

const container = connect(
  mapStateToProps,
  mapDispatchToProps
)(MembersInvitationContainer);

export default container;
