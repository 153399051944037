import React, { Component } from "react";
import PropTypes from "prop-types";
import { IconButton } from "../../components";

import "./Sidenav.scss";

class Sidenav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: this.props.collapsed || false,
    };

    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse() {
    const { collapsed } = this.state;

    this.setState({
      collapsed: !collapsed,
    });
  }

  render() {
    const { children, className } = this.props;
    const { collapsed } = this.state;

    return (
      <div
        className={`sidenav ${className} ${collapsed ? "is-collapsed" : ""}`}
      >
        {collapsed ? (
          <IconButton
            className="collapse"
            iconName="menu"
            onClick={this.toggleCollapse}
          />
        ) : (
          <IconButton
            className="collapse"
            iconName="close"
            onClick={this.toggleCollapse}
          />
        )}
        {children}
      </div>
    );
  }
}

const Brand = ({ children }) => <div className="sidenav-brand">{children}</div>;
const Links = ({ children }) => <div className="sidenav-links">{children}</div>;

Sidenav.propTypes = {
  children: PropTypes.any.isRequired,
  collapsed: PropTypes.bool,
  className: PropTypes.string,
};

Sidenav.defaultProps = {
  collapsed: false,
  className: "",
};

Brand.propTypes = {
  children: PropTypes.any,
};

Brand.defaultProps = {
  children: [],
};

Links.propTypes = {
  children: PropTypes.any,
};

Links.defaultProps = {
  children: [],
};

Sidenav.Brand = Brand;
Sidenav.Links = Links;

export default Sidenav;
