import * as types from "../types/labels.types";

const initialState = {
  isFetching: false,
  fetched: false,
  errors: {},
  availableLabels: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_LABELS:
      return initialState;
    case types.RESET_LABELS:
      return initialState;
    case types.FETCH_LABELS_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_LABELS_REJECTED:
      return {
        ...state,
        isFetching: false,
        fetched: false,
        errors: { ...action.payload },
      };
    case types.FETCH_LABELS_FULFILLED: {
      const availableLabels = Object.assign([], action.payload.data.labels);

      return {
        ...state,
        isFetching: false,
        fetched: true,
        availableLabels,
      };
    }
    default:
      return state;
  }
}
