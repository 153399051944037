import React, { Component } from "react";
import PropTypes from "prop-types";
import { Element } from "react-scroll";
import { Activity } from "../../../components";

import "./SubmissionActivities.scss";

class SubmissionActivities extends Component {
  constructor(props) {
    super(props);
    this.scrollElement = React.createRef();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    this.scrollElement.scrollTop = this.scrollElement.scrollHeight;
  }

  render() {
    const { submissionActivities } = this.props;

    return (
      <Element name="ActivitiesDiv" className="h-full">
        <div
          id="SubmissionActivities"
          className="pb-8 full-panel"
          ref={(div) => {
            this.scrollElement = div;
          }}
        >
          {submissionActivities.map((activity) => (
            <Activity key={activity.id} timestamp={activity.updatedAt}>
              <p>{activity.data}</p>
            </Activity>
          ))}
        </div>
      </Element>
    );
  }
}

SubmissionActivities.propTypes = {
  submissionActivities: PropTypes.array.isRequired,
};

export default SubmissionActivities;
