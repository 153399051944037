import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    Bulk: "Bulk",
    ID: "ID",
    "First Question": "First Question",
    Date: "Date",
    Thumbnail: "Thumbnail",
    Category: "Category",
    Status: "Status",
    Action: "Action",
    "No submissions found": "No submissions found",
    Search: "Search",
    SearchPlaceholder: "Search in current program ..",
    ExportToExcel: "Export to Excel",
    "Submitter Data": "Submitter Data",
    show_columns: "Show Columns",
    export: "export",
    import: "Import",
    all: "All",
    current_page: "Current page",
    "Select Label": "Select Label",
    Close: "Close",
    Export: "Export",
    Advanced: "Advanced",
    out_of: "out of",
    customize: "Customize",
    to: "to",
    Showing: "Showing",
    status: "Status",
    Labels: "Labels",
    filters: "Filters",
    actions: "../../actions",
    share: "share",
    public_funnel: "Public Funnel",
    search: "Search for ID, Label, Status or Submitted At",
    CreatedAt: "Submitted At",
    Score: "Score",
    "Choose the type of export": "Choose the type of export",
    "Razeen field names": "Razeen field names",
    "Original field names": "Original field names",
    avgScore: "Average Score",
    maxScore: "Max Score",
    "Export to email": "Export to email",
    "Enter email": "Enter email",
    email_warning: "* please use an email that you have access to",
  },
  ar: {
    Bulk: "شحنة",
    ID: "الرقم التعريفي",
    "First Question": "أوّل سؤال",
    Date: "التاريخ",
    Thumbnail: "الصّورة المصغّرة",
    Category: "تصنيف",
    Status: "حالة",
    Action: "إجراء",
    "No submissions found": "لا يوجد مشاركات",
    Search: "بحث",
    SearchPlaceholder: "بحث في البرنامج الحالي ..",
    ExportToExcel: "تصدير إلى ملف إكسل",
    "Submitter Data": "معلومات مقدم المشاركة",
    Score: "الدرجة",
    show_columns: "عرض",
    export: "تصدير",
    import: "Import",
    all: "الكل",
    current_page: "في العرض",
    "Select Label": "اختر وسمًا",
    status: "الحالة",
    Labels: "الوسوم",
    Close: "إغلاق",
    Export: "تصدير",
    Advanced: "بحث متقدّم",
    out_of: "من",
    customize: "تخصيص",
    to: "إلى",
    Showing: "عرض",
    filters: "الفلاتر",
    actions: "الاجراءات",
    share: "مشاركة",
    public_funnel: "مرحلة خارجية",
    search: "بحث عن المعرف أو الوسم أو الحالة أو تاريخ الإنشاء",
    CreatedAt: "تاريخ المشاركة",
    "Choose the type of export": "اختر نوع التصدير",
    "Razeen field names": "أسماء حقول رزين",
    "Original field names": "أسماء الحقول الأصلية",
    avgScore: "متوسط الدرجات",
    maxScore: "أعلى درجة",
    "Export to email": "تصدير إلى البريد الإلكتروني",
    "Enter email": "أدخل البريد الإلكتروني",
    email_warning: "* يرجى استخدام بريد إلكتروني تمتلك الوصول إليه",
  },
});

strings.setLanguage(locale || "en");

const translate = (string) => strings[string] || string;

export default translate;
