export const FETCH_PROGRAM_MEMBERS = "FETCH_PROGRAM_MEMBERS";
export const FETCH_PROGRAM_MEMBERS_FULFILLED =
  "FETCH_PROGRAM_MEMBERS_FULFILLED";
export const FETCH_PROGRAM_MEMBERS_REJECTED = "FETCH_PROGRAM_MEMBERS_REJECTED";

export const DELETE_PROGRAM_MEMBER = "FETCH_PROGRAM_MEMBERS";
export const DELETE_PROGRAM_MEMBER_FULFILLED =
  "FETCH_PROGRAM_MEMBERS_FULFILLED";
export const DELETE_PROGRAM_MEMBER_REJECTED = "FETCH_PROGRAM_MEMBERS_REJECTED";

export const FETCH_PROGRAM_GROUPS = "FETCH_PROGRAM_GROUPS";
export const FETCH_PROGRAM_GROUPS_FULFILLED = "FETCH_PROGRAM_GROUPS_FULFILLED";
export const FETCH_PROGRAM_GROUPS_REJECTED = "FETCH_PROGRAM_GROUPS_REJECTED";

export const CREATE_PROGRAM_MEMBERS_GROUP = "CREATE_PROGRAM_MEMBERS_GROUP";
export const CREATE_PROGRAM_MEMBERS_GROUP_FULFILLED =
  "CREATE_PROGRAM_MEMBERS_GROUP_FULFILLED";
export const CREATE_PROGRAM_MEMBERS_GROUP_REJECTED =
  "CREATE_PROGRAM_MEMBERS_GROUP_REJECTED";

export const RESET_PROGRAM_MEMBERS = "RESET_PROGRAM_MEMBERS";
