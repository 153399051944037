import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    live: "live",
    "Total Submitters": "Total Submitters",
    Submissions: "Submissions",
    "Reviewed Submissions": "Reviewed Submissions",
    "In Progress Submissions": "In Progress Submissions",
    "Filtration Completion": "Filtration Completion",
    Archived: "Archived",
    Created: "Created",
    no_funnels: "You have no funnels created yet.",
  },
  ar: {
    live: "حيّ",
    "Total Submitters": "عدد المشاركين",
    Submissions: "المشاركات",
    "Reviewed Submissions": "تمت مراجعتها",
    "In Progress Submissions": "قيد المراجعة",
    "Filtration Completion": "نسبة اكتمال المراجعة",
    Archived: "مؤرشفة",
    Created: "مضاف",
    no_funnels: "ليس لديك أي مراحل بعد.",
  },
});

const translate = (string) => strings[string];

strings.setLanguage(locale || "en");
export default translate;
