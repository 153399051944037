import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Select from "react-select";
import { humanizeField } from "../../../utils/utils";
import DatePicker from "react-datepicker";
import { Input, Icon } from "../../../components";
import translate from "../texts";

class FilterField extends Component {
  getOperators = () => {
    const {
      filter: {
        field: { type },
      },
      operatorsMap,
    } = this.props;
    return (
      operatorsMap[type] ||
      operatorsMap.string || { placeholder: "", operators: [] }
    );
  };

  formatOperatorLabels = (ops) =>
    ops.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.value]: cur.label,
      }),
      {}
    );

  prepareSavedFilterDate = (date) => {
    if (typeof date === "string") {
      return moment(date);
    }
    return date;
  };

  FilterFieldValue = (
    filter,
    placeholder,
    onFilterChange,
    fetchSubmissions
  ) => {
    let customPlaceholder = null;

    if (filter.operator === "%") {
      customPlaceholder = "reminder,modular";
    }

    if (filter.operator === "_null" || filter.operator === "not_null") {
      return <Input inputName="search" placeholder={"-----"} disabled />;
    }

    if (filter.field.type === "date") {
      return (
        <DatePicker
          className="date-field"
          onChange={(date) => onFilterChange(filter.id, "value", date)}
          displayFormat="YYYY-M-D"
          selected={this.prepareSavedFilterDate(filter.value)}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          popperPlacement="top-end"
          popperClassName="custom-date-picker"
        />
      );
    }
    if (
      filter.field.type === "array" ||
      filter.field.type === "auto_complete"
    ) {
      if (filter.field.category === "questions") {
        const fieldQuestions = this.props.questions.find(
          (question) => question.id === filter.field.id
        );
        return (
          <Select
            name="form-field-name"
            searchable={filter.field.type === "auto_complete"}
            value={filter.value}
            onChange={(e) => onFilterChange(filter.id, "value", e.value)}
            options={fieldQuestions.choices.map((choice) => ({
              value: choice.value,
              label: choice.label,
            }))}
            placeholder={placeholder}
          />
        );
      }
      return (
        <Select
          name="form-field-name"
          searchable={filter.field.type === "auto_complete"}
          value={filter.value}
          onChange={(e) => onFilterChange(filter.id, "value", e.value)}
          options={filter.field.choices.map((choice) => ({
            value: choice.value,
            label: choice.label,
          }))}
          placeholder={placeholder}
        />
      );
    }
    return (
      <Input
        inputName="search"
        placeholder={customPlaceholder || placeholder}
        onChange={(e) => onFilterChange(filter.id, "value", e.target.value)}
        value={filter.value}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            fetchSubmissions();
          }
        }}
      />
    );
  };

  render() {
    const {
      onFilterChange,
      filter,
      fields,
      removeFilter,
      fetchSubmissions,
      isSingleField,
    } = this.props;
    const { placeholder, operators } = this.getOperators();
    const formattedLabels = operators && this.formatOperatorLabels(operators);
    return (
      <div key={filter.id} className="filter-row">
        <div className="inputs-group">
          <div className="filter-field">
            {/* TODO: find a systemic way of declaring select groups (i.e Questions),
                      for now I'll hardcode it since we have 2 groups only. */}
            {/* TODO: Find a systemic way to convert cases between frontend and backend. */}
            <Select
              name="form-field-name"
              searchable={false}
              value={filter.field.id}
              onChange={(e) => onFilterChange(filter.id, "field", e.value)}
              options={fields.map((field) => ({
                label: humanizeField(field.name),
                value: field.id,
                clearableValue: false,
                disabled: field.disabled,
              }))}
              placeholder={translate("Select Field")}
            />
          </div>
          <div className="filter-operator">
            <Select
              name="form-field-name"
              searchable={false}
              value={filter.operator}
              onChange={(e) =>
                onFilterChange(filter.id, "operator", e.value, formattedLabels)
              }
              options={operators.map((operator) => ({
                value: operator.value,
                label: operator.label,
              }))}
              placeholder={translate("operator")}
            />
          </div>
          <div className="filter-value">
            {this.FilterFieldValue(
              filter,
              placeholder,
              onFilterChange,
              fetchSubmissions
            )}
          </div>
        </div>
        <div className="filter-remove">
          <button
            type="button"
            className="close-icon"
            onClick={() => removeFilter(filter.id)}
            disabled={isSingleField}
          >
            <Icon name="close-circle" />
          </button>
        </div>
      </div>
    );
  }
}

FilterField.propTypes = {
  filter: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  fetchSubmissions: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  operatorsMap: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  isSingleField: PropTypes.bool.isRequired,
};

export default FilterField;
