import * as types from "../types/members-management.types";

const initialState = {
  members: [],
  groups: [],
};

export default function membersManagementReducer(state = initialState, action) {
  switch (action.type) {
    case types.RESET_PROGRAM_MEMBERS: {
      return initialState;
    }
    case types.FETCH_PROGRAM_MEMBERS_FULFILLED: {
      const { members } = action.payload.data;
      return { ...state, members };
    }
    case types.FETCH_PROGRAM_MEMBERS_REJECTED: {
      let errorsList = {};
      if (action.payload.data) {
        errorsList = action.payload.data.errors;
      } else {
        errorsList = { errors: ["No data is retrieved from the server"] };
      }
      return { ...state, errors: errorsList };
    }
    case types.FETCH_PROGRAM_GROUPS_FULFILLED: {
      const { groups } = action.payload.data;
      return { ...state, groups };
    }
    case types.FETCH_PROGRAM_GROUPS_REJECTED: {
      const { errors } = action.payload.data;
      return { ...state, errors };
    }
    case types.CREATE_PROGRAM_MEMBERS_GROUP_FULFILLED: {
      const { group } = action.payload.data;
      return { ...state, groups: [...state.groups, group] };
    }
    case types.CREATE_PROGRAM_MEMBERS_GROUP_REJECTED: {
      const { errors } = action.payload.data;
      return { ...state, errors };
    }
    default:
      return state;
  }
}
