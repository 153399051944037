import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Icon } from "../../components";
import * as promptMessageActions from "../../actions/prompt-message.actions";
import * as workspaceActions from "../../actions/workspaces.actions";
import ProgramCard from "./components/ProgramCard";
import translate from "./texts";
import Cookies from "universal-cookie";

const Programs = ({
  setActiveWorkspaceName,
  setShowNewProgramBtn,
  archivedView,
  filter,
  refetch,
}) => {
  const dispatch = useDispatch();
  const { programs, ownerId, name, fetched, isFetching, programsCount } =
    useSelector((state) => state.workspaces);

  const { privileges } = useSelector((state) => state.privileges);
  const { workspaceIndex, workspaceId } = useParams();
  const history = useHistory();

  const onArchive = (programId) => {
    dispatch(workspaceActions.archiveProgram(programId)).then(() =>
      dispatch(
        workspaceActions.fetchPrograms(workspaceId, archivedView, filter)
      )
    );
  };

  const onLeaveHandler = (programId) => {
    const { deactivatePromptMessage } = promptMessageActions;
    if (!(programs.length - 1)) {
      history.push("/");
    }
    dispatch(deactivatePromptMessage());
    dispatch(workspaceActions.leaveProgram(programId)).then(
      dispatch(
        workspaceActions.fetchPrograms(workspaceId, archivedView, filter)
      )
    );
  };

  const onLeave = (programId) => {
    const { activatePromptMessage } = promptMessageActions;

    dispatch(
      activatePromptMessage({
        title: translate("leave_prompt"),
        message: "",
        buttonContent: translate("leave"),
        confirmationHandler: () => onLeaveHandler(programId, workspaceIndex),
        disabledButton: false,
      })
    );
  };

  const onUnArchive = (programId) => {
    dispatch(workspaceActions.unArchiveProgram(programId)).then(() =>
      dispatch(
        workspaceActions.fetchPrograms(workspaceId, archivedView, filter)
      )
    );
  };

  const actions = (program) => [
    {
      component: (
        <button
          key="archive"
          onClick={() => {
            onArchive(program.id);
          }}
          className="dropdown-item is-text program-action flex"
          type="button"
        >
          <Icon name="package-down me-1" />
          {translate("archive")}
        </button>
      ),
      privilege: "programs.archive",
    },
    {
      component: (
        <button
          key="archive"
          onClick={() => {
            onLeave(program.id);
          }}
          className="dropdown-item is-text program-action flex text-red-600"
          type="button"
        >
          <Icon name="location-exit" className="text-red-600 me-1" />
          {translate("leave")}
        </button>
      ),
      privilege: "programs.leave",
    },
  ];

  const isActionAuthorized = (programId, action) =>
    privileges[programId] && privileges[programId].includes(action.privilege);

  useEffect(() => {
    if (refetch) {
      dispatch(
        workspaceActions.fetchPrograms(workspaceId, archivedView, filter)
      );
    }
  }, [dispatch, archivedView, filter, refetch]);
  const cookies = new Cookies();

  // get userData from local storage
  const userData = cookies?.get("user");

  useEffect(() => {
    setActiveWorkspaceName(name);
    setShowNewProgramBtn(ownerId === userData?.id);
  }, [fetched]);

  return (
    <div>
      {programsCount !== 0 && (
        <div className="workspace-programs">
          {programs.map((program) => (
            <div
              key={program.id}
              id={`program_${program.id}`}
              className="program"
            >
              <ProgramCard
                programName={program.name}
                actions={actions(program)}
                submissionsCount={program.submissionCount}
                onClick={() =>
                  history.push(`/dashboard/programs/${program.id}/overview`)
                }
                logo={program.logo}
                archived={program.archived}
                programId={program.id}
                backgroundColor={program.backgroundColor}
                onArchive={onArchive}
                onUnArchive={() => {
                  onUnArchive(program.id);
                }}
                isActionAuthorized={isActionAuthorized}
              />
            </div>
          ))}
        </div>
      )}
      {programsCount === 0 && fetched && !isFetching && (
        <p className="empty-state">
          {translate("no_programs_found")}
          <span role="img" aria-label="face with head-bandage">
            {" "}
            🤕
          </span>
        </p>
      )}
    </div>
  );
};

Programs.propTypes = {
  setActiveWorkspaceName: PropTypes.func.isRequired,
  setShowNewProgramBtn: PropTypes.func.isRequired,
  archivedView: PropTypes.bool.isRequired,
  filter: PropTypes.string,
  refetch: PropTypes.bool.isRequired,
};

Programs.defaultProps = {
  filter: "",
};

export default Programs;
