import React from "react";
import PropTypes from "prop-types";
import "./Checkbox.scss";

const Checkbox = ({
  id,
  name,
  value,
  wide,
  label,
  checked,
  onChange,
  loading,
  className,
  hideIcon,
}) => (
  <div className={`checkbox ${wide ? "wide" : ""} ${className}`}>
    <label htmlFor={id}>
      <input
        id={id}
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      {loading ? (
        <svg width="16px" height="16px" viewBox="0 0 20 20">
          <circle cx="10" cy="10" r="9" />
          <path
            d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
            className="outer"
          />
        </svg>
      ) : (
        <div hidden={hideIcon || false} className="checkmark" />
      )}
      {label}
    </label>
  </div>
);

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  wide: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string,
  hideIcon: PropTypes.bool,
};

Checkbox.defaultProps = {
  wide: false,
  checked: false,
  loading: false,
  className: "",
  hideIcon: false,
};

export default Checkbox;
