import React from "react";
import PropTypes from "prop-types";
import { Button, SubmissionLabel, SubmissionStatus } from "../../../components";
import Permit from "../../../layouts/Authorization";

import translate from "../texts";
import { useSelector } from "react-redux";
import HistoryMenu from "./HistoryMenu";

const Controls = ({
  anySubmissionsSelected,
  programId,
  isFetching,
  isDeleting,
  label,
  availableLabels,
  closeTable,
  addLabel,
  deleteLabel,
  deleteSubmission,
  removeLabelFromSubmission,
  status,
  addStatus,
  toggleEmptyFields,
  showEmptyFields,
  isView,
  selectedSubmissionsIds,
}) => {
  const submission = useSelector((state) => state.submissions.submission);

  return (
    <div className="submission-info-ctrl" key={label}>
      <Button
        iconName="close"
        tiny
        flatWhite
        className="close-icon"
        onClick={closeTable}
      />
      {/* TODO: This should be separated in another component */}
      <div className="single-submission-header flex-wrap">
        {!anySubmissionsSelected && submission.hasHistory && (
          <HistoryMenu submission={submission} />
        )}

        {!anySubmissionsSelected && isView && (
          <>
            <Button
              iconName={showEmptyFields ? "eye-off" : "eye"}
              small
              flatWhite
              content={
                showEmptyFields
                  ? translate("hide_empty_fields")
                  : translate("show_empty_fields")
              }
              onClick={() => toggleEmptyFields()}
              color={showEmptyFields ? "#268aff" : "#9ca4af"}
            />
          </>
        )}
        <>
          <Permit targetPrivilege="submissions.delete" targetId={programId}>
            <Button
              iconName="delete"
              small
              flatWhite
              loading={isDeleting}
              content={translate("Delete")}
              onClick={() => deleteSubmission()}
            />
          </Permit>
          <Permit
            targetPrivilege="submissions.labels.update"
            targetId={programId}
          >
            <SubmissionLabel
              programId={programId}
              isFetching={isFetching}
              availableLabels={availableLabels}
              addLabel={addLabel}
              deleteLabel={deleteLabel}
              submissionLabel={label}
              removeLabelFromSubmission={removeLabelFromSubmission}
              creatable
              selectedSubmissionsIds={selectedSubmissionsIds}
            />
          </Permit>
          <Permit
            targetPrivilege="submissions.status_tracking"
            targetId={programId}
          >
            <SubmissionStatus
              programId={programId}
              addStatus={addStatus}
              submissionStatus={status}
              isFetching={isFetching}
              creatable
            />
          </Permit>
        </>
      </div>
    </div>
  );
};

Controls.propTypes = {
  anySubmissionsSelected: PropTypes.bool.isRequired,
  programId: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  label: PropTypes.string,
  availableLabels: PropTypes.array.isRequired,
  closeTable: PropTypes.func.isRequired,
  addLabel: PropTypes.func.isRequired,
  deleteLabel: PropTypes.func,
  removeLabelFromSubmission: PropTypes.func.isRequired,
  status: PropTypes.string,
  addStatus: PropTypes.func.isRequired,
  deleteSubmission: PropTypes.func.isRequired,
  toggleEmptyFields: PropTypes.func.isRequired,
  showEmptyFields: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  isView: PropTypes.bool.isRequired,
};

Controls.defaultProps = {
  label: "",
  deleteLabel: () => {},
  status: "",
};

export default Controls;
