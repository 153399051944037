import axios from "axios";
import * as types from "../types/submissions.types";

export function fetchSubmissions(
  programId,
  page = 1,
  sortBy = "",
  direction = "",
  query = "",
  queryFieldId = "",
  filters = [],
  funnelId = "",
  filterOperator = ""
) {
  return {
    type: types.FETCH_SUBMISSIONS,
    payload: axios.get(`
      ${
        process.env.REACT_APP_API_URL
      }/programs/${programId}/submissions/?page=${page}&sortBy=${encodeURIComponent(
      sortBy
    )}&direction=${direction}&query=${encodeURIComponent(
      query.trim()
    )}&fieldId=${encodeURIComponent(queryFieldId)}&filters=${encodeURIComponent(
      JSON.stringify(filters)
    )}&filterOperator=${filterOperator}&funnelId=${funnelId}`),
  };
}

// Pass programId and funnelId if the current user can be a filterer.
export function fetchSubmission(id, funnelId = "") {
  return {
    type: types.FETCH_SUBMISSION,
    payload: axios.get(
      `${process.env.REACT_APP_API_URL}/submissions/${id}?funnelId=${funnelId}`
    ),
  };
}

export function updateSubmissions(id, funnelId = "") {
  return {
    type: types.UPDATE_SUBMISSIONS,
    payload: axios.get(
      `${process.env.REACT_APP_API_URL}/submissions/${id}?funnelId=${funnelId}`
    ),
  };
}

export function deleteSubmissions(ids, funnelId, filters = [], query) {
  return {
    type: types.DELETE_SUBMISSION,
    payload: axios.delete(`${process.env.REACT_APP_API_URL}/submissions/`, {
      data: {
        ids,
        funnel_id: funnelId,
        filters,
        query,
      },
    }),
  };
}

export function setSortConfig(sortBy, direction) {
  return {
    type: types.SET_SORT_CONFIG,
    sortBy,
    direction,
  };
}

export function setFilterConfig(groups) {
  return {
    type: types.SET_SORT_CONFIG,
    groups,
  };
}

export function fetchSubmitters(programId, page = 1, query = "") {
  return {
    type: types.FETCH_SUBMISSIONS,
    payload: axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/submissions/${programId}/?page=${page}&submitters=true&query=${encodeURIComponent(
        query
      )}`
    ),
  };
}

export function resetSubmissions() {
  return {
    type: types.RESET_SUBMISSIONS,
  };
}

export function checkLabel({
  label,
  funnelId,
  filters = [],
  query = "",
  ids = [],
}) {
  return {
    type: types.CHECK_LABEL,
    payload: axios.post(
      `${process.env.REACT_APP_API_URL}/submissions/check_label`,
      {
        label_key: label,
        ids,
        funnel_id: funnelId,
        filters,
        query,
      }
    ),
  };
}

export function checkRemoveLabel({
  label,
  funnelId,
  filters = [],
  query = "",
  ids = [],
}) {
  return {
    type: types.CHECK_REMOVE_LABEL,
    payload: axios.post(
      `${process.env.REACT_APP_API_URL}/submissions/check_remove_label`,
      {
        label_key: label,
        ids,
        funnel_id: funnelId,
        filters,
        query,
      }
    ),
  };
}

export function addLabel(
  submissionsIds,
  label,
  funnelId,
  filters = [],
  query = ""
) {
  return {
    type: types.ADD_LABEL,
    payload: axios.post(`${process.env.REACT_APP_API_URL}/submissions/label`, {
      label_key: label,
      ids: submissionsIds,
      funnel_id: funnelId,
      filters,
      query,
    }),
    meta: {
      submissionsIds,
    },
  };
}

export function addStatus(
  submissionsIds,
  status,
  funnelId,
  filters = [],
  query = ""
) {
  return {
    type: types.ADD_STATUS,
    payload: axios.post(
      `${process.env.REACT_APP_API_URL}/submissions/status_tracking`,
      {
        status_tracking: status.status,
        notes: status.notes,
        ids: submissionsIds,
        funnel_id: funnelId,
        filters,
        query,
      }
    ),
    meta: {
      submissionsIds,
    },
  };
}

export function removeLabelFromSubmission(
  submissionsIds,
  label,
  funnelId,
  filters = [],
  query = ""
) {
  return {
    type: types.REMOVE_SUBMISSION_LABEL,
    payload: axios.post(
      `${process.env.REACT_APP_API_URL}/submissions/remove_label`,
      {
        label_key: label,
        ids: submissionsIds,
        funnel_id: funnelId,
        filters,
        query,
      }
    ),
    meta: {
      submissionsIds,
    },
  };
}

export function addComment(submissionId, comment, funnelId) {
  return {
    type: types.ADD_COMMENT,
    payload: axios.post(
      `${process.env.REACT_APP_API_URL}/submissions/${submissionId}/comment`,
      { comment, funnelId }
    ),
  };
}

export function deleteComment(submissionId, id) {
  return {
    type: types.DELETE_COMMENT,
    payload: axios.delete(
      `${process.env.REACT_APP_API_URL}/submission_activities/${id}/delete_comment`
    ),
  };
}

export function fetchSubmissionActivities(submissionId) {
  return {
    type: types.FETCH_SUBMISSION_ACTIVITIES,
    payload: axios.get(
      `${process.env.REACT_APP_API_URL}/submissions/${submissionId}/activities`
    ),
  };
}

export function addEvaluations(submissionId, evaluations, funnelId) {
  return {
    type: types.ADD_EVALUATIONS,
    payload: axios.post(
      `${process.env.REACT_APP_API_URL}/submissions/${submissionId}/evaluate`,
      { evaluations, funnelId }
    ),
  };
}

export function clearEvaluation(submissionId, funnelId) {
  return {
    type: types.CLEAR_EVALUATION,
    payload: axios.put(
      `${process.env.REACT_APP_API_URL}/submissions/${submissionId}/clear_evaluation`,
      { funnelId }
    ),
  };
}

export function openFirstSubmission(firstSubmissionIdFromCurrentList) {
  return {
    type: types.OPEN_FIRST_SUBMISSION,
    payload: firstSubmissionIdFromCurrentList,
  };
}

export function firstSubmissionDidOpen() {
  return {
    type: types.FIRST_SUBMISSION_DID_OPEN,
  };
}

export function setFetchSubmissionsHandler(func) {
  return {
    type: types.SET_FETCH_SUBMISSIONS_HANDLER,
    fetchHandler: func,
  };
}

// TODO: Separate filter actions to filters.actions.js file.
export function fetchOperators() {
  return {
    type: types.FETCH_OPERATORS,
    payload: axios.get(`${process.env.REACT_APP_API_URL}/filters/operators`),
  };
}

export function fetchFilters(programId) {
  return {
    type: types.FETCH_FILTERS,
    payload: axios.get(
      `${process.env.REACT_APP_API_URL}/programs/${programId}/filters`
    ),
  };
}

export function saveFilter(
  programId,
  groups,
  name,
  triggerActions = [],
  funnelId
) {
  return {
    type: types.SAVE_FILTER,
    payload: axios.post(
      `${process.env.REACT_APP_API_URL}/programs/${programId}/filters`,
      {
        groups,
        name,
        filter_actions: triggerActions,
        funnelId,
      }
    ),
  };
}

export function deleteFilter(programId, filterId) {
  return {
    type: types.DELETE_FILTER,
    payload: axios.delete(
      `${process.env.REACT_APP_API_URL}/programs/${programId}/filters/${filterId}`
    ),
    meta: {
      filterId,
    },
  };
}
export function fetchFilterActions() {
  return {
    type: types.FETCH_FILTER_ACTION_TYPES,
    payload: axios.get(`${process.env.REACT_APP_API_URL}/filter_actions/types`),
  };
}

// fetch submission statuses
export function fetchSubmissionStatuses() {
  return {
    type: types.SUBMISSION_STATUSES,
    payload: axios.get(`${process.env.REACT_APP_API_URL}/external_statuses`),
  };
}

export function fetchAllSubmissionStatuses() {
  return {
    type: types.ALL_SUBMISSION_STATUSES,
    payload: axios.get(
      `${process.env.REACT_APP_API_URL}/external_statuses/get_all`
    ),
  };
}

export function fetchSubmissionHistory(id) {
  return {
    type: types.FETCH_SUBMISSION_HISTORY,
    payload: axios.get(
      `${process.env.REACT_APP_API_URL}/submissions/${id}/history`
    ),
  };
}
