import * as types from "../types/funnels.types";

const initialState = {
  isFetching: false,
  error: null,
  fetched: false,
  errors: [],
  funnelSettings: {
    id: "",
    name: "",
    inputSources: [],
    submissionCount: 0,
    questions: [],
    hiddenColumns: [],
    fieldsOrder: {},
    assignedUsers: [],
    programFieldNames: [],
    operationBetweenInputSources: "$and",
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_FUNNEL_SETTINGS_FULFILLED: {
      return {
        ...state,
        funnelSettings: action.payload.data,
        isFetching: false,
        fetched: true,
      };
    }

    case types.FETCH_FUNNEL_SETTINGS_PENDING:
      return {
        ...state,
        isFetching: true,
      };

    case types.FETCH_FUNNEL_SETTINGS_REJECTED:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.response.data.errors,
      };

    case types.RESET_FUNNEL_STATE:
      return initialState;

    case types.CREATE_FUNNEL_FULFILLED:
    case types.CREATE_FUNNEL_PENDING: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case types.CREATE_FUNNEL_REJECTED: {
      return {
        ...state,
        isFetching: false,
        error: action.payload.response?.data?.errors[0]
          ? action.payload.response?.data?.errors[0]
          : null,
      };
    }

    case types.SAVE_FUNNEL_PENDING: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case types.SAVE_FUNNEL_REJECTED: {
      return {
        ...state,
        isFetching: false,
        error: action.payload.response?.data?.errors[0]
          ? action.payload.response?.data?.errors[0]
          : null,
      };
    }
    case types.SAVE_FUNNEL_FULFILLED: {
      const { data } = action.payload;

      return {
        ...state,
        fetched: true,
        funnelSettings: data,
      };
    }

    default:
      return state;
  }
}
