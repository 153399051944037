import LocalizedStrings from "react-localization";
import validations from "../validation-messages";

export const strings = new LocalizedStrings({
  en: {
    "Max size": "Max size in Megabyte: ",
    "You maximized the size": "You maximized the size limitation in Megabyte: ",
    maxSizeExceeded: "File size exceeded the maximum allowed size. Max. {0} MB",
    unsupportedFileType: "File format is not supported.",
    Megabytes: "Megabytes",
    FileUploadCaption:
      "Drop a file here, or click to select a file to upload. Max. {0} MB",
    required: "This field is required",
    DropHereOr: "Drop files here or",
    Browse: "browse",
    requiredLength: "The required length is {0} number",
    requiredRange: "The length should between {0} and {1}",
    validateAllQuestions:
      "Please make sure you have filled all questions correctly",
    ...validations.en,
  },
  ar: {
    "Max size": "الحد الأعلى لحجم الملف بالميجابايت: ",
    "You maximized the size": "لقد تجاوزت الحدّ المسموح به للملف بالميجابايت: ",
    maxSizeExceeded: "حجم الملف يتجاوز الحد المسموح. لغاية {0} ميجابايت",
    unsupportedFileType: "صيغة الملف غير مدعومة.",
    Megabytes: "ميجابايت",
    FileUploadCaption:
      "اسحب ملفاً هنا، أو انقر لاختيار الملف الذي تريد تحميله. لغاية {0} ميجابايت",
    required: "هذا الحقل مطلوب",
    DropHereOr: "اسحب ملفاً هنا أو",
    Browse: "اختر الملف الذي تريد تحميله",
    requiredLength: "يجب ان يكون الرقم مكون من {0} ارقام",
    requiredRange: "عدد الارقام يجب ان يكون بين {0} و {1}",
    validateAllQuestions: "يرجى التأكد من ملء جميع الأسئلة بشكل صحيح",
    ...validations.ar,
  },
});
// To prevent duplication of files content, this function is implemented
function getLanguage() {
  if (global.formSettings) {
    return global.formSettings.rtlDirectionEnabled ? "ar" : "en";
  }
  return "en";
}
strings.setLanguage(getLanguage());

const translate = (string, format) => {
  if (format) {
    return strings.formatString(strings[string], ...format);
  }
  return strings[string];
};

export default translate;
