import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    submissions: " submissions",
    submission: " submission",
    submitters: "submitters: ",
    unarchive: "Unarchive",
  },
  ar: {
    submissions: " مشاركة ",
    submission: "مشاركة واحدة",
    submitters: "المشاركين: ",
    unarchive: "إزالة  من الأرشيف",
  },
});

strings.setLanguage(locale || "en");

const translate = (string) => strings[string];

export default translate;
