import React from "react";
import PropTypes from "prop-types";
import "./Filter.scss";

/*
use this component as follows:
1- by default it have a white bg.
2- you can give it "inactive" attr and it'll change the bg color to light-grey
3- you can give add the icons and rest of nested components as children inside the Filter tag.
*/
const Filter = ({ inactive, content, className, children, onClick }) => (
  <span
    className={`tag filter ${
      inactive ? "inactive-filter" : "active-filter"
    } ${className}`}
    onClick={onClick}
    onKeyUp={onClick}
    role="presentation"
  >
    <span className="filter-content">{content}</span>
    {children}
  </span>
);

Filter.propTypes = {
  inactive: PropTypes.bool,
  content: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onClick: PropTypes.func,
  className: PropTypes.string,
};

Filter.defaultProps = {
  inactive: false,
  children: <div />,
  content: "",
  onClick: () => {},
  className: "",
};

export default Filter;
