import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    "Team members invitations": "Team members invitations",
    "Invite team members to your workspace":
      "Invite team members to your workspace",
    "Enter email address": "Enter email address",
    "Current Members": "Current Members",
    Role: "Role",
    Invite: "Invite",
    Cancel: "Cancel",
    Email: "Email",
    Select: "Select",
    manage_users: "Manage Members",
    Members: "Members",
    Inviting: "Inviting...",
    The_user_already_exist: "This user already invited",
    self_invetation: "You aren't allowed to invite yourself ",
    invalid_email_format: "Invalid email format",

    contest_manager: "Contest Manager",
    program_manager: "Program Manager",
    filterer: "Filterer",
    auditor: "Auditor",
    "Contest Manager": "Contest Manager",
    "Program Manager": "Program Manager",
    Filterer: "Filterer",
    Auditor: "Auditor",
    Judge: "Judge",
    senior_judge: "Senior Judge",
    custom_role: "Custom Role",
    "Custom Role": "Custom Role",
    data_tooltip_content:
      "Supported formats for bulk invitations are: comma-seperated, tab-seperated, space-seperated followed by Enter.",
  },
  ar: {
    "Team members invitations": "دعوة أعضاء الفريق",
    "Invite team members to your workspace":
      "قم بدعوة أعضاء الفريق لمساحة العمل الخاص بك",
    "Enter email address": "أدخل العنوان البريدي",
    "Current Members": "أعضاء حاليين",
    Role: "الدور",
    Invite: "دعوة",
    Cancel: "إلغاء",
    Email: "البريد الإلكتروني",
    Select: "اختر",
    manage_users: "إدارة الأعضاء",
    Members: "أعضاء",
    Inviting: "جاري الدعوة ...",
    The_user_already_exist: "تم إرسال دعوة لهذا المستخدم من قبل",
    self_invetation: "غير مسموح لك بدعوة نفسك",
    invalid_email_format: "الرجاء التأكد من صحة البريد الإلكتروني المدخل",

    contest_manager: "مدير مسابقة",
    program_manager: "مدير برنامج",
    filterer: "فارز",
    auditor: "مدقق",
    "Contest Manager": "مدير مسابقة",
    "Program Manager": "مدير برنامج",
    Filterer: "فارز",
    Auditor: "مدقق",
    custom_role: "دور خاص",
    "Custom Role": "دور خاص",
    Judge: "محكم",
    senior_judge: "محكم أول",   
    data_tooltip_content:
      "  يمكنك إضافة مجموعة من  عناوين البريد، يفصل بين كل عنوان و آخر فاصلة أو مسافة متبوعة ب Enter.",
  },
});

strings.setLanguage(locale || "en");

const translate = (string) => strings[string];

export default translate;
