import React from "react";
import BaseOptions from "./BaseOptions";

export default class EmailFieldOptions extends BaseOptions {
  render() {
    return (
      <>
        {this.renderTitle()}
        {this.renderPrimaryEmailOption()}
        {this.renderRequiredOption()}
        {this.renderVisibilityOption()}
      </>
    );
  }
}
