/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import UserAssignments from "./UserAssignments";

class UserAssignmentsContainer extends PureComponent {
  // This function will handle local state changes and then will call onChange callback
  handleFieldChange = (value) => {
    const selectedUsers = this.props.value;
    const index = selectedUsers.indexOf(value);
    const isMultiple = Array.isArray(value);

    if (!isMultiple && index > -1) {
      selectedUsers.splice(index, 1);
    } else {
      if (Array.isArray(value)) {
        selectedUsers.push(...value);
      }
      selectedUsers.push(...[isMultiple ? [...value] : value]);
    }

    this.props.onChange({ target: { value: null } }, selectedUsers);
  };

  render() {
    return (
      <UserAssignments
        users={this.props.users}
        selectedUsers={this.props.value}
        groups={this.props.groups}
        handleFieldChange={this.handleFieldChange}
        programId={this.props.programId}
      />
    );
  }
}

UserAssignmentsContainer.propTypes = {
  users: PropTypes.array,
  groups: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
};

UserAssignmentsContainer.defaultProps = {
  users: [],
  groups: [],
  value: [],
};

export default UserAssignmentsContainer;
