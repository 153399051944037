import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import * as submissionsActions from "../../actions/submissions.actions";
import Header from "./Header/Header";
import FieldsList from "./FieldsList/FieldsList";
import SaveFilter from "./SaveFilter/SaveFilter";

import "./AdvancedSearch.scss";

class AdvancedSearchContainer extends Component {
  state = {
    newFilterCollapsed: true,
  };

  wrapperRef = React.createRef();

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  toggleNewFilter = () => {
    const { newFilterCollapsed } = this.state;

    this.setState({
      newFilterCollapsed: !newFilterCollapsed,
      newFilterName: "",
    });
  };

  handleClickOutside = (event) => {
    const targetClasses = event.target.className.split(" ");

    if (
      this.wrapperRef &&
      !this.wrapperRef.current.contains(event.target) &&
      !targetClasses.includes("Select-option") &&
      !targetClasses.includes("mdi-chevron-down")
    ) {
      this.props.closeSearchControls();
    }
  };

  render() {
    const {
      operators,
      questions,
      closeSearchControls,
      filtrationFields,
      searchForSubmissions,
      updateSelectedFilters,
      userSelectedFilters,
      programId,
      actions,
      setAdvanceSearchState,
      funnelId,
      filterActions,
      setFilterOperator,
      filterOperatorValue,
    } = this.props;

    const { newFilterName, newFilterCollapsed } = this.state;

    return (
      <div className="advanced-search-container" ref={this.wrapperRef}>
        <Header closeSearchControls={closeSearchControls} />
        <FieldsList
          closeSearchControls={closeSearchControls}
          filtrationFields={filtrationFields}
          newFilterCollapsed={newFilterCollapsed}
          operators={operators}
          questions={questions}
          searchForSubmissions={searchForSubmissions}
          setAdvanceSearchState={setAdvanceSearchState}
          toggleNewFilter={this.toggleNewFilter}
          updateSelectedFilters={updateSelectedFilters}
          userSelectedFilters={userSelectedFilters}
          setFilterOperator={setFilterOperator}
          filterOperatorValue={filterOperatorValue}
        />
        <SaveFilter
          actions={actions}
          closeSearchControls={closeSearchControls}
          newFilterCollapsed={newFilterCollapsed}
          newFilterName={newFilterName}
          programId={programId}
          toggleNewFilter={this.toggleNewFilter}
          userSelectedFilters={userSelectedFilters}
          funnelId={funnelId}
          filterActions={filterActions}
        />
      </div>
    );
  }
}

AdvancedSearchContainer.propTypes = {
  actions: PropTypes.object,
  closeSearchControls: PropTypes.func.isRequired,
  filtrationFields: PropTypes.array.isRequired,
  operators: PropTypes.object.isRequired,
  programId: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  searchForSubmissions: PropTypes.func.isRequired,
  setAdvanceSearchState: PropTypes.func.isRequired,
  updateSelectedFilters: PropTypes.func.isRequired,
  userSelectedFilters: PropTypes.object.isRequired,
  funnelId: PropTypes.string.isRequired,
  filterActions: PropTypes.array.isRequired,
};

AdvancedSearchContainer.defaultProps = {
  actions: {},
};

const mapStateToProps = (state) => {
  const filtersState = state.filters;
  const submissionsState = state.submissions;
  const programState = state.program;

  return {
    filters: filtersState.filters,
    operators: filtersState.operators,
    filtrationFields: submissionsState.filtrationFields,
    programId: programState.program.id,
    questions: programState.program.questions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...submissionsActions }, dispatch),
});

const container = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedSearchContainer);

export default container;
