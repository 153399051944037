const strings = {
  en: {
    "The value should not contain numbers and special characters":
      "The value should not contain numbers and special characters",
    "The value should be a valid phone number":
      "The value should be a valid phone number",
    "Please upload file": "Please upload file",
    "The value should be a valid email address":
      "The value should be a valid email address",
  },
  ar: {
    "The value should not contain numbers and special characters":
      "الاسم غير صحيح",
    "Please upload file": "من فضلك قم برفع الملف",
    "The value should be a valid email address": "البريد الإلكتروني غير صحيح",
    "The value should be a valid phone number": "رقم الهاتف غير صحيح",
  },
};

export default strings;
