import axios from "axios";
import * as types from "../types/labels.types";

export function fetchLabels(programId) {
  return {
    type: types.FETCH_LABELS,
    payload: axios.get(
      `${process.env.REACT_APP_API_URL}/labels?program_id=${programId}`
    ),
  };
}

export function deleteLabelFromProgram(programId, label) {
  return {
    type: types.FETCH_LABELS,
    payload: axios.delete(
      `${process.env.REACT_APP_API_URL}/labels/${label}?program_id=${programId}&label_key=${label}`
    ),
  };
}
