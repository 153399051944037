import React from "react";
import PropTypes from "prop-types";
import { Modal, Box, FooterActions } from "../../components";
import "./ModalActions.scss";

const ModalActions = ({ modalActions, active, toggle, children }) => (
  <div className="modal-actions-container">
    <Modal active={active} toggle={toggle}>
      <Box>
        {children}
        <div className="modal-actions-controls">
          <FooterActions actions={modalActions} />
        </div>
      </Box>
    </Modal>
  </div>
);

ModalActions.propTypes = {
  active: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  modalActions: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

ModalActions.defaultProps = {
  modalActions: [],
  children: null,
};

export default ModalActions;
