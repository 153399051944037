import React from "react";
import ReactSelect from "react-select";
import "react-select/dist/react-select.css";
import { isRTL } from "../../utils/utils";
import "./Select.scss";

const Select = (props) => (
  <ReactSelect
    name="form-field-name"
    searchable={false}
    rtl={isRTL()}
    value=""
    {...props}
  />
);

export default Select;
