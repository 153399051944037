import { uniqueId } from "lodash";
import React, { useState } from "react";
import Select from "react-select";

function FormSelect({
  register,
  options,
  label = "",
  placeholder,
  defaultValue,
}) {
  const hasLabel = label.length > 0 ? uniqueId() : undefined;

  const [value, setValue] = useState(
    () => options.find((o) => o.value === defaultValue) || null
  );

  return (
    <div className="input-component control">
      {hasLabel ? <label htmlFor={hasLabel}>{label}</label> : null}
      <Select
        id={hasLabel}
        className="w-full"
        options={options}
        onChange={(value) => {
          setValue(value);
          register.onChange({
            target: { value: value.value, name: register.name },
            type: "change",
          });
        }}
        onBlur={(e) => {
          register.onBlur(e);
        }}
        value={value}
        placeholder={placeholder}
        searchable={false}
      />
    </div>
  );
}

export default FormSelect;
