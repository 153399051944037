import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as authActions from "../../actions/auth.actions";

/*
Wrapper to allow for display/hide components based on user privileges.

Props:
1- targetPrivilege: a privilege that user should have
   (single privilage string or an array of multiple strings).
2- targetId: Program/Workspace ID to check if user have any privileges there!
3- targetComponent: the component to show when user is permitted and
   vice versa (also you can pass it as child of Permit).
4- fixedPrivileges (Optional, added for test proposes and just in case!): list
   of privilege Strings to check it instead of Redux store, so you don't need to
   use the state nor providing targetId to the component then.
5- matchAll is used when targetPrivilege is an array to determine if you
   want to match all privilages or just one of them.

PLEASE use it as follows:

```

import Permit from 'Authorization';

<Permit targetPrivilege="submissions.edit" targetId="* program/workspace ID here *"
targetComponent={<button>Edit</button>} />

// OR

<Permit targetPrivilege="submissions.edit" targetId="* program/workspace ID here *">
  <button>Edit</button>
</Permit>

```

so, this component will display the Edit <button> if the user allowed to edit submissions!.
*/

function verifyPrivilages(availablePrivilages, targetPrivilege, matchAll) {
  if (typeof targetPrivilege === "string") {
    return availablePrivilages.includes(targetPrivilege);
  }
  if (Array.isArray(targetPrivilege)) {
    if (matchAll) {
      targetPrivilege.every(
        (privilege) => availablePrivilages.indexOf(privilege) >= 0
      );
    } else {
      return targetPrivilege.some(
        (privilege) => availablePrivilages.indexOf(privilege) >= 0
      );
    }
  }

  return false;
}

const AuthWrapper = (props) => {
  const privileges = props.privileges[props.targetId] || [];
  const fixedPrivileges = props.fixedPrivileges || [];
  const availablePrivilages = [...privileges, ...fixedPrivileges];

  if (
    verifyPrivilages(availablePrivilages, props.targetPrivilege, props.matchAll)
  ) {
    return props.targetComponent || props.children;
  }

  return null;
};

AuthWrapper.propTypes = {
  privileges: PropTypes.object,
  targetPrivilege: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  targetId: PropTypes.any.isRequired,
  targetComponent: PropTypes.any,
  fixedPrivileges: PropTypes.array,
  matchAll: PropTypes.bool,
};

AuthWrapper.defaultProps = {
  privileges: {},
  fixedPrivileges: [],
  targetComponent: null,
  matchAll: true,
};

const mapStateToProps = (state) => {
  const privilegesState = state.privileges;
  return { privileges: privilegesState.privileges };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(authActions, dispatch),
});

const PermitProduction = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthWrapper);

export { PermitProduction };
export { AuthWrapper as PermitTest };
