import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    Save: "Save",
    "saving ...": "saving ...",
    "Program name": "Program name",
    Name: "Name",
    all_programs: "All programs",
    overview: "Overview",
    submissions: "Submissions",
    fields: "Fields",
    formBuilder: "Form",
    Notifications: "Notifications",
    "All programs": "All programs",
    Reports: "Reports",
    "All submissions": "All submissions",
    update_program_name_success_msg: "Program name updated successfully",
    update_program_data_success_msg: "Program data updated successfully",
    "Edit Program": "Edit Program",
    "Program Name": "Program Name",
    "Integration Settings": "Integration Settings",
    "Allow program": "Allow program",
    "Allow funnel": "Allow funnel",
    "Allow submission": "Allow submission",
    "External Integration URL": "External Integration URL",
    "Allow submission update": "Allow submission update",
    "Allow submission delete": "Allow submission delete",
    "Filter By": "Filter By",
    operator: "operator",
    value: "value",
    "Enabled Fields": "Enabled Fields",
    submit: "Submit",
    requiredField: "This field is required",

    "AI Analysis Config": "AI Analysis Config",
    "Exact Match Check": "Exact Match Check",
    "choose fields": "choose fields",
    "Duplication Types": "Duplication Types",
    "add group": "add group",
    "remove group": "remove group",
    "Image Quality Check": "Image Quality Check",
    "Video Analysis": "Video Analysis",
    "Image Black Screen Check": "Image Black Screen Check",

    "Submission Unique Identifier": "Submission Unique Identifier",
    "Unique Identifier": "Unique Identifier",
    None: "None",
  },
  ar: {
    Save: "Save Arabic",
    "saving ...": "saving ... Arabic",
    "Program name": "اسم البرنامج",
    Name: "الاسم",
    all_programs: "جميع البرامج",
    overview: "نظرة عامة",
    submissions: "المشاركات",
    fields: "الحقول",
    formBuilder: "نموذج المشاركة",
    Notifications: "الاشعارات",
    "All programs": "جميع البرامج",
    Reports: "التقارير",
    "All submissions": "جميع المشاركات",
    update_program_name_success_msg: "تم تعديل اسم البرنامج بنجاح",
    update_program_data_success_msg: "تم تعديل بيانات البرنامج بنجاح",
    "Edit Program": "تعديل البرنامج",
    "Program Name": "اسم البرنامج",
    "Integration Settings": "اعدادات الاندماج",
    "Allow program": "السماح بالبرنامج",
    "Allow funnel": "السماح بالمرحلة",
    "Allow submission": "السماح بالمشاركة",
    "External Integration URL": "رابط الاندماج الخارجي",
    "Allow submission update": "السماح بتعديل المشاركة",
    "Allow submission delete": "السماح بحذف المشاركة",
    "Filter By": "تصفية بواسطة",
    operator: "المعامل",
    value: "القيمة",
    "Enabled Fields": "الحقول المفعلة",
    submit: "تقديم",
    requiredField: "هذا الحقل مطلوب",

    "AI Analysis Config": "إعدادات تحليل الذكاء الاصطناعي",
    "Exact Match Check": "التحقق من التطابق المتماثل",
    "choose fields": "اختر الحقول",
    "Duplication Types": "أنواع التكرار",
    "add group": "اضافة مجموعة",
    "remove group": "حذف مجموعة",
    "Image Quality Check": "فحص جودة الصورة",
    "Video Analysis": "تحليل الفيديو",
    "Image Black Screen Check": "فحص الصورة السوداء",

    "Submission Unique Identifier": "المعرف الفريد للمشاركة",
    "Unique Identifier": "المعرف الفريد",
    None: "لا يوجد معرف فريد",
  },
});

const translate = (string) => strings[string] || string;

strings.setLanguage(locale || "en");
export default translate;
