import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Element } from "react-scroll";
import { Button, Comment, Input } from "../../../components";
import TextLength from "../../../utils/settings/text-length";
import translate from "../texts";
import Cookies from "universal-cookie";

import "./SubmissionActivities.scss";

function SubmissionComments({
  pendingComment,
  onCommentChange,
  onCommentAdd,
  submissionActivities,
  submissionId,
}) {
  const cookies = new Cookies();

  const scrollElement = React.useRef();
  const scrollToBottom = () => {
    scrollElement.current.scrollTop = scrollElement.current.scrollHeight;
  };

  const [userData, setuserData] = useState(null);
  useEffect(() => {
    const user = cookies?.get("user");
    setuserData(user);
    scrollToBottom();
  }, []);

  return (
    <div>
      <Element name="ActivitiesDiv">
        <div id="SubmissionActivities" ref={scrollElement}>
          {submissionActivities
            .slice(0)
            .reverse()
            .map((activity) => {
              const oldData = activity.data.replaceAll("\n", " <br/>");
              const data = oldData.split(" ");
              const link = data.find((word) => word.includes("http"));
              const linkIndex = data.findIndex((word) => word.includes(link));
              data[
                linkIndex
              ] = `<a class='text-blue-700' target="_blank" href="${link?.replace(
                "\n",
                ""
              )}">${link}</a>`;

              return (
                activity.activityType === "Add_comment" && (
                  <Comment
                    id={activity.id}
                    timestamp={activity.updatedAt}
                    username={activity.ownerName}
                    className={
                      userData?.name === activity.ownerName ? "blue" : ""
                    }
                    submissionId={submissionId}
                    key={activity.id}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.join(" "),
                      }}
                    />
                  </Comment>
                )
              );
            })}
        </div>
      </Element>
      <div id="AddComment" className="">
        <div>
          <Input
            multiline
            value={pendingComment}
            onChange={onCommentChange}
            className="comment-input"
            maxlength={TextLength.LONG}
          />
        </div>
        <div>
          <Button
            id="BtnAddComment"
            disabled={pendingComment === ""}
            onClick={onCommentAdd}
            content={translate("Add Comment")}
            wide
          />
        </div>
      </div>
    </div>
  );
}

export default SubmissionComments;
