import React from "react";
import { FooterActions, Box, Input, IconButton } from "../../../components";
import Select from "react-select";
import TextLength from "../../../utils/settings/text-length";
import "./NewGroup.scss";
import translate from "../texts";

const NewGroup = (
  newGroupName,
  onNewGroupNameChange,
  members,
  newGroupMembers,
  onNewGroupMembersAdd,
  expanded,
  collapseHandler,
  footerActions
) => (
  <div className="create-group-container">
    <Box dark>
      {!expanded && (
        <div
          className="create-new-group"
          onClick={collapseHandler}
          onKeyUp={collapseHandler}
          role="presentation"
        >
          <IconButton
            iconName="account-multiple-plus"
            content={translate("create_new_group")}
            onClick={() => {}}
          />
        </div>
      )}
      {expanded && (
        <div className="create-group-form">
          <div className="form-inputs">
            <Input
              value={newGroupName}
              onChange={onNewGroupNameChange}
              placeholder={translate("group_name")}
              maxlength={TextLength.MEDIUM}
            />
            <Select
              multi
              searchable
              clearable={false}
              valueKey="id"
              labelKey="email"
              placeholder={translate("group_members")}
              arrowRenderer={null}
              noResultsText={null}
              onChange={onNewGroupMembersAdd}
              options={members.map((member) => member.user)}
              value={newGroupMembers}
            />
          </div>
          <FooterActions actions={footerActions} />
        </div>
      )}
    </Box>
  </div>
);

export default NewGroup;
