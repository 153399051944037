// FIXME: Potential bad use of snake case, switch everything to camel case
import React from "react";
import Rating from "react-rating";
import { Icon } from "../../../components";
import BaseField from "./BaseField";

/**
 * @class StarRating
 * @extends BaseField
 * @description Linear Scale component for rating
 */
export default class StarRating extends BaseField {
  getArrayOfSize = (active) => {
    const arr = [];
    /* eslint-disable  no-plusplus */
    for (let i = 1; i <= this.props.settings.size; i++) {
      arr.push(this.starRatingElement(i, active));
    }
    return arr;
  };

  starRatingElement = (i, active) => (
    <span className="rating-item">
      {/* eslint-disable-next-line react/no-this-in-sfc */}
      <Icon
        name="circle"
        className={active ? "mdi-star" : "mdi-star-outline"}
        style={active && this.getColor()}
      />
    </span>
  );

  getColor = () => {
    const color =
      this.props.settings.color === "transparent"
        ? "#3273DC"
        : this.props.settings.color || "#3273DC";
    return { color };
  };

  /**
   * @override
   */
  render() {
    const { id, validation, size, previewMode } = this.props.settings;
    let classes = "control rating-field";
    classes = previewMode ? classes : `${classes} with-normal-field`;

    /* eslint-disable camelcase */
    return (
      <div className="field">
        {this.renderLabel()}
        <div className={classes}>
          {!previewMode ? (
            <input
              name={id}
              id={id}
              value={this.state.value}
              required={validation.required}
            />
          ) : null}
          <Rating
            fullSymbol={this.getArrayOfSize(true)}
            emptySymbol={this.getArrayOfSize()}
            start={0}
            stop={size}
            step={1}
            // direction prop should be exist. if removed will cause a UI issue when hover
            direction=""
            onChange={(value) => this.onChange({ target: { value } })}
            initialRating={parseInt(this.state.value, 10) || 0}
          />
        </div>
        {this.renderErrors()}
      </div>
    );
  }
}
