import React from "react";
import PropTypes from "prop-types";
import pagination from "pagination";
import { Link } from "react-router-dom";
import "./Paginator.scss";

/**
 * A pagination component that displays dynamic number of buttons.
 */

const Paginator = ({
  totalResult,
  itemsPerPage,
  url,
  currentPage,
  showArrows,
  currentSubmissionId,
}) => {
  const buttonsArray = [];
  let activeClass = "";

  currentPage = parseInt(currentPage, 10);
  totalResult = parseInt(totalResult, 10);
  itemsPerPage = parseInt(itemsPerPage, 10);

  const paginatorData = new pagination.SearchPaginator({
    prelink: "/",
    pageLinks: 10,
    current: currentPage,
    rowsPerPage: itemsPerPage,
    totalResult,
  }).getPaginationData();

  const { range } = paginatorData;

  const firstLink = (
    <div key={`first-${paginatorData.first}`}>
      <Link
        className="button"
        to={`${url}/page/${paginatorData.first}/${currentSubmissionId}`}
      >
        <span>{paginatorData.first}</span>
      </Link>
    </div>
  );
  const lastLink = (
    <div key={`last-${paginatorData.last}`}>
      <Link
        className="button"
        to={`${url}/page/${paginatorData.last}/${currentSubmissionId}`}
      >
        <span>{paginatorData.last}</span>
      </Link>
    </div>
  );

  const nextLink = (
    <div key={`next-${paginatorData.next}`}>
      <Link
        className="button"
        to={`${url}/page/${paginatorData.next}/${currentSubmissionId}`}
      >
        <span>&raquo;</span>
      </Link>
    </div>
  );

  const previousLink = (
    <div key={`previous-${paginatorData.previous}`}>
      <Link
        className="button"
        to={`${url}/page/${paginatorData.previous}/${currentSubmissionId}`}
      >
        <span>&laquo;</span>
      </Link>
    </div>
  );

  if (range.indexOf(paginatorData.first) === -1 && currentPage !== 1) {
    buttonsArray.push(firstLink);
  }

  if (paginatorData.previous != null && showArrows) {
    buttonsArray.push(previousLink);
  }

  if (range.indexOf(paginatorData.first) === -1 && currentPage !== 1) {
    const firstDots = (
      <div key="pagefirstdots">
        <div className="button is-static" disabled>
          ...
        </div>
      </div>
    );
    buttonsArray.push(firstDots);
  }

  range.map((page) => {
    if (page === currentPage) {
      activeClass = "is-info";
    } else {
      activeClass = "";
    }

    const link = (
      <div key={`page-${page}`}>
        <Link
          className={`button ${activeClass}`}
          to={`${url}/page/${page}/${currentSubmissionId}`}
        >
          <span>{page}</span>
        </Link>
      </div>
    );

    buttonsArray.push(link);

    return link;
  });

  if (
    range.indexOf(paginatorData.last) === -1 &&
    paginatorData.pageCount !== currentPage
  ) {
    const lastDots = (
      <div key="pageLastdots">
        <div className="button is-static">...</div>
      </div>
    );

    buttonsArray.push(lastDots);
  }

  if (paginatorData.next !== null && showArrows) {
    buttonsArray.push(nextLink);
  }

  if (paginatorData.last != null && range.indexOf(paginatorData.last) === -1) {
    buttonsArray.push(lastLink);
  }

  return <div className="paginator">{buttonsArray}</div>;
};

Paginator.propTypes = {
  totalResult: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  currentPage: PropTypes.number.isRequired,
  showArrows: PropTypes.bool,
  currentSubmissionId: PropTypes.string,
};

Paginator.defaultProps = {
  showArrows: false,
  currentSubmissionId: "",
};

export default Paginator;
