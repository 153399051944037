import * as types from "../types/settings.types";

const initialQuestionsState = {
  isFetching: false,
  fetched: false,
  questions: [],
  error: {},
  errors: {},
};

export default function questions(state = initialQuestionsState, action) {
  switch (action.type) {
    case types.UPSERT_QUESTION_FULFILLED:
      return {
        ...state,
        question: action.payload.data.question,
      };
    default:
      return state;
  }
}

const initialSettingsState = {
  settingsPage: "scoringCardPage",
};

export function settings(state = initialSettingsState, action) {
  switch (action.type) {
    case types.SET_SETTINGS_NAVIGATION:
      return {
        ...state,
        settingsPage: action.settingsPage,
      };
    default:
      return state;
  }
}
