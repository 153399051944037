import React, { Component } from "react";
import PropTypes from "prop-types";
import { Input } from "../../components";
import { debounce } from "lodash";
import translate from "./texts";

import "./NormalSearch.scss";

class NormalSearch extends Component {
  constructor(props) {
    super(props);
    this.throttledDoSearch = debounce(props.doSearch, 400, { leading: false });
  }

  state = {
    query: "",
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.clear) {
      this.setState({ query: "" });
    }
  }

  removeSpaces = (str) => str.replace(/ /g, "_").toLowerCase();

  getKeyId = (array, value) => {
    const field = array.filter(
      (item) => this.removeSpaces(item.name) === value
    )[0];
    return field ? field.id : undefined;
  };

  handleColonSeparation = (value) => {
    const { autocomplete, suggestions } = this.props;

    if (autocomplete && value.includes(":")) {
      const key = this.getKeyId(suggestions, value.split(":")[0]);
      const query = value.split(":")[1];
      return { key, query };
    }

    return { key: "", query: value };
  };

  updateSearchQueryState = (e, autosuggest) => {
    const value = autosuggest ? autosuggest.newValue : e.target.value;
    const { updateQuery } = this.props;
    const { query, key } = this.handleColonSeparation(value);

    this.setState({ query: value });
    // Check if the updateQuery function is passed so that
    // some times the search query needed in the container
    if (updateQuery) {
      updateQuery(query, key);
    }

    this.throttledDoSearch();
  };

  render() {
    const {
      onFocus,
      placeholder,
      doSearch,
      style,
      className,
      autocomplete,
      suggestions,
    } = this.props;
    const { query } = this.state;

    return (
      <div className={`search-input ${className}`} style={{ ...style }}>
        <Input
          onFocus={onFocus}
          iconName="magnify"
          placeholder={`${placeholder}`}
          inputType="text"
          value={query}
          onChange={this.updateSearchQueryState}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              doSearch();
            }
          }}
          autocomplete={autocomplete}
          suggestions={suggestions}
        />
      </div>
    );
  }
}

NormalSearch.propTypes = {
  doSearch: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  updateQuery: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  clear: PropTypes.bool,
  autocomplete: PropTypes.bool,
  suggestions: PropTypes.array,
};

NormalSearch.defaultProps = {
  onFocus: null,
  updateQuery: null,
  placeholder: translate("search"),
  style: {},
  className: "",
  clear: false,
  autocomplete: false,
  suggestions: [],
};

export default NormalSearch;
