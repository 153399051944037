import axios from "axios";
import * as types from "../types/program.types";

// TODO: Add tests for these actions and their reducer.

export function resetProgram() {
  return {
    type: types.RESET_PROGRAM,
  };
}

export function fetchProgram(programId) {
  return {
    type: types.FETCH_PROGRAM,
    payload: axios.get(
      `${process.env.REACT_APP_API_URL}/programs/${programId}`
    ),
  };
}

export function updateProgramName(programId, programData) {
  return {
    type: types.UPDATE_PROGRAM_NAME,
    payload: axios.put(
      `${process.env.REACT_APP_API_URL}/programs/${programId}`,
      {
        ...programData,
      }
    ),
  };
}

export function distributeSubmissions(programId, includeAssigned) {
  return {
    type: types.DISTRIBUTE_SUBMISSIONS,
    payload: axios.post(
      `${process.env.REACT_APP_API_URL}/programs/${programId}/distribute_submissions`,
      { includeAssigned }
    ),
  };
}

export function distributeNumberOfSubmissions(
  programId,
  includeAssigned,
  numberOfSubmissions,
  groupId
) {
  return {
    type: types.DISTRIBUTE_SUBMISSIONS,
    payload: axios.post(
      `${process.env.REACT_APP_API_URL}/programs/${programId}/distribute_number_of_submissions`,
      {
        numberOfSubmissions,
        includeAssigned,
        group: groupId,
      }
    ),
  };
}

export function sendEmail(
  programId,
  email,
  selectedSubmissionsIds,
  isAllApplicants,
  filters,
  query
) {
  email = {
    ...email,
    submissions_ids: selectedSubmissionsIds,
    isAllApplicants,
    filters,
    query,
  };

  return {
    type: types.SEND_EMAIL,
    payload: axios.post(
      `${process.env.REACT_APP_API_URL}/programs/${programId}/submissions/send_email`,
      email
    ),
  };
}

export function assignSubmissions(
  programId,
  assigneeId,
  assigneeType,
  submissionIds,
  isAllSubmissions,
  filters,
  query
) {
  return {
    type: types.ASSIGN_SUBMISSIONS,
    payload: axios.post(
      `${process.env.REACT_APP_API_URL}/programs/${programId}/assign_submissions`,
      {
        assigneeId,
        assigneeType,
        submissionIds,
        isAllSubmissions,
        filters,
        query,
      }
    ),
  };
}

export function fetchInputSources(programId) {
  return {
    type: types.FETCH_INPUT_SOURCES,
    payload: axios.get(
      `${process.env.REACT_APP_API_URL}/programs/${programId}/input_sources`
    ),
  };
}
