import React, { Component } from "react";
import { Button } from "../../components";
import PropTypes from "prop-types";
import ObjectID from "bson-objectid";
import Field from "./Field";
import translate from "./texts";
import fieldsDefinitions from "./fields-definitions";
import "./CreateField.scss";

const FIELDS_LIST = Object.values(fieldsDefinitions);

class CreateField extends Component {
  state = {
    newField: { ...FIELDS_LIST[0] },
    addingNewField: false,
  };

  onSave = (fld) => {
    this.setState({ newField: { ...FIELDS_LIST[0] } });
    this.closeNewFieldView();
    if (!fld.id) {
      fld.id = this.generateID();
    }
    this.props.saveField(fld);
  };

  openNewFieldView = () => {
    this.props.removeFromEditList();

    const { newField } = this.state;
    newField.schema.id = this.generateID();
    this.setState({ addingNewField: true, newField });
  };

  closeNewFieldView = () => {
    this.setState({
      addingNewField: false,
      newField: { ...FIELDS_LIST[0] },
    });
  };

  generateID = () => new ObjectID().str;

  render = () => {
    const { newField, addingNewField } = this.state;
    return (
      <div id="create-field-container">
        {addingNewField && newField && (
          <Field
            field={newField}
            onSave={this.onSave}
            closeFieldView={this.closeNewFieldView}
            onFieldTypeChange={this.generateID}
            isNew
            fieldsList={this.props.fieldsList}
          />
        )}
        <div className="add-new-field-button-container">
          <Button
            flat
            wide
            iconName="plus-circle"
            onClick={this.openNewFieldView}
            content={translate("addNewField")}
          />
        </div>
      </div>
    );
  };
}

CreateField.propTypes = {
  saveField: PropTypes.func.isRequired,
  removeFromEditList: PropTypes.func.isRequired,
  fieldsList: PropTypes.array.isRequired,
};

export default CreateField;
