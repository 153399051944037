import * as types from "../types/program-members-modal.types";

export function toggleProgramMembersModal() {
  return {
    type: types.TOGGLE_PROGRAM_MEMBERS_MODAL,
  };
}

export function displayMembersInvitation() {
  return {
    type: types.DISPLAY_MEMBERS_INVITATION,
  };
}

export function displayMembersManagement() {
  return {
    type: types.DISPLAY_MEMBERS_MANAGEMENT,
  };
}
