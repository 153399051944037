import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    Save: "Save",
    "saving ...": "saving ...",
    "Program name": "Program name",
    Name: "Name",
    all_programs: "All programs",
    overview: "Overview",
    submissions: "Submissions",
    fields: "Fields",
    formBuilder: "Form",
    Notifications: "Notifications",
    "All programs": "All programs",
    Reports: "Reports",
    "All submissions": "All submissions",
  },
  ar: {
    Save: "Save Arabic",
    "saving ...": "saving ... Arabic",
    "Program name": "اسم البرنامج",
    Name: "الاسم",
    all_programs: "جميع البرامج",
    overview: "نظرة عامة",
    submissions: "المشاركات",
    fields: "الحقول",
    formBuilder: "نموذج المشاركة",
    Notifications: "الاشعارات",
    "All programs": "جميع البرامج",
    Reports: "التقارير",
    "All submissions": "جميع المشاركات",
  },
});

const translate = (string) => strings[string];

strings.setLanguage(locale || "en");
export default translate;
