import React from "react";
import AvatarPlaceHolder from "../../components/assets/images/avatar.svg";
import "./Avatar.scss";

const Avatar = () => (
  <div className="avatar">
    <img src={AvatarPlaceHolder} alt="Avatar" />
  </div>
);

export default Avatar;
