import React from "react";
import PropTypes from "prop-types";
import { Divider } from "../../components";
import "./Activity.scss";

const Activity = ({ timestamp, children }) => (
  <div className="activity">
    <Divider caption={timestamp} />
    <div className="activity-body">{children}</div>
  </div>
);

Activity.propTypes = {
  timestamp: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default Activity;
