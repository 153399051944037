import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    "Add Comment": "Add Comment",
    next: "Next",
    Label: "Label",
    Send: "Send",
    "sending ...": "sending ...",
    "New Email": "New Email",
    assign: "assign",
    "Funnel Score": "Funnel Score",
    status: "Status",
    labels: "Labels",
    score: "Score",
    reviewed_submission_assignment: "You can't assign reviewed submissions.",
    assigning_submissions: "Assigning Submissions",
    assigning_in_progress_submissions:
      'There is submissions with status "in progress", Assign them any way?',
    submissions_selected: "Submissions selected:",
    "New Email Subtitle":
      "Submission owner will recieve an email of the below content",
    all_applicants: "All applicants",
    "Filter and Score": "Filter and Score",
    Comments: "Comments",
    score_overview: "Score Overview",
    Subject: "Subject",
    Content: "Content",
    Recipients: "Recipients",
    Close: "Close",
    out_of: "out of",
    createdAt: "Submitted At",
    delete: "Delete",
    delete_message: "Are you sure you want to delete this submission?",
    reason_placeholder:
      "e.g. reason for answer, or more information about your evaluation",
    delete_label_title: "Confirm deleting the label",
    delete_label_warning:
      "Deleting the label will also remove it from all the submissions using it.  If you inteded to just remove it from this submission, click on the label itself.",
    question_mark: "?",
    show_empty_fields: "Show Empty Fields",
    hide_empty_fields: "Hide Empty Fields",
    go_to_submission: "Go To Submission",
    reviewed: "Reviewed",
    inProgress: "In-Progress",
    clear_evaluation_prompt:
      "Are you sure you want to clear the evaluation for this submission?",
    clear_evaluation: "Clear evaluation",
    evaluation_error: "An error has occurred while submiting the evaluation",
    externalStatus: "External Status",
    HistoryDate: "History Date",
    "Previous Scores": "Previous Scores",
    "Average score": "Average score",
    score: "Score",
    "Max Previous Score": "Max Previous Score",
  },
  ar: {
    "Add Comment": "إضافة تعليق",
    next: " التالي",
    Label: "وسم",
    "Funnel Score": "درجة المرحلة",
    status: "الحالة",
    labels: "الوسوم",
    score: "درجة",
    export: "تصدير",
    Send: "أرسل",
    "sending ...": "إرسال ...",
    "New Email": "رسالة جديدة",
    Recepients: "المستقبلون",
    assign: "تعيين",
    reviewed_submission_assignment: "لا يمكن تعين مشاركة مصححة.",
    assigning_submissions: "تعيين المشاركات",
    assigning_in_progress_submissions:
      "يوجد مشاركات قيد التصحيح، متابعة التعيين؟",
    submissions_selected: "المشاركات المحدَّدَة:",
    "New Email Subtitle":
      "صاحب المشاركه سيصله بريد الكتروني بمحتوى الرساله بالاسفل",
    all_applicants: "جميع المشاركين",
    "Filter and Score": "تقييم وترشيح",
    Comments: "تعليقات",
    score_overview: "نظرة عامة",
    Scores: "التقييم",
    "Select Label": "اختر وسمًا",
    Subject: "الموضوع",
    Content: "المحتوى",
    Recipients: "المرسل إليهم",
    Close: "إغلاق",
    Advanced: "بحث متقدّم",
    out_of: "من",
    createdAt: "تاريخ ووقت المشاركة",
    delete: "حذف",
    delete_message: "هل أنت متأكد من رغبتك بالحذف؟",
    reason_placeholder: "مثال، سبب اختيار الإجابة، أو تفاصيل إضافية عن تقييمك",
    delete_label_warning:
      "عند حذف هذه الوسم، سيتم أيضاً إزالته من جميع المشاركات الموسومة به. إذا كنت تقصد أن تزيل الوسم من هذه المشاركة فقط، اضغط على الوسم نفسه.",
    delete_label_title: "هل أنت متأكد من حذف الوسم",
    question_mark: "؟",
    show_empty_fields: "إظهار الحقول الفارغة",
    hide_empty_fields: "إخفاء الحقول الفارغة",
    go_to_submission: "اذهاب إلى المشاركة",
    reviewed: "تمت مراجعتها",
    inProgress: "متقدم",
    clear_evaluation_prompt: "هل أنت متأكد من رغبتك بحذف في حذف التقيم؟",
    clear_evaluation: "حذف التقيم",
    evaluation_error: "حدث خطأ اثناء التقيم",
    externalStatus: "الحالة الخارجية",
    HistoryDate: "تواريخ السجلات",
    "Previous Scores": "التقيمات السابقة",
    "Average score": "متوسط التقييم",
    score: "التقييم",
    "Max Previous Score": "أعلى تقييم سابق",
  },
});

strings.setLanguage(locale || "en");
// strings.setLanguage( 'en');

const translate = (string) => strings[string] || string;

export default translate;
