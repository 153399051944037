import React from "react";
import PropTypes from "prop-types";
import { Icon } from "../../components";
import { isImage, isPdf, isVideo } from "../../utils/file-extensions";
import { toast } from "react-toastify";
import translate from "../../texts";
import "./Thumbnail.scss";

const Thumbnail = ({ src, width, height, onClick, cover }) => {
  // TODO: invert fileName color to be white on type image.
  const styles = {
    width: `${width}px`,
    height: `${height}px`,
    backgroundSize: cover ? "cover" : "contain",
    backgroundPosition: "center",
    backgroundColor: "#f2f3f4",
  };
  let fileIcon = "link";
  let className = "";
  const CopyToClipboard = (url) => {
    toast(translate("copy_success"));
    navigator.clipboard.writeText(url);
  };
  if (isImage(src)) {
    styles.backgroundImage = `linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6)), url("${src}")`;
    fileIcon = "image";
    className = "bright";
  }
  if (isPdf(src)) {
    fileIcon = "file-pdf";
    className = "";
  }
  if (isVideo(src)) {
    fileIcon = "video";
    className = "";
  }
  return (
    <div
      className={`thumbnail ${className}`}
      style={styles}
      onClick={(e) => {
        if (e.target === e.currentTarget) onClick(src);
      }}
      onKeyUp={() => onClick(src)}
      role="presentation"
    >
      <button
        type="button"
        className="copy"
        onClick={() => {
          CopyToClipboard(src);
        }}
      >
        <Icon name="link" className="copy-button" />
      </button>
      <Icon className="thumbnail-file-icon" name={fileIcon} />
    </div>
  );
};

Thumbnail.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
  cover: PropTypes.bool,
};

Thumbnail.defaultProps = {
  cover: true,
  width: 180,
  height: 140,
  onClick: () => {},
};

export default Thumbnail;
