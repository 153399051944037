import axios from "axios";
import fetchPrivileges from "../actions/auth.actions";
import * as types from "../types/workspaces.types";

export function fetchWorkspaces(archived = false, filter = "") {
  const f = filter ? `&filter=${filter}` : "";
  return {
    type: types.FETCH_WORKSPACES,
    payload: axios.get(
      `${process.env.REACT_APP_API_URL}/workspaces?archived=${archived}${f}`
    ),
  };
}

export function fetchPrograms(workspaceId, archived = false, filter = "") {
  const f = filter ? `&filter=${filter}` : "";
  return {
    type: types.FETCH_PROGRAMS,
    payload: axios.get(
      `${process.env.REACT_APP_API_URL}/workspaces/${workspaceId}/programs?archived=${archived}${f}`
    ),
  };
}

export function createProgram(program) {
  return {
    type: types.SAVE_PROGRAM,
    payload: axios.post(`${process.env.REACT_APP_API_URL}/programs`, {
      program,
    }),
  };
}

export function archiveProgram(programId) {
  return {
    type: types.ARCHIVE_PROGRAM,
    payload: axios.put(
      `${process.env.REACT_APP_API_URL}/programs/${programId}/archive`
    ),
  };
}

export function unArchiveProgram(programId) {
  return {
    type: types.UNARCHIVE_PROGRAM,
    payload: axios.put(
      `${process.env.REACT_APP_API_URL}/programs/${programId}/unarchive`
    ),
  };
}

export function leaveProgram(programId) {
  return {
    type: types.LEAVE_PROGRAM,
    payload: axios.post(
      `${process.env.REACT_APP_API_URL}/programs/${programId}/leave`
    ),
  };
}

export function fetchTemplates() {
  return {
    type: types.FETCH_TEMPLATES,
    payload: axios.get(`${process.env.REACT_APP_API_URL}/templates`),
  };
}

export { fetchPrivileges };
