import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    filters: "filters",
    actions: "../../actions",
    action: "Action",
    name: "Name",
    apply: "Apply",
    edit: "Edit",
    delete: "Delete",
    "add-more": "Add More",
    search: "search",
  },
  ar: {
    filters: "الفلاتر",
    actions: "الإجراءات",
    action: "الإجراء",
    name: "الاسم",
    apply: "تفعيل",
    edit: "تعديل",
    delete: "حذف",
    "add-more": "إضافة المزيد",
    search: "بحث",
  },
});

strings.setLanguage(locale || "en");

const translate = (string) => strings[string];

export default translate;
