/* eslint-disable no-unused-vars */

import * as types from "../types/prompt-message.types";
import translate from "../layouts/PromptMessage/texts";

const initialState = {
  activated: false,
  title: "",
  message: "",
  confirmationHandler: () => {},
  buttonContent: translate("confirm"),
  disabledButton: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.ACTIVATE_PROMPT_MESSAGE:
      return {
        ...initialState,
        ...action.payload,
        activated: true,
      };
    case types.DEACTIVATE_PROMPT_MESSAGE:
      return initialState;
    default:
      return state;
  }
}
