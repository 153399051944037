import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    Programs: "Programs",
    many_programs: " PROGRAMS",
    program: "1 PROGRAM",
    Save: "Save",
    "saving ...": "saving ...",
    Name: "Name",
    Close: "Close",
    "New Program": "New Program",
    "Search Program": "Search for a program",
    template_title: "Template Title",
    template_source_sentence: "a template based on the porgram",
    create_button: "Create",
    all_programs: "All programs",
    archived_programs: "Archived programs",
    template_editor_placeholder:
      "You can express what this template provides. Select text and then choose how you want to format it.",
    create_program_title: "Create New Program",
    name_your_program: "Name your program",
    back: "Back",
    create: "Create Program",
    all_templates: "All Templates",
    use_template: "Use This Template",
    archive: "Archive",
    clone_as_template: "Clone as template",
    untitled: "Untitled",
    blank: "Blank",
    blank_description:
      "Start with new blank program and customize your options",
    creating_program_is_in_progress: "Creating program is in progress",
    Workspaces: "Workspaces",
    no_programs_found: "No programs found",
    leave: "Leave the program",
    leave_prompt: "Are you sure you want to leave this program?",
    no_workspace_found: "No workspace found",
  },
  ar: {
    Programs: "البرامج",
    many_programs: " برنامج ",
    program: "برنامج واحد",
    Save: "حفظ",
    "saving ...": "جارى الحفظ",
    Name: "الاسم",
    Close: "إغلاق",
    "New Program": "برنامج جديد",
    "Search Program": "ابحث عن برنامج",
    template_title: "اسم النموذج",
    template_source_sentence: "قالب مبني على برنامج",
    create_button: "إنشاء",
    all_programs: "جميع البرامج",
    archived_programs: "أرشيف البرامج",
    template_editor_placeholder:
      "عبّر عن ما يوفره هذا القالب.  بإمكانك تحديد النص وتنسيقه كما تريد.",
    create_program_title: "انشئ برنامج جديد",
    name_your_program: "اختر اسماً لبرنامجك",
    back: "رجوع",
    create: "انشئ البرنامج",
    all_templates: "جميع النماذج",
    use_template: "استخدم هذا النموذج",
    archive: "انقل الى الأرشيف",
    clone_as_template: "نسخ كقالب",
    untitled: "بدون عنوان",
    blank: "برنامج فارغ",
    blank_description: "ابدأ ببرنامج فارغ و قم بتخصيص خياراتك",
    creating_program_is_in_progress: " جاري إنشاء البرنامج ",
    Workspaces: "مساحات العمل",
    no_programs_found: "لا يوجد برامج",
    leave: "اخرج من البرنامج",
    leave_prompt: "هل أنت متأكد تريد الخروج من هذا البرنامج؟",
    no_workspace_found: "لا يوجد مساحات",
  },
});

strings.setLanguage(locale || "en");

const translate = (string) => strings[string];

export default translate;
